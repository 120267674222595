import {
  Autocomplete,
  Button,
  Chip,
  Divider,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { retrieveAllOffersApi } from "../../../components/api/OfferApiService";
import { createOrderApi } from "../../../components/api/OrderApiService";
import {
  retrieveAllSamplesApi,
  retrievePurposeFilteredSamplesApi,
} from "../../../components/api/SampleApiService";
import {
  companies,
  currencies,
  incoterms,
  packagingTypes,
  paymentTerm,
  portsOfDischarge,
  portsOfLoading,
  recordStatus,
  sampleStatus,
  status,
  units,
} from "../../../utils/menu-items";

const AddCustomerOrder = (props) => {
  useEffect(
    () =>
      setAddViewStates({
        ...addViewStates,
        orderType: "CUSTOMER",
        finalPriceCurrency: "USD",
        orderYear: new Date().getFullYear().toString(),
        orderGroupNumber: newOrderGroupNumber,
      }),
    [],
  );

  const [samples, setSamples] = useState([]);
  const [offers, setOffers] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [selectedFirstSample, setSelectedFirstSample] = useState(null);
  const [selectedPaymentTerm, setSelectedPaymentTerm] = useState(null);
  const [selectedPortOfDischarge, setSelectedPortOfDischarge] = useState(null);
  const [selectedPortOfLoading, setSelectedPortOfLoading] = useState(null);
  const currentYear = new Date().getFullYear().toString();
  const [filteredSamples, setFilteredSamples] = useState([]);

  function retrieveSamples() {
    retrieveAllSamplesApi()
      .then((response) => {
        setSamples(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveFilteredSamples(samplePurpose) {
    retrievePurposeFilteredSamplesApi(samplePurpose)
      .then((response) => {
        setFilteredSamples(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveOffers() {
    retrieveAllOffersApi("CUSTOMER")
      .then((response) => {
        setOffers(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  const navigate = useNavigate();
  const {
    setAddViewStates,
    addViewStates,
    rows,
    setRows,
    setAddView,
    newOrderGroupNumber,
  } = props;

  return (
    <>
      <Stack direcion="column">
        <Typography variant="body1" mb={2}>
          Create New San Group Sales Order
        </Typography>
        <Grid container xs={12} spacing="8px">
          <Grid item xs={12} sm={3}>
            <Typography variant="body2">Order Year</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              defaultValue={currentYear}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  orderYear: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="body2">Company</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              select
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  company: e.target.value,
                })
              }
            >
              {companies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="body2"> Sales Order Id</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  salesOrderId: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="body2">Select SG Approval Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              select
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  approvalStatus: e.target.value,
                })
              }
            >
              {status.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">Order Number</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              defaultValue={newOrderGroupNumber}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  orderGroupNumber: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">Select Record Status</Typography>
            <TextField
              id="outlined-select-status"
              select
              variant="outlined"
              size="small"
              fullWidth
              label={"ACTIVE"}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  recordStatus: e.target.value,
                })
              }
            >
              {recordStatus.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">Select Contract Value Date</Typography>
            <TextField
              size="small"
              fullWidth
              variant="outlined"
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  contractValueDate: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">Select Edit Status</Typography>
            <TextField
              id="outlined-select-status"
              select
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  editingPermission: e.target.value,
                })
              }
            >
              {status.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">Select Issue Date</Typography>
            <TextField
              size="small"
              fullWidth
              variant="outlined"
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  operationDate: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">Contract Link</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  contractLink: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Divider
              sx={{
                "&.MuiDivider-root": {
                  margin: "10px 0",
                  borderColor: "#1890ff",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant="body2">Select Offer</Typography>
            <Autocomplete
              options={offers}
              sx={{ maxWidth: "300px", width: "100%" }}
              getOptionLabel={(option) =>
                `Id: ${option?.id || ""}, Remark: ${option?.remark || ""}`
              }
              value={selectedOffer}
              onChange={(event, newValue) => {
                setSelectedOffer(newValue);
                setAddViewStates({
                  ...addViewStates,
                  offerId: newValue ? newValue.id : "",
                });
              }}
              onOpen={retrieveOffers}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" size="small" />
              )}
              renderTags={() => null}
            />
            {selectedOffer && (
              <Box mt={2}>
                <Chip
                  label={`Id: ${selectedOffer?.id || ""}, Remark: ${
                    selectedOffer?.remark || ""
                  }`}
                  onDelete={() => setSelectedOffer(null)}
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2"> Net Weight Per Carton</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  netWeightPerCase: e.target.value,
                  volume: e.target.value * addViewStates?.numberOfCases,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2"> Number Of Cartons</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  numberOfCases: e.target.value,
                  volume: e.target.value * addViewStates?.netWeightPerCase,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2"> Volume</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              value={addViewStates?.volume}
              fullWidth
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  volume: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Select Volume Unit</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              defaultValue={"KG"}
              select
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  volumeUnit: e.target.value,
                })
              }
            >
              {units.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Select Packaging Type</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              select
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  packaging: e.target.value,
                })
              }
            >
              {packagingTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2"> Final Price</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  finalPrice: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Select Final Price Currency</Typography>
            <TextField
              id="outlined-select-status"
              select
              variant="outlined"
              size="small"
              fullWidth
              defaultValue="USD"
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  finalPriceCurrency: e.target.value,
                })
              }
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2"> Quantity (KG)</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  quantity: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Incoterm</Typography>
            <TextField
              id="outlined-select-status"
              select
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  incoterm: e.target.value,
                })
              }
            >
              {incoterms.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Payment Term</Typography>
            <Autocomplete
              options={paymentTerm}
              getOptionLabel={(option) => (option ? option.label : "")}
              value={selectedPaymentTerm}
              onChange={(event, newValue) => {
                setSelectedPaymentTerm(newValue);
                setAddViewStates({
                  ...addViewStates,
                  paymentTerm: newValue ? newValue.value : "",
                });
              }}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              )}
              renderTags={() => null}
            />
            {selectedPaymentTerm && (
              <Box mt={2}>
                <Chip
                  label={selectedPaymentTerm.label}
                  onDelete={() => {
                    setSelectedPaymentTerm(null);
                    setAddViewStates({
                      ...addViewStates,
                      paymentTerm: "",
                    });
                  }}
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Port Of Discharge</Typography>
            <Autocomplete
              options={portsOfDischarge}
              getOptionLabel={(option) => (option ? option.label : "")}
              value={selectedPortOfDischarge}
              onChange={(event, newValue) => {
                setSelectedPortOfDischarge(newValue);
                setAddViewStates({
                  ...addViewStates,
                  portOfDischarge: newValue ? newValue.value : "",
                });
              }}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              )}
              renderTags={() => null}
            />
            {selectedPortOfDischarge && (
              <Box mt={2}>
                <Chip
                  label={selectedPortOfDischarge.label}
                  onDelete={() => {
                    setSelectedPortOfDischarge(null);
                    setAddViewStates({
                      ...addViewStates,
                      portOfDischarge: "",
                    });
                  }}
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Port Of Loading</Typography>
            <Autocomplete
              options={portsOfLoading}
              getOptionLabel={(option) => (option ? option.label : "")}
              value={selectedPortOfLoading}
              onChange={(event, newValue) => {
                setSelectedPortOfLoading(newValue);
                setAddViewStates({
                  ...addViewStates,
                  portOfLoading: newValue ? newValue.value : "",
                });
              }}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              )}
              renderTags={() => null}
            />
            {selectedPortOfLoading && (
              <Box mt={2}>
                <Chip
                  label={selectedPortOfLoading.label}
                  onDelete={() => {
                    setSelectedPortOfLoading(null);
                    setAddViewStates({
                      ...addViewStates,
                      portOfLoading: "",
                    });
                  }}
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Select ETD</Typography>
            <TextField
              size="small"
              fullWidth
              variant="outlined"
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  estimatedTimeOfDeparture: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Select ETA</Typography>
            <TextField
              size="small"
              fullWidth
              variant="outlined"
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  estimatedTimeOfArrival: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Shipment Schedule</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  shipmentSchedule: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2"> Shipping Term</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  shippingTerm: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Select Redrawn Sample</Typography>
            <Autocomplete
              options={filteredSamples}
              getOptionLabel={(option) =>
                `ID: ${option?.id || ""}, Remark: ${
                  option?.remark || ""
                }, Buying Grade: ${option?.item?.buyingGrade || ""}`
              }
              value={selectedFirstSample}
              onChange={(event, newValue) => {
                setSelectedFirstSample(newValue);
                setAddViewStates({
                  ...addViewStates,
                  firstSampleId: newValue ? newValue.id : "",
                });
              }}
              onOpen={() => retrieveFilteredSamples("Redrawn Sample")}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              )}
              renderTags={() => null}
            />
            {selectedFirstSample && (
              <Box mt={2}>
                <Chip
                  label={`ID: ${selectedFirstSample?.id || ""}, Remark: ${
                    selectedFirstSample?.remark || ""
                  }, Buying Grade: ${
                    selectedFirstSample?.item?.buyingGrade || ""
                  }`}
                  onDelete={() => {
                    setSelectedFirstSample(null);
                    setAddViewStates({
                      ...addViewStates,
                      firstSampleId: "",
                    });
                  }}
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">
              Select Redrawn Sample Status
            </Typography>
            <TextField
              id="outlined-select-status"
              select
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  firstSampleStatus: e.target.value,
                })
              }
            >
              {sampleStatus.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Remark</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  remark: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Comment</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  comment: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2"> Province</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  province: e.target.value,
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Description Of Goods</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  descriptionOfGoods: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Customer Reference</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  customerReference: e.target.value,
                })
              }
            />
          </Grid>
        </Grid>
        <Stack direction="row" spacing={1} mt={1}>
          <Button variant="outlined" onClick={() => setAddView(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={
              !(selectedOffer === null) && !selectedOffer?.stakeholder?.code
            }
            onClick={() => {
              const newId = Math.floor(Math.random() * 100000);
              setAddViewStates({ ...addViewStates, id: newId });
              setRows([addViewStates, ...rows]);
              setAddView(false);

              createOrderApi(addViewStates)
                .then((response) => {
                  toast.success("Sales order added successfully.");
                  window.location.reload();
                  navigate("/sales-order");
                })
                .catch((error) => {
                  toast.error("Something went wrong.");
                  console.log(error.response.data);
                });
            }}
          >
            Add
          </Button>
        </Stack>
        <br></br>
        {!(selectedOffer === null) && !selectedOffer?.stakeholder?.code && (
          <Typography variant="body1" color="error">
            No Customer SAP Code: Customer is not registered in SAP. Please
            register the customer to proceed with creating the order.
          </Typography>
        )}
      </Stack>
    </>
  );
};

export default AddCustomerOrder;
