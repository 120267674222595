import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Autocomplete,
  Button,
  ButtonGroup,
  Chip,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { retrieveAllItemsApi } from "../../../components/api/ItemApiService";
import { updateOfferApi } from "../../../components/api/OfferApiService";
import { createOrderApi } from "../../../components/api/OrderApiService";
import ClipLoader from "react-spinners/ClipLoader";
import {
  createSampleApi,
  retrieveAllSamplesApi,
  retrievePurposeFilteredSamplesApi,
  retrieveSampleByIdApi,
  updateSampleApi,
} from "../../../components/api/SampleApiService";
import { retrieveAllStakeholdersApi } from "../../../components/api/StakeholderApiService";
import {
  companies,
  currencies,
  incoterms,
  packagingTypes,
  paymentTerm,
  portsOfDischarge,
  portsOfLoading,
  recordStatus,
  samplePurposes,
  sampleStatus,
  units,
} from "../../../utils/menu-items";

const CreateOrder = (props) => {
  const { setCreateOrder, createOrder } = props;
  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState({
    id: 0,
    offerId: createOrder?.data?.id,
    orderType: "VENDOR",
    volumeUnit: "KG",
    company: createOrder?.data?.company || "",
    finalPriceCurrency: createOrder?.data?.firstOfferPriceCurrency || "",
    costId: createOrder?.data?.estimatedCostId,
    itemId: createOrder?.data?.itemId,
    item: createOrder?.data?.item,
    firstSampleId: createOrder?.data?.firstSampleId,
    firstSampleStatus: createOrder?.data?.firstSampleStatus,
    secondSampleId: createOrder?.data?.secondSampleId,
    statusDeadline: null,
    paymentTerm: createOrder?.data?.paymentTerm,
    portOfDischarge: createOrder?.data?.portOfDischarge,
    portOfLoading: createOrder?.data?.portOfLoading,
    incoterm: createOrder?.data?.incoterm,
    packaging: createOrder?.data?.packaging,
    netWeightPerCase: createOrder?.data?.netWeightPerCase,
    numberOfCases: createOrder?.data?.numberOfCases,
    volume: createOrder?.data?.volume,
    approvalStatus: "Not Approved",
    finalPrice: createOrder?.data?.finalOfferPrice,
  });

  const [samples, setSamples] = useState([]);
  const [selectedSecondSample, setSelectedSecondSample] = useState(null);
  const [selectedPaymentTerm, setSelectedPaymentTerm] = useState(null);
  const [selectedPortOfDischarge, setSelectedPortOfDischarge] = useState(null);
  const [selectedPortOfLoading, setSelectedPortOfLoading] = useState(null);
  const [isNewSampleOpen, setIsNewSampleOpen] = useState(false);
  const [sample, setSample] = useState([]);
  const [items, setItems] = useState([]);
  const [selectedOrigin, setSelectedOrigin] = useState("");
  const [stakeholders, setStakeholders] = useState([]);
  const [isEditSampleOpen, setIsEditSampleOpen] = useState(false);
  const [vendors, setVendors] = useState([]);
  const options = ["New", "Edit"];
  const anchorRef2 = React.useRef(null);
  const [selectedIndex2, setSelectedIndex2] = useState(0);
  const [open2, setOpen2] = useState(false);
  const [filteredSamples, setFilteredSamples] = useState([]);
  const [isPriceMismatchDialogOpen, setIsPriceMismatchDialogOpen] =
    useState(false);

  const processCreateOrder = async (currentOrder) => {
    /*
    const newTab = window.open("", "_blank");
    updateOffer(currentOrder);
    createOrderApi(orderData)
      .then((response) => {
        toast.success("Purchase order added successfully.");
        if (newTab) {
          newTab.location.href = "/purchase-order-view";
        }
        navigate("/vendor-offer-view");
        window.location.reload();
      })
      .catch((error) => {
        toast.error("Something went wrong. ", error.response.data);
        console.log(error.response.data);
      });
    setCreateOrder({ ...createOrder, data: orderData, visible: false });
    */
    setLoading(true);
    const newTab = window.open("", "_blank");
    try {
      updateOffer(currentOrder);
      const response = await createOrderApi(orderData);

      toast.success("Purchase order added successfully.");

      if (newTab) {
        newTab.location.href = "/purchase-order-view";
      }

      navigate("/vendor-offer-view");
      // Reload necessary parts without a full page reload
      // Optionally update state here to refresh the view
    } catch (error) {
      toast.error("Something went wrong. ", error.response?.data);
      console.error(error.response?.data);
    } finally {
      setLoading(false);
      setCreateOrder({ ...createOrder, data: orderData, visible: false });
    }
  };

  const handleCreateButtonClick = () => {
    if (createOrder?.data?.finalOfferPrice !== orderData?.finalPrice) {
      setIsPriceMismatchDialogOpen(true);
    } else {
      processCreateOrder(createOrder);
    }
  };

  const handleUpdatePriceAndProceed = () => {
    const updatedOrder = {
      ...createOrder,
      data: {
        ...createOrder?.data,
        finalOfferPrice: orderData?.finalPrice,
      },
    };

    setCreateOrder(updatedOrder);
    setIsPriceMismatchDialogOpen(false);
    processCreateOrder(updatedOrder);
  };

  const handleProceedWithoutUpdatingPrice = () => {
    setIsPriceMismatchDialogOpen(false);
    processCreateOrder(createOrder);
  };

  const handleClose2 = (event) => {
    if (anchorRef2.current && anchorRef2.current.contains(event.target)) {
      return;
    }

    setOpen2(false);
  };
  const handleClick2 = () => {
    if (selectedIndex2 === 0) {
      setIsNewSampleOpen(true);
      setIsEditSampleOpen(false);
    } else {
      setIsEditSampleOpen(true);
      setIsNewSampleOpen(false);
      retrieveSampleById(orderData?.secondSampleId);
    }
  };
  const handleToggle2 = () => {
    setOpen2((prevOpen) => !prevOpen);
  };
  const handleMenuItemClick2 = (event, index) => {
    setSelectedIndex2(index);
    setOpen2(false);
  };

  useEffect(() => {
    if (
      createOrder?.data?.status === "First Refusal" ||
      createOrder?.data?.status === "Optioned"
    ) {
      setCreateOrder({
        ...createOrder,
        data: {
          ...createOrder?.data,
          status: "Available",
          statusDeadline: null,
        },
      });
    }
  }, []);

  function retrieveFilteredSamples(samplePurpose) {
    retrievePurposeFilteredSamplesApi(samplePurpose)
      .then((response) => {
        setFilteredSamples(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }
  function retrieveItems() {
    retrieveAllItemsApi()
      .then((response) => {
        setItems(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }
  function retrieveVendors() {
    retrieveAllStakeholdersApi("VENDOR")
      .then((response) => {
        setVendors(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }
  function retrieveSampleById(id) {
    retrieveSampleByIdApi(id)
      .then((response) => {
        setSample(response?.data);
        console.log(id, response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }
  function retrieveStakeholders() {
    Promise.all([
      retrieveAllStakeholdersApi("VENDOR"),
      retrieveAllStakeholdersApi("CUSTOMER"),
    ])
      .then(([vendorResponse, customerResponse]) => {
        setStakeholders([...vendorResponse?.data, ...customerResponse?.data]);
      })
      .catch((error) => console.log(error.response?.data));
  }
  function updateOffer(createOrder) {
    updateOfferApi(createOrder?.data?.id, createOrder?.data)
      .then((response) => {
        window.location.reload();
        navigate("/vendor-offer-view");
      })
      .catch((error) => {
        toast.error("Something went wrong. ", error.response.data);
        console.log(error.response.data);
      });
  }
  function retrieveSamples() {
    retrieveAllSamplesApi()
      .then((response) => {
        setSamples(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  const navigate = useNavigate();

  return (
    <Grid container>
      <Grid item xs={12} style={{ position: "relative" }}>
        <Typography
          variant="h3"
          align="center"
          style={{
            color: "#777f87",
            position: "absolute",
            top: "-10px",
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "#fafafb",
            padding: "0 10px",
          }}
        >
          Offer Details
        </Typography>
      </Grid>
      <Grid
        container
        spacing="12px"
        style={{
          border: "1px solid #dbd9d9",
          borderRadius: "20px",
          marginTop: "10px",
          marginBottom: "10px",
          padding: "20px",
          boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Offer Number</Typography>
          <TextField
            variant="outlined"
            size="small"
            defaultValue={createOrder?.data?.offerGroupNumber}
            fullWidth
            onChange={(e) =>
              setOrderData({ ...orderData, offerGroupNumber: e.target.value })
            }
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Order Number</Typography>
          <TextField
            variant="outlined"
            size="small"
            defaultValue={createOrder?.data?.orderGroupNumber}
            fullWidth
            onChange={(e) =>
              setOrderData({ ...orderData, orderGroupNumber: e.target.value })
            }
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Item ID</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            value={createOrder?.data?.item?.id}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Tobacco Code</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            value={createOrder?.data?.item?.tobaccoCode}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Item Buying Grade</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            value={createOrder?.data?.item?.buyingGrade}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Item Sales Grade</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            value={createOrder?.data?.item?.salesGrade}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Item Crop</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            value={createOrder?.data?.item?.crop}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Item Origin</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            value={createOrder?.data?.item?.origin}
            fullWidth
          />
        </Grid>
        {createOrder?.data?.item?.origin === "China" && (
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Item Province</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              value={createOrder?.data?.item?.province}
              fullWidth
            />
          </Grid>
        )}
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Item Current Location</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            value={createOrder?.data?.item?.storage}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Nicotine</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            value={createOrder?.data?.item?.nicotine}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Sugar</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            value={createOrder?.data?.item?.sugar}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Moisture</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            value={createOrder?.data?.item?.moisture}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Vendor</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            value={createOrder?.data?.stakeholder?.name}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Vendor SAP Code</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            value={createOrder?.data?.stakeholder?.code}
            fullWidth
          />
        </Grid>

        {/*<Grid item xs={12} sm={2}>
          <Typography variant="body2">Estimated Total Cost</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            defaultValue={createOrder?.data?.estimatedCost?.totalCost}
            fullWidth
          />
          </Grid>*/}

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">First Offer</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            defaultValue={
              (createOrder?.data?.firstOfferPrice || "") +
              " " +
              createOrder?.data?.firstOfferPriceCurrency
            }
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Final Offer</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            defaultValue={
              (createOrder?.data?.finalOfferPrice || "") +
              " " +
              createOrder?.data?.finalOfferPriceCurrency
            }
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">First Sample</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            defaultValue={createOrder?.data?.firstSample?.id}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Packaging</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            defaultValue={createOrder?.data?.packaging}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Payment Term</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            defaultValue={createOrder?.data?.paymentTerm}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Incoterm</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            defaultValue={createOrder?.data?.incoterm}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Port Of Discharge</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            defaultValue={createOrder?.data?.portOfDischarge}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Port Of Loading</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            defaultValue={createOrder?.data?.portOfLoading}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Offered Number Of Cartons</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            defaultValue={createOrder?.data?.numberOfCases}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Offered Net Weight Per Carton</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            defaultValue={createOrder?.data?.netWeightPerCase}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Offered Volume</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            defaultValue={
              createOrder?.data?.volume + " " + createOrder?.data?.volumeUnit
            }
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Total Amount</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            defaultValue={
              createOrder?.data?.totalAmount +
              " " +
              createOrder?.data?.totalAmountCurrency
            }
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Offer Date</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            defaultValue={
              createOrder?.data?.offerDate
                ? format(parseISO(createOrder.data.offerDate), "yyyy-MM-dd")
                : ""
            }
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Offer Validity Date</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            defaultValue={
              createOrder?.data?.validityDate
                ? format(parseISO(createOrder.data.validityDate), "yyyy-MM-dd")
                : ""
            }
            fullWidth
          />
        </Grid>

        {/*<Grid item xs={12} sm={2}>
          <Typography variant="body2">Offer Cost ID</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            defaultValue={createOrder?.data?.estimatedCost?.id}
            fullWidth
          />
          </Grid>*/}
      </Grid>
      <Grid item xs={12} style={{ position: "relative" }}>
        <Typography
          variant="h3"
          align="center"
          style={{
            color: "#777f87",
            position: "absolute",
            top: "3px",
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "#fafafb",
            padding: "0 10px",
          }}
        >
          Order Details
        </Typography>
      </Grid>
      <Grid
        container
        spacing="12px"
        style={{
          border: "1px solid #dbd9d9",
          borderRadius: "20px",
          marginTop: "20px",
          padding: "20px",
          boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Director PO Approval Status</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            id="outlined-select-approval-status"
            disabled
            value="Not Approved"
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Buying Company</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            defaultValue={orderData?.company}
            id="outlined-select-status"
            select
            onChange={(e) =>
              setOrderData({ ...orderData, company: e.target.value })
            }
          >
            {companies.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Tobacco Code</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            defaultValue={createOrder?.data?.item?.tobaccoCode}
            onChange={(e) => {
              const newCode = e.target.value;
              setCreateOrder({
                ...createOrder,
                data: {
                  ...createOrder?.data,
                  tobaccoCode: e.target.value,
                },
              });
              setOrderData({ ...orderData, tobaccoCode: newCode });
            }}
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Internal Grade</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            defaultValue={createOrder?.data?.item?.internalGrade}
            onChange={(e) => {
              const newGrade = e.target.value;
              setCreateOrder({
                ...createOrder,
                data: {
                  ...createOrder?.data,
                  internalGrade: e.target.value,
                },
              });
              setOrderData({ ...orderData, internalGrade: newGrade });
            }}
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Select Redrawn Sample</Typography>
          <Grid container xs={12} spacing="8px">
            <Grid item xs={12} sm={8}>
              <Autocomplete
                options={filteredSamples}
                getOptionLabel={(option) =>
                  `ID: ${option?.id || ""}, Remark: ${
                    option?.remark || ""
                  }, Buying Grade: ${option?.item?.buyingGrade || ""}`
                }
                value={selectedSecondSample}
                onChange={(event, newValue) => {
                  setSelectedSecondSample(newValue);
                  setOrderData({
                    ...orderData,
                    secondSampleId: newValue ? newValue.id : "",
                  });
                }}
                onOpen={() => retrieveFilteredSamples("Redrawn Sample")}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                )}
                renderTags={() => null}
              />
              {selectedSecondSample && (
                <Box mt={2}>
                  <Chip
                    label={`ID: ${selectedSecondSample?.id || ""}, Remark: ${
                      selectedSecondSample?.remark || ""
                    }, Buying Grade: ${
                      selectedSecondSample?.item?.buyingGrade || ""
                    }`}
                    onDelete={() => {
                      setSelectedSecondSample(null);
                      setOrderData({ ...orderData, secondSampleId: "" });
                    }}
                  />
                </Box>
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
              <ButtonGroup
                variant="outlined"
                ref={anchorRef2}
                aria-label="Button group with a nested menu"
              >
                <Button onClick={handleClick2}>
                  {options[selectedIndex2]}
                </Button>
                <Button
                  size="small"
                  aria-controls={open2 ? "split-button-menu" : undefined}
                  aria-expanded={open2 ? "true" : undefined}
                  aria-label="select merge strategy"
                  aria-haspopup="menu"
                  onClick={handleToggle2}
                  sx={{
                    width: "20px !important",
                    minWidth: "0px !important",
                  }}
                >
                  <ArrowDropDownIcon />
                </Button>
              </ButtonGroup>
              <Popper
                sx={{
                  zIndex: 1,
                }}
                open={open2}
                anchorEl={anchorRef2.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom" ? "center top" : "center bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose2}>
                        <MenuList id="split-button-menu" autoFocusItem>
                          {options.map((option, index) => (
                            <MenuItem
                              key={option}
                              disabled={index === 2}
                              selected={index === selectedIndex2}
                              onClick={(event) =>
                                handleMenuItemClick2(event, index)
                              }
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Select Second Sample Status</Typography>
          <TextField
            id="outlined-select-status"
            select
            variant="outlined"
            size="small"
            fullWidth
            onChange={(e) =>
              setOrderData({ ...orderData, secondSampleStatus: e.target.value })
            }
          >
            {sampleStatus.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Select Order Packaging Type</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            defaultValue={createOrder?.data?.packaging}
            select
            onChange={(e) =>
              setOrderData({ ...orderData, packaging: e.target.value })
            }
          >
            {packagingTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Ordered Net Weight Per Carton</Typography>
          <TextField
            type="number"
            variant="outlined"
            size="small"
            fullWidth
            value={orderData.netWeightPerCase}
            onWheel={(e) => e.target.blur()}
            onChange={(e) =>
              setOrderData({
                ...orderData,
                netWeightPerCase: e.target.value,
                volume: e.target.value * orderData?.numberOfCases,
              })
            }
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Ordered Number Of Cartons</Typography>
          <TextField
            type="number"
            variant="outlined"
            size="small"
            fullWidth
            value={orderData.numberOfCases}
            onWheel={(e) => e.target.blur()}
            onChange={(e) =>
              setOrderData({
                ...orderData,
                numberOfCases: e.target.value,
                volume: e.target.value * orderData?.netWeightPerCase,
              })
            }
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Ordered Volume</Typography>
          <TextField
            type="number"
            variant="outlined"
            size="small"
            fullWidth
            value={orderData?.volume}
            onWheel={(e) => e.target.blur()}
            onChange={(e) =>
              setOrderData({ ...orderData, volume: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Ordered Volume Unit</Typography>
          <TextField
            type="search"
            variant="outlined"
            size="small"
            defaultValue={"KG"}
            select
            fullWidth
            onChange={(e) =>
              setOrderData({ ...orderData, volumeUnit: e.target.value })
            }
          >
            {units.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Enter Final Price</Typography>
          <TextField
            type="number"
            variant="outlined"
            size="small"
            fullWidth
            value={orderData?.finalPrice}
            onWheel={(e) => e.target.blur()}
            onChange={(e) =>
              setOrderData({ ...orderData, finalPrice: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Select Final Price Currency</Typography>
          <TextField
            id="outlined-select-status"
            select
            variant="outlined"
            size="small"
            defaultValue={orderData?.finalPriceCurrency || ""}
            fullWidth
            onChange={(e) =>
              setOrderData({ ...orderData, finalPriceCurrency: e.target.value })
            }
          >
            {currencies.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        {/*<Grid item xs={12} sm={2}>
          <Typography variant="body2">Select Order Cost</Typography>
          <Autocomplete
            options={costs}
            getOptionLabel={(option) =>
              `Remark: ${option?.remark || ""}, Total Cost: ${
                option?.totalCost || ""
              }`
            }
            value={selectedCost}
            onChange={(event, newValue) => {
              setSelectedCost(newValue);
              setOrderData({
                ...orderData,
                costId: newValue ? newValue.id : "",
              });
            }}
            onOpen={retrieveCosts}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                fullWidth
              />
            )}
            renderTags={() => null}
          />
          {selectedCost && (
            <Box mt={2}>
              <Chip
                label={`Remark: ${selectedCost?.remark || ""}, Total Cost: ${
                  selectedCost?.totalCost || ""
                }`}
                onDelete={() => {
                  setSelectedCost(null);
                  setOrderData({ ...orderData, costId: "" });
                }}
              />
            </Box>
          )}
        </Grid>
        */}
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Select Contract Value Date</Typography>
          <TextField
            size="small"
            fullWidth
            variant="outlined"
            id="date"
            type="date"
            inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
            InputLabelProps={{
              shrink: true,
            }}
            onKeyDown={(e) => e.preventDefault()}
            onChange={(e) =>
              setOrderData({ ...orderData, contractValueDate: e.target.value })
            }
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Order Incoterm</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            defaultValue={createOrder?.data?.incoterm}
            select
            onChange={(e) =>
              setOrderData({ ...orderData, incoterm: e.target.value })
            }
          >
            {incoterms.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Order Port Of Discharge</Typography>
          <Autocomplete
            options={portsOfDischarge}
            getOptionLabel={(option) => (option ? option.label : "")}
            value={selectedPortOfDischarge}
            onChange={(event, newValue) => {
              setSelectedPortOfDischarge(newValue);
              setOrderData({
                ...orderData,
                portOfDischarge: newValue ? newValue.value : "",
              });
            }}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                label={createOrder?.data?.portOfDischarge}
                fullWidth
              />
            )}
            renderTags={() => null}
          />
          {selectedPortOfDischarge && (
            <Box mt={2}>
              <Chip
                label={selectedPortOfDischarge.label}
                onDelete={() => {
                  setSelectedPortOfDischarge(null);
                  setOrderData({ ...orderData, portOfDischarge: "" });
                }}
              />
            </Box>
          )}
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Order Port Of Loading</Typography>
          <Autocomplete
            options={portsOfLoading}
            getOptionLabel={(option) => (option ? option.label : "")}
            value={selectedPortOfLoading}
            onChange={(event, newValue) => {
              setSelectedPortOfLoading(newValue);
              setOrderData({
                ...orderData,
                portOfLoading: newValue ? newValue.value : "",
              });
            }}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                label={createOrder?.data?.portOfLoading}
                fullWidth
              />
            )}
            renderTags={() => null}
          />
          {selectedPortOfLoading && (
            <Box mt={2}>
              <Chip
                label={selectedPortOfLoading.label}
                onDelete={() => {
                  setSelectedPortOfLoading(null);
                  setOrderData({ ...orderData, portOfLoading: "" });
                }}
              />
            </Box>
          )}
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Order Payment Term</Typography>
          <Autocomplete
            options={paymentTerm}
            getOptionLabel={(option) => (option ? option.label : "")}
            value={selectedPaymentTerm}
            onChange={(event, newValue) => {
              setSelectedPaymentTerm(newValue);
              setOrderData({
                ...orderData,
                paymentTerm: newValue ? newValue.value : "",
              });
            }}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                label={createOrder?.data?.paymentTerm}
                fullWidth
              />
            )}
            renderTags={() => null}
          />
          {selectedPaymentTerm && (
            <Box mt={2}>
              <Chip
                label={selectedPaymentTerm.label}
                onDelete={() => {
                  setSelectedPaymentTerm(null);
                  setOrderData({ ...orderData, paymentTerm: "" });
                }}
              />
            </Box>
          )}
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Select Order Issue Date</Typography>
          <TextField
            size="small"
            fullWidth
            variant="outlined"
            id="date"
            type="date"
            inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
            InputLabelProps={{
              shrink: true,
            }}
            onKeyDown={(e) => e.preventDefault()}
            error={!orderData?.operationDate}
            helperText={!orderData?.operationDate ? "Issue date is required" : ""}
            onChange={(e) =>
              setOrderData({ ...orderData, operationDate: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Select ETD</Typography>
          <TextField
            size="small"
            fullWidth
            variant="outlined"
            id="date"
            type="date"
            inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) =>
              setOrderData({
                ...orderData,
                estimatedTimeOfDeparture: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Select ETA</Typography>
          <TextField
            size="small"
            fullWidth
            variant="outlined"
            id="date"
            type="date"
            inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) =>
              setOrderData({
                ...orderData,
                estimatedTimeOfArrival: e.target.value,
              })
            }
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Shipment Schedule</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            onChange={(e) =>
              setOrderData({ ...orderData, shipmentSchedule: e.target.value })
            }
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Order Remark</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            onChange={(e) =>
              setOrderData({ ...orderData, remark: e.target.value })
            }
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Order Comment</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            onChange={(e) =>
              setOrderData({ ...orderData, comment: e.target.value })
            }
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Enter Shipping Term</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            onChange={(e) =>
              setOrderData({
                ...orderData,
                shippingTerm: e.target.value,
              })
            }
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">SAP PO Number</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            onChange={(e) =>
              setOrderData({
                ...orderData,
                purchaseOrderNumber: e.target.value,
              })
            }
          />
        </Grid>
        {/*<Grid item xs={12} sm={2}>
          <Typography variant="body2">SAP PO Number</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            onChange={(e) =>
              setOrderData({
                ...orderData,
                purchaseOrderNumber: e.target.value,
              })
            }
          />
        </Grid>*/}

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Select Order Record Status</Typography>
          <TextField
            id="outlined-select-status"
            select
            variant="outlined"
            size="small"
            fullWidth
            label={"ACTIVE"}
            onChange={(e) =>
              setOrderData({
                ...orderData,
                recordStatus: e.target.value,
              })
            }
          >
            {recordStatus.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Grid container spacing="12px">
        <Grid item xs={12} sm={12}></Grid>
        <Button
          variant="outlined"
          sx={{ ml: 1.5, mt: 4, mr: 1 }}
          onClick={() => {
            setOrderData({ data: null });
            setCreateOrder({ visible: false });
          }}
        >
          Cancel
        </Button>
        <div>
          {loading ? (
            <ClipLoader color="#000000" loading={loading} size={35} />
          ) : (
            <Button
              variant="contained"
              sx={{ mt: 4 }}
              disabled={
              ((createOrder?.data?.stakeholder &&
                !createOrder?.data?.stakeholder?.code) || !orderData?.operationDate)
              }
              onClick={handleCreateButtonClick}
            >
              Create
            </Button>
          )}
        </div>
        <Dialog
          open={isPriceMismatchDialogOpen}
          onClose={() => setIsPriceMismatchDialogOpen(false)}
        >
          <DialogTitle>Price Mismatch Detected</DialogTitle>
          <DialogContent>
            The final offer price (
            <strong>{createOrder?.data?.finalOfferPrice}</strong>) does not
            match the final order price (
            <strong>{orderData?.finalPrice}</strong>).
            <br />
            Would you like to update the final offer price to match the final
            order price before proceeding?
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setIsPriceMismatchDialogOpen(false)}
              color="secondary"
            >
              Cancel
            </Button>
            <Button onClick={handleProceedWithoutUpdatingPrice} color="primary">
              Proceed Without Updating Price
            </Button>
            <Button onClick={handleUpdatePriceAndProceed} color="primary">
              Update Price and Proceed
            </Button>
          </DialogActions>
        </Dialog>
        <Grid item xs={12} sm={12}></Grid>
        <Grid item xs={12} sm={6}>
          {createOrder?.data?.stakeholder &&
            !createOrder?.data?.stakeholder?.code && (
              <Typography variant="body1" color="error">
                No Vendor SAP Code: Vendor is not registered in SAP. Please
                register the vendor to proceed with creating the order.
              </Typography>
            )}
        </Grid>
      </Grid>
      {isNewSampleOpen && (
        <>
          {" "}
          <Grid item xs={12} style={{ position: "relative" }}>
            <Typography
              variant="h3"
              align="center"
              style={{
                color: "#777f87",
                position: "absolute",
                top: "10px",
                left: "50%",
                transform: "translateX(-50%)",
                backgroundColor: "#fafafb",
                padding: "0 10px",
              }}
            >
              New Sample Details
            </Typography>
          </Grid>
          <Grid
            container
            spacing="12px"
            style={{
              border: "1px solid #dbd9d9",
              borderRadius: "20px",
              marginTop: "30px",
              padding: "20px",
              boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Grid container xs={12} spacing="8px">
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Sample Purpose</Typography>
                <TextField
                  id="outlined-select-status"
                  select
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      purpose: e.target.value,
                    })
                  }
                >
                  {samplePurposes.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Item</Typography>
                <Autocomplete
                  options={items}
                  getOptionLabel={(option) =>
                    `ID: ${option.id || ""} - Buying Grade: ${
                      option.buyingGrade || ""
                    } - Sales Grade: ${option.salesGrade || ""} - Origin: ${
                      option?.origin || ""
                    } - Crop: ${option.crop || ""}`
                  }
                  value={sample?.itemId}
                  onChange={(event, newValue) => {
                    setSample({
                      ...sample,
                      itemId: newValue ? newValue.id : null,
                    });
                  }}
                  onOpen={retrieveItems}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  )}
                  renderTags={() => null}
                />
                {sample?.itemId && (
                  <Box mt={2}>
                    <Chip
                      label={`ID: ${
                        items.find((item) => item.id === sample?.itemId)?.id ||
                        ""
                      }`}
                      style={{ marginBottom: "5px" }}
                      onDelete={() =>
                        setSample({
                          ...sample,
                          itemId: null,
                        })
                      }
                    />
                    <br />
                    <Chip
                      label={`Buying Grade: ${
                        items.find((item) => item.id === sample?.itemId)
                          ?.buyingGrade || ""
                      }`}
                      style={{ marginBottom: "5px" }}
                    />
                    <br />
                    <Chip
                      label={`Sales Grade: ${
                        items.find((item) => item.id === sample?.itemId)
                          ?.salesGrade || ""
                      }`}
                      style={{ marginBottom: "5px" }}
                    />
                    <br />
                    <Chip
                      label={`Crop: ${
                        items.find((item) => item.id === sample?.itemId)
                          ?.crop || ""
                      }`}
                      style={{ marginBottom: "5px" }}
                    />
                    <br />
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Sample Image Link</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      image: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Sender</Typography>
                <Autocomplete
                  options={stakeholders}
                  getOptionLabel={(option) =>
                    `${option.id || ""} - ${option.type || ""} - ${
                      option.name || ""
                    }`
                  }
                  value={sample?.senderId}
                  onChange={(event, newValue) => {
                    setSample({
                      ...sample,
                      senderId: newValue ? newValue.id : null,
                    });
                  }}
                  onOpen={retrieveStakeholders}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  )}
                  renderTags={() => null}
                />
                {sample?.senderId && (
                  <Box mt={2}>
                    <Chip
                      label={`ID: ${
                        stakeholders.find(
                          (stakeholder) => stakeholder.id === sample?.senderId,
                        )?.id || ""
                      }`}
                      style={{ marginBottom: "5px" }}
                      onDelete={() =>
                        setSample({
                          ...sample,
                          senderId: null,
                        })
                      }
                    />
                    <br />
                    <Chip
                      label={`Type: ${
                        stakeholders.find(
                          (stakeholder) => stakeholder.id === sample?.senderId,
                        )?.type || ""
                      }`}
                      style={{ marginBottom: "5px" }}
                    />
                    <br />
                    <Chip
                      label={`Name: ${
                        stakeholders.find(
                          (stakeholder) => stakeholder.id === sample?.senderId,
                        )?.name || ""
                      }`}
                      style={{ marginBottom: "5px" }}
                    />
                    <br />
                  </Box>
                )}
              </Grid>

              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Receiver</Typography>
                <Autocomplete
                  options={stakeholders}
                  getOptionLabel={(option) =>
                    `${option.id || ""} - ${option.type || ""} - ${
                      option.name || ""
                    }`
                  }
                  value={sample?.receiverId}
                  onChange={(event, newValue) => {
                    setSample({
                      ...sample,
                      receiverId: newValue ? newValue.id : null,
                    });
                  }}
                  onOpen={retrieveStakeholders}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  )}
                  renderTags={() => null}
                />
                {sample?.receiverId && (
                  <Box mt={2}>
                    <Chip
                      label={`ID: ${
                        stakeholders.find(
                          (stakeholder) =>
                            stakeholder.id === sample?.receiverId,
                        )?.id || ""
                      }`}
                      style={{ marginBottom: "5px" }}
                      onDelete={() =>
                        setSample({
                          ...sample,
                          receiverId: null,
                        })
                      }
                    />
                    <br />
                    <Chip
                      label={`Type: ${
                        stakeholders.find(
                          (stakeholder) =>
                            stakeholder.id === sample?.receiverId,
                        )?.type || ""
                      }`}
                      style={{ marginBottom: "5px" }}
                    />
                    <br />
                    <Chip
                      label={`Name: ${
                        stakeholders.find(
                          (stakeholder) =>
                            stakeholder.id === sample?.receiverId,
                        )?.name || ""
                      }`}
                      style={{ marginBottom: "5px" }}
                    />
                    <br />
                  </Box>
                )}
              </Grid>

              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Enter Sales Grade</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      salesGrade: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Enter Sales Nicotine</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  type="number"
                  fullWidth
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      salesNicotine: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Enter Sales Sugar</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  type="number"
                  fullWidth
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      salesSugar: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Enter Sales Moisture</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  type="number"
                  fullWidth
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      salesMoisture: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Enter Sales Origin</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      salesOrigin: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Enter Sales Crop</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="number"
              onWheel={(e) => e.target.blur()}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      salesCrop: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Enter Date Of Delivery</Typography>
                <TextField
                  size="small"
                  fullWidth
                  variant="outlined"
                  id="date"
                  type="date"
                  inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      deliveryDate: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Enter Date Of Receive</Typography>
                <TextField
                  size="small"
                  fullWidth
                  variant="outlined"
                  id="date"
                  type="date"
                  inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      receiveDate: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">
                  Enter Courier Information
                </Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      courier: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Enter AWB Number</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      awbNumber: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Enter Remark</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      remark: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Enter Customer Feedback</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      customerFeedback: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Is Sample Approved</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      approved: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">
                  Enter SG Approval Status
                </Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      approvalStatus: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Sample Paid By</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      paidBy: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Status</Typography>
                <TextField
                  id="outlined-select-status"
                  select
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      status: e.target.value,
                    })
                  }
                >
                  {recordStatus.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={2}>
              <Button
                variant="outlined"
                size="small"
                sx={{ ml: 1.5, mt: 4, mr: 1 }}
                onClick={() => {
                  setIsNewSampleOpen(false);
                  setSample([]);
                  setSelectedOrigin("");
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="small"
                sx={{ mt: 4 }}
                onClick={() => {
                  createSampleApi(sample)
                    .then((response) => {
                      toast.success("Sample added successfully.");
                    })
                    .catch((error) => {
                      console.log(error.response.data);
                      toast.error("Something Went Wrong.");
                    });
                  setIsNewSampleOpen(false);
                  setSample([]);
                  setSelectedOrigin("");
                }}
              >
                Create Sample
              </Button>
            </Grid>
          </Grid>{" "}
        </>
      )}
      {isEditSampleOpen && (
        <>
          {" "}
          <Grid item xs={12} style={{ position: "relative" }}>
            <Typography
              variant="h3"
              align="center"
              style={{
                color: "#777f87",
                position: "absolute",
                top: "10px",
                left: "50%",
                transform: "translateX(-50%)",
                backgroundColor: "#fafafb",
                padding: "0 10px",
              }}
            >
              Edit Sample {sample?.id} Details
            </Typography>
          </Grid>
          <Grid
            container
            spacing="12px"
            style={{
              border: "1px solid #dbd9d9",
              borderRadius: "20px",
              marginTop: "30px",
              padding: "20px",
              boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Grid container xs={12} spacing="8px">
              <Grid item xs={12} sm={2}>
                <Typography variant="body2"> Sample Purpose</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  id="outlined-select-purpose"
                  select
                  value={sample?.purpose || ""}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      purpose: e.target.value,
                    })
                  }
                >
                  {samplePurposes.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Item</Typography>
                <Autocomplete
                  options={items}
                  getOptionLabel={(option) =>
                    `ID: ${option.id || ""} - Buying Grade: ${
                      option.buyingGrade || ""
                    } - Sales Grade: ${option.salesGrade || ""} - Origin: ${
                      option?.origin || ""
                    } - Crop: ${option.crop || ""}`
                  }
                  value={sample?.item?.id}
                  onChange={(event, newValue) => {
                    setSample({
                      ...sample,
                      item: {
                        ...sample?.item,
                        id: newValue ? newValue.id : null,
                      },
                    });
                  }}
                  onOpen={retrieveItems}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      fullWidth
                      label={
                        (sample?.item?.id || "") +
                        " " +
                        (sample?.item?.buyingGrade || "") +
                        " " +
                        (sample?.item?.salesGrade || "")
                      }
                    />
                  )}
                  renderTags={() => null}
                />
                {sample?.item?.id && (
                  <Box mt={2}>
                    <Chip
                      label={`ID: ${
                        items.find((item) => item.id === sample?.item.id)?.id ||
                        ""
                      }`}
                      style={{ marginBottom: "5px" }}
                      onDelete={() =>
                        setSample({
                          ...sample,
                          item: {
                            ...sample?.item,
                            id: null,
                          },
                        })
                      }
                    />
                    <br />
                    <Chip
                      label={`Buying Grade: ${
                        items.find((item) => item.id === sample?.item.id)
                          ?.buyingGrade || ""
                      }`}
                      style={{ marginBottom: "5px" }}
                    />
                    <br />
                    <Chip
                      label={`Sales Grade: ${
                        items.find((item) => item.id === sample?.item.id)
                          ?.salesGrade || ""
                      }`}
                      style={{ marginBottom: "5px" }}
                    />
                    <br />
                    <Chip
                      label={`Crop: ${
                        items.find((item) => item.id === sample?.item.id)
                          ?.crop || ""
                      }`}
                      style={{ marginBottom: "5px" }}
                    />
                    <br />
                  </Box>
                )}
              </Grid>

              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Image URL</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={sample?.image || ""}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      image: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Sender</Typography>
                <Autocomplete
                  options={stakeholders}
                  getOptionLabel={(option) =>
                    `${option.id || ""} - ${option.type || ""} - ${
                      option.name || ""
                    }`
                  }
                  value={sample?.sender?.id}
                  onChange={(event, newValue) => {
                    setSample({
                      ...sample,
                      sender: {
                        ...sample?.sender,
                        id: newValue ? newValue.id : null,
                      },
                    });
                  }}
                  onOpen={retrieveStakeholders}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      fullWidth
                      label={
                        (sample?.sender?.id || "") +
                        " " +
                        (sample?.sender?.name || "")
                      }
                    />
                  )}
                  renderTags={() => null}
                />
                {sample?.sender?.id && (
                  <Box mt={2}>
                    <Chip
                      label={`ID: ${
                        stakeholders.find(
                          (stakeholder) => stakeholder.id === sample?.sender.id,
                        )?.id || ""
                      }`}
                      style={{ marginBottom: "5px" }}
                      onDelete={() =>
                        setSample({
                          ...sample,
                          sender: {
                            ...sample?.sender,
                            id: null,
                          },
                        })
                      }
                    />
                    <br />
                    <Chip
                      label={`Type: ${
                        stakeholders.find(
                          (stakeholder) => stakeholder.id === sample?.sender.id,
                        )?.type || ""
                      }`}
                      style={{ marginBottom: "5px" }}
                    />
                    <br />
                    <Chip
                      label={`Name: ${
                        stakeholders.find(
                          (stakeholder) => stakeholder.id === sample?.sender.id,
                        )?.name || ""
                      }`}
                      style={{ marginBottom: "5px" }}
                    />
                    <br />
                  </Box>
                )}
              </Grid>

              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Receiver</Typography>
                <Autocomplete
                  options={stakeholders}
                  getOptionLabel={(option) =>
                    `${option.id || ""} - ${option.type || ""} - ${
                      option.name || ""
                    }`
                  }
                  value={sample?.receiver?.id}
                  onChange={(event, newValue) => {
                    setSample({
                      ...sample,
                      receiver: {
                        ...sample?.receiver,
                        id: newValue ? newValue.id : null,
                      },
                    });
                  }}
                  onOpen={retrieveStakeholders}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      fullWidth
                      label={
                        (sample?.receiver?.id || "") +
                        " " +
                        (sample?.receiver?.name || "")
                      }
                    />
                  )}
                  renderTags={() => null}
                />
                {sample?.receiver?.id && (
                  <Box mt={2}>
                    <Chip
                      label={`ID: ${
                        stakeholders.find(
                          (stakeholder) =>
                            stakeholder.id === sample?.receiver.id,
                        )?.id || ""
                      }`}
                      style={{ marginBottom: "5px" }}
                      onDelete={() =>
                        setSample({
                          ...sample,
                          receiver: {
                            ...sample?.receiver,
                            id: null,
                          },
                        })
                      }
                    />
                    <br />
                    <Chip
                      label={`Type: ${
                        stakeholders.find(
                          (stakeholder) =>
                            stakeholder.id === sample?.receiver.id,
                        )?.type || ""
                      }`}
                      style={{ marginBottom: "5px" }}
                    />
                    <br />
                    <Chip
                      label={`Name: ${
                        stakeholders.find(
                          (stakeholder) =>
                            stakeholder.id === sample?.receiver.id,
                        )?.name || ""
                      }`}
                      style={{ marginBottom: "5px" }}
                    />
                    <br />
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Sales Grade</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={sample?.salesGrade || ""}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      salesGrade: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Sales Nicotine</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  type="number"
                  fullWidth
                  value={sample?.salesNicotine || ""}
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      salesNicotine: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Sales Sugar</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="number"
                  value={sample?.salesSugar || ""}
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      salesSugar: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Sales Moisture</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="number"
                  value={sample?.salesMoisture || ""}
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      salesMoisture: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Sales Origin</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={sample?.salesOrigin || ""}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      salesOrigin: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Sales Crop</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  type="number"
                  fullWidth
                  value={sample?.salesCrop || ""}
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      salesCrop: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Date Of Delivery</Typography>
                <TextField
                  id="date"
                  type="date"
                  inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={
                    sample?.deliveryDate
                      ? format(parseISO(sample?.deliveryDate), "yyyy-MM-dd")
                      : ""
                  }
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      deliveryDate: e.target.value
                        ? e.target.value + "T00:00:00.000+00:00"
                        : null,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Date Of Receive</Typography>
                <TextField
                  id="date"
                  type="date"
                  inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={
                    sample?.receiveDate
                      ? format(parseISO(sample?.receiveDate), "yyyy-MM-dd")
                      : ""
                  }
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      receiveDate: e.target.value
                        ? e.target.value + "T00:00:00.000+00:00"
                        : null,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Courier</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={sample?.courier || ""}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      courier: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">AWB Number</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={sample?.awbNumber || ""}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      awbNumber: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Remark</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={sample?.remark || ""}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      remark: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Customer Feedback</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={sample?.customerFeedback || ""}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      customerFeedback: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Approved</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={sample?.approved || ""}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      approved: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Approval Details</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={sample?.approvalDetails || ""}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      approvalDetails: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Paid By</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={sample?.paidBy || ""}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      paidBy: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2"> Status</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  id="outlined-select-status"
                  select
                  value={sample?.status || ""}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      status: e.target.value,
                    })
                  }
                >
                  {recordStatus.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={2}>
              <Button
                variant="outlined"
                size="small"
                sx={{ ml: 1.5, mt: 4, mr: 1 }}
                onClick={() => {
                  setIsEditSampleOpen(false);
                  setSample([]);
                  setSelectedOrigin("");
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="small"
                sx={{ mt: 4 }}
                onClick={() => {
                  updateSampleApi(sample?.id, sample)
                    .then((response) => {
                      toast.success("Sample edited successfully.");
                    })
                    .catch((error) => {
                      toast.error("Something went wrong.");
                      console.log(error.response.data);
                    });
                  setIsEditSampleOpen(false);
                  setSample([]);
                  setSelectedOrigin("");
                }}
              >
                Save Sample
              </Button>
            </Grid>
          </Grid>{" "}
        </>
      )}
    </Grid>
  );
};

export default CreateOrder;
