import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createFreightOfferApi } from "../../../components/api/FreightOfferApiService";
import { currencies, incoterms, status, units, recordStatus, portsOfLoading, portsOfDischarge } from "../../../utils/menu-items";
import toast from "react-hot-toast";
import { format, parseISO } from "date-fns";

const AddFreightOffer = (props) => {
  const navigate = useNavigate();
  const { setAddViewStates, addViewStates, rows, setRows, setAddView } = props;

  const [quantityCheckboxState, setQuantityCheckboxState] = useState({
    checked20: false,
    checked40: false,
    checked40HC: false,
    checkedFtl:false,
  });

  const handleQuantityCheckboxChange = (event) => {
    setQuantityCheckboxState({
      ...quantityCheckboxState,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <>
      <Stack direcion="column">
        <Typography variant="body1" mb={2}>
          Create New Freight Offer
        </Typography>
        <Grid container xs={12} spacing="8px">

        <Grid item xs={12} sm={2}>
            <Typography variant="body2">Forwarder</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-forwarder"
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  forwarder: e.target.value,
                })
              }
            >
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Select Point Of Loading</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-pol"
              select
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  pointOfLoading: e.target.value,
                })
              }
            >
              {portsOfLoading.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Select Point Of Discharge</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-pod"
              select
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  pointOfDischarge: e.target.value,
                })
              }
            >
              {portsOfDischarge.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Select Incoterm</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-terms"
              select
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  terms: e.target.value,
                })
              }
            >
              {incoterms.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          </Grid>
         </Stack>
          <Stack direcion="column" style={{marginBottom:"10px"}}>
          <Grid container xs={12} spacing="8px">    
            <Grid item xs={12} sm={0.66}>
          <Typography variant="body2" style={{ visibility: 'hidden' }}>Quantity</Typography>
        <FormControlLabel
          control={
            <Checkbox
              name="checked20"
              checked={quantityCheckboxState.checked20}
              onChange={handleQuantityCheckboxChange}
            />
          }
          label="20"
        /></Grid>
          {quantityCheckboxState.checked20 && (
            <>
        <Grid item xs={12} sm={1.34}>
          <Typography variant="body2" style={{ visibility: 'hidden' }}>20"</Typography>
          <TextField
            label="Rate"
            variant="outlined"
            fullWidth
            type="number"
            onWheel={(e) => e.target.blur()}
            onChange={(e) =>
              setAddViewStates({
                ...addViewStates,
                twentyFtRate: e.target.value,
              })
            }
          />
        </Grid>
        
        <Grid item xs={12} sm={2}>
        <Typography variant="body2">
          20 Ft Currency
        </Typography>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          id="outlined-select-20ft-currency"
          select
          onChange={(e) =>
            setAddViewStates({
              ...addViewStates,
              twentyFtCurrency: e.target.value,
            })
          }
        >
          {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      </>
      )}
      <Grid item xs={12} sm={0.66}>
      <Typography variant="body2" style={{ visibility: 'hidden' }}>Quantity</Typography>
        <FormControlLabel
          control={
            <Checkbox
              name="checked40"
              checked={quantityCheckboxState.checked40}
              onChange={handleQuantityCheckboxChange}
            />
          }
          label="40"
        /></Grid>
           {quantityCheckboxState.checked40 && (
            <>
        <Grid item xs={12} sm={1.34}>
          <Typography variant="body2" style={{ visibility: 'hidden' }}>40"</Typography>
          <TextField
            label="Rate"
            variant="outlined"
            fullWidth
            type="number"
            onWheel={(e) => e.target.blur()}
            onChange={(e) =>
              setAddViewStates({
                ...addViewStates,
                fortyFtRate: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={2}>
            <Typography variant="body2">
              40 Ft Currency
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-40ft-currency"
              select
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  fortyFtCurrency: e.target.value,
                })
              }
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

</>
      )}
      <Grid item xs={12} sm={0.66}>
      <Typography variant="body2" style={{ visibility: 'hidden' }}>Quantity</Typography>
        <FormControlLabel
          control={
            <Checkbox
              name="checked40HC"
              checked={quantityCheckboxState.checked40HC}
              onChange={handleQuantityCheckboxChange}
            />
          }
          label="40HC"
        />
        </Grid>
          {quantityCheckboxState.checked40HC && (
            <>
        <Grid item xs={12} sm={1.34}>
          <Typography variant="body2" style={{ visibility: 'hidden' }}>40HC</Typography>
          <TextField
            label="Rate"
            variant="outlined"
            fullWidth
            type="number"
            onWheel={(e) => e.target.blur()}
            onChange={(e) =>
              setAddViewStates({
                ...addViewStates,
                fortyHcRate: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={2}>
            <Typography variant="body2">
              40 HC Currency
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-40hc-currency"
              select
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  fortyHcCurrency: e.target.value,
                })
              }
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

</>
      )} 
          <Grid item xs={12} sm={0.66}>
      <Typography variant="body2" style={{ visibility: 'hidden' }}>Quantity</Typography>
        <FormControlLabel
          control={
            <Checkbox
              name="checkedFtl"
              checked={quantityCheckboxState.checkedFtl}
              onChange={handleQuantityCheckboxChange}
            />
          }
          label="FTL"
        /></Grid>
        {quantityCheckboxState.checkedFtl && (
            <>
        <Grid item xs={12} sm={1.34}>
          <Typography variant="body2" style={{ visibility: 'hidden' }}>FTL</Typography>
          <TextField
            label="Rate"
            variant="outlined"
            fullWidth
            type="number"
            onWheel={(e) => e.target.blur()}
            onChange={(e) =>
              setAddViewStates({
                ...addViewStates,
                ftlRate: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={2}>
            <Typography variant="body2">
              FTL Currency
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-40hc-currency"
              select
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  ftlCurrency: e.target.value,
                })
              }
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

</>
      )}</Grid>
      </Stack>
      <Stack direcion="column">
      <Grid container xs={12} spacing="8px">   
          <Grid item xs={12} sm={2}>
          <Typography variant="body2">Valid From</Typography>
          <TextField
            id="date"
            type="date"
            inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
            InputLabelProps={{
              shrink: true,
            }}
            onKeyDown={(e) => e.preventDefault()}
            variant="outlined"
            size="small"
            fullWidth
            value={
              addViewStates?.validFrom
                ? format(parseISO(addViewStates?.validFrom), "yyyy-MM-dd")
                : ""
            }
            onChange={(e) =>
              setAddViewStates({
                ...addViewStates,
                validFrom: e.target.value
                    ? e.target.value + "T00:00:00.000+00:00"
                    : null,
              })
            }
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Valid To</Typography>
          <TextField
            id="date"
            type="date"
            inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
            InputLabelProps={{
              shrink: true,
            }}
            onKeyDown={(e) => e.preventDefault()}
            variant="outlined"
            size="small"
            fullWidth
            value={
              addViewStates?.validTo
                ? format(parseISO(addViewStates?.validTo), "yyyy-MM-dd")
                : ""
            }
            onChange={(e) =>
              setAddViewStates({
                ...addViewStates,
                validTo: e.target.value
                    ? e.target.value + "T00:00:00.000+00:00"
                    : null,
              })
            }
          />
        </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Transit Time</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  transitTime: e.target.value,
                })
              }
            />
          </Grid>
      
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Remark</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  remark: e.target.value,
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Carrier</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  carrier: e.target.value,
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Record Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-status"
              select
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  recordStatus: e.target.value,
                })
              }
            >
              {recordStatus.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Stack direction="row" spacing={1} mt={1}>
          <Button variant="outlined" onClick={() => setAddView(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              const newId = Math.floor(Math.random() * 100000);
              setAddViewStates({ ...addViewStates, id: newId });
              setRows([addViewStates, ...rows]);
              setAddView(false);

              createFreightOfferApi(addViewStates)
                .then((response) => {
                  toast.success("FreightOffer added successfully.");
                  window.location.reload();
                  navigate("/freight-offer-view");
                })
                .catch((error) => {
                  toast.error("Something went wrong.");
                  console.log(error.response.data);
                });
            }}
          >
            Add
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default AddFreightOffer;