import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Collapse,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { retrieveFilteredFreightOffersApi } from "../../../components/api/FreightOfferApiService";
import { retrieveFilteredShipmentRequestsApi } from "../../../components/api/ShipmentRequestApiService";
import { retrieveMaxShippingInstructionNumberApi } from "../../../components/api/ShippingInstructionApiService";
import { retrieveAllStakeholdersApi } from "../../../components/api/StakeholderApiService";
import { retrieveFilteredWarehouseRecordsApi } from "../../../components/api/WarehouseApiService";
import {
  freightOfferRemarks,
  portsOfDischarge,
  portsOfLoading,
  recordStatus,
  shippedBy,
  shippingInstructionStatus,
  shippingInstructionTypes,
} from "../../../utils/menu-items";

const AddShippingInstructionView = (props) => {
  const {
    key,
    index,
    addShippingInstructionArray,
    setAddShippingInstructionArray,
    offer,
  } = props;

  const [notifies, setNotifies] = useState([]);
  const [freightOffers, setFreightOffers] = useState([]);
  const [shipmentRequests, setShipmentRequests] = useState([]);

  const [shippers, setShippers] = useState([]);
  const [selectedShipper, setSelectedShipper] = useState(null);
  const [quantityCheckboxState, setQuantityCheckboxState] = useState({
    checked20: false,
    checked40: false,
    checked40HC: false,
    checkedFtl: false,
  });
  const [consignees, setConsignees] = useState([]);
  const [selectedConsignee, setSelectedConsignee] = useState(null);
  const [sameAsCustomerConsignee, setSameAsCustomerConsignee] = useState(false);
  const [selectedNotify, setSelectedNotify] = useState(null);
  const [sameAsCustomerNotify, setSameAsCustomerNotify] = useState(false);
  const [sameAsConsigneeNotify, setSameAsConsigneeNotify] = useState(false);
  const [selectedShipmentRequest, setSelectedShipmentRequest] = useState(
    addShippingInstructionArray[index]?.shipmentRequest || null,
  );

  const [warehouses, setWarehouses] = useState([]);
  const destination = addShippingInstructionArray[index]?.destination || "";
  const [selectedWarehouse, setSelectedWarehouse] = useState({
    name: destination,
  });
  const filterWarehouse = {
    recordStatusList: ["ACTIVE"],
  };

  function retrieveFilteredWarehouseRecords(filterWarehouse) {
    retrieveFilteredWarehouseRecordsApi(filterWarehouse)
      .then((response) => {
        setWarehouses(response?.data);
        return response?.data || [];
      })
      .catch((error) => console.log(error.response?.data));
  }

  const handleNotifyCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setSameAsCustomerNotify(isChecked);
    setSameAsConsigneeNotify(false);
    if (isChecked && selectedShipmentRequest?.salesOrder?.offer?.stakeholder) {
      setSelectedNotify(
        selectedShipmentRequest?.salesOrder?.offer?.stakeholder,
      );

      let dummyArr = addShippingInstructionArray;
      dummyArr[index] = {
        ...dummyArr[index],
        notifyId: selectedShipmentRequest?.salesOrder?.offer?.stakeholder?.id,
      };
      setAddShippingInstructionArray([...dummyArr]);
    } else {
      setSelectedNotify(null);
      let dummyArr = addShippingInstructionArray;
      dummyArr[index] = {
        ...dummyArr[index],
        notifyId: "",
      };
      setAddShippingInstructionArray([...dummyArr]);
    }
  };

  const handleNotifyConsigneeCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setSameAsConsigneeNotify(isChecked);
    setSameAsCustomerNotify(false);
    if (isChecked && selectedConsignee) {
      setSelectedNotify(selectedConsignee);

      let dummyArr = addShippingInstructionArray;
      dummyArr[index] = {
        ...dummyArr[index],
        notifyId: selectedConsignee?.id,
      };
      setAddShippingInstructionArray([...dummyArr]);
    } else {
      setSelectedNotify(null);
      let dummyArr = addShippingInstructionArray;
      dummyArr[index] = {
        ...dummyArr[index],
        notifyId: "",
      };
      setAddShippingInstructionArray([...dummyArr]);
    }
  };

  const handleConsigneeCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setSameAsCustomerConsignee(isChecked);

    if (isChecked && selectedShipmentRequest?.salesOrder?.offer?.stakeholder) {
      setSelectedConsignee(
        selectedShipmentRequest?.salesOrder?.offer?.stakeholder,
      );

      let dummyArr = addShippingInstructionArray;
      dummyArr[index] = {
        ...dummyArr[index],
        consigneeId:
          selectedShipmentRequest?.salesOrder?.offer?.stakeholder?.id,
      };
      setAddShippingInstructionArray([...dummyArr]);
    } else {
      setSelectedConsignee(null);
      let dummyArr = addShippingInstructionArray;
      dummyArr[index] = {
        ...dummyArr[index],
        consigneeId: "",
      };
      setAddShippingInstructionArray([...dummyArr]);
    }
  };

  const handleQuantityCheckboxChange = (event) => {
    setQuantityCheckboxState({
      ...quantityCheckboxState,
      [event.target.name]: event.target.checked,
    });
  };

  const handleShowHide = () => {
    let dummyArr = addShippingInstructionArray;
    dummyArr[index].show = !offer.show;
    setAddShippingInstructionArray([...dummyArr]);
  };

  const handleDelete = () => {
    let dummyArr = addShippingInstructionArray;
    dummyArr.splice(index, 1);
    setAddShippingInstructionArray([...dummyArr]);
  };

  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    const parsedDate = parseISO(selectedDate);

    if (!isNaN(parsedDate)) {
      let updatedArr = addShippingInstructionArray.map((item) => ({
        ...item,
        dateOfLoading: selectedDate,
        dateOfLoadingRemark: format(parsedDate, "dd/MMM/yyyy"),
      }));

      setAddShippingInstructionArray(updatedArr);
    } else {
      console.error("Invalid date:", selectedDate);
    }
  };

  function retrieveNotifies() {
    retrieveAllStakeholdersApi("NOTIFY")
      .then((response) => {
        setNotifies(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  const today = new Date().toISOString().split("T")[0];
  const filterFreightOffers = {
    validTo: today,
    recordStatusList: ["ACTIVE"],
  };

  function retrieveFreightOffers() {
    retrieveFilteredFreightOffersApi(filterFreightOffers)
      .then((response) => {
        setFreightOffers(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  const filterShipmentRequests = {
    approvalStatusList: ["Approved"],
    statusList: ["Open"],
    recordStatusList: ["ACTIVE"],
  };
  function retrieveShipmentRequests() {
    retrieveFilteredShipmentRequestsApi(filterShipmentRequests)
      .then((response) => {
        setShipmentRequests(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  const getDigit = (type) => {
    if (type === "HT" || type === "HD") {
      return 100;
    } else if (
      type === "ST" ||
      type === "SD" ||
      type === "B" ||
      type === "SL" ||
      type === "CT"
    ) {
      return 1000;
    } else {
      return 1000;
    }
  };

  function handleRetrieveSiNumber() {
    const rawShippingInstructionDate =
      addShippingInstructionArray[index]?.shippingInstructionDate;
    let currentYear;

    if (rawShippingInstructionDate) {
      const shippingInstructionDate = new Date(rawShippingInstructionDate);
      if (!isNaN(shippingInstructionDate)) {
        currentYear = shippingInstructionDate.getFullYear();
      } else {
        currentYear = new Date().getFullYear();
      }
    } else {
      currentYear = new Date().getFullYear();
    }
    retrieveMaxShippingInstructionNumberApi(
      addShippingInstructionArray[0]?.shippingInstructionType,
      currentYear,
    )
      .then((response) => {
        const siNumberFromApi = response?.data || 0;
        const rawShippingInstructionDate =
          addShippingInstructionArray[index]?.shippingInstructionDate;
        let currentYear;

        if (rawShippingInstructionDate) {
          const shippingInstructionDate = new Date(rawShippingInstructionDate);
          if (!isNaN(shippingInstructionDate)) {
            currentYear = shippingInstructionDate.getFullYear();
          } else {
            currentYear = new Date().getFullYear();
          }
        } else {
          currentYear = new Date().getFullYear();
        }
        const siType = offer?.shippingInstructionType || "";
        const calculatedNumber =
          (currentYear % 10) * getDigit(siType) + (siNumberFromApi + 1);
        const finalShippingInstructionNumber = `${siType}${calculatedNumber}`;

        let updatedArr = addShippingInstructionArray.map((item) => ({
          ...item,
          shippingInstructionNumber: siNumberFromApi + 1,
          finalShippingInstructionNumber: finalShippingInstructionNumber,
          isSiNumberRetrieved: true,
        }));

        setAddShippingInstructionArray(updatedArr);
      })
      .catch((error) => {
        console.log(error.response?.data);
        toast.error("Error retrieving SI number.");
      });
  }

  function retrieveShippers() {
    retrieveAllStakeholdersApi("SHIPPER")
      .then((response) => {
        setShippers(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveConsignees() {
    Promise.all([
      retrieveAllStakeholdersApi("CONSIGNEE"),
      retrieveAllStakeholdersApi("CUSTOMER"),
    ])
      .then(([consigneeResponse, customerResponse]) => {
        setConsignees([...consigneeResponse?.data, ...customerResponse?.data]);
      })
      .catch((error) => console.log(error.response?.data));
  }

  return (
    <>
      <Grid
        container
        item
        xs={12}
        display={"flex"}
        justifyContent={"end"}
        gap={1}
      >
        <Button
          variant="outlined"
          size="small"
          sx={{ minWidth: "80px" }}
          onClick={() => handleShowHide()}
        >
          {offer.show ? "Hide" : "Show"}
        </Button>
        <Button
          variant="contained"
          size="small"
          sx={{ minWidth: "80px" }}
          onClick={() => handleDelete()}
        >
          Delete
        </Button>
      </Grid>
      <Collapse in={offer.show} key={key}>
        <Grid container xs={12} spacing="8px">
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Select Shipment Request</Typography>
            <Autocomplete
              options={shipmentRequests}
              getOptionLabel={(option) => {
                const values = [
                  option?.id,
                  option?.purchaseOrder?.purchaseOrderNumber
                    ? `SAP PO Number: ${option.purchaseOrder?.purchaseOrderNumber}`
                    : null,
                  option?.purchaseOrder?.offer?.stakeholder?.name
                    ? `Supplier: ${option.purchaseOrder?.offer?.stakeholder?.name}`
                    : null,
                  option?.purchaseOrder?.offer?.item?.origin
                    ? `Origin: ${option.purchaseOrder?.offer?.item?.origin}`
                    : null,
                  option?.purchaseOrder?.offer?.item?.buyingGrade
                    ? `Buying Grade: ${option.purchaseOrder?.offer?.item?.buyingGrade}`
                    : null,
                  option?.status ? `${option.status}` : null,
                ].filter((value) => value);

                return values.join(" - ");
              }}
              value={selectedShipmentRequest}
              onChange={(event, newValue) => {
                setSelectedShipmentRequest(newValue);
                setSelectedWarehouse({
                  ...selectedWarehouse,
                  name: newValue?.destination,
                });
                let dummyArr = addShippingInstructionArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  shipmentRequest: newValue ? newValue : null,
                  shipmentRequestId: newValue ? newValue.id : "",
                  destination: newValue ? newValue.destination : "",
                  warehouse: newValue ? newValue.warehouse : "",
                  pointOfLoading: newValue ? newValue.portOfLoading : "",
                  pointOfUnloading: newValue ? newValue.portOfDischarge : "",
                  description: newValue
                    ? newValue?.salesOrder?.descriptionOfGoods
                    : "",
                };

                setAddShippingInstructionArray([...dummyArr]);

                if (sameAsCustomerConsignee && newValue) {
                  const stakeholder = newValue?.salesOrder?.offer?.stakeholder;
                  setSelectedConsignee(stakeholder);
                  if (stakeholder) {
                    let dummyArr = addShippingInstructionArray;
                    dummyArr[index] = {
                      ...dummyArr[index],
                      consigneeId: stakeholder?.id,
                    };
                    setAddShippingInstructionArray([...dummyArr]);
                  }
                }

                if (sameAsCustomerNotify && newValue) {
                  const stakeholder = newValue?.salesOrder?.offer?.stakeholder;
                  setSelectedNotify(stakeholder);

                  let dummyArr = addShippingInstructionArray;
                  dummyArr[index] = {
                    ...dummyArr[index],
                    notifyId: stakeholder?.id,
                  };
                  setAddShippingInstructionArray([...dummyArr]);
                }
              }}
              onOpen={retrieveShipmentRequests}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              )}
              renderTags={() => null}
            />
            {selectedShipmentRequest && (
              <Box mt={2}>
                <Chip
                  label={`ID: ${selectedShipmentRequest?.id || ""}`}
                  onDelete={() => {
                    setSelectedShipmentRequest(null);

                    let dummyArr = addShippingInstructionArray;
                    dummyArr[index] = {
                      ...dummyArr[index],
                      shipmentRequestId: "",
                      destination: "",
                      pointOfLoading: "",
                      pointOfUnloading: "",
                      description: "",
                    };
                    setAddShippingInstructionArray([...dummyArr]);
                  }}
                  style={{ marginBottom: "5px" }}
                />
                <br></br>
                <Chip
                  label={`Type: ${
                    selectedShipmentRequest?.shipmentRequestType || ""
                  }`}
                  style={{ marginBottom: "5px" }}
                />
                <br></br>
                <Chip
                  label={`Destination: ${
                    selectedShipmentRequest?.destination || ""
                  }`}
                  style={{ marginBottom: "5px" }}
                />
                <br></br>
                <Chip
                  label={`SAP PO Number: ${
                    selectedShipmentRequest?.purchaseOrder
                      ?.purchaseOrderNumber || ""
                  }`}
                  style={{ marginBottom: "5px" }}
                />
                <br></br>
                <Chip
                  label={`Supplier: ${
                    selectedShipmentRequest?.purchaseOrder?.offer?.stakeholder
                      ?.name || ""
                  }`}
                  style={{ marginBottom: "5px" }}
                />
                <br></br>
                <Chip
                  label={`SAP SO Number: ${
                    selectedShipmentRequest?.salesOrder?.salesOrderNumber || ""
                  }`}
                  style={{ marginBottom: "5px" }}
                />
                <br></br>
                <Chip
                  label={`Customer: ${
                    selectedShipmentRequest?.salesOrder?.offer?.stakeholder
                      ?.name || ""
                  }`}
                  style={{ marginBottom: "5px" }}
                />
                <br></br>
                <Chip
                  label={`Origin: ${
                    selectedShipmentRequest?.purchaseOrder?.offer?.item
                      ?.origin || ""
                  }`}
                  style={{ marginBottom: "5px" }}
                />
                <br></br>
                <Chip
                  label={`Buying Grade: ${
                    selectedShipmentRequest?.purchaseOrder?.offer?.item
                      ?.buyingGrade || ""
                  }`}
                  style={{ marginBottom: "5px" }}
                />
                <br></br>
                <Chip
                  label={`Status: ${selectedShipmentRequest?.status || ""}`}
                  style={{ marginBottom: "5px" }}
                />
                <br></br>
                <Chip
                  label={`Assigned To: ${
                    selectedShipmentRequest?.assignedUser?.name || ""
                  }`}
                  style={{ marginBottom: "5px" }}
                />
                <br></br>
              </Box>
            )}
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">SI Date</Typography>
            <TextField
              size="small"
              fullWidth
              variant="outlined"
              id="date"
              type="date"
              value={
                offer?.shippingInstructionDate
                  ? format(
                      parseISO(offer.shippingInstructionDate),
                      "yyyy-MM-dd",
                    )
                  : ""
              }
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              onChange={(e) => {
                let updatedArr = addShippingInstructionArray.map((item) => ({
                  ...item,
                  shippingInstructionDate: e.target.value,
                  isSiNumberRetrieved: false,
                }));

                setAddShippingInstructionArray(updatedArr);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">SI Type</Typography>
            <TextField
              key={offer?.shippingInstructionType || "default-1"}
              id="outlined-select-shipping-instruction-type"
              select
              variant="outlined"
              size="small"
              label={offer?.shippingInstructionType}
              fullWidth
              onChange={(e) => {
                let updatedArr = addShippingInstructionArray.map((item) => ({
                  ...item,
                  shippingInstructionType: e.target.value,
                  isSiNumberRetrieved: false,
                }));

                setAddShippingInstructionArray(updatedArr);
              }}
            >
              {shippingInstructionTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">SI Number</Typography>
            {addShippingInstructionArray[index]?.isSiNumberRetrieved ? (
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                type="number"
                value={
                  addShippingInstructionArray[index]?.shippingInstructionNumber
                }
                onChange={(e) => {
                  const siNumberValue = parseInt(e.target.value, 10);
                  const rawShippingInstructionDate =
                    addShippingInstructionArray[index]?.shippingInstructionDate;
                  let currentYear;

                  if (rawShippingInstructionDate) {
                    const shippingInstructionDate = new Date(
                      rawShippingInstructionDate,
                    );
                    if (!isNaN(shippingInstructionDate)) {
                      currentYear = shippingInstructionDate.getFullYear();
                    } else {
                      currentYear = new Date().getFullYear();
                    }
                  } else {
                    currentYear = new Date().getFullYear();
                  }
                  const siType =
                    addShippingInstructionArray[0]?.shippingInstructionType ||
                    "";
                  const calculatedNumber =
                    (currentYear % 10) * getDigit(siType) + siNumberValue;
                  const finalShippingInstructionNumber = `${siType}${calculatedNumber}`;

                  let updatedArr = addShippingInstructionArray.map((item) => ({
                    ...item,
                    shippingInstructionNumber: siNumberValue,
                    finalShippingInstructionNumber:
                      finalShippingInstructionNumber,
                    isSiNumberRetrieved: true,
                  }));

                  setAddShippingInstructionArray(updatedArr);
                }}
              />
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleRetrieveSiNumber}
              >
                Get Number
              </Button>
            )}
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Final SI Number</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={
                addShippingInstructionArray[index]
                  ?.finalShippingInstructionNumber || ""
              }
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Status</Typography>
            <TextField
              id="outlined-select-status"
              select
              variant="outlined"
              size="small"
              fullWidth
              label={addShippingInstructionArray[0]?.status}
              onChange={(e) => {
                let dummyArr = addShippingInstructionArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  status: e.target.value,
                };
                setAddShippingInstructionArray([...dummyArr]);
              }}
            >
              {shippingInstructionStatus.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Date Of Loading</Typography>
            <TextField
              size="small"
              fullWidth
              variant="outlined"
              id="date"
              type="date"
              value={offer?.dateOfLoading}
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              onChange={handleDateChange}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Date Of Loading Remark</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={offer?.dateOfLoadingRemark}
              onChange={(e) => {
                let updatedArr = addShippingInstructionArray.map((item) => ({
                  ...item,
                  dateOfLoadingRemark: e.target.value,
                }));

                setAddShippingInstructionArray(updatedArr);
              }}
            />
          </Grid>

          {(selectedShipmentRequest?.shipmentRequestType === "FWTC" ||
            selectedShipmentRequest?.shipmentRequestType === "FWTV" ||
            selectedShipmentRequest?.shipmentRequestType === "FWTW" ||
            selectedShipmentRequest?.shipmentRequestType === "FWTWCT" ||
            selectedShipmentRequest?.shipmentRequestType === "FWTINW" ||
            selectedShipmentRequest?.shipmentRequestType === "FWTIMP") && (
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Warehouse</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                value={offer?.warehouse || ""}
                disabled
              />
            </Grid>
          )}

          {(selectedShipmentRequest?.shipmentRequestType === "FVTW" ||
            selectedShipmentRequest?.shipmentRequestType === "FVTWCT" ||
            selectedShipmentRequest?.shipmentRequestType === "FVTIMP" ||
            selectedShipmentRequest?.shipmentRequestType === "FVTINW" ||
            selectedShipmentRequest?.shipmentRequestType === "FWTW" ||
            selectedShipmentRequest?.shipmentRequestType === "FWTWCT" ||
            selectedShipmentRequest?.shipmentRequestType === "FWTIMP" ||
            selectedShipmentRequest?.shipmentRequestType === "FWTINW") && (
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Destination</Typography>
              <Autocomplete
                options={warehouses || []}
                getOptionLabel={(option) =>
                  `ID: ${option?.id || ""}, Code: ${
                    option?.code || ""
                  }, Name: ${option?.name || ""}`
                }
                value={selectedWarehouse}
                onChange={(event, newValue) => {
                  setSelectedWarehouse(newValue);
                  let dummyArr = addShippingInstructionArray;
                  dummyArr[index] = {
                    ...dummyArr[index],
                    destination: newValue ? newValue?.name : "",
                  };
                  setAddShippingInstructionArray([...dummyArr]);
                }}
                onOpen={() => retrieveFilteredWarehouseRecords(filterWarehouse)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                )}
                renderTags={() => null}
              />
              {selectedWarehouse && (
                <Box mt={2}>
                  <Chip
                    label={`ID: ${selectedWarehouse?.id || ""}`}
                    onDelete={() => {
                      setSelectedWarehouse(null);
                      let dummyArr = addShippingInstructionArray;
                      dummyArr[index] = {
                        ...dummyArr[index],
                        destination: "",
                      };
                      setAddShippingInstructionArray([...dummyArr]);
                    }}
                    style={{ marginBottom: "5px" }}
                  />
                  <br></br>
                  <Chip
                    label={`Code: ${selectedWarehouse?.code || ""}`}
                    style={{ marginBottom: "5px" }}
                  />
                  <br />
                  <Chip
                    label={`Name: ${selectedWarehouse?.name || ""}`}
                    style={{ marginBottom: "5px" }}
                  />
                  <br />
                  <Chip
                    label={`Contact: ${
                      selectedWarehouse?.contactPerson?.contactPerson || ""
                    }`}
                    style={{ marginBottom: "5px" }}
                  />
                  <br />
                  <Chip
                    label={`Storage Pricing By ${
                      selectedWarehouse?.storagePricingBy || ""
                    }`}
                    style={{ marginBottom: "5px" }}
                  />
                  <br />
                </Box>
              )}
            </Grid>
          )}

          {(selectedShipmentRequest?.shipmentRequestType === "FVTC" ||
            selectedShipmentRequest?.shipmentRequestType === "FWTC") && (
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Destination</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                value={offer?.destination || ""}
                onChange={(e) => {
                  let dummyArr = addShippingInstructionArray;
                  dummyArr[index] = {
                    ...dummyArr[index],
                    destination: e.target.value,
                  };
                  setAddShippingInstructionArray([...dummyArr]);
                }}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Select Freight Offer</Typography>
            <Autocomplete
              key={
                addShippingInstructionArray[index]?.selectedFreightOffer?.id ||
                "default"
              }
              options={freightOffers}
              getOptionLabel={(option) => {
                const values = [
                  option?.id,
                  option?.forwarder,
                  option?.pointOfLoading,
                  option?.pointOfDischarge,
                  option?.fortyFtRate
                    ? `40Ft: ${option.fortyFtRate} ${
                        option?.fortyFtCurrency || ""
                      }`
                    : null,
                  option?.twentyFtRate
                    ? `20Ft: ${option.twentyFtRate} ${
                        option?.twentyFtCurrency || ""
                      }`
                    : null,
                  option?.transitTime ? `Transit: ${option.transitTime}` : null,
                  option?.validTo
                    ? `Valid To: ${
                        option.validTo
                          ? format(new Date(option?.validTo), "dd/MM/yyyy")
                          : ""
                      }`
                    : null,
                  option?.remark,
                ].filter((value) => value);

                return values.join(" - ");
              }}
              value={addShippingInstructionArray[index]?.selectedFreightOffer}
              onChange={(event, newValue) => {
                let updatedArr = addShippingInstructionArray.map((item) => ({
                  ...item,
                  freightOfferId: newValue ? newValue.id : "",
                  transportationContact: newValue
                    ? newValue.forwarder || ""
                    : "",
                  selectedFreightOffer: newValue,
                }));
                setAddShippingInstructionArray(updatedArr);
              }}
              onOpen={retrieveFreightOffers}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              )}
              renderTags={() => null}
            />
            {addShippingInstructionArray[index]?.selectedFreightOffer && (
              <Box mt={2}>
                <Chip
                  label={`ID: ${
                    addShippingInstructionArray[index]?.selectedFreightOffer
                      ?.id || ""
                  }`}
                  onDelete={() => {
                    let updatedArr = addShippingInstructionArray.map(
                      (item) => ({
                        ...item,
                        freightOfferId: "",
                        transportationContact: "",
                        selectedFreightOffer: null,
                      }),
                    );
                    setAddShippingInstructionArray(updatedArr);
                  }}
                  style={{ marginBottom: "5px" }}
                />
                <br></br>
                <Chip
                  label={`Forwarder: ${
                    addShippingInstructionArray[index]?.selectedFreightOffer
                      ?.forwarder || ""
                  }`}
                  style={{ marginBottom: "5px" }}
                />
                <br></br>
                <Chip
                  label={`Point Of Loading: ${
                    addShippingInstructionArray[index]?.selectedFreightOffer
                      ?.pointOfLoading || ""
                  }`}
                  style={{ marginBottom: "5px" }}
                />
                <br></br>
                <Chip
                  label={`Point Of Discharge: ${
                    addShippingInstructionArray[index]?.selectedFreightOffer
                      ?.pointOfDischarge || ""
                  }`}
                  style={{ marginBottom: "5px" }}
                />
                <br></br>
                <Chip
                  label={`40 Ft Rate: ${
                    addShippingInstructionArray[index]?.selectedFreightOffer
                      ?.fortyFtRate || ""
                  } ${
                    addShippingInstructionArray[index]?.selectedFreightOffer
                      ?.fortyFtCurrency || ""
                  }`}
                  style={{ marginBottom: "5px" }}
                />
                <br></br>
                <Chip
                  label={`20 Ft Rate: ${
                    addShippingInstructionArray[index]?.selectedFreightOffer
                      ?.twentyFtRate || ""
                  } ${
                    addShippingInstructionArray[index]?.selectedFreightOffer
                      ?.twentyFtCurrency || ""
                  }`}
                  style={{ marginBottom: "5px" }}
                />
                <br></br>
                <Chip
                  label={`Transit Time: ${
                    addShippingInstructionArray[index]?.selectedFreightOffer
                      ?.transitTime || ""
                  }`}
                  style={{ marginBottom: "5px" }}
                />
                <br></br>
                <Chip
                  label={`Valid To: ${
                    addShippingInstructionArray[index]?.selectedFreightOffer
                      ?.validTo
                      ? format(
                          new Date(
                            addShippingInstructionArray[
                              index
                            ]?.selectedFreightOffer?.validTo,
                          ),
                          "dd/MM/yyyy",
                        )
                      : ""
                  }`}
                  style={{ marginBottom: "5px" }}
                />
                <br></br>
                <Chip
                  label={`Remark: ${
                    addShippingInstructionArray[index]?.selectedFreightOffer
                      ?.remark || ""
                  }`}
                  style={{ marginBottom: "5px" }}
                />
                <br></br>
              </Box>
            )}
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Transportation Contact</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={offer?.transportationContact}
              onChange={(e) => {
                let dummyArr = addShippingInstructionArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  transportationContact: e.target.value,
                };
                setAddShippingInstructionArray([...dummyArr]);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Select Freight Offer Remark</Typography>
            <TextField
              id="outlined-select-freight-remark"
              select
              variant="outlined"
              size="small"
              label={addShippingInstructionArray[index]?.freightRemark}
              fullWidth
              onChange={(e) => {
                let updatedArr = addShippingInstructionArray.map((item) => ({
                  ...item,
                  freightRemark: e.target.value,
                }));

                setAddShippingInstructionArray(updatedArr);
              }}
            >
              {freightOfferRemarks.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid container xs={12} spacing="8px">
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Carton Range</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) => {
                let dummyArr = addShippingInstructionArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  cartonNumber: e.target.value,
                };
                setAddShippingInstructionArray([...dummyArr]);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Number Of Cartons</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="number"
              value={selectedShipmentRequest?.numberOfCases || ""}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Net Weight Per Carton</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="number"
              value={selectedShipmentRequest?.netWeightPerCase || ""}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Shipment Quantity</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={
                selectedShipmentRequest?.shipmentQuantity
                  ? `${new Intl.NumberFormat("de-DE", {
                      style: "decimal",
                      minimumFractionDigits: 2,
                    }).format(selectedShipmentRequest.shipmentQuantity)} ${
                      selectedShipmentRequest?.shipmentQuantityUnit
                    }`
                  : ""
              }
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Gross Weight</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="number"
              onWheel={(e) => e.target.blur()}
              onChange={(e) => {
                let dummyArr = addShippingInstructionArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  grossWeight: e.target.value,
                };
                setAddShippingInstructionArray([...dummyArr]);
              }}
            />
          </Grid>
        </Grid>
        <Grid container xs={12} spacing="8px">
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">To Be Shipped By</Typography>
            <TextField
              id="outlined-select-shipped-by"
              select
              variant="outlined"
              size="small"
              label={offer?.shippedBy}
              fullWidth
              onChange={(e) => {
                let updatedArr = addShippingInstructionArray.map((item) => ({
                  ...item,
                  shippedBy: e.target.value,
                }));

                setAddShippingInstructionArray(updatedArr);
              }}
            >
              {shippedBy.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Shipper</Typography>
            <Autocomplete
              options={shippers}
              getOptionLabel={(option) =>
                `${option?.id || ""} - ${option?.name || ""}`
              }
              value={selectedShipper}
              onChange={(event, newValue) => {
                setSelectedShipper(newValue);

                let updatedArr = addShippingInstructionArray.map((item) => ({
                  ...item,
                  shipperId: newValue ? newValue.id : "",
                }));

                setAddShippingInstructionArray(updatedArr);
              }}
              onOpen={retrieveShippers}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              )}
              renderTags={() => null}
            />
            {selectedShipper && (
              <Box mt={2}>
                <Chip
                  label={`${selectedShipper?.id || ""} - ${
                    selectedShipper?.name || ""
                  }`}
                  onDelete={() => setSelectedShipper(null)}
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={0.66}>
            <Typography variant="body2" style={{ visibility: "hidden" }}>
              Quantity
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  name="checked20"
                  checked={quantityCheckboxState.checked20}
                  onChange={handleQuantityCheckboxChange}
                />
              }
              label="20"
            />
          </Grid>
          {quantityCheckboxState.checked20 && (
            <Grid item xs={12} sm={1.34}>
              <Typography variant="body2" style={{ visibility: "hidden" }}>
                20"
              </Typography>
              <TextField
                label="Number Of Containers"
                variant="outlined"
                fullWidth
                type="number"
              onWheel={(e) => e.target.blur()}
                onChange={(e) => {
                  let dummyArr = addShippingInstructionArray;
                  dummyArr[index] = {
                    ...dummyArr[index],
                    toBeShippedBy20: e.target.value,
                  };
                  setAddShippingInstructionArray([...dummyArr]);
                }}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={0.66}>
            <Typography variant="body2" style={{ visibility: "hidden" }}>
              Quantity
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  name="checked40"
                  checked={quantityCheckboxState.checked40}
                  onChange={handleQuantityCheckboxChange}
                />
              }
              label="40"
            />
          </Grid>
          {quantityCheckboxState.checked40 && (
            <Grid item xs={12} sm={1.34}>
              <Typography variant="body2" style={{ visibility: "hidden" }}>
                40"
              </Typography>
              <TextField
                label="Number Of Containers"
                variant="outlined"
                fullWidth
                type="number"
              onWheel={(e) => e.target.blur()}
                onChange={(e) => {
                  let dummyArr = addShippingInstructionArray;
                  dummyArr[index] = {
                    ...dummyArr[index],
                    toBeShippedBy40: e.target.value,
                  };
                  setAddShippingInstructionArray([...dummyArr]);
                }}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={0.66}>
            <Typography variant="body2" style={{ visibility: "hidden" }}>
              Quantity
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  name="checked40HC"
                  checked={quantityCheckboxState.checked40HC}
                  onChange={handleQuantityCheckboxChange}
                />
              }
              label="40HC"
            />
          </Grid>
          {quantityCheckboxState.checked40HC && (
            <Grid item xs={12} sm={1.34}>
              <Typography variant="body2" style={{ visibility: "hidden" }}>
                40HC
              </Typography>
              <TextField
                label="Number Of Containers"
                variant="outlined"
                fullWidth
                type="number"
              onWheel={(e) => e.target.blur()}
                onChange={(e) => {
                  let dummyArr = addShippingInstructionArray;
                  dummyArr[index] = {
                    ...dummyArr[index],
                    toBeShippedBy40HC: e.target.value,
                  };
                  setAddShippingInstructionArray([...dummyArr]);
                }}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={0.66}>
            <Typography variant="body2" style={{ visibility: "hidden" }}>
              Quantity
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  name="checkedFtl"
                  checked={quantityCheckboxState.checkedFtl}
                  onChange={handleQuantityCheckboxChange}
                />
              }
              label="FTL"
            />
          </Grid>

          {quantityCheckboxState.checkedFtl && (
            <Grid item xs={12} sm={1.34}>
              <Typography variant="body2" style={{ visibility: "hidden" }}>
                FTL
              </Typography>
              <TextField
                label="Number Of Containers"
                variant="outlined"
                fullWidth
                type="number"
              onWheel={(e) => e.target.blur()}
                onChange={(e) => {
                  let dummyArr = addShippingInstructionArray;
                  dummyArr[index] = {
                    ...dummyArr[index],
                    toBeShippedByFtl: e.target.value,
                  };
                  setAddShippingInstructionArray([...dummyArr]);
                }}
              />
            </Grid>
          )}
        </Grid>
        <Grid container xs={12} spacing="8px">
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Bill Of Lading Number</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) => {
                let dummyArr = addShippingInstructionArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  billOfLadingNumber: e.target.value,
                };
                setAddShippingInstructionArray([...dummyArr]);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Bill Of Lading Date</Typography>
            <TextField
              size="small"
              fullWidth
              variant="outlined"
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              onChange={(e) => {
                let dummyArr = addShippingInstructionArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  billOfLadingDate: e.target.value,
                };
                setAddShippingInstructionArray([...dummyArr]);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">CMR Number</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={offer?.cmrNumber}
              onChange={(e) => {
                let dummyArr = addShippingInstructionArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  cmrNumber: e.target.value,
                };
                setAddShippingInstructionArray([...dummyArr]);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">CMR Date</Typography>
            <TextField
              size="small"
              fullWidth
              variant="outlined"
              id="date"
              type="date"
              value={offer?.cmrDate}
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              onChange={(e) => {
                let dummyArr = addShippingInstructionArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  cmrDate: e.target.value,
                };
                setAddShippingInstructionArray([...dummyArr]);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">ETD</Typography>
            <TextField
              size="small"
              fullWidth
              variant="outlined"
              id="date"
              type="date"
              value={
                offer?.estimatedTimeOfDeparture
                  ? format(
                      parseISO(offer.estimatedTimeOfDeparture),
                      "yyyy-MM-dd",
                    )
                  : ""
              }
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              onChange={(e) => {
                let dummyArr = addShippingInstructionArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  estimatedTimeOfDeparture: e.target.value,
                };
                setAddShippingInstructionArray([...dummyArr]);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">ETA</Typography>
            <TextField
              size="small"
              fullWidth
              variant="outlined"
              id="date"
              type="date"
              value={
                offer?.estimatedTimeOfArrival
                  ? format(parseISO(offer.estimatedTimeOfArrival), "yyyy-MM-dd")
                  : ""
              }
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              onChange={(e) => {
                let dummyArr = addShippingInstructionArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  estimatedTimeOfArrival: e.target.value,
                };
                setAddShippingInstructionArray([...dummyArr]);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Consignee</Typography>
            <Autocomplete
              options={consignees}
              getOptionLabel={(option) =>
                `${option?.id || ""} - ${option?.type || ""} - ${
                  option?.name || ""
                }`
              }
              value={selectedConsignee}
              onChange={(event, newValue) => {
                setSelectedConsignee(newValue);

                let updatedArr = addShippingInstructionArray.map((item) => ({
                  ...item,
                  consigneeId: newValue ? newValue.id : "",
                }));

                setAddShippingInstructionArray(updatedArr);
              }}
              onOpen={retrieveConsignees}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              )}
              renderTags={() => null}
            />
            {selectedConsignee && (
              <Box mt={2}>
                <Chip
                  label={`${selectedConsignee?.id || ""} - ${
                    selectedConsignee?.type || ""
                  } - ${selectedConsignee?.name || ""}`}
                  style={{ marginBottom: "5px" }}
                  onDelete={() => setSelectedConsignee(null)}
                />{" "}
                <br></br>
                <Chip
                  label={`SAP Code: ${selectedConsignee.code || ""}`}
                  style={{ marginBottom: "5px" }}
                />
                <br></br>
                <Chip
                  label={`Reference Customer: ${
                    selectedConsignee?.reference || ""
                  }`}
                  style={{ marginBottom: "5px" }}
                />
                <br></br>
              </Box>
            )}
            <FormControlLabel
              control={
                <Checkbox
                  checked={sameAsCustomerConsignee}
                  onChange={handleConsigneeCheckboxChange}
                  color="primary"
                />
              }
              label="Same As Customer"
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Notify Party(ies)</Typography>
            <Autocomplete
              options={notifies}
              getOptionLabel={(option) =>
                `${option?.id || ""} - ${option?.name || ""}`
              }
              value={selectedNotify}
              onChange={(event, newValue) => {
                setSelectedNotify(newValue);

                let updatedArr = addShippingInstructionArray.map((item) => ({
                  ...item,
                  notifyId: newValue ? newValue.id : "",
                }));

                setAddShippingInstructionArray(updatedArr);
              }}
              onOpen={retrieveNotifies}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              )}
              renderTags={() => null}
            />
            {selectedNotify && (
              <Box mt={2}>
                <Chip
                  label={`${selectedNotify?.id || ""} - ${
                    selectedNotify?.name || ""
                  }`}
                  onDelete={() => setSelectedNotify(null)}
                />
              </Box>
            )}
            <FormControlLabel
              control={
                <Checkbox
                  checked={sameAsCustomerNotify}
                  onChange={handleNotifyCheckboxChange}
                  color="primary"
                />
              }
              label="Same As Customer"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={sameAsConsigneeNotify}
                  onChange={handleNotifyConsigneeCheckboxChange}
                  color="primary"
                />
              }
              label="Same As Consignee"
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Point Of Loading</Typography>
            <TextField
              id="outlined-select-pol"
              variant="outlined"
              size="small"
              fullWidth
              select
              label={addShippingInstructionArray[index].pointOfLoading}
              onChange={(e) => {
                let updatedArr = addShippingInstructionArray.map((item) => ({
                  ...item,
                  pointOfLoading: e.target.value,
                }));

                setAddShippingInstructionArray(updatedArr);
              }}
            >
              {portsOfLoading.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Point Of Unloading</Typography>
            <TextField
              id="outlined-select-pod"
              variant="outlined"
              size="small"
              fullWidth
              select
              label={addShippingInstructionArray[index].pointOfUnloading}
              onChange={(e) => {
                let updatedArr = addShippingInstructionArray.map((item) => ({
                  ...item,
                  pointOfUnloading: e.target.value,
                }));
                setAddShippingInstructionArray(updatedArr);
              }}
            >
              {portsOfDischarge.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Description</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={offer?.description || ""}
              onChange={(e) => {
                let dummyArr = addShippingInstructionArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  description: e.target.value,
                };
                setAddShippingInstructionArray([...dummyArr]);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Revision</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={offer?.revision}
              onChange={(e) => {
                let updatedArr = addShippingInstructionArray.map((item) => ({
                  ...item,
                  revision: e.target.value,
                }));

                setAddShippingInstructionArray(updatedArr);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Remark</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) => {
                let dummyArr = addShippingInstructionArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  remark: e.target.value,
                };
                setAddShippingInstructionArray([...dummyArr]);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Link</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={offer?.link}
              onChange={(e) => {
                let updatedArr = addShippingInstructionArray.map((item) => ({
                  ...item,
                  link: e.target.value,
                }));

                setAddShippingInstructionArray(updatedArr);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Record Status</Typography>
            <TextField
              id="outlined-select-status"
              select
              disabled
              variant="outlined"
              size="small"
              label={"ACTIVE"}
              fullWidth
              onChange={(e) => {
                let dummyArr = addShippingInstructionArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  recordStatus: e.target.value,
                };
                setAddShippingInstructionArray([...dummyArr]);
              }}
            >
              {recordStatus.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};

export default AddShippingInstructionView;
