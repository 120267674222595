import {
  Button,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import React, { useState } from "react";
import { eurUsdExchangeApi, updateCostApi } from "../../../components/api/CostApiService";
import { currencies, incoterms, status, units, recordStatus } from "../../../utils/menu-items";
import toast from "react-hot-toast";

const EditCost = (props) => {
  const { editCost, setEditCost } = props;

  const initialCurrencyState = {
    basePriceCurrency: "USD",
    freightCurrency: "USD",
    insuranceCurrency: "USD",
    storageCostCurrency: "USD",
    financeCostCurrency: "USD",
    othersCurrency: "USD",
    firstCommisionCurrency: "USD",
    secondCommisionCurrency: "USD",
    thirdCommisionCurrency: "USD",
    profitCurrency: "USD",
  };
  
  const [currenciesState, setCurrenciesState] = useState(initialCurrencyState);
  
  const editCostFieldMap = {
    basePriceCurrency: "basePrice",
    freightCurrency: "freightCost",
    insuranceCurrency: "insuranceCost",
    storageCostCurrency: "storageCost",
    financeCostCurrency: "financeCost",
    othersCurrency: "others",
    firstCommisionCurrency: "firstCommision",
    secondCommisionCurrency: "secondCommision",
    thirdCommisionCurrency: "thirdCommision",
    profitCurrency: "profit",
  };

  const handleCurrencyExchange = async (field, amount) => {
    const editCostField = editCostFieldMap[field];
    const currentCurrency = editCost?.data?.[field];
    const targetCurrency = currentCurrency === "EUR" ? "USD" : "EUR";
    
    if (!currentCurrency) {
      console.log(`Currency type for field ${field} is undefined`);
      return;
    }

    try {
      const response = await eurUsdExchangeApi(currentCurrency, amount);
      const updatedCost = response?.data;
      setEditCost((prevCost) => ({
        ...prevCost,
        data: {
          ...prevCost.data,
          [editCostField]: updatedCost,
          [field]: targetCurrency,
        }
      }));
    } catch (error) {
      console.error(`Failed to exchange currency: ${error.response?.data || error.message}`);
      setEditCost((prevCost) => ({
        ...prevCost,
        data: {
          ...prevCost.data,
          [editCostField]: prevCost.data[editCostField],
          [field]: prevCost.data[field],
        }
      }));
    }
  };

  return (
    <>
      <Stack direcion="column">
        <Typography variant="body1" mb={2}>
          Edit Cost
        </Typography>
        <Grid container item xs={12} spacing="8px">
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Base Price</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={editCost?.data?.basePrice}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setEditCost({
                  ...editCost,
                  data: {
                    ...editCost.data,
                    basePrice: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Base Price Currency</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-incoterm-price-currency"
              select
              label={editCost?.data?.basePriceCurrency || ""}
              value={editCost?.data?.basePriceCurrency || ""}
              onChange={(e) =>
                setEditCost({
                  ...editCost,
                  data: {
                    ...editCost.data,
                    basePriceCurrency: e.target.value,
                  },
                })
              }
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={1}>
  <Typography variant="body2">Exchange</Typography>
  <Button
    variant="outlined"
    disabled={
      editCost?.data?.basePriceCurrency === "USD"
    }
    onClick={() => handleCurrencyExchange("basePriceCurrency", editCost?.data?.basePrice || 0.0)}
  >
    $
  </Button>
  <Button
    variant="outlined"
    disabled={
      editCost?.data?.basePriceCurrency === "EUR"
    }
    onClick={() => handleCurrencyExchange("basePriceCurrency", editCost?.data?.basePrice || 0.0)}
  >
    €
  </Button>
</Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Price Per Container</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={editCost?.data?.pricePerContainer}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setEditCost({
                  ...editCost,
                  data: {
                    ...editCost.data,
                  pricePerContainer: e.target.value,
                  freightCost: (e.target.value / editCost?.data?.kgPerContainer).toFixed(2),
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Kg Per Container</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={editCost?.data?.kgPerContainer}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setEditCost({
                  ...editCost,
                  data: {
                    ...editCost.data,
                  kgPerContainer: e.target.value,
                  freightCost: (editCost?.data?.pricePerContainer / e.target.value).toFixed(2),
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Freight Cost Per Kg</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="number"
              value={editCost?.data?.freightCost}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setEditCost({
                  ...editCost,
                  data: {
                    ...editCost.data,
                    freightCost: e.target.value,
                  },
                })
              }
            />
          </Grid>      
          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Freight Cost Currency</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-freight-cost-currency"
              select
              value={editCost?.data?.freightCurrency}
              label={editCost?.data?.freightCurrency}
              onChange={(e) =>
                setEditCost({
                  ...editCost,
                  data: {
                    ...editCost.data,
                    freightCurrency: e.target.value,
                  },
                })
              }
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={1}>
  <Typography variant="body2">Exchange</Typography>
  <Button
    variant="outlined"
    disabled={
      editCost?.data?.freightCurrency === "USD"
    }
    onClick={() => handleCurrencyExchange("freightCurrency", editCost?.data?.freightCost || 0.0)}
  >
    $
  </Button>
  <Button
    variant="outlined"
    disabled={
      editCost?.data?.freightCurrency === "EUR"
    }
    onClick={() => handleCurrencyExchange("freightCurrency", editCost?.data?.freightCost || 0.0)}
  >
    €
  </Button>
</Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Freight Offer By</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editCost?.data?.freightOfferBy}
              onChange={(e) =>
                setEditCost({
                  ...editCost,
                  data: {
                    ...editCost.data,
                    freightOfferBy: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Freight Offer Date</Typography>
            <TextField
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              variant="outlined"
              size="small"
              fullWidth
              value={
                editCost?.data?.freightOfferDate
                  ? format(
                      parseISO(editCost.data.freightOfferDate),
                      "yyyy-MM-dd",
                    )
                  : ""
              }
              onChange={(e) =>
                setEditCost({
                  ...editCost,
                  data: {
                    ...editCost.data,
                    freightOfferDate: e.target.value
                      ? e.target.value + "T00:00:00.000+00:00"
                      : null,
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Insurance Cost</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="number"
              value={editCost?.data?.insuranceCost}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setEditCost({
                  ...editCost,
                  data: {
                    ...editCost.data,
                    insuranceCost: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Insurance Cost Currency</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-insurance-cost-currency"
              select
              value={editCost?.data?.insuranceCurrency}
              label={editCost?.data?.insuranceCurrency}
              onChange={(e) =>
                setEditCost({
                  ...editCost,
                  data: {
                    ...editCost.data,
                    insuranceCurrency: e.target.value,
                  },
                })
              }
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={1}>
  <Typography variant="body2">Exchange</Typography>
  <Button
    variant="outlined"
    disabled={
      editCost?.data?.insuranceCurrency === "USD"
    }
    onClick={() => handleCurrencyExchange("insuranceCurrency", editCost?.data?.insuranceCost || 0.0)}
  >
    $
  </Button>
  <Button
    variant="outlined"
    disabled={
      editCost?.data?.insuranceCurrency === "EUR"
    }
    onClick={() => handleCurrencyExchange("insuranceCurrency", editCost?.data?.insuranceCost || 0.0)}
  >
    €
  </Button>
</Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Storage Cost</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="number"
              value={editCost?.data?.storageCost}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setEditCost({
                  ...editCost,
                  data: {
                    ...editCost.data,
                    storageCost: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Storage Cost Currency</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-storage-cost-currency"
              select
              value={editCost?.data?.storageCostCurrency}
              label={editCost?.data?.storageCostCurrency}
              onChange={(e) =>
                setEditCost({
                  ...editCost,
                  data: {
                    ...editCost.data,
                    storageCostCurrency: e.target.value,
                  },
                })
              }
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={1}>
  <Typography variant="body2">Exchange</Typography>
  <Button
    variant="outlined"
    disabled={
      editCost?.data?.storageCostCurrency === "USD"
    }
    onClick={() => handleCurrencyExchange("storageCostCurrency", editCost?.data?.storageCost || 0.0)}
  >
    $
  </Button>
  <Button
    variant="outlined"
    disabled={
      editCost?.data?.storageCostCurrency === "EUR"
    }
    onClick={() => handleCurrencyExchange("storageCostCurrency", editCost?.data?.storageCost || 0.0)}
  >
    €
  </Button>
</Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Finance Cost</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="number"
              value={editCost?.data?.financeCost}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setEditCost({
                  ...editCost,
                  data: {
                    ...editCost.data,
                    financeCost: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Finance Cost Currency</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-finance-cost-currency"
              select
              value={editCost?.data?.financeCostCurrency}
              label={editCost?.data?.financeCostCurrency}
              onChange={(e) =>
                setEditCost({
                  ...editCost,
                  data: {
                    ...editCost.data,
                    financeCostCurrency: e.target.value,
                  },
                })
              }
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={1}>
  <Typography variant="body2">Exchange</Typography>
  <Button
    variant="outlined"
    disabled={
      editCost?.data?.financeCostCurrency === "USD"
    }
    onClick={() => handleCurrencyExchange("financeCostCurrency", editCost?.data?.financeCost || 0.0)}
  >
    $
  </Button>
  <Button
    variant="outlined"
    disabled={
      editCost?.data?.financeCostCurrency === "EUR"
    }
    onClick={() => handleCurrencyExchange("financeCostCurrency", editCost?.data?.financeCost || 0.0)}
  >
    €
  </Button>
</Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Others</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="number"
              value={editCost?.data?.others}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setEditCost({
                  ...editCost,
                  data: {
                    ...editCost.data,
                    others: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Other Costs Currency</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-other-cost-currency"
              select
              value={editCost?.data?.othersCurrency}
              label={editCost?.data?.othersCurrency}
              onChange={(e) =>
                setEditCost({
                  ...editCost,
                  data: {
                    ...editCost.data,
                    othersCurrency: e.target.value,
                  },
                })
              }
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={1}>
  <Typography variant="body2">Exchange</Typography>
  <Button
    variant="outlined"
    disabled={
      editCost?.data?.othersCurrency === "USD"
    }
    onClick={() => handleCurrencyExchange("othersCurrency", editCost?.data?.others || 0.0)}
  >
    $
  </Button>
  <Button
    variant="outlined"
    disabled={
      editCost?.data?.othersCurrency === "EUR"
    }
    onClick={() => handleCurrencyExchange("othersCurrency", editCost?.data?.others || 0.0)}
  >
    €
  </Button>
</Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Other Costs Remark</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editCost?.data?.othersRemark}
              onChange={(e) =>
                setEditCost({
                  ...editCost,
                  data: {
                    ...editCost.data,
                    othersRemark: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">First Commision</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="number"
              value={editCost?.data?.firstCommision}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setEditCost({
                  ...editCost,
                  data: {
                    ...editCost.data,
                    firstCommision: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <Typography variant="body2">First Commision Currency</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-first-commision-currency"
              select
              value={editCost?.data?.firstCommisionCurrency}
              label={editCost?.data?.firstCommisionCurrency}
              onChange={(e) =>
                setEditCost({
                  ...editCost,
                  data: {
                    ...editCost.data,
                    firstCommisionCurrency: e.target.value,
                  },
                })
              }
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={1}>
  <Typography variant="body2">Exchange</Typography>
  <Button
    variant="outlined"
    disabled={
      editCost?.data?.firstCommisionCurrency === "USD"
    }
    onClick={() => handleCurrencyExchange("firstCommisionCurrency", editCost?.data?.firstCommision || 0.0)}
  >
    $
  </Button>
  <Button
    variant="outlined"
    disabled={
      editCost?.data?.firstCommisionCurrency === "EUR"
    }
    onClick={() => handleCurrencyExchange("firstCommisionCurrency", editCost?.data?.firstCommision || 0.0)}
  >
    €
  </Button>
</Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Second Commision</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="number"
              value={editCost?.data?.secondCommision}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setEditCost({
                  ...editCost,
                  data: {
                    ...editCost.data,
                    secondCommision: e.target.value,
                  },
                })
              }
            />
          </Grid>
          
          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Second Commision Currency</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-second-commision-currency"
              select
              value={editCost?.data?.secondCommisionCurrency}
              label={editCost?.data?.secondCommisionCurrency}
              onChange={(e) =>
                setEditCost({
                  ...editCost,
                  data: {
                    ...editCost.data,
                    secondCommisionCurrency: e.target.value,
                  },
                })
              }
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={1}>
  <Typography variant="body2">Exchange</Typography>
  <Button
    variant="outlined"
    disabled={
      editCost?.data?.secondCommisionCurrency === "USD"
    }
    onClick={() => handleCurrencyExchange("secondCommisionCurrency", editCost?.data?.secondCommision || 0.0)}
  >
    $
  </Button>
  <Button
    variant="outlined"
    disabled={
      editCost?.data?.secondCommisionCurrency === "EUR"
    }
    onClick={() => handleCurrencyExchange("secondCommisionCurrency", editCost?.data?.secondCommision || 0.0)}
  >
    €
  </Button>
</Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Third Commision</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="number"
              value={editCost?.data?.thirdCommision}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setEditCost({
                  ...editCost,
                  data: {
                    ...editCost.data,
                    thirdCommision: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Third Commision Currency</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-third-commision-currency"
              select
              value={editCost?.data?.thirdCommisionCurrency}
              label={editCost?.data?.thirdCommisionCurrency}
              onChange={(e) =>
                setEditCost({
                  ...editCost,
                  data: {
                    ...editCost.data,
                    thirdCommisionCurrency: e.target.value,
                  },
                })
              }
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={1}>
  <Typography variant="body2">Exchange</Typography>
  <Button
    variant="outlined"
    disabled={
      editCost?.data?.thirdCommisionCurrency === "USD"
    }
    onClick={() => handleCurrencyExchange("thirdCommisionCurrency", editCost?.data?.thirdCommision || 0.0)}
  >
    $
  </Button>
  <Button
    variant="outlined"
    disabled={
      editCost?.data?.thirdCommisionCurrency === "EUR"
    }
    onClick={() => handleCurrencyExchange("thirdCommisionCurrency", editCost?.data?.thirdCommision || 0.0)}
  >
    €
  </Button>
</Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Total Unit Cost Before Profit</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="number"
              value={editCost?.data?.totalCost}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setEditCost({
                  ...editCost,
                  data: {
                    ...editCost.data,
                    totalCost: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Profit</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={editCost?.data?.profit}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setEditCost({
                  ...editCost,
                  data: {
                    ...editCost.data,
                    profit: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Profit Currency</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-profit-currency"
              select
              value={editCost?.data?.profitCurrency}
              label={editCost?.data?.profitCurrency}
              onChange={(e) =>
                setEditCost({
                  ...editCost,
                  data: {
                    ...editCost.data,
                    profitCurrency: e.target.value,
                  },
                })
              }
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={1}>
  <Typography variant="body2">Exchange</Typography>
  <Button
    variant="outlined"
    disabled={
      editCost?.data?.profitCurrency === "USD"
    }
    onClick={() => handleCurrencyExchange("profitCurrency", editCost?.data?.profit || 0.0)}
  >
    $
  </Button>
  <Button
    variant="outlined"
    disabled={
      editCost?.data?.profitCurrency === "EUR"
    }
    onClick={() => handleCurrencyExchange("profitCurrency", editCost?.data?.profit || 0.0)}
  >
    €
  </Button>
</Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Total Unit Cost After Profit</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="number"
              value={editCost?.data?.totalOfferCost}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setEditCost({
                  ...editCost,
                  data: {
                    ...editCost.data,
                    totalOfferCost: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Remark</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editCost?.data?.remark}
              onChange={(e) =>
                setEditCost({
                  ...editCost,
                  data: {
                    ...editCost.data,
                    remark: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-status"
              select
              value={editCost?.data?.status}
              onChange={(e) =>
                setEditCost({
                  ...editCost,
                  data: {
                    ...editCost.data,
                    status: e.target.value,
                  },
                })
              }
            >
              {recordStatus.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Stack direction="row" spacing={1} mt={1}>
          <Button
            variant="outlined"
            onClick={() =>
              setEditCost({ visible: false, data: null, index: null })
            }
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              updateCostApi(editCost.data.id, editCost.data)
                .then((response) => {
                  toast.success("Cost edited successfully.");
                  window.location.reload();

                  setEditCost({ visible: false, data: null, index: null });
                })
                .catch((error) => {
                  toast.error("Something went wrong.");
                  console.log(error.response.data);
                });
            }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              editCost.data.status = "PASSIVE";
              updateCostApi(editCost.data.id, editCost.data)
                .then((response) => {
                  toast.success("Cost deleted successfully.");
                  window.location.reload();
                  setEditCost({ visible: false, data: null, index: null });
                })
                .catch((error) => {
                  toast.error("Something went wrong.");
                  console.log(error.response.data);
                });
            }}
          >
            Delete
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default EditCost;
