import {
  Button,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { updateItemApi } from "../../../components/api/ItemApiService";
import {
  leafForm,
  origin,
  recordStatus,
  type,
} from "../../../utils/menu-items";
import CustomerDialog from "./Dialogs/ItemDialog";

const customers = [
  { id: "customerA", label: 1234 },
  { id: "customerB", label: 12345 },
  { id: "customerC", label: 123456 },
  { id: "customerD", label: 1234567 },
];

const EditItem = (props) => {
  const { editItem, setEditItem } = props;

  const [customerOpt, setCustomerOpt] = useState(customers);

  const [openCustomerDialog, setOpenCustomerDialog] = useState(false);
  return (
    <>
      <Stack direcion="column">
        <Typography variant="body1" mb={2}>
          Edit Item
        </Typography>
        <Grid container item xs={12} spacing="8px">
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Tobacco Code</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editItem?.data?.tobaccoCode}
              onChange={(e) =>
                setEditItem({
                  ...editItem,
                  data: {
                    ...editItem.data,
                    tobaccoCode: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Sales Grade</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editItem?.data?.salesGrade}
              onChange={(e) =>
                setEditItem({
                  ...editItem,
                  data: {
                    ...editItem.data,
                    salesGrade: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Buying Grade</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editItem?.data?.buyingGrade}
              onChange={(e) =>
                setEditItem({
                  ...editItem,
                  data: {
                    ...editItem.data,
                    buyingGrade: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Internal Grade</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editItem?.data?.internalGrade}
              onChange={(e) =>
                setEditItem({
                  ...editItem,
                  data: {
                    ...editItem.data,
                    internalGrade: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Origin</Typography>
            <Grid container item xs={12} spacing="8px">
              <Grid item xs={12} sm={9}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  id="outlined-select-origin"
                  select
                  label={editItem?.data?.origin}
                  onChange={(e) =>
                    setEditItem({
                      ...editItem,
                      data: {
                        ...editItem.data,
                        origin: e.target.value,
                      },
                    })
                  }
                >
                  {origin.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Button variant="outlined">New</Button>
              </Grid>
            </Grid>
          </Grid>
          
          {( (editItem?.data?.origin === "China") && 
                <Grid item xs={12} sm={2}>
          <Typography variant="body2">Province</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            value={editItem?.data?.province || ""}
            onChange={(e) =>
              setEditItem({
                ...editItem,
                data: {
                  ...editItem.data,
                  province: e.target.value,
                },
              })
            }
          />
        </Grid>
)}
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Type</Typography>
            <Grid container item xs={12} spacing="8px">
              <Grid item xs={12} sm={9}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  id="outlined-select-type"
                  select
                  label={editItem?.data?.type}
                  onChange={(e) =>
                    setEditItem({
                      ...editItem,
                      data: {
                        ...editItem.data,
                        type: e.target.value,
                      },
                    })
                  }
                >
                  {type.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Button variant="outlined">New</Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Leaf Form</Typography>
            <Grid container item xs={12} spacing="8px">
              <Grid item xs={12} sm={9}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  id="outlined-select-leaf-form"
                  select
                  label={editItem?.data?.leafForm}
                  onChange={(e) =>
                    setEditItem({
                      ...editItem,
                      data: {
                        ...editItem.data,
                        leafForm: e.target.value,
                      },
                    })
                  }
                >
                  {leafForm.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Button variant="outlined"> New</Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Crop Year</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editItem?.data?.crop}
              onChange={(e) =>
                setEditItem({
                  ...editItem,
                  data: {
                    ...editItem.data,
                    crop: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Current Location</Typography>
            <Grid container item xs={12} spacing="8px">
              <Grid item xs={12} sm={9}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  id="outlined-select-storage"
                  label={editItem?.data?.storage}
                  onChange={(e) =>
                    setEditItem({
                      ...editItem,
                      data: {
                        ...editItem.data,
                        storage: e.target.value,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Button variant="outlined">New</Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Nicotine</Typography>
            <TextField
              variant="outlined"
              size="small"
              type="number"
              fullWidth
              value={editItem?.data?.nicotine}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setEditItem({
                  ...editItem,
                  data: {
                    ...editItem.data,
                    nicotine: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Sugar</Typography>
            <TextField
              variant="outlined"
              size="small"
              type="number"
              fullWidth
              value={editItem?.data?.sugar}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setEditItem({
                  ...editItem,
                  data: {
                    ...editItem.data,
                    sugar: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Moisture</Typography>
            <TextField
              variant="outlined"
              size="small"
              type="number"
              fullWidth
              value={editItem?.data?.moisture}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setEditItem({
                  ...editItem,
                  data: {
                    ...editItem.data,
                    moisture: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2"> Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-status"
              select
              value={editItem?.data?.status}
              onChange={(e) =>
                setEditItem({
                  ...editItem,
                  data: {
                    ...editItem.data,
                    status: e.target.value,
                  },
                })
              }
            >
              {recordStatus.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Stack direction="row" spacing={1} mt={1}>
          <Button
            variant="outlined"
            onClick={() =>
              setEditItem({ visible: false, data: null, index: null })
            }
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              updateItemApi(editItem.data.id, editItem.data)
                .then((response) => {
                  toast.success("Item edited successfully.");
                  window.location.reload();

                  setEditItem({ visible: false, data: null, index: null });
                })
                .catch((error) => {
                  toast.error("Something went wrong.");
                  console.log(error.response.data);
                });
            }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              editItem.data.status = "PASSIVE";
              updateItemApi(editItem.data.id, editItem.data)
                .then((response) => {
                  toast.success("Item deleted successfully.");
                  window.location.reload();
                  setEditItem({ visible: false, data: null, index: null });
                })
                .catch((error) => {
                  toast.error("Something went wrong.");
                  console.log(error.response.data);
                });
            }}
          >
            Delete
          </Button>
        </Stack>
      </Stack>

      {openCustomerDialog && (
        <CustomerDialog
          open={openCustomerDialog}
          setOpen={setOpenCustomerDialog}
          customerOpt={customerOpt}
          setCustomerOpt={setCustomerOpt}
        />
      )}
    </>
  );
};

export default EditItem;
