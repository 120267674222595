import { ReloadOutlined } from "@ant-design/icons";
import {
  Autocomplete,
  Button,
  ButtonGroup,
  Chip,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import {
  createCostApi,
  retrieveAllCostsApi,
  retrieveCostByIdApi,
  updateCostApi,
} from "../../../../components/api/CostApiService";
import { createOrderApi } from "../../../../components/api/OrderApiService";
import { retrieveAllSamplesApi } from "../../../../components/api/SampleApiService";
import {
  companies,
  currencies,
  incoterms,
  packagingTypes,
  paymentTerm,
  portsOfLoading,
  portsOfDischarge,
  recordStatus,
  sampleStatus,
  status,
  units,
} from "../../../../utils/menu-items";
import { updateOfferApi } from "../../../../components/api/OfferApiService";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const options = ["New", "Edit"];

const CreateOrder = (props) => {
  const { setCreateOrder, createOrder } = props;
  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState({
    id: 0,
    offerId: createOrder?.data?.id,
    orderType: "CUSTOMER",
    volumeUnit: "KG",
    company:createOrder?.data?.company || "",
    finalPriceCurrency: createOrder?.data?.firstOfferPriceCurrency || "",
    costId: createOrder?.data?.estimatedCostId,
    itemId: createOrder?.data?.itemId,
    firstSampleId: createOrder?.data?.firstSampleId,
    firstSampleStatus: createOrder?.data?.firstSampleStatus,
    secondSampleId: createOrder?.data?.secondSampleId,
    statusDeadline: null,
    paymentTerm: createOrder?.data?.paymentTerm,
    incoterm: createOrder?.data?.incoterm,
    portOfDischarge: createOrder?.data?.portOfDischarge,
    portOfLoading: createOrder?.data?.portOfLoading,
    packaging: createOrder?.data?.packaging,
    netWeightPerCase: createOrder?.data?.netWeightPerCase,
    numberOfCases: createOrder?.data?.numberOfCases,
    finalPrice: createOrder?.data?.finalOfferPrice,
    volume: createOrder?.data?.volume,
  });

  useEffect(() => {
    if(createOrder?.data?.status === "First Refusal" || createOrder?.data?.status === "Optioned"){
    setCreateOrder({
      ...createOrder,
      data: {
        ...createOrder?.data,
          status: "Available",
          statusDeadline:null,
      },
    })}
    setCost({
      ...cost,
      basePriceCurrency: "USD",
    })
  }, []);

  function updateOffer(createOrder){
    updateOfferApi(createOrder?.data?.id, createOrder?.data)
    .then((response) => {
      window.location.reload();
      navigate("/customer-offer-view");
    })
    .catch((error) => {
      toast.error("Something went wrong. ", error.response.data);
      console.log(error.response.data);
    });
  }

  const calculateTotalCost = () => {
    const totalCost =
      (parseFloat(cost?.basePrice) || 0) +
      (parseFloat(cost?.freightCost) || 0) +
      (parseFloat(cost?.insuranceCost) || 0) +
      (parseFloat(cost?.storageCost) || 0) +
      (parseFloat(cost?.financeCost) || 0) +
      (parseFloat(cost?.others) || 0) +
      (parseFloat(cost?.firstCommision) || 0) +
      (parseFloat(cost?.secondCommision) || 0) +
      (parseFloat(cost?.thirdCommision) || 0)
    setCost({ ...cost, totalCost });
  };

  const calculateTotalCostWithProfit = () => {
    const totalOfferCost =
      (parseFloat(cost?.basePrice) || 0) +
      (parseFloat(cost?.freightCost) || 0) +
      (parseFloat(cost?.insuranceCost) || 0) +
      (parseFloat(cost?.storageCost) || 0) +
      (parseFloat(cost?.financeCost) || 0) +
      (parseFloat(cost?.others) || 0) +
      (parseFloat(cost?.firstCommision) || 0) +
      (parseFloat(cost?.secondCommision) || 0) +
      (parseFloat(cost?.thirdCommision) || 0) +
      (parseFloat(cost?.profit) || 0)
    setCost({ ...cost, totalOfferCost });
  };

  const navigate = useNavigate();

  const [costs, setCosts] = useState([]);
  const [samples, setSamples] = useState([]);
  const [selectedSecondSample, setSelectedSecondSample] = useState(null);
  const [selectedCost, setSelectedCost] = useState(null);
  const [selectedPaymentTerm, setSelectedPaymentTerm] = useState(null);
  const [selectedPortOfDischarge, setSelectedPortOfDischarge] = useState(null);
  const [selectedPortOfLoading, setSelectedPortOfLoading] = useState(null);
  const [isNewCostOpen, setIsNewCostOpen] = useState(false);
  const [isEditCostOpen, setIsEditCostOpen] = useState(false);
  const [cost, setCost] = useState([]);

  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isPriceMismatchDialogOpen, setIsPriceMismatchDialogOpen] = useState(false);

  const processCreateOrder = async (currentOrder) => {
    setLoading(true);
    const newTab = window.open("", "_blank");
    
    try {
      updateOffer(currentOrder);
      const response = await createOrderApi(orderData);
  
      toast.success("Sales order added successfully.");
  
      if (newTab) {
        newTab.location.href = "/sales-order";
      }
  
      navigate("/customer-offer-view");
    } catch (error) {
      toast.error("Something went wrong. ", error.response?.data);
      console.error(error.response?.data);
    } finally {
      setLoading(false);
      setCreateOrder({ ...createOrder, data: orderData, visible: false });
    }
    };

    const handleCreateButtonClick = () => {
      if (createOrder?.data?.finalOfferPrice !== orderData?.finalPrice) {
        setIsPriceMismatchDialogOpen(true);
      } else {
        processCreateOrder(createOrder);
      }
    };
  
    const handleUpdatePriceAndProceed = () => {
      const updatedOrder = {
        ...createOrder,
        data: {
          ...createOrder?.data,
          finalOfferPrice: orderData?.finalPrice,
        },
      };
    
      setCreateOrder(updatedOrder); 
      setIsPriceMismatchDialogOpen(false); 
      processCreateOrder(updatedOrder);
    };
    
    const handleProceedWithoutUpdatingPrice = () => {
      setIsPriceMismatchDialogOpen(false);
      processCreateOrder(createOrder); 
    };

  const handleClick = () => {
    if (selectedIndex === 0) {
      setIsNewCostOpen(true);
    } else {
      setIsEditCostOpen(true);
      retrieveCostById(orderData?.costId);
    }
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function retrieveCosts() {
    retrieveAllCostsApi()
      .then((response) => {
        setCosts(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveCostById(id) {
    retrieveCostByIdApi(id)
      .then((response) => {
        setCost(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveSamples() {
    retrieveAllSamplesApi()
      .then((response) => {
        setSamples(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  return (
    <Grid container>
      <Grid item xs={12} style={{ position: "relative" }}>
        <Typography
          variant="h3"
          align="center"
          style={{
            color: "#777f87",
            position: "absolute",
            top: "-10px",
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "#fafafb",
            padding: "0 10px",
          }}
        >
          Offer Details
        </Typography>
      </Grid>
      <Grid
        container
        spacing="12px"
        style={{
          border: "1px solid #dbd9d9",
          borderRadius: "20px",
          marginTop: "10px",
          marginBottom: "10px",
          padding: "20px",
          boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Offer Number</Typography>
          <TextField
            variant="outlined"
            size="small"
            defaultValue={createOrder?.data?.offerGroupNumber}
            fullWidth
            onChange={(e) =>
              setOrderData({ ...orderData, offerGroupNumber: e.target.value })
            }
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Order Number</Typography>
          <TextField
            variant="outlined"
            size="small"
            defaultValue={createOrder?.data?.orderGroupNumber}
            fullWidth
            onChange={(e) =>
              setOrderData({ ...orderData, orderGroupNumber: e.target.value })
            }
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Item ID</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            value={createOrder?.data?.item?.id}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Tobacco Code</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            value={createOrder?.data?.item?.tobaccoCode}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Buying Grade</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            value={createOrder?.data?.item?.buyingGrade}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Sales Grade</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            value={createOrder?.data?.item?.salesGrade}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Crop</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            value={createOrder?.data?.item?.crop}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Origin</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            value={createOrder?.data?.item?.origin}
            fullWidth
          />
        </Grid>
        {(createOrder?.data?.item?.origin==="China" && <Grid item xs={12} sm={2}>
          <Typography variant="body2">Province</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            value={createOrder?.data?.item?.province}
            fullWidth
          />
        </Grid>)}
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Current Location</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            value={createOrder?.data?.item?.storage}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Nicotine</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            value={createOrder?.data?.item?.nicotine}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Sugar</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            value={createOrder?.data?.item?.sugar}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Moisture</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            value={createOrder?.data?.item?.moisture}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Customer</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            value={createOrder?.data?.stakeholder?.name}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Customer SAP Code</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            value={createOrder?.data?.stakeholder?.code}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Estimated Total Unit Cost</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            defaultValue={createOrder?.data?.estimatedCost?.totalCost}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Buying Price Per Kg</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            defaultValue={
              createOrder?.data?.buyingPrice +
              " " +
              createOrder?.data?.buyingPriceCurrency
            }
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={2}>
            <Typography variant="body2">Total Calculated Cost</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              value={createOrder?.data?.estimatedCost?.totalCost}
              fullWidth
              disabled
            />
          </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">First Offer</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            type="number"
            defaultValue={
              (createOrder?.data?.firstOfferPrice || "") +
              " " +
              createOrder?.data?.firstOfferPriceCurrency
            }
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Final Offer</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            defaultValue={
              (createOrder?.data?.finalOfferPrice || "") +
              " " +
              createOrder?.data?.finalOfferPriceCurrency
            }
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">First Sample</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            defaultValue={createOrder?.data?.firstSample?.id}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Packaging</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            defaultValue={createOrder?.data?.packaging}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Payment Term</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            defaultValue={createOrder?.data?.paymentTerm}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Incoterm</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            defaultValue={createOrder?.data?.incoterm}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Port Of Discharge</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            defaultValue={createOrder?.data?.portOfDischarge}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Port Of Loading</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            defaultValue={createOrder?.data?.portOfLoading}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Offered Number Of Cartons</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            defaultValue={createOrder?.data?.numberOfCases}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Offered Net Weight Per Carton</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            defaultValue={createOrder?.data?.netWeightPerCase}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Offered Volume</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            defaultValue={
              createOrder?.data?.volume + " " + createOrder?.data?.volumeUnit
            }
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Total Amount</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            defaultValue={
              createOrder?.data?.totalAmount +
              " " +
              createOrder?.data?.totalAmountCurrency
            }
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Offer Date</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            defaultValue={
              createOrder?.data?.offerDate
                ? format(parseISO(createOrder.data.offerDate), "yyyy-MM-dd")
                : ""
            }
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Offer Validity Date</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            defaultValue={
              createOrder?.data?.validityDate
                ? format(parseISO(createOrder.data.validityDate), "yyyy-MM-dd")
                : ""
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Offer Cost ID</Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            defaultValue={createOrder?.data?.estimatedCost?.id}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ position: "relative" }}>
        <Typography
          variant="h3"
          align="center"
          style={{
            color: "#777f87",
            position: "absolute",
            top: "3px",
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "#fafafb",
            padding: "0 10px",
          }}
        >
          Order Details
        </Typography>
      </Grid>
      <Grid
        container
        spacing="12px"
        style={{
          border: "1px solid #dbd9d9",
          borderRadius: "20px",
          marginTop: "20px",
          padding: "20px",
          boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
              <Grid item xs={12} sm={2}>
          <Typography variant="body2">Director SO Approval Status</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            id="outlined-select-approval-status"
            disabled
            value="Not Approved"
          >
          </TextField>
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Company</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            defaultValue={orderData?.company}
            id="outlined-select-status"
            select
            onChange={(e) =>{
              setOrderData({ ...orderData,
                company: e.target.value
              });
            }}
          >
            {companies.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Tobacco Code</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            defaultValue={createOrder?.data?.item?.tobaccoCode}
            onChange={(e) =>{
              const newCode = e.target.value;
              setCreateOrder({
                ...createOrder,
                data: {
                  ...createOrder?.data,
                    tobaccoCode: e.target.value
                },
              })
              setOrderData({ ...orderData,
                tobaccoCode: newCode 
              });
            }}
          >
          </TextField>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Internal Grade</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            defaultValue={createOrder?.data?.item?.internalGrade}
            onChange={(e) =>{
              const newGrade = e.target.value;
              setCreateOrder({
                ...createOrder,
                data: {
                  ...createOrder?.data,
                  internalGrade: e.target.value
                },
              })
              setOrderData({ ...orderData,
                internalGrade: newGrade 
              });
            }}
          >
          </TextField>
        </Grid>

{/*
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Status</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            id="outlined-select-status"
            select
            onChange={(e) =>
              setOrderData({ ...orderData, orderStatus: e.target.value })
            }
          >
            {offerStatus.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Select Status Deadline</Typography>
          <div
            style={{
              borderColor:
                (orderData?.statusDeadline === null ||
                  orderData?.statusDeadline === "") &&
                (orderData?.orderStatus === "First Refusal" ||
                  orderData?.orderStatus === "Optioned")
                  ? "red"
                  : "inherit",
              borderWidth:
                (orderData?.statusDeadline === null ||
                  orderData?.statusDeadline === "") &&
                (orderData?.orderStatus === "First Refusal" ||
                  orderData?.orderStatus === "Optioned")
                  ? "1px"
                  : "inherit",
              borderStyle:
                (orderData?.statusDeadline === null ||
                  orderData?.statusDeadline === "") &&
                (orderData?.orderStatus === "First Refusal" ||
                  orderData?.orderStatus === "Optioned")
                  ? "solid"
                  : "inherit",
            }}
          >
            <TextField
              size="small"
              fullWidth
              variant="outlined"
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) =>
                setOrderData({ ...orderData, statusDeadline: e.target.value })
              }
            />
          </div>
        </Grid>

        */}

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Item Sales Grade</Typography>
          <TextField
            variant="outlined"
            size="small"
            defaultValue={createOrder?.data?.item?.salesGrade}
            fullWidth
            onChange={(e) =>{
              const newCode = e.target.value;
              setCreateOrder({
                ...createOrder,
                data: {
                  ...createOrder?.data,
                    salesGrade: e.target.value
                },
              })
              setOrderData({ ...orderData,
                itemSalesGrade: newCode 
              });
            }}
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Select Second Sample</Typography>
          <Autocomplete
            options={samples}
            getOptionLabel={(option) =>
              `ID: ${option?.id || ""}, Remark: ${
                option?.remark || ""
              }, Buying Grade: ${option?.item?.buyingGrade || ""}`
            }
            value={selectedSecondSample}
            onChange={(event, newValue) => {
              setSelectedSecondSample(newValue);
              setOrderData({
                ...orderData,
                secondSampleId: newValue ? newValue.id : "",
              });
            }}
            onOpen={retrieveSamples}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                fullWidth
              />
            )}
            renderTags={() => null}
          />
          {selectedSecondSample && (
            <Box mt={2}>
              <Chip
                label={`ID: ${selectedSecondSample?.id || ""}, Remark: ${
                  selectedSecondSample?.remark || ""
                }, Buying Grade: ${
                  selectedSecondSample?.item?.buyingGrade || ""
                }`}
                onDelete={() => {
                  setSelectedSecondSample(null);
                  setOrderData({ ...orderData, secondSampleId: "" });
                }}
              />
            </Box>
          )}
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Select Second Sample Status</Typography>
          <TextField
            id="outlined-select-status"
            select
            variant="outlined"
            size="small"
            fullWidth
            onChange={(e) =>
              setOrderData({ ...orderData, secondSampleStatus: e.target.value })
            }
          >
            {sampleStatus.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Select Order Packaging Type</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            defaultValue={createOrder?.data?.packaging}
            select
            onChange={(e) =>
              setOrderData({ ...orderData, packaging: e.target.value })
            }
          >
            {packagingTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">
            Ordered Net Weight Per Carton
          </Typography>
          <TextField
            type="number"
            variant="outlined"
            size="small"
            value={orderData?.netWeightPerCase}
            fullWidth
            onWheel={(e) => e.target.blur()}
            onChange={(e) =>
              setOrderData({
                ...orderData,
                netWeightPerCase: e.target.value,
                volume: e.target.value * orderData?.numberOfCases,
              })
            }
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Ordered Number Of Cartons</Typography>
          <TextField
            type="number"
            variant="outlined"
            size="small"
            fullWidth
            value={orderData?.numberOfCases}
            onWheel={(e) => e.target.blur()}
            onChange={(e) =>
              setOrderData({
                ...orderData,
                numberOfCases: e.target.value,
                volume: e.target.value * orderData?.netWeightPerCase,
              })
            }
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Ordered Volume</Typography>
          <TextField
            type="number"
            variant="outlined"
            size="small"
            fullWidth
            value={orderData?.volume}
            onWheel={(e) => e.target.blur()}
            onChange={(e) =>
              setOrderData({ ...orderData, volume: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Ordered Volume Unit</Typography>
          <TextField
            type="search"
            variant="outlined"
            size="small"
            defaultValue={"KG"}
            select
            fullWidth
            onChange={(e) =>
              setOrderData({ ...orderData, volumeUnit: e.target.value })
            }
          >
            {units.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Enter Final Price</Typography>
          <TextField
            type="number"
            variant="outlined"
            size="small"
            fullWidth
            value={orderData?.finalPrice}
            onWheel={(e) => e.target.blur()}
            onChange={(e) =>
              setOrderData({ ...orderData, finalPrice: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Select Final Price Currency</Typography>
          <TextField
            id="outlined-select-status"
            select
            variant="outlined"
            size="small"
            defaultValue={orderData?.finalPriceCurrency || ""}
            fullWidth
            onChange={(e) =>
              setOrderData({ ...orderData, finalPriceCurrency: e.target.value })
            }
          >
            {currencies.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

       {/* <Grid item xs={12} sm={2}>
          <Typography variant="body2">Select Order Cost</Typography>
          <Grid container xs={12} spacing="8px">
            <Grid item xs={12} sm={8}>
              <Autocomplete
                options={costs}
                getOptionLabel={(option) =>
                  `Id: ${option?.id || ""}, Remark: ${
                    option?.remark || ""
                  }, Total Unit Cost: ${option?.totalCost || ""}`
                }
                value={selectedCost}
                onChange={(event, newValue) => {
                  setSelectedCost(newValue);
                  setOrderData({
                    ...orderData,
                    costId: newValue ? newValue.id : "",
                  });
                }}
                onOpen={retrieveCosts}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                )}
                renderTags={() => null}
              />
              {selectedCost && (
                <Box mt={2}>
                  <Chip
                    label={`Id: ${selectedCost?.id || ""}, Remark: ${
                      selectedCost?.remark || ""
                    }, Total Unit Cost: ${selectedCost?.totalCost || ""}`}
                    onDelete={() => {
                      setSelectedCost(null);
                      setOrderData({ ...orderData, costId: "" });
                    }}
                  />
                </Box>
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
              <ButtonGroup
                variant="outlined"
                ref={anchorRef}
                aria-label="Button group with a nested menu"
              >
                <Button onClick={handleClick}>{options[selectedIndex]}</Button>
                <Button
                  size="small"
                  aria-controls={open ? "split-button-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-label="select merge strategy"
                  aria-haspopup="menu"
                  onClick={handleToggle}
                  sx={{
                    width: "20px !important",
                    minWidth: "0px !important",
                  }}
                >
                  <ArrowDropDownIcon />
                </Button>
              </ButtonGroup>
              <Popper
                sx={{
                  zIndex: 1,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom" ? "center top" : "center bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="split-button-menu" autoFocusItem>
                          {options.map((option, index) => (
                            <MenuItem
                              key={option}
                              disabled={index === 2}
                              selected={index === selectedIndex}
                              onClick={(event) =>
                                handleMenuItemClick(event, index)
                              }
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2"> Total Unit Cost </Typography>
          <TextField
            disabled
            variant="outlined"
            size="small"
            value={(parseFloat(selectedCost?.totalOfferCost) || 0) + (parseFloat(orderData?.finalPrice) || 0 )}
            fullWidth
          />
              </Grid> */}

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Select Contract Value Date</Typography>
          <TextField
            size="small"
            fullWidth
            variant="outlined"
            id="date"
            type="date"
            inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
            InputLabelProps={{
              shrink: true,
            }}
            onKeyDown={(e) => e.preventDefault()}
            onChange={(e) =>
              setOrderData({ ...orderData, contractValueDate: e.target.value })
            }
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Order Incoterm</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            defaultValue={createOrder?.data?.incoterm}
            select
            onChange={(e) =>
              setOrderData({ ...orderData, incoterm: e.target.value })
            }
          >
            {incoterms.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Order Port Of Discharge</Typography>
          <Autocomplete
            options={portsOfDischarge}
            getOptionLabel={(option) => (option ? option.label : "")}
            value={selectedPortOfDischarge}
            onChange={(event, newValue) => {
              setSelectedPortOfDischarge(newValue);
              setOrderData({
                ...orderData,
                portOfDischarge: newValue ? newValue.value : "",
              });
            }}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                label={createOrder?.data?.portOfDischarge}
                fullWidth
              />
            )}
            renderTags={() => null}
          />
          {selectedPortOfDischarge && (
            <Box mt={2}>
              <Chip
                label={selectedPortOfDischarge.label}
                onDelete={() => {
                  setSelectedPortOfDischarge(null);
                  setOrderData({ ...orderData, portOfDischarge: "" });
                }}
              />
            </Box>
          )}
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Order Port Of Loading</Typography>
          <Autocomplete
            options={portsOfLoading}
            getOptionLabel={(option) => (option ? option.label : "")}
            value={selectedPortOfLoading}
            onChange={(event, newValue) => {
              setSelectedPortOfLoading(newValue);
              setOrderData({
                ...orderData,
                portOfLoading: newValue ? newValue.value : "",
              });
            }}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                label={createOrder?.data?.portOfLoading}
                fullWidth
              />
            )}
            renderTags={() => null}
          />
          {selectedPortOfLoading && (
            <Box mt={2}>
              <Chip
                label={selectedPortOfLoading.label}
                onDelete={() => {
                  setSelectedPortOfLoading(null);
                  setOrderData({ ...orderData, portOfLoading: "" });
                }}
              />
            </Box>
          )}
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Order Payment Term</Typography>
          <Autocomplete
            options={paymentTerm}
            getOptionLabel={(option) => (option ? option.label : "")}
            value={selectedPaymentTerm}
            onChange={(event, newValue) => {
              setSelectedPaymentTerm(newValue);
              setOrderData({
                ...orderData,
                paymentTerm: newValue ? newValue.value : "",
              });
            }}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                defaultValue={createOrder?.data?.paymentTerm}
                label={createOrder?.data?.paymentTerm}
                fullWidth
              />
            )}
            renderTags={() => null}
          />
          {selectedPaymentTerm && (
            <Box mt={2}>
              <Chip
                label={selectedPaymentTerm.label}
                onDelete={() => {
                  setSelectedPaymentTerm(null);
                  setOrderData({ ...orderData, paymentTerm: "" });
                }}
              />
            </Box>
          )}
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Select Order Issue Date</Typography>
          <TextField
            size="small"
            fullWidth
            variant="outlined"
            id="date"
            type="date"
            inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
            InputLabelProps={{
              shrink: true,
            }}
            onKeyDown={(e) => e.preventDefault()}
            error={!orderData?.operationDate}
            helperText={!orderData?.operationDate ? "Issue date is required" : ""}
            onChange={(e) =>
              setOrderData({ ...orderData, operationDate: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Select ETD</Typography>
          <TextField
            size="small"
            fullWidth
            variant="outlined"
            id="date"
            type="date"
            inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) =>
              setOrderData({
                ...orderData,
                estimatedTimeOfDeparture: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Select ETA</Typography>
          <TextField
            size="small"
            fullWidth
            variant="outlined"
            id="date"
            type="date"
            inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) =>
              setOrderData({
                ...orderData,
                estimatedTimeOfArrival: e.target.value,
              })
            }
          />
        </Grid>


        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Description of Goods</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            onChange={(e) =>
              setOrderData({ ...orderData, descriptionOfGoods: e.target.value })
            }
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Shipment Schedule</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            onChange={(e) =>
              setOrderData({ ...orderData, shipmentSchedule: e.target.value })
            }
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Order Remark</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            onChange={(e) =>
              setOrderData({ ...orderData, remark: e.target.value })
            }
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Order Comment</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            onChange={(e) =>
              setOrderData({ ...orderData, comment: e.target.value })
            }
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Shipping Term</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            onChange={(e) =>
              setOrderData({
                ...orderData,
                shippingTerm: e.target.value,
              })
            }
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Customer Reference</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            onChange={(e) =>
              setOrderData({
                ...orderData,
                customerReference: e.target.value,
              })
            }
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">SAP SO Number</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            onChange={(e) =>
              setOrderData({ ...orderData, salesOrderNumber: e.target.value })
            }
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Select Order Record Status</Typography>
          <TextField
            id="outlined-select-status"
            select
            variant="outlined"
            size="small"
            fullWidth
            label={"ACTIVE"}
            onChange={(e) =>
              setOrderData({
                ...orderData,
                recordStatus: e.target.value,
              })
            }
          >
            {recordStatus.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      {isNewCostOpen && (
        <>
          {" "}
          <Grid item xs={12} style={{ position: "relative" }}>
            <Typography
              variant="h3"
              align="center"
              style={{
                color: "#777f87",
                position: "absolute",
                top: "10px",
                left: "50%",
                transform: "translateX(-50%)",
                backgroundColor: "#fafafb",
                padding: "0 10px",
              }}
            >
              New Cost Details
            </Typography>
          </Grid>
          <Grid
            container
            spacing="12px"
            style={{
              border: "1px solid #dbd9d9",
              borderRadius: "20px",
              marginTop: "30px",
              padding: "20px",
              boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Buying Price Per Kg</Typography>
              <TextField
                disabled
                type="number"
                variant="outlined"
                size="small"
                fullWidth
                value={createOrder?.data?.buyingPrice}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">
                Buying Price Per Kg Currency
              </Typography>
              <TextField
                disabled
                type="number"
                variant="outlined"
                size="small"
                fullWidth
                label={createOrder?.data?.buyingPriceCurrency}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
            <Typography variant="body2">Price Per Container</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={cost?.pricePerContainer}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setCost({
                  ...cost,
                  pricePerContainer: e.target.value,
                  freightCost: (e.target.value / cost?.kgPerContainer).toFixed(2),
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Kg Per Container</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={cost?.kgPerContainer}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setCost({
                  ...cost,
                  kgPerContainer: e.target.value,
                  freightCost: (cost?.pricePerContainer / e.target.value).toFixed(2),
                })
              }
            />
          </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Freight Costs Per Kg</Typography>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                fullWidth
                value={cost?.freightCost}
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  setCost({ ...cost, freightCost: e.target.value })
                }
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Freight Cost Currency</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                id="outlined-select-status"
                select
                onChange={(e) =>
                  setCost({ ...cost, freightCurrency: e.target.value })
                }
              >
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Freight Offer By</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                onChange={(e) =>
                  setCost({ ...cost, freightOfferBy: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Select Feight Offer Date</Typography>
              <TextField
                size="small"
                fullWidth
                variant="outlined"
                id="date"
                type="date"
                inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                InputLabelProps={{
                  shrink: true,
                }}
                onKeyDown={(e) => e.preventDefault()}
                onChange={(e) =>
                  setCost({ ...cost, freightOfferDate: e.target.value })
                }
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Insurance Costs Per Kg</Typography>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                fullWidth
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  setCost({ ...cost, insuranceCost: e.target.value })
                }
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Insurance Cost Currency</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                id="outlined-select-status"
                select
                onChange={(e) =>
                  setCost({ ...cost, insuranceCurrency: e.target.value })
                }
              >
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Storage Cost Per Kg</Typography>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                fullWidth
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  setCost({ ...cost, storageCost: e.target.value })
                }
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Storage Cost Currency</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                id="outlined-select-status"
                select
                onChange={(e) =>
                  setCost({ ...cost, storageCostCurrency: e.target.value })
                }
              >
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Finance Cost Per Kg</Typography>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                fullWidth
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  setCost({ ...cost, financeCost: e.target.value })
                }
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Finance Cost Currency</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                id="outlined-select-status"
                select
                onChange={(e) =>
                  setCost({ ...cost, financeCostCurrency: e.target.value })
                }
              >
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Other Costs Per Kg</Typography>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                fullWidth
                onWheel={(e) => e.target.blur()}
                onChange={(e) => setCost({ ...cost, others: e.target.value })}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Other Cost Currency</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                id="outlined-select-status"
                select
                onChange={(e) =>
                  setCost({ ...cost, othersCurrency: e.target.value })
                }
              >
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Other Costs Remark</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                onChange={(e) =>
                  setCost({ ...cost, othersRemark: e.target.value })
                }
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">First Commision Per Kg</Typography>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                fullWidth
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  setCost({ ...cost, firstCommision: e.target.value })
                }
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">First Commision Currency</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                id="outlined-select-status"
                select
                onChange={(e) =>
                  setCost({ ...cost, firstCommisionCurrency: e.target.value })
                }
              >
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Second Commision Per Kg</Typography>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                fullWidth
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  setCost({ ...cost, secondCommision: e.target.value })
                }
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Second Commision Currency</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                id="outlined-select-status"
                select
                onChange={(e) =>
                  setCost({ ...cost, secondCommisionCurrency: e.target.value })
                }
              >
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Third Commision Per Kg</Typography>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                fullWidth
                onWheel={(e) => e.target.blur()}
                onChange={(e) => {
                  setCost({ ...cost, thirdCommision: e.target.value })
                }
                }
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Third Commision Currency</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                id="outlined-select-status"
                select
                onChange={(e) =>
                  setCost({ ...cost, thirdCommisionCurrency: e.target.value })
                }
              >
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid container alignItems="center" item xs={12} sm={2}>
              <Typography variant="body2">
                Total Unit Cost Before Profit
              </Typography>
              <Grid container spacing={1} alignItems="center">
                <Grid Grid item xs={10} sm={9}>
                  <TextField
                    type="number"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={cost.totalCost}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => {
                      setCost({ ...cost, totalCost: e.target.value });
                    }}
                  />
                </Grid>
                <Grid item xs={1} sm={1}>
                  <Button style={{ padding: 0 , color:'red'}} onClick={calculateTotalCost}>
                    <ReloadOutlined style={{ fontSize: "16px" }} />
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Profit Per Kg</Typography>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                fullWidth
                onWheel={(e) => e.target.blur()}
                onChange={(e) => setCost({ ...cost, profit: e.target.value })}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Profit Currency</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                id="outlined-select-status"
                select
                onChange={(e) =>
                  setCost({ ...cost, profitCurrency: e.target.value })
                }
              >
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Total Unit Cost After Profit</Typography>
              <Grid container spacing={1} alignItems="center">
                <Grid Grid item xs={10} sm={9}>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                fullWidth
                value={cost.totalOfferCost}
                onWheel={(e) => e.target.blur()}
                onChange={(e) => { 
                  setCost({ ...cost, totalOfferCost: e.target.value });
                }}
              />
              </Grid>
                <Grid item xs={1} sm={1}>
                  <Button style={{ padding: 0 , color:'red'}} onClick={calculateTotalCostWithProfit}>
                    <ReloadOutlined style={{ fontSize: "16px" }} />
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Cost Remark</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                onChange={(e) => setCost({ ...cost, remark: e.target.value })}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Select Cost Record Status</Typography>
              <TextField
                id="outlined-select-status"
                select
                variant="outlined"
                size="small"
                fullWidth
                onChange={(e) => setCost({ ...cost, status: e.target.value })}
              >
                {recordStatus.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={2}>
              <Button
                variant="outlined"
                size="small"
                sx={{ ml: 1.5, mt: 4, mr: 1 }}
                onClick={() => {
                  setIsNewCostOpen(false);
                  setCost([]);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="small"
                sx={{ mt: 4 }}
                onClick={() => {
                  createCostApi(cost)
                    .then((response) => {
                      toast.success("Cost added successfully.");
                    })
                    .catch((error) => {
                      console.log(error.response.data);
                      toast.error("Something Went Wrong.");
                    });
                  setIsNewCostOpen(false);
                  setCost([]);
                }}
              >
                Create Cost
              </Button>
            </Grid>
          </Grid>{" "}
        </>
      )}
      {isEditCostOpen && (
        <>
          {" "}
          <Grid item xs={12} style={{ position: "relative" }}>
            <Typography
              variant="h3"
              align="center"
              style={{
                color: "#777f87",
                position: "absolute",
                top: "10px",
                left: "50%",
                transform: "translateX(-50%)",
                backgroundColor: "#fafafb",
                padding: "0 10px",
              }}
            >
              Edit Cost {cost?.id} Details
            </Typography>
          </Grid>
          <Grid
            container
            spacing="12px"
            style={{
              border: "1px solid #dbd9d9",
              borderRadius: "20px",
              marginTop: "30px",
              padding: "20px",
              boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Buying Price Per Kg</Typography>
              <TextField
                disabled
                type="number"
                variant="outlined"
                size="small"
                fullWidth
                value={createOrder?.data?.buyingPrice || ""}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">
                Buying Price Per Kg Currency
              </Typography>
              <TextField
                disabled
                type="number"
                variant="outlined"
                size="small"
                fullWidth
                label={createOrder?.data?.buyingPriceCurrency || ""}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
            <Typography variant="body2">Price Per Container</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={cost?.pricePerContainer}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setCost({
                  ...cost,
                  pricePerContainer: e.target.value,
                  freightCost: (e.target.value / cost?.kgPerContainer).toFixed(2),
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Kg Per Container</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={cost?.kgPerContainer}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setCost({
                  ...cost,
                  kgPerContainer: e.target.value,
                  freightCost: (cost?.pricePerContainer / e.target.value).toFixed(2),
                })
              }
            />
          </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Freight Costs Per Kg</Typography>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                value={cost?.freightCost || ""}
                fullWidth
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  setCost({ ...cost, freightCost: e.target.value })
                }
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Freight Cost Currency</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label={cost?.freightCurrency || ""}
                id="outlined-select-status"
                select
                onChange={(e) =>
                  setCost({ ...cost, freightCurrency: e.target.value })
                }
              >
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Freight Offer By</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                value={cost?.freightOfferBy || ""}
                onChange={(e) =>
                  setCost({ ...cost, freightOfferBy: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Select Feight Offer Date</Typography>
              <TextField
                size="small"
                fullWidth
                variant="outlined"
                value={
                  cost.freightOfferDate
                    ? format(parseISO(cost.freightOfferDate), "yyyy-MM-dd")
                    : "" || ""
                }
                id="date"
                type="date"
                inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                InputLabelProps={{
                  shrink: true,
                }}
                onKeyDown={(e) => e.preventDefault()}
                onChange={(e) =>
                  setCost({ ...cost, freightOfferDate: e.target.value })
                }
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Insurance Costs Per Kg</Typography>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                fullWidth
                value={cost?.insuranceCost || ""}
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  setCost({ ...cost, insuranceCost: e.target.value })
                }
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Insurance Cost Currency</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label={cost?.insuranceCurrency || ""}
                id="outlined-select-status"
                select
                onChange={(e) =>
                  setCost({ ...cost, insuranceCurrency: e.target.value })
                }
              >
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Storage Cost Per Kg</Typography>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                value={cost?.storageCost || ""}
                fullWidth
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  setCost({ ...cost, storageCost: e.target.value })
                }
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Storage Cost Currency</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label={cost?.storageCostCurrency || ""}
                id="outlined-select-status"
                select
                onChange={(e) =>
                  setCost({ ...cost, storageCostCurrency: e.target.value })
                }
              >
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Finance Cost Per Kg</Typography>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                value={cost?.financeCost || ""}
                fullWidth
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  setCost({ ...cost, financeCost: e.target.value })
                }
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Finance Cost Currency</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label={cost?.financeCostCurrency || ""}
                id="outlined-select-status"
                select
                onChange={(e) =>
                  setCost({ ...cost, financeCostCurrency: e.target.value })
                }
              >
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Other Costs Per Kg</Typography>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                value={cost?.others || ""}
                fullWidth
                onWheel={(e) => e.target.blur()}
                onChange={(e) => setCost({ ...cost, others: e.target.value })}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Other Cost Currency</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label={cost?.othersCurrency || ""}
                id="outlined-select-status"
                select
                onChange={(e) =>
                  setCost({ ...cost, othersCurrency: e.target.value })
                }
              >
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Other Costs Remark</Typography>
              <TextField
                variant="outlined"
                size="small"
                value={cost?.othersRemark || ""}
                fullWidth
                onChange={(e) =>
                  setCost({ ...cost, othersRemark: e.target.value })
                }
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">First Commision Per Kg</Typography>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                value={cost?.firstCommision || ""}
                fullWidth
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  setCost({ ...cost, firstCommision: e.target.value })
                }
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">First Commision Currency</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label={cost?.firstCommisionCurrency || ""}
                id="outlined-select-status"
                select
                onChange={(e) =>
                  setCost({ ...cost, firstCommisionCurrency: e.target.value })
                }
              >
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Second Commision Per Kg</Typography>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                value={cost?.secondCommision || ""}
                fullWidth
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  setCost({ ...cost, secondCommision: e.target.value })
                }
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Second Commision Currency</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label={cost?.secondCommisionCurrency || ""}
                id="outlined-select-status"
                select
                onChange={(e) =>
                  setCost({ ...cost, secondCommisionCurrency: e.target.value })
                }
              >
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Third Commision Per Kg</Typography>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                value={cost?.thirdCommision || ""}
                fullWidth
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  setCost({ ...cost, thirdCommision: e.target.value })
                }
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Third Commision Currency</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label={cost?.thirdCommisionCurrency || ""}
                id="outlined-select-status"
                select
                onChange={(e) =>
                  setCost({ ...cost, thirdCommisionCurrency: e.target.value })
                }
              >
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">
                Total Unit Cost Before Profit Per Kg
              </Typography>
              <Grid container spacing={1} alignItems="center">
                <Grid Grid item xs={10} sm={9}>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                value={cost?.totalCost || ""}
                fullWidth
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  setCost({ ...cost, totalCost: e.target.value })
                }
              />
                </Grid>
                <Grid item xs={1} sm={1}>
                  <Button style={{ padding: 0 , color:'red'}} onClick={calculateTotalCost}>
                    <ReloadOutlined style={{ fontSize: "16px" }} />
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Profit Per Kg</Typography>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                fullWidth
                value={cost?.profit || ""}
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  setCost({
                    ...cost,
                    profit: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Profit Currency</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                id="outlined-select-profit-currency"
                select
                label={cost?.profitCurrency || ""}
                onChange={(e) =>
                  setCost({
                    ...cost,
                    profitCurrency: e.target.value,
                  })
                }
              >
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Total Unit Cost After Profit</Typography>
              <Grid container spacing={1} alignItems="center">
                <Grid Grid item xs={10} sm={9}>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                value={cost?.totalOfferCost || ""}
                fullWidth
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  setCost({ ...cost, totalOfferCost: e.target.value })
                }
              />
              </Grid>
                <Grid item xs={1} sm={1}>
                  <Button style={{ padding: 0 , color:'red'}} onClick={calculateTotalCostWithProfit}>
                    <ReloadOutlined style={{ fontSize: "16px" }} />
                  </Button>
                </Grid>
              </Grid>
            </Grid>


            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Cost Remark</Typography>
              <TextField
                variant="outlined"
                size="small"
                value={cost?.remark || ""}
                fullWidth
                onChange={(e) => setCost({ ...cost, remark: e.target.value })}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Select Cost Record Status</Typography>
              <TextField
                id="outlined-select-status"
                select
                variant="outlined"
                size="small"
                label={cost?.status}
                fullWidth
                onChange={(e) => setCost({ ...cost, status: e.target.value })}
              >
                {recordStatus.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={2}>
              <Button
                variant="outlined"
                size="small"
                sx={{ ml: 1.5, mt: 4, mr: 1 }}
                onClick={() => {
                  setIsEditCostOpen(false);
                  setCost([]);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="small"
                sx={{ mt: 4 }}
                onClick={() => {
                  updateCostApi(cost.id, cost)
                    .then((response) => {
                      toast.success("Cost edited successfully.");
                      setCost([]);
                    })
                    .catch((error) => {
                      toast.error("Something went wrong.");
                      console.log(error.response.data);
                    });
                  setIsEditCostOpen(false);
                  setCost([]);
                }}
              >
                Save Cost
              </Button>
            </Grid>
          </Grid>{" "}
        </>
      )}
       <Grid  container
        spacing="12px">
       <Grid item xs={12} sm={12}></Grid>
      <Button
        variant="outlined"
        sx={{ ml: 1.5, mt: 4, mr: 1 }}
        onClick={() => {
          setOrderData({ data: null });
          setCreateOrder({ visible: false });
        }}
      >
        Cancel
      </Button>
      <div>
      {loading ? (
        <ClipLoader color="#000000" loading={loading} size={35} />
      ) : (
      <Button
        variant="contained"
        sx={{ mt: 4 }}
        disabled={(createOrder?.data?.stakeholder && !createOrder?.data?.stakeholder?.code) || !orderData?.operationDate}
        onClick={handleCreateButtonClick}
      >
        Create
      </Button>
        )}
    </div>

      <Dialog open={isPriceMismatchDialogOpen} onClose={() => setIsPriceMismatchDialogOpen(false)}>
        <DialogTitle>Price Mismatch Detected</DialogTitle>
        <DialogContent>
          The final offer price (<strong>{createOrder?.data?.finalOfferPrice}</strong>) does not match
          the final order price (<strong>{orderData?.finalPrice}</strong>).
          <br />
          Would you like to update the final offer price to match the final order price before proceeding?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsPriceMismatchDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleProceedWithoutUpdatingPrice} color="primary">
            Proceed Without Updating Price
          </Button>
          <Button onClick={handleUpdatePriceAndProceed} color="primary">
            Update Price and Proceed
          </Button>
        </DialogActions>
      </Dialog>
      <Grid item xs={12} sm={12}></Grid>
      <Grid item xs={12} sm={6}>
      {(createOrder?.data?.stakeholder && !createOrder?.data?.stakeholder?.code) && (
        <Typography variant="body1" color="error">
          No Customer SAP Code: Customer is not registered in SAP. Please register the customer to proceed with creating the order.
        </Typography>
      )}
      </Grid>
      </Grid>
    </Grid>
  );
};

export default CreateOrder;
