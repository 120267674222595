import { Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import {
  createMultipleOffersApi,
  retrieveMaxOfferNumberApi,
} from "../../../components/api/OfferApiService";
import AddCustomerOfferView from "./AddCustomerOfferView";
import CustomerDialog from "./Dialogs/CustomerDialog";

const customers = [
  { id: 152456, label: "PT Namora" },
  { id: 5856, label: "ULT Dubai/Mr Lin" },
  { id: 741856, label: "Khatoco" },
  { id: 12854, label: "Wan Ting" },
];

const AddCustomerOffer = (props) => {
  const navigate = useNavigate();
  const { setAddView, newOfferGroupNumber, locatedData, prevLocation } = props;
  const [customerOpt, setCustomerOpt] = useState(customers);
  const [maxOfferGroupNumber, setMaxOfferGroupNumber] = useState();

  const transformData = (data) => {
    return data.map((d) => {
      const {
        finalOfferPrice,
        finalOfferPriceCurrency,
        offerGroupNumber,
        offerType,
        numberOfCases,
        netWeightPerCase,
        volume,
        remainingQuantity,
        company,
        offer,
        item,
        id,
      } = d;
      return {
        offerYear: new Date().getFullYear().toString(),
        buyingPrice: finalOfferPrice || null,
        buyingPriceCurrency: finalOfferPriceCurrency || "USD",
        selectedOffer: offer || d,
        offerGroupNumber: maxOfferGroupNumber,
        offerType: "CUSTOMER",
        company: company,
        itemId: item?.id || null,
        salesGrade: item?.buyingGrade || null,
        salesOrigin: item?.origin || null,
        salesCrop: item?.crop || null,
        salesNicotine: item?.nicotine || null,
        salesSugar: item?.sugar || null,
        item: item ? item : null,
        referenceVendorOfferId: id || null,
        numberOfCases: 
        remainingQuantity && netWeightPerCase !== 0 
          ? remainingQuantity / netWeightPerCase 
          : numberOfCases || null,
        netWeightPerCase: netWeightPerCase || null,
        volume: remainingQuantity || volume || null,
      };
    });
  };
  function getMaxOfferNumber() {
    retrieveMaxOfferNumberApi("CUSTOMER")
      .then((response) => {
        setMaxOfferGroupNumber(response?.data + 1);
      })
      .catch((error) => console.log(error.response?.data));
  }
  useEffect(() => {
    getMaxOfferNumber();
  }, [locatedData]);

  useEffect(() => {
    setAddOfferArray(() => {
      if (locatedData?.selectedData) {
        return transformData(locatedData.selectedData);
      } else {
        return [generateDefaultObject(locatedData)];
      }
    });
  }, [maxOfferGroupNumber]);

  const generateDefaultObject = (locatedData) => ({
    id: Math.floor(Math.random() * 100000),
    offerYear: new Date().getFullYear().toString(),
    offerGroupNumber: maxOfferGroupNumber,
    orderGroupNumber: null,
    offerStatus: { id: null, label: null },
    offerType: "CUSTOMER",
    stakeholderId: null,
    approvalStatus: null,
    status: null,
    statusDeadline: null,
    item: locatedData?.item || null,
    itemId: locatedData?.item?.id || null,
    salesGrade: locatedData?.item?.buyingGrade || null,
    salesOrigin: locatedData?.item?.origin || null,
    salesCrop: locatedData?.item?.crop || null,
    salesNicotine: locatedData?.item?.nicotine || null,
    salesSugar: locatedData?.item?.sugar || null,
    company: locatedData?.company || null,
    costId: null,
    buyingPrice: locatedData?.buyingPrice || null,
    buyingPriceCurrency: locatedData?.buyingPriceCurrency || "USD",
    firstOfferPrice: null,
    firstOfferPriceCurrency: "USD",
    finalOfferPrice: null,
    finalOfferPriceCurrency: "USD",
    firstSampleId: null,
    secondSampleId: null,
    firstSampleDate: null,
    secondSampleDate: null,
    packaging: null,
    paymentTerm: null,
    numberOfCases: locatedData?.numberOfCases || null,
    netWeightPerCase: locatedData?.netWeightPerCase || null,
    volume: locatedData?.volume || null,
    volumeUnit: "KG",
    offerDate: null,
    offerLink: null,
    referenceVendorOfferId: locatedData?.offer?.id || null,
    selectedOffer: locatedData?.offer || null,
    validityDate: null,
    incoterm: locatedData?.incoterm || null,
    remark: null,
    comment: null,
    documentStatus: null,
    recordStatus: null,
    show: true,
  });

  const [addOfferArray, setAddOfferArray] = useState(() => {
    if (locatedData?.selectedData) {
      return transformData(locatedData.selectedData);
    } else {
      return [generateDefaultObject(locatedData)];
    }
  });

  const [openCustomerDialog, setOpenCustomerDialog] = useState(false);

  const handleAddAnother = () => {
    addOfferArray.map((offer) => (offer.show = false));
    setAddOfferArray([
      ...addOfferArray,
      {
        id: Math.floor(Math.random() * 100000),
        offerType: "CUSTOMER",
        offerGroupNumber: maxOfferGroupNumber,
        orderGroupNumber: null,
        offerStatus: { id: null, label: null },
        stakeholderId: null,
        approvalStatus: null,
        status: null,
        statusDeadline: null,
        item: null,
        itemId: null,
        salesGrade: null,
        salesOrigin: null,
        salesCrop: null,
        salesNicotine: null,
        salesSugar: null,
        company: null,
        costId: null,
        buyingPrice: null,
        buyingPriceCurrency: "USD",
        firstOfferPrice: null,
        firstOfferPriceCurrency: "USD",
        finalOfferPrice: null,
        finalOfferPriceCurrency: "USD",
        firstSampleId: null,
        secondSampleId: null,
        firstSampleDate: null,
        secondSampleDate: null,
        packaging: null,
        paymentTerm: null,
        numberOfCases: null,
        netWeightPerCase: null,
        volume: null,
        volumeUnit: "KG",
        offerDate: null,
        offerLink: null,
        validityDate: null,
        incoterm: locatedData?.incoterm || null,
        remark: null,
        comment: null,
        documentStatus: null,
        recordStatus: null,
        offerYear: null,
        show: true,
      },
    ]);
  };

  const isButtonDisabled = addOfferArray.some(
    (record) =>
      ((record.status === "First Refusal" || record.status === "Optioned") &&
      (!record.statusDeadline || record.statusDeadline.trim() === "")) || (!record?.offerDate),
  );

  return (
    <>
      <Stack direcion="column">
        <Typography variant="h5" mb={2}>
          Create New Customer Offer
        </Typography>
        <Stack spacing={1}>
          {addOfferArray?.map((offer, index) => (
            <AddCustomerOfferView
              key={index}
              index={index}
              addOfferArray={addOfferArray}
              setAddOfferArray={setAddOfferArray}
              offer={offer}
              customerOpt={customerOpt}
              setOpenCustomerDialog={setOpenCustomerDialog}
              newOfferGroupNumber={newOfferGroupNumber || maxOfferGroupNumber}
            />
          ))}
        </Stack>

        <Stack direction="row" spacing={1} mt={1}>
          <Button variant="text" onClick={() => handleAddAnother()}>
            + Add another
          </Button>
        </Stack>
        <Stack direction="row" spacing={1} mt={1}>
          <Button
            variant="outlined"
            onClick={() => {
              if (prevLocation) {
                navigate(`/${prevLocation}`);
              } else {
                setAddView(false);
              }
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={isButtonDisabled}
            variant="contained"
            onClick={() => {
              createMultipleOffersApi(addOfferArray)
                .then((response) => {
                  toast.success("Customer offer added successfully.");
                  if (prevLocation) {
                    navigate(`/${prevLocation}`);
                  } else {
                    window.location.reload();
                    navigate("/customer-offer-view");
                    setAddView(false);
                  }
                })
                .catch((error) => {
                  toast.error(error.response.data.message);
                  console.log(error.response.data);
                });
            }}
          >
            Add
          </Button>
        </Stack>
        {isButtonDisabled && (
          <Typography style={{ color: "red", marginTop: "10px" }}>
            Please ensure all required fields are completed.
          </Typography>
        )}
      </Stack>

      {openCustomerDialog && (
        <CustomerDialog
          open={openCustomerDialog}
          setOpen={setOpenCustomerDialog}
          customerOpt={customerOpt}
          setCustomerOpt={setCustomerOpt}
        />
      )}
    </>
  );
};

export default AddCustomerOffer;
