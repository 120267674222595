import DeleteIcon from "@mui/icons-material/Delete";
import { Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  retrieveAllStakeholdersApi,
  retrieveFilteredStakeholdersApi,
  updateStakeholderApi,
} from "../../../components/api/StakeholderApiService";
import ExportExcel from "../../../utils/excelExport";
import { recordStatus } from "../../../utils/menu-items";
import AddConsignee from "./AddConsignee";
import EditConsignee from "./EditConsignee";
import { format } from "date-fns";
import toast from "react-hot-toast";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "editButton",
    disablePadding: true,
    label: "",
  },
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "code",
    numeric: true,
    disablePadding: true,
    label: "Consignee Code",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Consignee Name",
  },
  {
    id: "contactPerson",
    numeric: false,
    disablePadding: true,
    label: "Contact Person",
  },
  {
    id: "reference",
    numeric: false,
    disablePadding: true,
    label: "Reference Customer",
  },
  {
    id: "creationTime",
    numeric: false,
    disablePadding: true,
    label: "Creation Time",
  },
  {
    id: "createdBy",
    numeric: false,
    disablePadding: true,
    label: "Created By",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    label: "Status",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected, showFilters, setShowFilters, rows, setAddView, selected,
    selectedData } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity,
            ),
        }),
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Stack direction="row" spacing={1} alignItems={"center"}>
          <Typography
          sx={{ fontWeight: 600, fontSize: "20px" }}
            variant="h6"
            id="tableTitle"
            component="div"
            color="#1E2685"
          >
            Consignees
          </Typography>
          <Typography></Typography>
          {numSelected > 0 ? (
          <Typography
          sx={{ fontWeight: 200, fontSize: "16px" }}
            color="#1E2685"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (<Typography></Typography>)}
          
        {numSelected > 0 && (
          <Tooltip title="Delete">
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </Stack>

      <Stack direction="row" spacing={1} sx={{ mr: 2 }}>
        <ExportExcel excelData={selectedData} fileName={"ConsigneeExport"} buttonLabel="Export Table"/>
        <Button
          disableElevation
          variant="outlined"
          size="small"
          onClick={() => setAddView(true)}
        >
          Add
        </Button>

        <Button
          variant="outlined"
          onClick={() => setShowFilters(!showFilters)}
          sx={{ minWidth: "130px" }}
          size="small"
        >
          {showFilters ? "Hide Filters" : "Show Filters"}
        </Button>
      </Stack>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function ConsigneeView() {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [showFilters, setShowFilters] = useState(false);
  const [addView, setAddView] = useState(false);
  const [addViewStates, setAddViewStates] = useState(null);
  const [rows, setRows] = useState([]);

  const [filterConsignee, setFilterConsignee] = useState({
    id: 0,
    code: "",
    name: "",
    contactPerson: "",
    type: "CONSIGNEE",
    statusList: ["ACTIVE"],
  });
  useEffect(() => retrieveConsignees(), []);

  const [specialInstructionsDialogOpen, setSpecialInstructionsDialogOpen] = useState(false);
  const [specialInstructions, setSpecialInstructions] = useState([]);
  const [documentsRequiredDialogOpen, setDocumentsRequiredDialogOpen] = useState(false);
  const [selectedConsignee, setSelectedConsignee] = useState(null);
  const [documentsRequiredList, setDocumentsRequiredList] = useState([]);

  const handleSpecialInstructionsClick = (row) => {
    setSelectedConsignee(row);
    setSpecialInstructions(row.specialInstructionsList || []); 
    setSpecialInstructionsDialogOpen(true);
  };

  const handleDocumentsRequiredClick = (row) => {
    setSelectedConsignee(row);
  if (Array.isArray(row?.documentsRequiredList)) {
    setDocumentsRequiredList(row.documentsRequiredList);
  } else {
    setDocumentsRequiredList([]);
  }
  setDocumentsRequiredDialogOpen(true);
  };

  const handleAddSpecialInstruction = () => {
    setSpecialInstructions([...specialInstructions, ""]); 
  };
  
  const handleAddDocument = () => {
    setDocumentsRequiredList([...documentsRequiredList, { name: '', originalAmount: 0, copyAmount: 0 }]);
  };

  const handleRemoveSpecialInstruction = (index) => {
    const updatedInstructions = (specialInstructions || []).filter(
      (_, i) => i !== index
    );
    
    setSpecialInstructions(updatedInstructions); 
    setSelectedConsignee({
      ...selectedConsignee,
      specialInstructionsList: updatedInstructions,
    });
  };
  
  const handleRemoveDocument = (index) => {
    const updatedDocuments = documentsRequiredList.filter((_, i) => i !== index);
    setDocumentsRequiredList(updatedDocuments);
  };

  const handleSpecialInstructionChange = (e, index) => {
    const updatedInstructions = specialInstructions.map((instruction, i) =>
      i === index ? e.target.value : instruction
    );
    setSpecialInstructions(updatedInstructions); 
  };
  
  const handleDocumentChange = (e, index, field) => {
    const updatedDocuments = documentsRequiredList.map((doc, i) =>
      i === index ? { ...doc, [field]: e.target.value } : doc
    );
    setDocumentsRequiredList(updatedDocuments);
  };

  const handleSaveSpecialInstructions = () => {
    updateStakeholderApi(selectedConsignee?.id, {
      ...selectedConsignee,
      specialInstructionsList: specialInstructions, 
    })
      .then((response) => {
        toast.success(
          `Saving instructions for customer: ${selectedConsignee?.name}`,
          { autoClose: 3000 }
        );
        window.location.reload();
      })
      .catch((error) => {
        toast.error("Something went wrong.");
        console.log(error.response.data);
      });
    setSpecialInstructionsDialogOpen(false); 
  };

  const handleSpecialInstructionsDialogClose = () => {
    setSpecialInstructionsDialogOpen(false);
  };

  const handleSaveDocumentsRequired = () => {
    updateStakeholderApi(selectedConsignee?.id, {
      ...selectedConsignee,
      documentsRequiredList: documentsRequiredList, 
    })
      .then((response) => {
        window.location.reload();
        toast.success(
          `Saving documents for customer: ${selectedConsignee?.name}`,
          { autoClose: 3000 }
        );
      })
      .catch((error) => {
        toast.error("Something went wrong.");
        console.log(error.response.data);
      });
    setDocumentsRequiredDialogOpen(false); 
  };

  const handleDocumentsRequiredDialogClose = () => {
    setDocumentsRequiredDialogOpen(false);
  };

  function retrieveConsignees() {
    retrieveAllStakeholdersApi("CONSIGNEE")
      .then((response) => {
        setRows(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  const [editConsignee, setEditConsignee] = useState({
    visible: false,
    data: null,
    index: null,
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      setSelectedData(rows);
      return;
    }else{
      setSelected([]);
      setSelectedData([]);
    }
  };
  
  const handleClick = (event, name, row) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    const selectedDataIndex = selectedData.indexOf(row);
    let newSelectedData = [];

    if (selectedDataIndex === -1) {
      newSelectedData = newSelectedData.concat(selectedData, row);
    } else if (selectedDataIndex === 0) {
      newSelectedData = newSelectedData.concat(selectedData.slice(1));
    } else if (selectedDataIndex === selectedData.length - 1) {
      newSelectedData = newSelectedData.concat(selectedData.slice(0, -1));
    } else if (selectedDataIndex > 0) {
      newSelectedData = newSelectedData.concat(
        selectedData.slice(0, selectedDataIndex),
        selectedData.slice(selectedDataIndex + 1),
      );
    }
    setSelectedData(newSelectedData);

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, rows, editConsignee],
  );

  return (
    <div>
      {addView ? (
        <AddConsignee
          setAddViewStates={setAddViewStates}
          addViewStates={addViewStates}
          rows={rows}
          setRows={setRows}
          setAddView={setAddView}
        />
      ) : editConsignee.visible ? (
        <EditConsignee
          rows={rows}
          setRows={setRows}
          setAddView={setAddView}
          editConsignee={editConsignee}
          setEditConsignee={setEditConsignee}
        />
      ) : (
        <>
          {showFilters && (
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <Grid
                container
                spacing={1}
                justifyContent="flex-start"
                alignItems="center"
              >
                <TextField
                  id="outlined-search"
                  label="Consignee Code"
                  value={filterConsignee?.code || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterConsignee({
                      ...filterConsignee,
                      code: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-search"
                  label="Consignee Name"
                  value={filterConsignee?.name || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterConsignee({
                      ...filterConsignee,
                      name: e.target.value,
                    })
                  }
                />
               <TextField
                  id="outlined-search"
                  label="Contact Person"
                  value={filterConsignee?.contactPerson || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterConsignee({
                      ...filterConsignee,
                      contactPerson: e.target.value,
                    })
                  }
                />
                <TextField
                  label="Status"
                  type="search"
                  id="outlined-select-status"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterConsignee.statusList || [],
                    onChange: (e) => {
                      setFilterConsignee({
                        ...filterConsignee,
                        statusList: e.target.value,
                      });
                    },
                  }}
                >
                  {recordStatus.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <br></br>
              <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={3} sm={1}>
                  <Button
                    disableElevation
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      retrieveFilteredStakeholdersApi(filterConsignee)
                        .then((response) => {
                          setRows(response?.data);
                        })
                        .catch((error) => {
                          console.log(error?.response?.data);
                        });
                    }}
                  >
                    Search
                  </Button>
                </Grid>
                <Grid item xs={3} sm={1}>
                  <Button
                    disableElevation
                    variant="outlined"
                    fullWidth
                    onClick={() => {
                      setFilterConsignee({
                        id: 0,
                        code: "",
                        name: "",
                        contactPerson: "",
                        type: "CONSIGNEE",
                        statusList: ["ACTIVE"],
                      });
                    }}
                  >
                    Clear Filters
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}

          <Box sx={{ width: "100%", mt: 2 }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
              <EnhancedTableToolbar
                numSelected={selected.length}
                showFilters={showFilters}
                setShowFilters={setShowFilters}
                rows={rows}
                setAddView={setAddView}
                selected={selected}
                selectedData={selectedData}
              />
             <TableContainer style={{ maxHeight: 700 }}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  sx={{
                    minWidth: 750,
                    '& .MuiTableCell-head': {
                      backgroundColor: '#20315d',
                      color: 'white',
                    },
                  }}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {visibleRows.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          style={
                            index % 2
                              ? { background: "#f0f0f0" }
                              : { background: "white" }
                          }
                          hover
                          onClick={(event) => handleClick(event, row.id, row)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell padding="normal">
                            <Button
                              onClick={(e) => {
                                setEditConsignee({
                                  visible: true,
                                  data: row,
                                  index: index,
                                });
                                e.preventDefault();
                              }}
                            >
                              Edit
                            </Button>
                          </TableCell>
                          <TableCell align="center"  sx={{ maxWidth: "150px", padding: "0px", margin: "0px" }}>
                           <Button
                             variant="outlined"
                             onClick={() => handleDocumentsRequiredClick(row)}
                           >
                             Documents Required
                           </Button>
                         </TableCell>
                          <TableCell align="center"  sx={{ maxWidth: "150px", padding: "0px", margin: "0px" }}>
                           <Button
                             variant="outlined"
                             onClick={() => handleSpecialInstructionsClick(row)} 
                           >
                             Special Instructions
                           </Button>
                         </TableCell>
                          <TableCell align="center">{row?.id}</TableCell>
                          <TableCell align="center">{row?.code}</TableCell>
                          <TableCell align="center">{row?.name}</TableCell>
                          <TableCell align="center">{row?.contactPerson}</TableCell>
                          <TableCell align="center">{row?.reference}</TableCell>
                          <TableCell align="center">{row?.timestamp ? format(new Date(row.timestamp), 'dd/MM/yyyy HH:mm'): ''}</TableCell>
                          <TableCell align="center">{row?.creator}</TableCell>
                          <TableCell align="center">{row?.status}</TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label="Dense padding"
            />
          </Box>
          <Dialog open={specialInstructionsDialogOpen} onClose={handleSpecialInstructionsDialogClose} fullWidth>
        <DialogTitle>Special Instructions for {selectedConsignee?.name}</DialogTitle>
        <DialogContent>
          {specialInstructions.map((instruction, index) => (
            <div
              key={index}
              style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}
            >
              <TextField
                label={`Special Instruction ${index + 1}`}
                value={instruction}
                onChange={(e) => handleSpecialInstructionChange(e, index)}
                fullWidth
                margin="normal"
              />
              <IconButton onClick={() => handleRemoveSpecialInstruction(index)}>
                <CloseIcon />
              </IconButton>
            </div>
          ))}

          <IconButton onClick={handleAddSpecialInstruction}>
            <AddIcon />
          </IconButton>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSpecialInstructionsDialogClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSaveSpecialInstructions} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={documentsRequiredDialogOpen} onClose={handleDocumentsRequiredDialogClose} fullWidth>
      <DialogTitle>Documents Required for {selectedConsignee?.name}</DialogTitle>
      <DialogContent>
        {documentsRequiredList.map((document, index) => (
          <div
            key={index}
            style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}
          >
            <TextField
              label={`Document Name ${index + 1}`}
              value={document.name}
              onChange={(e) => handleDocumentChange(e, index, 'name')}
              fullWidth
              margin="normal"
              style={{ flex: 2, marginRight: "8px" }}
            />
            <TextField
              label="Original Amount"
              type="number"
              value={document.originalAmount}
              onWheel={(e) => e.target.blur()}
              onChange={(e) => handleDocumentChange(e, index, 'originalAmount')}
              margin="normal"
              style={{ flex: 1, marginRight: "8px" }}
            />
            <TextField
              label="Copy Amount"
              type="number"
              value={document.copyAmount}
              onWheel={(e) => e.target.blur()}
              onChange={(e) => handleDocumentChange(e, index, 'copyAmount')}
              margin="normal"
              style={{ flex: 1 }}
            />
            <IconButton onClick={() => handleRemoveDocument(index)}>
              <CloseIcon />
            </IconButton>
          </div>
        ))}

        <IconButton onClick={handleAddDocument}>
          <AddIcon />
        </IconButton>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDocumentsRequiredDialogClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSaveDocumentsRequired} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
        </>
      )}
    </div>
  );
}
