import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { retrieveAllWarehouseApi } from "../../../../components/api/WarehouseApiService";
import { updateMultipleStoragePricesApi } from "../../../../components/api/StoragePriceApiService";
import { currencies } from "../../../../utils/menu-items";

const EditStoragePrice = (props) => {
  const navigate = useNavigate();
  const { setEditStorage, editStorage, rows, setRows, setAddView } = props;

  const [warehouses, setWarehouses] = useState();
  function retrieveWarehouse() {
    retrieveAllWarehouseApi()
      .then((response) => {
        setWarehouses(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }
  console.log("editStorage", editStorage);
  return (
    <Stack direction="column">
      <Typography variant="body1" mb={2}>
        Edit Storage Price
      </Typography>
      <Grid container xs={12} spacing="8px">
        <Grid item xs={12} sm={3}>
          <Typography variant="body2">Warehouse</Typography>
          <Autocomplete
            options={warehouses || []}
            getOptionLabel={(option) =>
              `ID: ${option?.id || ""}, Code: ${option?.code || ""}, Name: ${
                option?.name || ""
              }`
            }
            value={editStorage.data.warehouse}
            onChange={(event, newValue) => {
              setEditStorage({
                ...editStorage,
                data: {
                  ...editStorage.data,
                  warehouseId: newValue?.id,
                  warehouse: newValue,
                },
              });
            }}
            onOpen={() => retrieveWarehouse()}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                fullWidth
              />
            )}
            renderTags={() => null}
          />
          {editStorage?.data?.warehouse && (
            <Box mt={2}>
              <Chip
                label={`ID: ${editStorage?.data?.warehouse?.id || ""}`}
                onDelete={() => {
                  setEditStorage({
                    ...editStorage,
                    data: {
                      ...editStorage.data,
                      warehouseId: null,
                      warehouse: null,
                    },
                  });
                }}
                style={{ marginBottom: "5px" }}
              />
              <br></br>
              <Chip
                label={`Code: ${editStorage?.data?.warehouse?.code || ""}`}
                style={{ marginBottom: "5px" }}
              />
              <br />
              <Chip
                label={`Name: ${editStorage?.data?.warehouse?.name || ""}`}
                style={{ marginBottom: "5px" }}
              />
              <br />
              <Chip
                label={`Contact: ${
                  editStorage?.data?.warehouse?.contactPerson?.contactPerson ||
                  ""
                }`}
                style={{ marginBottom: "5px" }}
              />
              <br />
              <Chip
                label={`Storage Pricing By ${
                  editStorage?.data?.warehouse?.storagePricingBy || ""
                }`}
                style={{ marginBottom: "5px" }}
              />
              <br />
            </Box>
          )}
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant="body2">Year</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            type="number"
            value={editStorage?.data?.year}
            onWheel={(e) => e.target.blur()}
            onChange={(e) =>
              setEditStorage({
                ...editStorage,
                data: {
                  ...editStorage.data,
                  year: e.target.value,
                },
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}></Grid>

        <Grid item xs={12} sm={2}>
            <Typography variant="body2">Currency</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select--currency"
              select
              label={editStorage?.data?.currency || ""}
              value={editStorage?.data?.currency || ""}
              onChange={(e) =>
                setEditStorage({
                  ...editStorage,
                  data: {
                    ...editStorage.data,
                    currency: e.target.value,
                  },
                })
              }
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={10}></Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">January</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            type="number"
            value={editStorage?.data?.january}
            onWheel={(e) => e.target.blur()}
            onChange={(e) =>
              setEditStorage({
                ...editStorage,
                data: {
                  ...editStorage.data,
                  january: e.target.value,
                },
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">February</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            type="number"
            value={editStorage?.data?.february}
            onWheel={(e) => e.target.blur()}
            onChange={(e) =>
              setEditStorage({
                ...editStorage,
                data: {
                  ...editStorage.data,
                  february: e.target.value,
                },
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">March</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            type="number"
            value={editStorage?.data?.march}
            onWheel={(e) => e.target.blur()}
            onChange={(e) =>
              setEditStorage({
                ...editStorage,
                data: {
                  ...editStorage.data,
                  march: e.target.value,
                },
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">April</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            type="number"
            value={editStorage?.data?.april}
            onWheel={(e) => e.target.blur()}
            onChange={(e) =>
              setEditStorage({
                ...editStorage,
                data: {
                  ...editStorage.data,
                  april: e.target.value,
                },
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">May</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            type="number"
            value={editStorage?.data?.may}
            onWheel={(e) => e.target.blur()}
            onChange={(e) =>
              setEditStorage({
                ...editStorage,
                data: {
                  ...editStorage.data,
                  may: e.target.value,
                },
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">June</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            type="number"
            value={editStorage?.data?.june}
            onWheel={(e) => e.target.blur()}
            onChange={(e) =>
              setEditStorage({
                ...editStorage,
                data: {
                  ...editStorage.data,
                  june: e.target.value,
                },
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">July</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            type="number"
            value={editStorage?.data?.july}
            onWheel={(e) => e.target.blur()}
            onChange={(e) =>
              setEditStorage({
                ...editStorage,
                data: {
                  ...editStorage.data,
                  july: e.target.value,
                },
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">August</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            type="number"
            value={editStorage?.data?.august}
            onWheel={(e) => e.target.blur()}
            onChange={(e) =>
              setEditStorage({
                ...editStorage,
                data: {
                  ...editStorage.data,
                  august: e.target.value,
                },
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">September</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            type="number"
            value={editStorage?.data?.september}
            onWheel={(e) => e.target.blur()}
            onChange={(e) =>
              setEditStorage({
                ...editStorage,
                data: {
                  ...editStorage.data,
                  september: e.target.value,
                },
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">October</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            type="number"
            value={editStorage?.data?.october}
            onWheel={(e) => e.target.blur()}
            onChange={(e) =>
              setEditStorage({
                ...editStorage,
                data: {
                  ...editStorage.data,
                  october: e.target.value,
                },
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">November</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            type="number"
            value={editStorage?.data?.november}
            onWheel={(e) => e.target.blur()}
            onChange={(e) =>
              setEditStorage({
                ...editStorage,
                data: {
                  ...editStorage.data,
                  november: e.target.value,
                },
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">December</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            type="number"
            value={editStorage?.data?.december}
            onWheel={(e) => e.target.blur()}
            onChange={(e) =>
              setEditStorage({
                ...editStorage,
                data: {
                  ...editStorage.data,
                  december: e.target.value,
                },
              })
            }
          />
        </Grid>
      </Grid>
      <Stack direction="row" spacing={1} mt={1}>
        <Button
          variant="outlined"
          onClick={() =>
            setEditStorage({ visible: false, index: null, data: null })
          }
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setRows([editStorage?.data, ...rows]);
            setEditStorage({ visible: false, index: null, data: null });

            updateMultipleStoragePricesApi([editStorage?.data])
              .then((response) => {
                window.location.reload();
                navigate("/storage-price");
              })
              .catch((error) => {
                console.log(error.response.data);
              });
          }}
        >
          Edit
        </Button>
      </Stack>
    </Stack>
  );
};

export default EditStoragePrice;
