import React, { useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { retrieveSampleByIdApi } from "../../../../components/api/SampleApiService";

const SampleButton = ({ row, id, setEditSample }) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    if (loading) return; 
    setLoading(true);
    try {
      const sampleData = await retrieveSampleByIdApi(id);
      if (!sampleData) {
        alert("No data returned from the API.");
        return;
      }
      setEditSample({
        visible: true,
        data: sampleData?.data,
        index: row?.index,
      });
      console.log(sampleData);
    } catch (error) {
      console.error("Error retrieving sample:", error);
    } finally {
      setLoading(false); 
    }
  };

  return (
    <Button
      sx={{
        minWidth: 0,
        padding: "2px 4px",
        fontSize: "0.8rem",
        lineHeight: 1,
      }}
      onClick={handleClick}
      variant="text"
      disabled={loading}
      startIcon={loading && <CircularProgress size={16} />}
    >
      {id}
    </Button>
  );
};

export default SampleButton;
