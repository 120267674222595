import {
  Box,
  Button,
  Modal,
  TableCell as StyledTableCell,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { retrieveFilteredStoragePricesApi } from "../../../../components/api/StoragePriceApiService";

const ManualStorageCostButton = ({ rows, onCostCalculated }) => {
  const [manualOpen, setManualOpen] = useState(false);
  const [historyOpen, setHistoryOpen] = useState(false);
  const [manualEntryDate, setManualEntryDate] = useState("");
  const [manualExitDate, setManualExitDate] = useState("");
  const [manualAmount, setManualAmount] = useState("");
  const [history, setHistory] = useState([]); // To store history details

  const handleManualOpen = () => setManualOpen(true);
  const handleManualClose = () => setManualOpen(false);
  const handleHistoryOpen = () => setHistoryOpen(true);
  const handleHistoryClose = () => setHistoryOpen(false);

  const calculateManualStorageCost = async () => {
    if (!manualEntryDate || !manualExitDate || !manualAmount) {
      alert("Please fill out all fields!");
      return;
    }

    const entryDate = new Date(manualEntryDate);
    const exitDate = new Date(manualExitDate);
    const monthsDifference =
      exitDate.getFullYear() * 12 +
      exitDate.getMonth() -
      (entryDate.getFullYear() * 12 + entryDate.getMonth());

    let totalStorageCost = 0;
    const monthNames = [
      "january",
      "february",
      "march",
      "april",
      "may",
      "june",
      "july",
      "august",
      "september",
      "october",
      "november",
      "december",
    ];

    let currentIterationMonth = new Date(entryDate);
    const historyDetails = [];

    for (let i = 0; i <= monthsDifference; i++) {
      const filteredStorageCost = {
        id: 0,
        warehouseIdList: [rows[0]?.warehouse?.id],
        year: currentIterationMonth.getFullYear(),
        recordStatusList: ["ACTIVE"],
      };

      try {
        const apiResponse = await retrieveFilteredStoragePricesApi(
          filteredStorageCost,
        );
        const apiResponseData = apiResponse?.data;
        const monthName = monthNames[currentIterationMonth.getMonth()];
        const priceForMonth = apiResponseData[0]?.[monthName] ?? 0;

        const monthlyStorageCost = manualAmount * priceForMonth;
        totalStorageCost += monthlyStorageCost;

        historyDetails.push({
          month: monthName,
          year: currentIterationMonth.getFullYear(),
          priceForMonth,
          manualAmount,
          monthlyStorageCost,
        });

        currentIterationMonth.setMonth(currentIterationMonth.getMonth() + 1);
      } catch (error) {
        console.error("Error fetching storage prices:", error);
      }
    }

    onCostCalculated(totalStorageCost);
    setHistory(historyDetails);
    handleManualClose();
    handleHistoryOpen();
  };

  return (
    <>
      <Button variant="outlined" color="primary" onClick={handleManualOpen}>
        Manual Storage Cost
      </Button>

      {/* Manual Input Modal */}
      <Modal open={manualOpen} onClose={handleManualClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            Manual Storage Cost Calculation
          </Typography>

          <TextField
            label="Entry Date"
            type="date"
            value={manualEntryDate}
            onChange={(e) => setManualEntryDate(e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
            onKeyDown={(e) => e.preventDefault()}
            sx={{ marginBottom: 2 }}
          />

          <TextField
            label="Exit Date"
            type="date"
            value={manualExitDate}
            onChange={(e) => setManualExitDate(e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
            onKeyDown={(e) => e.preventDefault()}
            sx={{ marginBottom: 2 }}
          />

          <TextField
            label="Amount (kg)"
            value={manualAmount}
            onChange={(e) => setManualAmount(e.target.value)}
            type="number"
                  onWheel={(e) => e.target.blur()}
            fullWidth
            sx={{ marginBottom: 2 }}
          />

          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={calculateManualStorageCost}
          >
            Calculate
          </Button>
        </Box>
      </Modal>

      {/* History Modal */}
      <Modal open={historyOpen} onClose={handleHistoryClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            maxHeight: "70vh",
            overflowY: "auto",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            Manual Storage Cost History
          </Typography>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#20315d" }}>
                <StyledTableCell sx={{ color: "#fff", fontWeight: "bold" }}>
                  Month
                </StyledTableCell>
                <StyledTableCell sx={{ color: "#fff", fontWeight: "bold" }}>
                  Year
                </StyledTableCell>
                <StyledTableCell sx={{ color: "#fff", fontWeight: "bold" }}>
                  Price
                </StyledTableCell>
                <StyledTableCell sx={{ color: "#fff", fontWeight: "bold" }}>
                  Amount
                </StyledTableCell>
                <StyledTableCell sx={{ color: "#fff", fontWeight: "bold" }}>
                  Monthly Cost
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history.map((record, index) => (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor: index % 2 === 0 ? "#f0f8ff" : "#ffffff",
                    "&:hover": {
                      backgroundColor: "#e6f7ff",
                    },
                  }}
                >
                  <StyledTableCell>{record.month}</StyledTableCell>
                  <StyledTableCell>{record.year}</StyledTableCell>
                  <StyledTableCell
                    sx={{ fontWeight: "bold", textAlign: "center" }}
                  >
                    {`${new Intl.NumberFormat("en-US", {
                      style: "decimal",
                      minimumFractionDigits: 2,
                    }).format(record.priceForMonth)} USD`}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ fontWeight: "bold", textAlign: "center" }}
                  >
                    {`${new Intl.NumberFormat("en-US", {
                      style: "decimal",
                      minimumFractionDigits: 2,
                    }).format(record.manualAmount)} KG`}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ fontWeight: "bold", textAlign: "center" }}
                  >
                    {`${new Intl.NumberFormat("en-US", {
                      style: "decimal",
                      minimumFractionDigits: 2,
                    }).format(record.monthlyStorageCost)} USD`}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <StyledTableCell
                  colSpan={4}
                  sx={{ fontWeight: "bold", textAlign: "right" }}
                >
                  Total Cost:
                </StyledTableCell>
                <StyledTableCell
                  sx={{ fontWeight: "bold", textAlign: "center" }}
                >
                  {`${new Intl.NumberFormat("en-US", {
                    style: "decimal",
                    minimumFractionDigits: 2,
                  }).format(
                    history.reduce(
                      (sum, record) => sum + record.monthlyStorageCost,
                      0,
                    ),
                  )} USD`}
                </StyledTableCell>
              </TableRow>
            </TableFooter>
          </Table>
          <Button
            onClick={handleHistoryClose}
            variant="contained"
            color="secondary"
            sx={{ marginTop: 2 }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default ManualStorageCostButton;
