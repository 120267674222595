import DeleteIcon from "@mui/icons-material/Delete";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { alpha } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import { format } from "date-fns";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import ItemButton from './Dialogs/ItemButton.js'; 
import {
  retrieveAllCostsApi,
  retrieveCostByIdApi,
} from "../../../components/api/CostApiService";
import {
  retrieveAllItemsApi,
  retrieveItemByIdApi,
} from "../../../components/api/ItemApiService";
import { acquireLockApi } from "../../../components/api/LockApiService";
import {
  approveOfferApi,
  createOfferApi,
  deleteOffersApi,
  retrieveAllOffersApi,
  retrieveFilteredOffersApi,
  retrieveMaxOfferNumberApi,
  retrieveSelectedOffersByIdApi,
} from "../../../components/api/OfferApiService";
import {
  retrieveAllSamplesApi,
  retrieveSampleByIdApi,
} from "../../../components/api/SampleApiService";
import { retrieveAllStakeholdersApi } from "../../../components/api/StakeholderApiService";
import ExportExcel from "../../../utils/excelExport";
import EditCost from "../CostView/EditCost";
import EditItem from "../ItemView/EditItem";
import EditSample from "../SampleView/EditSample";
import AddCustomerOffer from "./AddCustomerOffer";
import CustomerOfferSearch from "./CustomerOfferSearch";
import CreateOfferPdf from "./Dialogs/CreateOfferPdf";
import CreateOrder from "./Dialogs/CreateOrder";
import CreateVendorOffer from "./Dialogs/CreateVendorOffer";
import EditCustomerOffer from "./EditCustomerOffer";
import AddSingleCustomerOffer from "./Dialogs/AddSingleCustomerOffer";
import { useLocation } from "react-router";
import CostButton from "./Dialogs/CostButton.js";
import SampleButton from "./Dialogs/SampleButton.js";
import ApproveOfferButton from "./Dialogs/ApproveOfferButton.js";

const headCells = [
  {
    id: "editButton",
    disablePadding: true,
    label: "",
    width: 45,
  },
  {
    id: "approveButton",
    disablePadding: true,
    label: "",
  },
  {
    id: "createOButton",
    disablePadding: true,
    label: "",
  },
  {
    id: "duplicateButton",
    disablePadding: true,
    label: "",
  },
  {
    id: "addToTheGroup",
    disablePadding: true,
    label: "",
  },
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "offerGroupNumber",
    numeric: true,
    disablePadding: true,
    label: "Offer Number",
  },
  {
    id: "itemId",
    numeric: true,
    disablePadding: true,
    label: "Item ID",
  },
  {
    id: "itemTobaccoCode",
    numeric: true,
    disablePadding: true,
    label: "Tobacco Code",
    width: 150,
  },
  {
    id: "itemBuyingGrade",
    numeric: false,
    disablePadding: true,
    label: "Buying Grade",
  },
  {
    id: "itemSalesGrade",
    numeric: false,
    disablePadding: true,
    label: "Sales Grade",
  },
  {
    id: "itemCrop",
    numeric: false,
    disablePadding: true,
    label: "Crop",
  },
  {
    id: "salesCrop",
    numeric: false,
    disablePadding: true,
    label: "Sales Crop",
  },
  {
    id: "itemNicotine",
    numeric: true,
    disablePadding: true,
    label: "Nicotine",
  },
  {
    id: "salesNicotine",
    numeric: true,
    disablePadding: true,
    label: "Sales Nicotine",
  },
  {
    id: "itemSugar",
    numeric: true,
    disablePadding: true,
    label: "Sugar",
  },
  {
    id: "salesSugar",
    numeric: true,
    disablePadding: true,
    label: "Sales Sugar",
  },
  {
    id: "itemMoisture",
    numeric: true,
    disablePadding: true,
    label: "Moisture",
  },
  {
    id: "itemOrigin",
    numeric: false,
    disablePadding: true,
    label: "Origin",
  },
    {
    id: "salesOrigin",
    numeric: false,
    disablePadding: true,
    label: "Sales Origin",
  },
  {
    id: "itemType",
    numeric: false,
    disablePadding: true,
    label: "Type",
  },
  {
    id: "itemStorage",
    numeric: false,
    disablePadding: true,
    label: "Current Location",
  },
  {
    id: "company",
    numeric: false,
    disablePadding: true,
    label: "Company",
    width: 150,
  },
  {
    id: "approvalStatus",
    numeric: false,
    disablePadding: true,
    label: "SG Approval Status",
    width: 150,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    label: "Status",
    width: 85,
  },
  {
    id: "statusDeadline",
    numeric: false,
    disablePadding: true,
    label: "Status Deadline",
  },
  {
    id: "customer",
    numeric: true,
    disablePadding: true,
    label: "SAP Customer Code",
  },
  {
    id: "customerName",
    numeric: false,
    disablePadding: true,
    label: "Customer Name",
  },
  {
    id: "costId",
    numeric: true,
    disablePadding: true,
    label: "Cost Id",
  },
  {
    id: "estimatedTotalCost",
    numeric: true,
    disablePadding: true,
    label: "Estimated Total Cost",
  },
  {
    id: "buyingPrice",
    numeric: true,
    disablePadding: true,
    label: "Buying Price Per Kg",
  },
  {
    id: "firstOfferPrice",
    numeric: true,
    disablePadding: true,
    label: "First Offer",
  },
  {
    id: "firstOfferPriceUsd",
    numeric: true,
    disablePadding: true,
    label: "First Offer (USD)",
  },
  {
    id: "finalOfferPrice",
    numeric: true,
    disablePadding: true,
    label: "Final Offer",
  },
  {
    id: "finalOfferPriceUsd",
    numeric: true,
    disablePadding: false,
    label: "Final Offer (USD)",
  },
  {
    id: "offerSampleId",
    numeric: true,
    disablePadding: true,
    label: "Offer Sample",
  },
  {
    id: "offerSampleDate",
    numeric: true,
    disablePadding: true,
    label: "Offer Sample Date",
  },
  {
    id: "offerSampleStatus",
    numeric: false,
    disablePadding: true,
    label: "Offer Sample Status",
  },
  {
    id: "trialSampleId",
    numeric: true,
    disablePadding: true,
    label: "Trial Sample",
  },
  {
    id: "trialSampleDate",
    numeric: true,
    disablePadding: true,
    label: "Trial Sample Date",
  },
  {
    id: "trialSampleStatus",
    numeric: false,
    disablePadding: true,
    label: "Trial Sample Status",
  },
  {
    id: "packaging",
    numeric: false,
    disablePadding: false,
    label: "Packaging",
    width: 85,
  },
  {
    id: "paymentTerm",
    numeric: false,
    disablePadding: true,
    label: "Payment Term",
    width: 150,
  },
  {
    id: "incoterm",
    numeric: true,
    disablePadding: true,
    label: "Incoterm",
  },
  {
    id: "numberOfCase",
    numeric: true,
    disablePadding: true,
    label: "Number Of Cartons",
  },
  {
    id: "netWeightPerCase",
    numeric: true,
    disablePadding: true,
    label: "Net Weight Per Carton",
  },
  {
    id: "volume",
    numeric: true,
    disablePadding: true,
    label: "Volume",
  },
  {
    id: "totalAmount",
    numeric: true,
    disablePadding: true,
    label: "Total Amount",
  },
  {
    id: "offerDate",
    numeric: false,
    disablePadding: true,
    label: "Offer Date",
  },
  {
    id: "validityDate",
    numeric: false,
    disablePadding: true,
    label: "Validity Date",
  },
  {
    id: "portOfDischarge",
    numeric: false,
    disablePadding: true,
    label: "Port Of Discharge",
    width: 200,
  },
  {
    id: "portOfLoading",
    numeric: false,
    disablePadding: true,
    label: "Port Of Loading",
    width: 200,
  },
  {
    id: "remark",
    numeric: false,
    disablePadding: true,
    label: "Remark",
    width: 200,
  },
  {
    id: "comment",
    numeric: false,
    disablePadding: true,
    label: "Comment",
    width: 200,
  },
  {
    id: "offerLink",
    numeric: false,
    disablePadding: true,
    label: "Offer Link",
  },
  {
    id: "documentStatus",
    numeric: false,
    disablePadding: true,
    label: "Document Status",
  },
  {
    id: "creationTime",
    numeric: false,
    disablePadding: true,
    label: "Creation Time",
  },
  {
    id: "createdBy",
    numeric: false,
    disablePadding: true,
    label: "Created By",
  },
  {
    id: "recordStatus",
    numeric: false,
    disablePadding: true,
    label: "Record Status",
  },
  {
    id: "referenceOffer",
    numeric: false,
    disablePadding: true,
    label: "Reference Vendor Offer",
  },

  /*{
    id: "vendorOrderButton",
    disablePadding: true,
    label: "",
  },*/
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "asc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]) || [];
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={
              headCell.id === "id"
                ? {
                    position: 'sticky',
                    left: 0,
                    zIndex: 3, 
                  }
                : headCell.id === "offerGroupNumber"
                ? {
                    position: 'sticky',
                    left: 50,
                    zIndex: 3, 
                  }
                : headCell.id === "itemBuyingGrade"
                ? {
                    position: 'sticky',
                    left: 180,
                    zIndex: 3, 
                  }
                : null
            }
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const {
    numSelected,
    setShowFilters,
    showFilters,
    rows,
    setAddView,
    selected,
    setPdfPopup,
    selectedData,
  } = props;
  const [deletePopup, setDeletePopup] = useState(false);

  function createNewExportObject(rows) {
    const newObjects = [];
    const headerMapping = {
      ID: "id",
      "Offer Number": (row) =>
        `${row?.offerYear || ""}${
          row?.offerYear && row?.offerGroupNumber ? "-" : ""
        }${row?.offerGroupNumber || ""}`,
      "Order Number": "orderGroupNumber",
      "Item ID": "item?.id",
      "Tobacco Code": "item?.tobaccoCode",
      "Buying Grade": "item?.buyingGrade",
      "Sales Grade": "salesGrade",
      Crop: "item?.crop",
      Nicotine: "item?.nicotine",
      Sugar: "item?.sugar",
      Origin: "item?.origin",
      Type: "item?.type",
      "Current Location": "item?.storage",
      Company: "company",
      "Approval Status": "approvalStatus",
      Status: "status",
      "Status Deadline": (row) =>
        row?.statusDeadline
          ? format(new Date(row.statusDeadline), "dd/MM/yyyy")
          : "",
      "SAP Customer Code": "stakeholder?.code",
      "Customer Name": "stakeholder?.name",
      "Estimated Cost ID": "estimatedCost?.id",
      "Estimated Total Cost": "estimatedCost?.totalCost",
      "Buying Price": "buyingPrice",
      "Buying Price Currency": "buyingPriceCurrency",
      "First Offer Price": "firstOfferPrice",
      "First Offer Price Currency": "firstOfferPriceCurrency",
      "USD First Offer Price": "usdFirstOfferPrice",
      "Final Price": "finalOfferPrice",
      "Final Price Currency": "finalOfferPriceCurrency",
      "USD Final Offer Price": "usdFinalOfferPrice",
      "First Sample ID": "firstSample?.id",
      "First Sample Delivery Date": (row) =>
        row?.firstSample?.deliveryDate
          ? format(new Date(row.firstSample.deliveryDate), "dd/MM/yyyy")
          : "",
      "First Sample Status": "firstSampleStatus",
      "Second Sample ID": "secondSample?.id",
      "Second Sample Delivery Date": (row) =>
        row?.secondSample?.deliveryDate
          ? format(new Date(row.secondSample.deliveryDate), "dd/MM/yyyy")
          : "",
      "Second Sample Status": "secondSampleStatus",
      Packaging: "packaging",
      "Payment Term": "paymentTerm",
      Incoterm: "incoterm",
      "Number of Cartons": "numberOfCases",
      "Net Weight Per Carton": "netWeightPerCase",
      Volume: "volume",
      "Volume Unit": "volumeUnit",
      "Total Amount": "totalAmount",
      "Total Amount Currency": "totalAmountCurrency",
      "Offer Date": (row) =>
        row?.offerDate ? format(new Date(row.offerDate), "dd/MM/yyyy") : "",
      "Validity Date": (row) =>
        row?.validityDate
          ? format(new Date(row.validityDate), "dd/MM/yyyy")
          : "",
      "Port of Loading": "portOfLoading",
      Remark: "remark",
      Comment: "comment",
      "Offer Link": "offerLink",
      "Document Status": "documentStatus",
      "Record Status": "recordStatus",
      "Editing Permission": "editingPermission",
      "Reference Offer ID": "referenceSanOfferId",
    };

    rows.forEach((row) => {
      const newObject = {};
      Object.entries(headerMapping).forEach(([header, pathOrFunction]) => {
        let value;
        if (typeof pathOrFunction === "function") {
          // Execute function for special processing
          value = pathOrFunction(row);
        } else {
          // Direct mapping or nested properties
          const keys = pathOrFunction.split("?.");
          value = row;
          for (const key of keys) {
            if (value == null) break;
            value = value[key];
          }
        }
        newObject[header] = value ?? ""; // Use nullish coalescing operator to default to empty string
      });
      newObjects.push(newObject);
    });

    return newObjects;
  }

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity,
            ),
        }),
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Dialog
        open={deletePopup}
        onClose={() => setDeletePopup(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete {selected.length} offer(s)?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              deleteOffersApi(selected)
                .then((response) => {
                  console.log(response?.data);
                  toast.success("Customer offer(s) deleted successfully.");
                })
                .catch((error) => {
                  console.log(error.response?.data);
                  toast.error("Customer offer(s) could not be deleted.");
                });

              window.location.reload();
            }}
            autoFocus
          >
            Yes
          </Button>
          <Button onClick={() => setDeletePopup(false)}>No</Button>
        </DialogActions>
      </Dialog>

      <Stack direction="row" spacing={1} alignItems={"center"}>
        <Typography
          sx={{ fontWeight: 600, fontSize: "20px" }}
          variant="h6"
          id="tableTitle"
          component="div"
          color="#1E2685"
        >
          Customer Offers
        </Typography>
        <Typography></Typography>
        {numSelected > 0 ? (
          <Typography
            sx={{ fontWeight: 200, fontSize: "16px" }}
            color="#1E2685"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography></Typography>
        )}

        {numSelected > 0 && (
          <Tooltip title="Delete">
            <IconButton onClick={() => setDeletePopup(true)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
        {numSelected > 0 && (
          <Button
            variant="outlined"
            sx={{ minWidth: "130px" }}
            style={{ whiteSpace: "nowrap", marginLeft: 20 }}
            onClick={() => {
              retrieveSelectedOffersByIdApi(selected, "CUSTOMER")
                .then((response) => {
                  setTimeout(() => {
                    setPdfPopup({
                      data: response?.data,
                      visible: true,
                    });
                  }, 1000);
                  console.log("pdfpopup ", response?.data);
                  toast.success("Customer offers retrieved successfully.");
                })
                .catch((error) => {
                  console.log(error.response?.data);
                  toast.error("Customer offers could not be retrieved.");
                });
            }}
          >
            Create Offer Excel
          </Button>
        )}
      </Stack>

      <Stack direction={"row"} spacing={1} sx={{ mr: 2 }}>
        <ExportExcel
          excelData={createNewExportObject(selectedData)}
          fileName={"ExcelExport"}
          buttonLabel="Export Table"
        />

        <Button
          disableElevation
          variant="outlined"
          size="small"
          onClick={() => setAddView(true)}
        >
          Add
        </Button>
        <Button
          variant="outlined"
          onClick={() => setShowFilters(!showFilters)}
          sx={{ minWidth: "130px" }}
          size="small"
        >
          {showFilters ? "Hide Filters" : "Show Filters"}
        </Button>
      </Stack>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function CustomerOfferView() {
  const storedUserId = localStorage.getItem("userId");

  const location = useLocation();
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [items, setItems] = useState([]);
  const [vendorOffers, setVendorOffers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [samples, setSamples] = useState([]);
  const [costs, setCosts] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  var filterCurr = new Date();
  filterCurr.setFullYear(filterCurr.getFullYear() - 100);
  var filterDate = filterCurr.toISOString().substring(0, 10);
  const [filterCustomerOffer, setFilterCustomerOffer] = useState({
    id: 0,
    offerType: "CUSTOMER",
    stakeHolderType: "CUSTOMER",
    itemBuyingGrade: "",
    itemIdList: [],
    companyList: [],
    approvalStatusList: [],
    statusList: [],
    vendorList: [],
    stakeholderIdList: [],
    firstSampleIdList: [],
    secondSampleIdList: [],
    costIdList: [],
    firstSampleDate: filterDate,
    secondSampleDate: filterDate,
    firstSampleStatusList: [],
    secondSampleStatusList: [],
    paymentTerm: "",
    paymentTermList: [],
    salesOrigin: "",
    salesOriginList: [],
    incotermList: [],
    volumeUnitList: [],
    firstPriceCurrencyList: [],
    finalPriceCurrencyList: [],
    remark: "",
    comment: "",
    portOfLoading: "",
    portOfLoadingList: [],
    portOfDischargeList: [],
    documentStatusList: [],
    tobaccoCode: "",
    salesGrade: "",
    buyingGrade: "",
    customerGrade: "",
    internalGrade:"",
    originList: [],
    typeList: [],
    leafFormList: [],
    colorList: [],
    storageList: [],
    itemStorage: "",
    referenceVendorOfferIdList: [],
    recordStatusList: ["ACTIVE"],
  });
  const [addView, setAddView] = useState(false);
  const [addViewStates, setAddViewStates] = useState(null);
  const [rows, setRows] = useState([]);
  const [dense, setDense] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [maxOfferGroupNumber, setMaxOfferGroupNumber] = useState();
  const [item, setItem] = useState([]);
  const [cost, setCost] = useState([]);
  const [sample, setSample] = useState([]);
  const [lock, setLock] = useState({
    userId: storedUserId,
    recordType: "EDITCUSTOMEROFFER",
  });
  const [showOrderPopup, setShowOrderPopup] = useState({
    visible: false,
    data: null,
  });
  const [pdfPopup, setPdfPopup] = useState({
    visible: false,
    data: null,
  });
  const [showOfferPopup, setShowOfferPopup] = useState({
    visible: false,
    data: null,
  });
  const [addOfferToGroup, setAddOfferToGroup] = useState({
    visible: false,
    data: null,
    index: null,
  });
  const [editCustomerOffer, setEditCustomerOffer] = useState({
    visible: false,
    data: null,
    index: null,
  });
  const [editItem, setEditItem] = useState({
    visible: false,
    data: null,
    index: null,
  });
  const [editCost, setEditCost] = useState({
    visible: false,
    data: null,
    index: null,
  });
  const [editSample, setEditSample] = useState({
    visible: false,
    data: null,
    index: null,
  });

  useEffect(() => {
    retrieveCustomerOffers();
    getMaxOfferNumber();
    if (location.state) {
      setAddView(true);
    }
  }, [location]);

  useEffect(() => {
    retrieveCustomerOffers();
    getMaxOfferNumber();
  }, []);

  function acquireLock(lock) {
    return new Promise((resolve, reject) => {
      acquireLockApi(lock)
        .then((response) => {
          const isLocked = response?.data;
          resolve(isLocked); // Resolve the Promise with the isLocked value
        })
        .catch((error) => {
          console.log(error.response?.data);
          reject(error); // Reject the Promise with the error
        });
    });
  }
  function retrieveItems() {
    retrieveAllItemsApi()
      .then((response) => {
        setItems(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveVendorOffers() {
    retrieveAllOffersApi("VENDOR")
      .then((response) => {
        setVendorOffers(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }


  function retrieveItemById(id) {
    retrieveItemByIdApi(id)
      .then((response) => {
        setItem(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveSamples() {
    retrieveAllSamplesApi()
      .then((response) => {
        setSamples(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveSampleById(id) {
    retrieveSampleByIdApi(id)
      .then((response) => {
        setSample(response?.data);
        console.log(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveCustomers() {
    retrieveAllStakeholdersApi("CUSTOMER")
      .then((response) => {
        setCustomers(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveCosts() {
    retrieveAllCostsApi()
      .then((response) => {
        setCosts(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveCostById(id) {
    retrieveCostByIdApi(id)
      .then((response) => {
        setCost(response?.data);
        console.log(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveCustomerOffers() {
    retrieveAllOffersApi("CUSTOMER")
      .then((response) => {
        setRows(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function getMaxOfferNumber() {
    retrieveMaxOfferNumberApi("CUSTOMER")
      .then((response) => {
        setMaxOfferGroupNumber(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function approveOffer(offerId) {
    approveOfferApi(offerId)
      .then((response) => {
        window.location.reload();
        toast.success("Customer offer approved successfully.");
      })
      .catch((error) => {
        toast.error("No Authorization");
        console.log(error.response.data);
      });
  }

  const newOfferGroupNumber = maxOfferGroupNumber + 1;

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      setSelectedData(rows);
      return;
    }else{
      setSelected([]);
      setSelectedData([]);
    }
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, name, row) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    const selectedDataIndex = selectedData.indexOf(row);
    let newSelectedData = [];

    if (selectedDataIndex === -1) {
      newSelectedData = newSelectedData.concat(selectedData, row);
    } else if (selectedDataIndex === 0) {
      newSelectedData = newSelectedData.concat(selectedData.slice(1));
    } else if (selectedDataIndex === selectedData.length - 1) {
      newSelectedData = newSelectedData.concat(selectedData.slice(0, -1));
    } else if (selectedDataIndex > 0) {
      newSelectedData = newSelectedData.concat(
        selectedData.slice(0, selectedDataIndex),
        selectedData.slice(selectedDataIndex + 1),
      );
    }
    setSelectedData(newSelectedData);

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDuplicate = (row) => {
    const newRow = {
      ...row,
      itemId: row?.item?.id,
      stakeholderId: row?.stakeholder?.id,
      firstSampleId: row?.firstSample?.id,
      secondSampleId: row?.secondSample?.id,
      estimatedCostId: row?.estimatedCost?.id,
    };
    console.log(row);
    createOfferApi(newRow)
      .then((response) => {
        toast.success("Customer offer duplicated successfully.");
        window.location.reload();
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(error.response.data);
      });
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  function formatDate(date) {
    let day = date.getDate().toString().padStart(2, "0");
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
  var today = new Date();
  var formattedToday = formatDate(today);
  var tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  var formattedTomorrow = formatDate(tomorrow);

  var dayAfter = new Date(today);
  dayAfter.setDate(today.getDate() + 2);
  var formattedDayAfter = formatDate(dayAfter);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const startOfDay = (date) =>
    new Date(date.getFullYear(), date.getMonth(), date.getDate());

  const startOfToday = startOfDay(today);

  const firstRefusalOffers = new Map();
  rows.forEach((row) => {
    if (
      row.status === "First Refusal" &&
      new Date(row.statusDeadline) >= startOfToday
    ) {
      const existingOffers = firstRefusalOffers.get(row?.item?.id) || [];
      existingOffers.push({
        offerId: row?.id,
        volume: row?.volume,
      });
      firstRefusalOffers.set(row?.item?.id, existingOffers);
    }
  });

  const optionedOffers = new Map();
  rows.forEach((row) => {
    if (
      row.status === "Optioned" &&
      new Date(row.statusDeadline) >= startOfToday
    ) {
      const existingOptionedOffers = optionedOffers.get(row?.item?.id) || [];
      existingOptionedOffers.push({
        offerId: row?.id,
        volume: row?.volume,
      });
      optionedOffers.set(row?.item?.id, existingOptionedOffers);
    }
  });

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, rows, editCustomerOffer],
  );

  return (
    <div>
      {addView ? (
        <AddCustomerOffer
          setAddView={setAddView}
          locatedData={location?.state?.data}
          prevLocation={location?.state?.prevLocation}
          newOfferGroupNumber={newOfferGroupNumber}
        />
      ) : editCustomerOffer.visible ? (
        <EditCustomerOffer
          rows={rows}
          setRows={setRows}
          setAddView={setAddView}
          editCustomerOffer={editCustomerOffer}
          setEditCustomerOffer={setEditCustomerOffer}
        />
      ) : addOfferToGroup.visible ? (
        <AddSingleCustomerOffer
          setAddViewStates={setAddViewStates}
          addViewStates={addViewStates}
          rows={rows}
          setRows={setRows}
          setAddView={setAddView}
          addOfferToGroup={addOfferToGroup}
          setAddOfferToGroup={setAddOfferToGroup}
        />
      ) : showOrderPopup.visible ? (
        <CreateOrder
          setCreateOrder={setShowOrderPopup}
          createOrder={showOrderPopup}
        />
      ) : pdfPopup.visible ? (
        <CreateOfferPdf setPdfPopup={setPdfPopup} pdfPopup={pdfPopup} />
      ) : showOfferPopup.visible ? (
        <CreateVendorOffer
          setCreateVendorOffer={setShowOfferPopup}
          createVendorOffer={showOfferPopup}
        />
      ) : editItem.visible ? (
        <EditItem
          rows={rows}
          setRows={setRows}
          setAddView={setAddView}
          editItem={editItem}
          setEditItem={setEditItem}
        />
      ) : editCost.visible ? (
        <EditCost
          rows={rows}
          setRows={setRows}
          setAddView={setAddView}
          editCost={editCost}
          setEditCost={setEditCost}
        />
      ) : editSample.visible ? (
        <EditSample
          rows={rows}
          setRows={setRows}
          setAddView={setAddView}
          editSample={editSample}
          setEditSample={setEditSample}
        />
      ) : (
        <>
          {showFilters && (
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <CustomerOfferSearch
                setFilterCustomerOffer={setFilterCustomerOffer}
                filterCustomerOffer={filterCustomerOffer}
                retrieveItems={retrieveItems}
                items={items}
                retrieveVendorOffers={retrieveVendorOffers}
                referenceVendorOffers={vendorOffers}
                retrieveCustomers={retrieveCustomers}
                retrieveSamples={retrieveSamples}
                samples={samples}
                customers={customers}
                retrieveCosts={retrieveCosts}
                costs={costs}
              />
              <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={3} sm={1}>
                  <Button
                    disableElevation
                    variant="contained"
                    fullWidth
                    size="small"
                    onClick={() => {
                      retrieveFilteredOffersApi(filterCustomerOffer)
                        .then((response) => {
                          setRows(response?.data);
                        })
                        .catch((error) => {
                          console.log(error?.response?.data);
                        });
                    }}
                  >
                    Search
                  </Button>
                </Grid>
                <Grid item xs={3} sm={1}>
                  <Button
                    disableElevation
                    variant="outlined"
                    fullWidth
                    size="small"
                    onClick={() => {
                      setFilterCustomerOffer({
                        id: 0,
                        offerYear: "",
                        offerType: "CUSTOMER",
                        stakeHolderType: "CUSTOMER",
                        itemBuyingGrade: "",
                        itemIdList: [],
                        stakeholderIdList: [],
                        costIdList: [],
                        companyList: [],
                        approvalStatusList: [],
                        statusList: [],
                        statusDeadline: "",
                        firstSampleDate: "",
                        secondSampleDate: "",
                        firstSampleIdList: [],
                        firstSampleStatusList: [],
                        secondSampleIdList: [],
                        secondSampleStatusList: [],
                        paymentTerm: "",
                        paymentTermList: [],
                        salesOrigin: "",
                        salesOriginList: [],
                        salesCrop: null,
                        salesNicotine: null,
                        salesSugar: null,
                        incotermList: [],
                        offerDate: "",
                        validityDate: "",
                        validityDatePassed: "",
                        volumeUnitList: [],
                        firstPriceCurrencyList: [],
                        finalPriceCurrencyList: [],
                        remark: "",
                        portOfLoading: "",
                        portOfLoadingList:  [],
                        portOfDischargeList: [],
                        comment: "",
                        documentStatusList: [],
                        tobaccoCode: "",
                        salesGrade: "",
                        buyingGrade: "",
                        customerGrade: "",
                        internalGrade:"",
                        originList: [],
                        typeList: [],
                        leafFormList: [],
                        colorList: [],
                        storageList: [],
                        itemStorage: "",
                        crop: null,
                        nicotine: null,
                        sugar: null,
                        moisture: null,
                        firstOfferPrice: null,
                        finalOfferPrice: null,
                        numberOfCases: null,
                        netWeightPerCase: null,
                        volume: null,
                        totalAmount: null,
                        offerGroupNumber: null,
                        orderGroupNumber: null,
                        referenceVendorOfferIdList: [],
                        recordStatusList: ["ACTIVE"],
                      });
                    }}
                  >
                    Clear Filters
                  </Button>
                </Grid>
                <Grid item xs={3} sm={1}>
                  <Button
                    disableElevation
                    variant="contained"
                    fullWidth
                    size="small"
                    onClick={() => {
                      setShowOrderPopup({ data: null, visible: true });
                    }}
                  >
                    Create SO
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}

          <Box sx={{ width: "100%", mt: 2 }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
              <EnhancedTableToolbar
                numSelected={selected.length}
                showFilters={showFilters}
                setShowFilters={setShowFilters}
                rows={rows}
                setAddView={setAddView}
                selected={selected}
                setPdfPopup={setPdfPopup}
                pdfPopup={pdfPopup}
                selectedData={selectedData}
              />
              <TableContainer style={{ maxHeight: 700 }}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  sx={{
                    minWidth: 750,
                    "& .MuiTableCell-root": {
                      borderRight: "1px solid rgba(224, 224, 224, 1)",
                    },
                    "& .MuiTableCell-head": {
                      backgroundColor: "#20315d",
                      color: "white",
                      borderRight: "1px solid rgba(49, 64, 128, 1)",
                    },
                  }}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                  style={{ width: "max-content" }}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />

                  <TableBody>
                    {visibleRows.map((row, index) => {
                      const isItemSelected = isSelected(row?.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                        style={
                          isSelected(row.id) ? { background: "#d7e7fc",
                            textDecoration: new Date(row?.validityDate) < today && row?.validityDate != null
                            ? "line-through"
                            : new Date(row?.statusDeadline) < today && row?.statusDeadline != null
                            ? "line-through"
                            :"none"
                           }
                            : new Date(row?.validityDate) < today && row?.validityDate != null
                            ? { textDecoration: "line-through", background: "white" }
                            : new Date(row?.statusDeadline) < today && row?.statusDeadline != null
                            ? { textDecoration: "line-through", background: "white" }
                            : row?.status === "Confirmed"
                            ? { background: "#c8e6c9" }
                            : new Date(row?.statusDeadline).toDateString() === today.toDateString()
                            ? { background: "#f22e2e" }
                            : new Date(row?.statusDeadline).toDateString() === tomorrow.toDateString()
                            ? { background: "#f76f6f " }
                            : new Date(row?.statusDeadline).toDateString() === dayAfter.toDateString()
                            ? { background: "#f5d3d3 " }
                            : index % 2
                            ? { background: "#f0f0f0" }
                            : { background: "white" }
                        }
                          hover
                          onClick={(event) => handleClick(event, row?.id, row)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row?.id}
                          selected={isItemSelected}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell padding="normal">
                            <Button
                              onClick={async (e) => {
                                setLock({ ...lock, recordId: row.id });
                                try {
                                  const updatedLock = {
                                    ...lock,
                                    recordId: row.id,
                                  };
                                  const isLocked = await acquireLock(
                                    updatedLock,
                                  );
                                  console.log("islocked:", isLocked);

                                  if (isLocked) {
                                    setEditCustomerOffer({
                                      visible: true,
                                      data: row,
                                      index: index,
                                    });
                                    e.preventDefault();
                                  } else {
                                    toast.error(
                                      "It seems another user is currently editing this record. Please try again later or close any open windows.",
                                    );
                                  }
                                } catch (error) {
                                  console.error("Error acquiring lock:", error);
                                  // Handle the error if needed
                                }
                              }}
                            >
                              Edit
                            </Button>
                          </TableCell>
                          <TableCell padding="normal">
                          <ApproveOfferButton
                               row={row}
                               approveOffer={approveOffer}>
                            </ApproveOfferButton>
                          </TableCell>
                          <TableCell padding="normal">
                            <Button
                              style={{ whiteSpace: "nowrap" }}
                              variant="outlined"
                              disabled={
                                row?.approvalStatus !== "Approved" ||
                                ((row?.statusDeadline
                                  ? format(
                                      new Date(row?.statusDeadline),
                                      "dd/MM/yyyy",
                                    )
                                  : "") < formattedToday &&
                                  rows?.statusDeadline != null)
                              }
                              onClick={() => {
                                const offerDetails = firstRefusalOffers.get(
                                  row.item?.id,
                                );
                                const optionedOfferDetails = optionedOffers.get(
                                  row.item?.id,
                                );

                                if (
                                  offerDetails &&
                                  offerDetails.length > 0 &&
                                  row.status !== "First Refusal"
                                ) {
                                  const offerDetailsMessage = offerDetails
                                    .map(
                                      (offer, index) =>
                                        `Offer ${index + 1}: ID: ${
                                          offer.offerId
                                        } Volume: ${offer.volume} KG`,
                                    )
                                    .join("\n");

                                  const confirmMessage = `Selected item's offers with "First Refusal" status: \n\n${offerDetailsMessage}\n\nDo you want to continue?`;

                                  if (!window.confirm(confirmMessage)) {
                                    return;
                                  }
                                }

                                if (
                                  optionedOfferDetails &&
                                  optionedOfferDetails.length > 0 &&
                                  row.status !== "Optioned"
                                ) {
                                  const offerDetailsMessage =
                                    optionedOfferDetails
                                      .map(
                                        (offer, index) =>
                                          `Offer ${index + 1}: ID: ${
                                            offer.offerId
                                          } Volume: ${offer.volume} KG`,
                                      )
                                      .join("\n");

                                  const confirmMessage = `Selected item's offers with "Optioned" status: \n\n${offerDetailsMessage}\n\nDo you want to continue?`;

                                  if (!window.confirm(confirmMessage)) {
                                    return;
                                  }
                                }
                                setShowOrderPopup({ data: row, visible: true });
                              }}
                            >
                              Create SO
                            </Button>
                          </TableCell>
                          {
                            <TableCell padding="normal">
                              <Button
                                variant="outlined"
                                onClick={() => handleDuplicate(row)}
                              >
                                Duplicate
                              </Button>
                            </TableCell>
                          }
                          <TableCell padding="normal">
                            <Button
                              variant="outlined"
                              onClick={(e) => {
                                setAddOfferToGroup({
                                  visible: true,
                                  data: row,
                                  index: index,
                                });
                                e.preventDefault();
                              }}
                            >
                              Add New Offer To The Group
                            </Button>
                          </TableCell>
                          <TableCell align="center" sx={{ position: 'sticky', left: 0, background: 'inherit', zIndex: 1 }}>{row?.id}</TableCell>
                          <TableCell align="center" sx={{ position: 'sticky', left: 50, background: 'inherit', zIndex: 1 }}>
                            {row?.offerYear}
                            {row?.offerYear && row?.offerGroupNumber && "-"}
                            {row?.offerGroupNumber}
                          </TableCell>
                          <TableCell align="center">
                          <ItemButton
                              row={row}
                              setEditItem={setEditItem}
                            />
                          </TableCell>
                          <TableCell align="center">
                            {row?.item?.tobaccoCode}
                          </TableCell>
                          <TableCell align="center" sx={{ position: 'sticky', left: 180, background: 'inherit', zIndex: 1 }}>
                            {row?.item?.buyingGrade}
                          </TableCell>
                          <TableCell align="center">
                            {row?.salesGrade}
                          </TableCell>
                          <TableCell align="center">
                            {row?.item?.crop}
                          </TableCell>
                          <TableCell align="center">{row?.salesCrop}</TableCell>
                          <TableCell align="center">
                            {row?.item?.nicotine}
                          </TableCell>
                          <TableCell align="center">
                            {row?.salesNicotine}
                          </TableCell>
                          <TableCell align="center">
                            {row?.item?.sugar}
                          </TableCell>
                          <TableCell align="center">
                            {row?.salesSugar}
                          </TableCell>
                          <TableCell align="center">
                            {row?.item?.moisture}
                          </TableCell>
                          <TableCell align="center">
                            {row?.item?.origin}
                          </TableCell>
                          <TableCell align="center">
                            {row?.salesOrigin}
                          </TableCell>
                          <TableCell align="center">
                            {row?.item?.type}
                          </TableCell>
                          <TableCell align="center">
                            {row?.item?.storage}
                          </TableCell>
                          <TableCell align="center">{row?.company}</TableCell>
                          <TableCell align="center">
                            {row?.approvalStatus}
                          </TableCell>
                          <TableCell align="center">{row?.status}</TableCell>
                          <TableCell align="center">
                            {row?.statusDeadline
                              ? format(
                                  new Date(row?.statusDeadline),
                                  "dd/MM/yyyy",
                                )
                              : ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.stakeholder?.code}
                          </TableCell>
                          <TableCell align="center">
                            {row?.stakeholder?.name}
                          </TableCell>
                          <TableCell align="center">
                          <CostButton
                              row={row}
                              setEditCost={setEditCost}
                            />
                          </TableCell>
                          <TableCell align="center">
                            {row?.estimatedCost?.totalCost}
                          </TableCell>
                          <TableCell align="center">
                            {row?.buyingPrice &&
                              (row?.buyingPrice +
                                " " +
                                row?.buyingPriceCurrency ||
                                "")}
                          </TableCell>
                          <TableCell align="center">
                            {row?.firstOfferPrice
                              ? `${new Intl.NumberFormat("en-US", {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                }).format(row.firstOfferPrice)}`
                              : ""}{" "}
                            {row?.firstOfferPriceCurrency}
                          </TableCell>
                          <TableCell align="center">
                          {row?.usdFirstOfferPrice
                              ? `${new Intl.NumberFormat("en-US", {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                }).format(row.usdFirstOfferPrice)} USD`
                              : ""}{" "}
                          </TableCell>
                          <TableCell align="center">
                            {row?.finalOfferPrice
                              ? `${new Intl.NumberFormat("en-US", {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                }).format(row.finalOfferPrice)}`
                              : ""}{" "}
                            {row?.finalOfferPriceCurrency}
                          </TableCell>
                          <TableCell align="center">
                          {row?.usdFinalOfferPrice
                              ? `${new Intl.NumberFormat("en-US", {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                }).format(row.usdFinalOfferPrice)} USD`
                              : ""}{" "}
                          </TableCell>
                          <TableCell align="center">
                          <SampleButton
                              row={row}
                              id={row?.firstSample?.id}
                              setEditSample={setEditSample}
                            />
                          </TableCell>
                          <TableCell align="center">
                            {row?.firstSample?.deliveryDate
                              ? format(
                                  new Date(row?.firstSample?.deliveryDate),
                                  "dd/MM/yyyy",
                                )
                              : ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.firstSampleStatus}
                          </TableCell>
                          <TableCell align="center">
                          <SampleButton
                              row={row}
                              id={row?.secondSample?.id}
                              setEditSample={setEditSample}
                            />
                          </TableCell>
                          <TableCell align="center">
                            {row?.secondSample?.deliveryDate
                              ? format(
                                  new Date(row?.secondSample?.deliveryDate),
                                  "dd/MM/yyyy",
                                )
                              : ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.secondSampleStatus}
                          </TableCell>
                          <TableCell align="center">{row?.packaging}</TableCell>
                          <TableCell
                            align="center"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {row?.paymentTerm}
                          </TableCell>
                          <TableCell align="center">{row?.incoterm}</TableCell>
                          <TableCell align="center">
                            {row?.numberOfCases}
                          </TableCell>
                          <TableCell align="center">
                            {row?.netWeightPerCase}
                          </TableCell>
                          <TableCell align="center">
                            {row?.volume
                              ? `${new Intl.NumberFormat("de-DE", {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                }).format(row.volume)}`
                              : ""}{" "}
                            {row?.volumeUnit}
                          </TableCell>
                          <TableCell align="center">
                            {row?.totalAmount
                              ? `${new Intl.NumberFormat("en-US", {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                }).format(row.totalAmount)}`
                              : ""}{" "}
                            {row?.finalOfferPriceCurrency}
                          </TableCell>
                          <TableCell align="center">
                            {row?.offerDate
                              ? format(new Date(row?.offerDate), "dd/MM/yyyy")
                              : ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.validityDate
                              ? format(
                                  new Date(row?.validityDate),
                                  "dd/MM/yyyy",
                                )
                              : ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.portOfDischarge}
                          </TableCell>
                          <TableCell align="center">
                            {row?.portOfLoading}
                          </TableCell>
                          <TableCell align="center">{row?.remark}</TableCell>
                          <TableCell align="center">{row?.comment}</TableCell>
                          <TableCell align="center">
                            <a
                              href={row?.offerLink}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {row?.offerLink && (
                                <Button
                                  disableElevation
                                  variant="outlined"
                                  size="small"
                                >
                                  Offer Link
                                </Button>
                              )}
                            </a>
                          </TableCell>
                          <TableCell align="center">
                            {row?.documentStatus}
                          </TableCell>
                          <TableCell align="center">{row?.timestamp ? format(new Date(row.timestamp), 'dd/MM/yyyy HH:mm'): ''}</TableCell>
                          <TableCell align="center">{row?.creator}</TableCell>
                          <TableCell align="center">
                            {row?.recordStatus}
                          </TableCell>
                          {
                            <TableCell align="center">
                              {row?.referenceVendorOfferId}
                            </TableCell>
                          }

                          {/* <TableCell padding="normal">
                            <Button
                              style={{
                                whiteSpace: "nowrap",
                              }}
                              variant="outlined"
                              onClick={() =>
                                setShowOfferPopup({
                                  data: row,
                                  visible: true,
                                })
                              }
                            >
                              Create Vendor Offer
                            </Button>

                            </TableCell> */}
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <div style={{ marginTop: '20px', marginLeft: '20px' }}>
  <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'center' }}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span
        style={{
          background: "#c8e6c9",
          padding: "4px 8px",
          marginRight: "5px",
          borderRadius: '8px', // Rounded corners
          display: 'inline-block',
        }}
      >
        Confirmed
      </span>
      <span>- Confirmed status</span>
    </div>

    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span
        style={{
          background: "#f22e2e",
          padding: "4px 8px",
          marginRight: "5px",
          borderRadius: '8px', // Rounded corners
          display: 'inline-block',
        }}
      >
        Due Today
      </span>
      <span>- Status deadline today</span>
    </div>

    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span
        style={{
          background: "#f76f6f",
          padding: "4px 8px",
          marginRight: "5px",
          borderRadius: '8px', // Rounded corners
          display: 'inline-block',
        }}
      >
        Due Tomorrow
      </span>
      <span>- Status deadline tomorrow</span>
    </div>

    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span
        style={{
          background: "#f5d3d3",
          padding: "4px 8px",
          marginRight: "5px",
          borderRadius: '8px', // Rounded corners
          display: 'inline-block',
        }}
      >
        Due Day After Tomorrow
      </span>
      <span>- Status deadline day after tomorrow</span>
    </div>

    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span
        style={{
          textDecoration: "line-through",
          padding: "4px 8px",
          marginRight: "5px",
          borderRadius: '8px', // Rounded corners
          display: 'inline-block',
        }}
      >
        Expired
      </span>
      <span>- Expired status deadline or validity date</span>
    </div>
  </div>
</div>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label="Dense padding"
            />
          </Box>
        </>
      )}
    </div>
  );
}
