import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  MenuItem,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import toast from "react-hot-toast";
import { updateShippingInstructionApi } from "../../../../components/api/ShippingInstructionApiService";
import { currencies } from "../../../../utils/menu-items";
import { format, parseISO } from "date-fns";

function EditShippingInstructionCostDialog({
  open,
  onClose,
  rowData,
  onUpdated,
}) {
  const [invoiceData, setInvoiceData] = useState(rowData || {});

  useEffect(() => {
    setInvoiceData(rowData || {});
  }, [rowData]);

  const handleTextFieldChange = (event) => {
    const { name, value } = event.target;
    setInvoiceData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCostTextFieldChange = (event) => {
    const { name, value } = event.target;
    setInvoiceData((prev) => ({
      ...prev,
      cost: {
        ...prev.cost,
        [name]: value,
      },
    }));
  };

  const renderDateField = (label, name, value, onChange) => (
    <TextField
      size="small"
      label={label}
      fullWidth
      name={name}
      margin="normal"
      value={value ? format(parseISO(value), "yyyy-MM-dd") : ""}
      type="date"
      inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
      InputLabelProps={{
        shrink: true,
      }}
      onKeyDown={(e) => e.preventDefault()}
      onChange={onChange}
    />
  );

  const renderCostField = (
    label,
    totalFieldName,
    unitFieldName,
    currencyFieldName,
    dateFieldName,
    isDirect
  ) => (
    <>
      <TextField
        label={`Total ${label}`}
        name={totalFieldName}
        type="number"
        fullWidth
        margin="normal"
        value={
          isDirect
            ? invoiceData?.[totalFieldName] ||
              Math.round(
                (invoiceData?.[unitFieldName] || 0) *
                  (invoiceData?.shipmentRequest?.shipmentQuantity || 0)
              ) ||
              ""
            : invoiceData?.cost?.[totalFieldName] ||
              Math.round(
                (invoiceData?.cost?.[unitFieldName] || 0) *
                  (invoiceData?.shipmentRequest?.shipmentQuantity || 0)
              ) ||
              ""
        }
        onWheel={(e) => e.target.blur()}
        onChange={(event) => {
          const totalValue = parseFloat(event.target.value) || 0;
          setInvoiceData((prev) => ({
            ...prev,
            ...(isDirect
              ? {
                  [totalFieldName]: totalValue,
                  [unitFieldName]:
                    prev?.shipmentRequest?.shipmentQuantity > 0
                      ? totalValue / prev.shipmentRequest.shipmentQuantity
                      : "",
                }
              : {
                  cost: {
                    ...prev.cost,
                    [totalFieldName]: totalValue,
                    [unitFieldName]:
                      prev?.shipmentRequest?.shipmentQuantity > 0
                        ? totalValue / prev.shipmentRequest.shipmentQuantity
                        : "",
                  },
                }),
          }));
        }}
      />
      <TextField
        label={`Unit ${label}`}
        name={unitFieldName}
        type="number"
        fullWidth
        margin="normal"
        value={
          isDirect
            ? invoiceData?.[unitFieldName] || ""
            : invoiceData?.cost?.[unitFieldName] || ""
        }
        InputProps={{
          readOnly: true,
        }}
      />
      <TextField
        label={`${label} Currency`}
        name={currencyFieldName}
        select
        fullWidth
        margin="normal"
        value={
          isDirect
            ? invoiceData?.[currencyFieldName] || ""
            : invoiceData?.cost?.[currencyFieldName] || ""
        }
        onChange={
          isDirect ? handleTextFieldChange : handleCostTextFieldChange
        }
      >
        {currencies.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      {renderDateField(
        `${label} Date`,
        dateFieldName,
        isDirect
          ? invoiceData?.[dateFieldName]
          : invoiceData?.cost?.[dateFieldName],
        isDirect ? handleTextFieldChange : handleCostTextFieldChange
      )}
    </>
  );

  const handleSave = () => {
    updateShippingInstructionApi(rowData.id, invoiceData)
      .then(() => {
        toast.success("Costs updated successfully.");
        window.location.reload();
        onUpdated();
        onClose();
      })
      .catch((error) => {
        console.error(error.response?.data);
        toast.error("Failed to update costs.");
      });
  };

  const isFieldFilled = (fieldName, isDirect) =>
    isDirect
      ? !!invoiceData?.[fieldName]
      : !!invoiceData?.cost?.[fieldName];

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Edit Shipping Costs {invoiceData?.shipmentRequest?.purchaseOrder?.incoterm ? "PO: "+invoiceData?.shipmentRequest?.purchaseOrder?.incoterm:""}{invoiceData?.shipmentRequest?.salesOrder?.incoterm ? " -SO: " + invoiceData?.shipmentRequest?.salesOrder?.incoterm:""}</DialogTitle>
      <DialogContent>
      <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
            sx={{
                color: isFieldFilled("actualInsuranceCost", true)
                  ? "#388e3c" 
                  : "inherit",
                fontWeight: isFieldFilled("actualInsuranceCost", true)
                  ? "bold"
                  : "normal",
              }}>Insurance Cost</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {renderCostField(
              "Insurance Cost",
              "actualTotalInsuranceCost",
              "actualInsuranceCost",
              "actualInsuranceCurrency",
              "actualTimeOfInsurance",
              true
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography   sx={{
        color: isFieldFilled("actualFreightPrice", true)
          ? "#388e3c" 
          : "inherit",
        fontWeight: isFieldFilled("actualFreightPrice", true)
          ? "bold"
          : "normal",
      }}>Freight Cost</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {renderCostField(
              "Freight Cost",
              "actualTotalFreightPrice",
              "actualFreightPrice",
              "actualFreightCurrency",
              "actualTimeOfFreight",
              true
            )}
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
            sx={{
                color: isFieldFilled("secondFreightCost", false)
                  ? "#388e3c" 
                  : "inherit",
                fontWeight: isFieldFilled("secondFreightCost", false)
                  ? "bold"
                  : "normal",
              }}>Second Freight Cost</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {renderCostField(
              "Second Freight Cost",
              "totalSecondFreightCost",
              "secondFreightCost",
              "secondFreightCostCurrency",
              "secondFreightDate",
              false
            )}
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
             sx={{
                color: isFieldFilled("importExportExpense", false)
                  ? "#388e3c" 
                  : "inherit",
                fontWeight: isFieldFilled("importExportExpense", false)
                  ? "bold"
                  : "normal",
              }}>Import/Export Expenses</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {renderCostField(
              "Import/Export Expenses",
              "totalImportExportExpense",
              "importExportExpense",
              "importExportExpenseCurrency",
              "importExportExpenseDate",
              false
            )}
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
             sx={{
                color: isFieldFilled("demurrage", false)
                  ? "#388e3c" 
                  : "inherit",
                fontWeight: isFieldFilled("demurrage", false)
                  ? "bold"
                  : "normal",
              }}>Demurrage</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {renderCostField(
              "Demurrage",
              "totalDemurrage",
              "demurrage",
              "demurrageCurrency",
              "demurrageDate",
              false
            )}
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
             sx={{
                color: isFieldFilled("fumigation", false)
                  ? "#388e3c" 
                  : "inherit",
                fontWeight: isFieldFilled("fumagation", false)
                  ? "bold"
                  : "normal",
              }}>Fumigation</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {renderCostField(
              "Fumigation",
              "totalFumigation",
              "fumigation",
              "fumigationCurrency",
              "fumigationDate",
              false
            )}
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
             sx={{
                color: isFieldFilled("handling", false)
                  ? "#388e3c" 
                  : "inherit",
                fontWeight: isFieldFilled("handling", false)
                  ? "bold"
                  : "normal",
              }}>Handling</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {renderCostField(
              "Handling",
              "totalHandling",
              "handling",
              "handlingCurrency",
              "handlingDate",
              false
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
             sx={{
                color: isFieldFilled("debitNote", false)
                  ? "#388e3c" 
                  : "inherit",
                fontWeight: isFieldFilled("debitNote", false)
                  ? "bold"
                  : "normal",
              }}>Debit Note</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {renderCostField(
              "Debit Note",
              "totalDebitNote",
              "debitNote",
              "debitNoteCurrency",
              "debitNoteDate",
              false
            )}
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
             sx={{
                color: isFieldFilled("creditNote", false)
                  ? "#388e3c" 
                  : "inherit",
                fontWeight: isFieldFilled("creditNote", false)
                  ? "bold"
                  : "normal",
              }}>Credit Note</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {renderCostField(
              "Credit Note",
              "totalCreditNote",
              "creditNote",
              "creditNoteCurrency",
              "creditNoteDate",
              false
            )}
          </AccordionDetails>
        </Accordion>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditShippingInstructionCostDialog;
