import React, { useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { retrieveCostByIdApi } from "../../../../components/api/CostApiService";

const CostButton = ({ row, setEditCost }) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    if (loading) return; 
    setLoading(true);
    try {
      const estimatedCostData = await retrieveCostByIdApi(row?.estimatedCost?.id);
      if (!estimatedCostData) {
        alert("No data returned from the API.");
        return;
      }
      setEditCost({
        visible: true,
        data: estimatedCostData?.data,
        index: row?.index,
      });
      console.log(estimatedCostData);
    } catch (error) {
      console.error("Error retrieving estimatedCost:", error);
    } finally {
      setLoading(false); 
    }
  };

  return (
    <Button
      sx={{
        minWidth: 0,
        padding: "2px 4px",
        fontSize: "0.8rem",
        lineHeight: 1,
      }}
      onClick={handleClick}
      variant="text"
      disabled={loading}
      startIcon={loading && <CircularProgress size={16} />}
    >
      {row?.estimatedCost?.id}
    </Button>
  );
};

export default CostButton;
