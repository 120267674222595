import { Button, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createOfferApi } from "../../../../components/api/OfferApiService";
import {
  status,
  offerStatus,
  sampleStatus,
  paymentTerm,
} from "../../../../utils/menu-items";

const CreateVendorOffer = (props) => {
  const navigate = useNavigate();
  const { setCreateVendorOffer, createVendorOffer } = props;
  const [orderData, setOrderData] = useState({});

  useEffect(() => {
    setCreateVendorOffer({
      ...createVendorOffer,
      data: {
        ...createVendorOffer.data,
        offerType: "VENDOR",
        itemId: createVendorOffer?.data?.item?.id,
        estimatedCostId: createVendorOffer?.data?.estimatedCost?.id,
        firstSampleId: createVendorOffer?.data?.firstSample?.id,
        secondSampleId: createVendorOffer?.data?.secondSample?.id,
        referenceCustomerOfferId: createVendorOffer?.data?.id,
      },
    });
  }, []);
  // useEffect(() => {retrieveItem()}, [createVendorOffer?.data?.itemId]);

  return (
    <Grid container spacing="12px">
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Offer Number</Typography>
        <TextField
          variant="outlined"
          size="small"
          value={createVendorOffer?.data?.offerGroupNumber}
          fullWidth
          onChange={(e) =>
            setCreateVendorOffer({
              ...createVendorOffer,
              data: {
                ...createVendorOffer.data,
                offerGroupNumber: e.target.value,
              },
            })
          }
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Item ID</Typography>
        <TextField
          variant="outlined"
          size="small"
          defaultValue={createVendorOffer?.data?.item?.id || ""}
          fullWidth
          onBlur={(e) =>
            setCreateVendorOffer({
              ...createVendorOffer,
              data: {
                ...createVendorOffer.data,
                itemId: e.target.value,
                item: {
                  ...(createVendorOffer.data.item || {}),
                  id:
                    e.target.value !== "" ? parseInt(e.target.value, 10) : null,
                },
              },
            })
          }
        />
        <Button>Change</Button>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Tobacco Code</Typography>
        <TextField
          disabled
          variant="outlined"
          size="small"
          value={createVendorOffer?.data?.item?.tobaccoCode || ""}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Item Sales Grade</Typography>
        <TextField
          disabled
          variant="outlined"
          size="small"
          value={createVendorOffer?.data?.item?.salesGrade || ""}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Nicotine</Typography>
        <TextField
          disabled
          variant="outlined"
          size="small"
          value={createVendorOffer?.data?.item?.nicotine || ""}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Sugar</Typography>
        <TextField
          disabled
          variant="outlined"
          size="small"
          value={createVendorOffer?.data?.item?.sugar || ""}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Item Origin</Typography>
        <TextField
          disabled
          variant="outlined"
          size="small"
          value={createVendorOffer?.data?.item?.origin || ""}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Item Type</Typography>
        <TextField
          disabled
          variant="outlined"
          size="small"
          value={createVendorOffer?.data?.item?.type || ""}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Company</Typography>
        <TextField
          variant="outlined"
          size="small"
          value={createVendorOffer?.data?.company}
          fullWidth
          onChange={(e) =>
            setCreateVendorOffer({
              ...createVendorOffer,
              data: {
                ...createVendorOffer.data,
                company: e.target.value,
              },
            })
          }
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">SG Approval Status</Typography>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          id="outlined-select-approval-status"
          value={createVendorOffer?.data?.approvalStatus}
          select
          onChange={(e) =>
            setCreateVendorOffer({
              ...createVendorOffer,
              data: {
                ...createVendorOffer.data,
                approvalStatus: e.target.value,
              },
            })
          }
        >
          {offerStatus.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
          {createVendorOffer?.data?.approvalStatus &&
            !status.some(
              (option) =>
                option.value === createVendorOffer?.data?.approvalStatus,
            ) && (
              <MenuItem value={createVendorOffer?.data?.approvalStatus}>
                {createVendorOffer?.data?.approvalStatus}
              </MenuItem>
            )}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Status</Typography>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          id="outlined-select-status"
          value={createVendorOffer?.data?.status}
          select
          onChange={(e) =>
            setCreateVendorOffer({
              ...createVendorOffer,
              data: {
                ...createVendorOffer.data,
                status: e.target.value,
              },
            })
          }
        >
          {status.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
          {createVendorOffer?.data?.status &&
            !status.some(
              (option) => option.value === createVendorOffer?.data?.status,
            ) && (
              <MenuItem value={createVendorOffer?.data?.status}>
                {createVendorOffer?.data?.status}
              </MenuItem>
            )}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Status Deadline</Typography>
        <TextField
          id="date"
          type="date"
          inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          size="small"
          fullWidth
          value={
            createVendorOffer?.data?.statusDeadline
              ? format(
                  parseISO(createVendorOffer.data.statusDeadline),
                  "yyyy-MM-dd",
                )
              : ""
          }
          onChange={(e) =>
            setCreateVendorOffer({
              ...createVendorOffer,
              data: {
                ...createVendorOffer.data,
                statusDeadline: e.target.value
                  ? e.target.value + "T00:00:00.000+00:00"
                  : null,
              },
            })
          }
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Customer</Typography>
        <TextField
          variant="outlined"
          size="small"
          value={createVendorOffer?.data?.stakeholder?.id}
          fullWidth
          onChange={(e) =>
            setCreateVendorOffer({
              ...createVendorOffer,
              data: {
                ...createVendorOffer.data,
                stakeholderId: e.target.value,
              },
            })
          }
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Customer Name</Typography>
        <TextField
          disabled
          variant="outlined"
          size="small"
          value={createVendorOffer?.data?.stakeholder?.name}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Customer SAP Code</Typography>
        <TextField
          disabled
          variant="outlined"
          size="small"
          value={createVendorOffer?.data?.stakeholder?.code}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Estimated Cost ID</Typography>
        <TextField
          variant="outlined"
          size="small"
          value={createVendorOffer?.data?.estimatedCost?.id}
          fullWidth
          onChange={(e) =>
            setCreateVendorOffer({
              ...createVendorOffer,
              data: {
                ...createVendorOffer.data,
                estimatedCostId: e.target.value,
              },
            })
          }
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Estimated Total Cost</Typography>
        <TextField
          disabled
          variant="outlined"
          size="small"
          value={createVendorOffer?.data?.estimatedCost?.totalCost}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Buying Price Per Kg</Typography>
        <TextField
          variant="outlined"
          size="small"
          value={createVendorOffer?.data?.buyingPrice}
          fullWidth
          onChange={(e) =>
            setCreateVendorOffer({
              ...createVendorOffer,
              data: {
                ...createVendorOffer.data,
                buyingPrice: e.target.value,
              },
            })
          }
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Buying Price Per Kg Currency</Typography>
        <TextField
          variant="outlined"
          size="small"
          value={createVendorOffer?.data?.buyingPriceCurrency}
          fullWidth
          onChange={(e) =>
            setCreateVendorOffer({
              ...createVendorOffer,
              data: {
                ...createVendorOffer.data,
                buyingPriceCurrency: e.target.value,
              },
            })
          }
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">First Offer</Typography>
        <TextField
          variant="outlined"
          size="small"
          type="number"
          value={createVendorOffer?.data?.firstOfferPrice}
          fullWidth
          onWheel={(e) => e.target.blur()}
          onChange={(e) =>
            setCreateVendorOffer({
              ...createVendorOffer,
              data: {
                ...createVendorOffer.data,
                firstOfferPrice: e.target.value,
              },
            })
          }
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">First Offer Price Currency</Typography>
        <TextField
          variant="outlined"
          size="small"
          value={createVendorOffer?.data?.firstOfferPriceCurrency}
          fullWidth
          onChange={(e) =>
            setCreateVendorOffer({
              ...createVendorOffer,
              data: {
                ...createVendorOffer.data,
                firstOfferPriceCurrency: e.target.value,
              },
            })
          }
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Final Offer</Typography>
        <TextField
          variant="outlined"
          size="small"
          type="number"
          value={createVendorOffer?.data?.finalOfferPrice}
          fullWidth
          onWheel={(e) => e.target.blur()}
          onChange={(e) =>
            setCreateVendorOffer({
              ...createVendorOffer,
              data: {
                ...createVendorOffer.data,
                finalOfferPrice: e.target.value,
              },
            })
          }
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Final Offer Currency</Typography>
        <TextField
          variant="outlined"
          size="small"
          value={createVendorOffer?.data?.finalOfferPriceCurrency}
          fullWidth
          onChange={(e) =>
            setCreateVendorOffer({
              ...createVendorOffer,
              data: {
                ...createVendorOffer.data,
                finalOfferPriceCurrency: e.target.value,
              },
            })
          }
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">First Sample</Typography>
        <TextField
          variant="outlined"
          size="small"
          value={createVendorOffer?.data?.firstSample?.id}
          fullWidth
          onChange={(e) =>
            setCreateVendorOffer({
              ...createVendorOffer,
              data: {
                ...createVendorOffer.data,
                firstSampleId: e.target.value,
              },
            })
          }
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">First Sample Date</Typography>
        <TextField
          disabled
          variant="outlined"
          size="small"
          value={
            createVendorOffer?.data?.firstSample?.deliveryDate
              ? format(
                  parseISO(createVendorOffer.data.firstSample.deliveryDate),
                  "yyyy-MM-dd",
                )
              : ""
          }
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">First Sample Status</Typography>
        <TextField
          variant="outlined"
          size="small"
          value={createVendorOffer?.data?.firstSampleStatus}
          fullWidth
          select
          onChange={(e) =>
            setCreateVendorOffer({
              ...createVendorOffer,
              data: {
                ...createVendorOffer.data,
                firstSampleStatus: e.target.value,
              },
            })
          }
        >
          {sampleStatus.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Second Sample</Typography>
        <TextField
          variant="outlined"
          size="small"
          value={createVendorOffer?.data?.secondSample?.id}
          fullWidth
          onChange={(e) =>
            setCreateVendorOffer({
              ...createVendorOffer,
              data: {
                ...createVendorOffer.data,
                secondSampleId: e.target.value,
              },
            })
          }
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Second Sample Date</Typography>
        <TextField
          disabled
          variant="outlined"
          size="small"
          value={
            createVendorOffer?.data?.secondSample?.deliveryDate
              ? format(
                  parseISO(createVendorOffer.data.secondSample?.deliveryDate),
                  "yyyy-MM-dd",
                )
              : ""
          }
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Second Sample Status</Typography>
        <TextField
          variant="outlined"
          size="small"
          value={createVendorOffer?.data?.secondSampleStatus}
          fullWidth
          select
          onChange={(e) =>
            setCreateVendorOffer({
              ...createVendorOffer,
              data: {
                ...createVendorOffer.data,
                secondSampleStatus: e.target.value,
              },
            })
          }
        >
          {sampleStatus.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Packaging</Typography>
        <TextField
          variant="outlined"
          size="small"
          value={createVendorOffer?.data?.packaging}
          fullWidth
          onChange={(e) =>
            setCreateVendorOffer({
              ...createVendorOffer,
              data: {
                ...createVendorOffer.data,
                packaging: e.target.value,
              },
            })
          }
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Payment Term</Typography>
        <TextField
          variant="outlined"
          size="small"
          value={createVendorOffer?.data?.paymentTerm}
          fullWidth
          select
          onChange={(e) =>
            setCreateVendorOffer({
              ...createVendorOffer,
              data: {
                ...createVendorOffer.data,
                paymentTerm: e.target.value,
              },
            })
          }
        >
          {paymentTerm.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Incoterm</Typography>
        <TextField
          variant="outlined"
          size="small"
          value={createVendorOffer?.data?.incoterm}
          fullWidth
          onChange={(e) =>
            setCreateVendorOffer({
              ...createVendorOffer,
              data: {
                ...createVendorOffer.data,
                incoterm: e.target.value,
              },
            })
          }
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Number Of Cartons</Typography>
        <TextField
          variant="outlined"
          size="small"
          value={createVendorOffer?.data?.numberOfCases}
          fullWidth
          onChange={(e) =>
            setCreateVendorOffer({
              ...createVendorOffer,
              data: {
                ...createVendorOffer.data,
                numberOfCases: e.target.value,
              },
            })
          }
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Net Weight Per Carton</Typography>
        <TextField
          variant="outlined"
          size="small"
          value={createVendorOffer?.data?.netWeightPerCase}
          fullWidth
          onChange={(e) =>
            setCreateVendorOffer({
              ...createVendorOffer,
              data: {
                ...createVendorOffer.data,
                netWeightPerCase: e.target.value,
              },
            })
          }
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Volume</Typography>
        <TextField
          variant="outlined"
          size="small"
          value={createVendorOffer?.data?.volume}
          fullWidth
          onChange={(e) =>
            setCreateVendorOffer({
              ...createVendorOffer,
              data: {
                ...createVendorOffer.data,
                volume: e.target.value,
              },
            })
          }
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Volume Unit</Typography>
        <TextField
          variant="outlined"
          size="small"
          value={createVendorOffer?.data?.volumeUnit}
          fullWidth
          onChange={(e) =>
            setCreateVendorOffer({
              ...createVendorOffer,
              data: {
                ...createVendorOffer.data,
                volumeUnit: e.target.value,
              },
            })
          }
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Total Amount</Typography>
        <TextField
          variant="outlined"
          size="small"
          value={createVendorOffer?.data?.totalAmount}
          fullWidth
          onChange={(e) =>
            setCreateVendorOffer({
              ...createVendorOffer,
              data: {
                ...createVendorOffer.data,
                totalAmount: e.target.value,
              },
            })
          }
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Total Amount Currency</Typography>
        <TextField
          variant="outlined"
          size="small"
          value={createVendorOffer?.data?.totalAmountCurrency}
          fullWidth
          onChange={(e) =>
            setCreateVendorOffer({
              ...createVendorOffer,
              data: {
                ...createVendorOffer.data,
                totalAmountCurrency: e.target.value,
              },
            })
          }
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Offer Date</Typography>
        <TextField
          id="date"
          type="date"
          inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
          InputLabelProps={{
            shrink: true,
          }}
          onKeyDown={(e) => e.preventDefault()}
          variant="outlined"
          size="small"
          fullWidth
          value={
            createVendorOffer?.data?.offerDate
              ? format(parseISO(createVendorOffer.data.offerDate), "yyyy-MM-dd")
              : ""
          }
          onChange={(e) =>
            setCreateVendorOffer({
              ...createVendorOffer,
              data: {
                ...createVendorOffer.data,
                offerDate: e.target.value,
              },
            })
          }
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Offer Validity Date</Typography>
        <TextField
          id="date"
          type="date"
          inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
          InputLabelProps={{
            shrink: true,
          }}
          onKeyDown={(e) => e.preventDefault()}
          variant="outlined"
          size="small"
          fullWidth
          value={
            createVendorOffer?.data?.validityDate
              ? format(
                  parseISO(createVendorOffer.data.validityDate),
                  "yyyy-MM-dd",
                )
              : ""
          }
          onChange={(e) =>
            setCreateVendorOffer({
              ...createVendorOffer,
              data: {
                ...createVendorOffer.data,
                validityDate: e.target.value,
              },
            })
          }
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Offer Link</Typography>
        <TextField
          variant="outlined"
          size="small"
          value={createVendorOffer?.data?.offerLink}
          fullWidth
          onChange={(e) =>
            setCreateVendorOffer({
              ...createVendorOffer,
              data: {
                ...createVendorOffer.data,
                offerLink: e.target.value,
              },
            })
          }
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Document Status</Typography>
        <TextField
          variant="outlined"
          size="small"
          value={createVendorOffer?.data?.documentStatus}
          fullWidth
          onChange={(e) =>
            setCreateVendorOffer({
              ...createVendorOffer,
              data: {
                ...createVendorOffer.data,
                documentStatus: e.target.value,
              },
            })
          }
        />
      </Grid>

      <Button
        variant="outlined"
        size="small"
        sx={{ ml: 1.5, mt: 4, mr: 1 }}
        onClick={() => setCreateVendorOffer({ visible: false, data: null })}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        size="small"
        sx={{ mt: 4 }}
        onClick={() => {
          setCreateVendorOffer({
            ...orderData,
            data: orderData,
            visible: false,
          });
          createOfferApi(createVendorOffer.data)
            .then((response) => {
              window.location.reload();
              navigate("/customer-offer-view");
            })
            .catch((error) => {
              console.log(error.response.data);
            });
        }}
      >
        Create
      </Button>
    </Grid>
  );
};

export default CreateVendorOffer;
