import DeleteIcon from "@mui/icons-material/Delete";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  Stack,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import { alpha } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { visuallyHidden } from "@mui/utils";
import { format, parseISO } from "date-fns";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  retrieveAllFreightOffersApi,
  retrieveFreightOfferByIdApi,
} from "../../../components/api/FreightOfferApiService";
import {
  retrieveAllShipmentRequestsApi,
  retrieveShipmentRequestByIdApi,
} from "../../../components/api/ShipmentRequestApiService";
import {
  deleteShippingInstructionsApi,
  retrieveAllShippingInstructionsApi,
  retrieveFilteredShippingInstructionsApi,
  retrieveSelectedShippingInstructionsByIdApi,
  updateShippingInstructionApi,
} from "../../../components/api/ShippingInstructionApiService";

import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import ExportExcel from "../../../utils/excelExport";
import {
  currencies,
  portsOfDischarge,
  portsOfLoading,
  recordStatus,
  shippedBy,
  shippingInstructionStatus,
} from "../../../utils/menu-items";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ShippingInstructionHistoryButton from "./Dialogs/ShippingInstructionHistoryButton";
import EditShippingInstructionCostDialog from "./Dialogs/EditShippingInstructionCostDialog.js";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "editButton",
    disablePadding: true,
    label: "",
  },
  {
    id: "historyButton",
    disablePadding: true,
    label: "",
  },
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "shipmentRequest",
    disablePadding: true,
    label: "SR",
  },
  {
    id: "shipmentRequestType",
    disablePadding: true,
    label: "Type",
  },
  {
    id: "shipmentQuantity",
    disablePadding: true,
    label: "Shipment Quantity",
  },
  {
    id: "storageQuantity",
    disablePadding: true,
    label: "Storage Quantity",
  },
  {
    id: "siNumber",
    disablePadding: true,
    label: "SI Number",
  },
  {
    id: "siDate",
    disablePadding: true,
    label: "SI Date",
  },
  {
    id: "ata",
    disablePadding: true,
    label: "ATA",
  },
  {
    id: "purchaseOrder",
    disablePadding: true,
    label: "PO",
  },
  {
    id: "poCompany",
    disablePadding: true,
    label: "PO Company",
  },
  {
    id: "poNumber",
    disablePadding: true,
    label: "SAP PO Number",
  },
  {
    id: "salesOrder",
    disablePadding: true,
    label: "SO",
  },
  {
    id: "soCompany",
    disablePadding: true,
    label: "SO Company",
  },
  {
    id: "soNumber",
    disablePadding: true,
    label: "SAP SO Number",
  },
  {
    id: "unitSellingPrice",
    disablePadding: true,
    label: "Unit Selling Price",
  },
  {
    id: "itemID",
    disablePadding: true,
    label: "Item ID",
  },
  {
    id: "supplierGradeMark",
    disablePadding: true,
    label: "Supplier Grade Mark",
  },
  {
    id: "customerGradeMark",
    disablePadding: true,
    label: "Customer Grade Mark",
  },
  {
    id: "crop",
    numeric: true,
    disablePadding: true,
    label: "Crop",
  },
  {
    id: "warehouse",
    disablePadding: true,
    label: "Warehouse",
  },
  {
    id: "destination",
    disablePadding: true,
    label: "Destination",
  },
  {
    id: "pointOfLoading",
    disablePadding: true,
    label: "Point Of Loading",
  },
  {
    id: "pointOfUnloading",
    disablePadding: true,
    label: "Point Of Unloading",
  },
  {
    id: "freightOffer",
    disablePadding: true,
    label: "Freight Offer",
  },
  {
    id: "unitBuyingPrice",
    disablePadding: true,
    label: "Unit Buying Price",
  },
  {
    id: "unitBuyingPriceUsd",
    disablePadding: true,
    label: "Unit Buying Price USD",
  },
  {
    id: "actualFreightPrice",
    disablePadding: true,
    label: "Unit Freight Cost",
  },
  {
    id: "actualFreightPriceUsd",
    disablePadding: true,
    label: "Unit Freight Cost (USD)",
  },
  {
    id: "actualInsuranceCost",
    disablePadding: true,
    label: "Unit Insurance Cost",
  },
  {
    id: "actualInsuranceCostUsd",
    disablePadding: true,
    label: "Unit Insurance Cost (USD)",
  },
  {
    id: "baseUnitStorageCost",
    disablePadding: true,
    label: "Base Unit Storage Cost",
  },
  {
    id: "baseUnitStorageCostUsd",
    disablePadding: true,
    label: "Base Unit Storage Cost USD",
  },
  {
    id: "actualUnitStorageCost",
    disablePadding: true,
    label: "Unit Storage Cost",
  },
  {
    id: "actualUnitStorageCostUsd",
    disablePadding: true,
    label: "Unit Storage Cost USD",
  },
  {
    id: "actualStorageCostPerKg",
    disablePadding: true,
    label: "Storage Cost Per Kg",
  },
  {
    id: "actualStorageCostPerKgUsd",
    disablePadding: true,
    label: "Storage Cost Per Kg USD",
  },
  {
    id: "actualTotalCost",
    disablePadding: true,
    label: "Total Cost",
  },
  {
    id: "numberOfCartons",
    numeric: true,
    disablePadding: true,
    label: "Number Of Cartons",
  },
  {
    id: "netWeight",
    numeric: true,
    disablePadding: true,
    label: "Net Weight",
  },
  {
    id: "remark",
    numeric: false,
    disablePadding: false,
    label: "Remark",
  },
  {
    id: "referenceSi",
    numeric: false,
    disablePadding: true,
    label: "Reference SI",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "creationTime",
    numeric: false,
    disablePadding: true,
    label: "Creation Time",
  },
  {
    id: "createdBy",
    numeric: false,
    disablePadding: true,
    label: "Created By",
  },
  {
    id: "recordStatus",
    numeric: false,
    disablePadding: false,
    label: "Record Status",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={
              headCell.id === "id"
                ? {
                    position: "sticky",
                    left: 0,
                    zIndex: 3,
                  }
                : headCell.id === "siNumber"
                ? {
                    position: "sticky",
                    left: 50,
                    zIndex: 3,
                  }
                : null
            }
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const {
    numSelected,
    showFilters,
    setShowFilters,
    rows,
    selected,
    selectedData,
  } = props;
  const [deletePopup, setDeletePopup] = useState(false);
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity,
            ),
        }),
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Dialog
        open={deletePopup}
        onClose={() => setDeletePopup(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete {selected.length} order(s)?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              deleteShippingInstructionsApi(selected)
                .then((response) => {
                  console.log(response?.data);
                  toast.success("SI record(s) deleted successfully.");
                })
                .catch((error) => {
                  console.log(error.response?.data);
                  toast.error("SI record(s) could not be deleted.");
                });

              window.location.reload();
            }}
            autoFocus
          >
            Yes
          </Button>
          <Button onClick={() => setDeletePopup(false)}>No</Button>
        </DialogActions>
      </Dialog>

      <Stack direction="row" spacing={1} alignItems={"center"}>
        <Typography
          sx={{ fontWeight: 600, fontSize: "20px" }}
          variant="h6"
          id="tableTitle"
          component="div"
          color="#1E2685"
        >
          Shipping Instruction Costing
        </Typography>

        <Typography></Typography>
        {numSelected > 0 ? (
          <Typography
            sx={{ fontWeight: 200, fontSize: "16px" }}
            color="#1E2685"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography></Typography>
        )}

        {numSelected > 0 && (
          <Tooltip title="Delete">
            <IconButton onClick={() => setDeletePopup(true)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      <Stack spacing={1} direction="row" sx={{ mr: 2 }}>
        <ExportExcel
          excelData={selectedData}
          fileName={"ShippingInstructionExport"}
          buttonLabel="Export Table"
        />
        <Button
          variant="outlined"
          onClick={() => setShowFilters(!showFilters)}
          sx={{ minWidth: "130px", mr: 2 }}
          size="small"
        >
          {showFilters ? "Hide Filters" : "Show Filters"}
        </Button>
      </Stack>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function ShippingInstructionCostingView() {
  const [rows, setRows] = useState([]);
  useEffect(() => retrieveShippingInstructions(), []);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [showFilters, setShowFilters] = useState(false);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [invoiceData, setInvoiceData] = useState([]);
  const [closeDialogOpen, setCloseDialogOpen] = useState(false);
  const [isEditShippingCostDialogOpen, setIsEditShippingCostDialogOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleTextFieldChange = (event) => {
    const { name, value } = event.target;
    setInvoiceData({ ...invoiceData, [name]: value });
  };

  const handleTransshipmentPortChange = (e, index) => {
    const newPorts = [...invoiceData.transshipmentPorts];
    newPorts[index] = e.target.value;
    setInvoiceData({ ...invoiceData, transshipmentPorts: newPorts });
  };

  const handleRemoveTransshipmentPort = (index) => {
    const newPorts = invoiceData.transshipmentPorts.filter(
      (_, i) => i !== index,
    );
    setInvoiceData({ ...invoiceData, transshipmentPorts: newPorts });
  };

  const handleAddTransshipmentPort = () => {
    const newPorts = [...invoiceData.transshipmentPorts, ""];
    setInvoiceData({ ...invoiceData, transshipmentPorts: newPorts });
  };

  const [
    isAdditionalDepartureTimesVisible,
    setIsAdditionalDepartureTimesVisible,
  ] = useState(false);

  const handleDepartureCellClick = () => {
    setIsAdditionalDepartureTimesVisible((prevVisibility) => !prevVisibility);
  };

  const [componentsState, setComponentsState] = useState({
    shipmentRequest: {
      dropdownOpen: false,
      inputValue: "",
    },
    freightOffer: {
      dropdownOpen: false,
      inputValue: "",
    },
  });

  const updateComponentState = (componentKey, newState) => {
    setComponentsState((prevState) => ({
      ...prevState,
      [componentKey]: {
        ...prevState[componentKey],
        ...newState,
      },
    }));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [showPorts, setShowPorts] = useState(false);

  const togglePortsVisibility = () => {
    setShowPorts(!showPorts);
  };

  const [filterShippingInstruction, setFilterShippingInstruction] = useState({
    id: 0,
    shipmentRequestIdList: [],
    freightOfferIdList: [],
    purchaseOrderIdList: [],
    salesOrderIdList: [],
    transportationContact: "",
    finalShippingInstructionNumber: "",
    cartonNumber: "",
    shippedByList: [],
    billOfLadingNumber: "",
    cmrNumber: "",
    shipperIdList: [],
    consigneeIdList: [],
    notifyIdList: [],
    pointOfLoadingList: [],
    pointOfUnloadingList: [],
    description: "",
    remark: "",
    statusList: [],
    recordStatusList: ["ACTIVE"],
  });

  function retrieveShippingInstructions() {
    retrieveAllShippingInstructionsApi()
      .then((response) => {
        setRows(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  const [shipmentRequest, setShipmentRequest] = useState([]);
  const [shipmentRequests, setShipmentRequests] = useState([]);
  const [freightOffer, setFreightOffer] = useState([]);
  const [freightOffers, setFreightOffers] = useState([]);

  function retrieveShipmentRequestById(id) {
    retrieveShipmentRequestByIdApi(id)
      .then((response) => {
        setShipmentRequest(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveShipmentRequests() {
    retrieveAllShipmentRequestsApi()
      .then((response) => {
        setShipmentRequests(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveFreightOffers() {
    retrieveAllFreightOffersApi()
      .then((response) => {
        setFreightOffers(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }
  function retrieveFreightOfferById(id) {
    retrieveFreightOfferByIdApi(id)
      .then((response) => {
        setFreightOffer(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  const [editShippingInstruction, setEditShippingInstruction] = useState({
    visible: false,
    data: null,
    index: null,
  });

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      setSelectedData(rows);
      return;
    } else {
      setSelected([]);
      setSelectedData([]);
    }
  };

  const handleClick = (event, name, row) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    const selectedDataIndex = selectedData.indexOf(row);
    let newSelectedData = [];

    if (selectedDataIndex === -1) {
      newSelectedData = newSelectedData.concat(selectedData, row);
    } else if (selectedDataIndex === 0) {
      newSelectedData = newSelectedData.concat(selectedData.slice(1));
    } else if (selectedDataIndex === selectedData.length - 1) {
      newSelectedData = newSelectedData.concat(selectedData.slice(0, -1));
    } else if (selectedDataIndex > 0) {
      newSelectedData = newSelectedData.concat(
        selectedData.slice(0, selectedDataIndex),
        selectedData.slice(selectedDataIndex + 1),
      );
    }
    setSelectedData(newSelectedData);

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, rows, editShippingInstruction],
  );

  return (
    <div>
      <Dialog
        open={uploadDialogOpen}
        onClose={() => setUploadDialogOpen(false)}
        aria-labelledby="upload-dialog-title"
      >
        <DialogTitle id="upload-dialog-title">Select File</DialogTitle>
        <DialogContent>
          <Select
            value={selectedOption}
            onChange={handleOptionChange}
            fullWidth
            displayEmpty
          >
            <MenuItem value="" disabled>
              Select an option
            </MenuItem>
            <MenuItem value="accountsReceivableLink">AR Invoice</MenuItem>
            <MenuItem value="supplierInvoiceLink">Supplier Invoice</MenuItem>
            <MenuItem value="insuranceLink">Insurance</MenuItem>
            <MenuItem value="freightLink">Freight</MenuItem>
            <MenuItem value="billOfLadingLink">BL</MenuItem>
            <MenuItem value="weightListLink">Weight List</MenuItem>
            <MenuItem value="cmrLink">CMR</MenuItem>
            <MenuItem value="goodsReceiptNumber">GR</MenuItem>
            <MenuItem value="landedCostNumber">LC</MenuItem>
            <MenuItem value="deliveryNumber">Del</MenuItem>
            <MenuItem value="shippingDetails">Shipping Details</MenuItem>
          </Select>

          {selectedOption === "accountsReceivableLink" && (
            <div>
              <TextField
                label="AR Invoice Link"
                name="accountsReceivableLink"
                value={invoiceData?.accountsReceivableLink}
                onChange={handleTextFieldChange}
                fullWidth
                margin="normal"
              />
              <a
                href={invoiceData?.accountsReceivableLink}
                target="_blank"
                rel="noreferrer"
              >
                <Button disabled={!invoiceData?.accountsReceivableLink}>
                  See AR Invoice
                </Button>
              </a>
              <TextField
                label="AR Number"
                name="accountsReceivableNumber"
                value={invoiceData?.accountsReceivableNumber}
                onChange={handleTextFieldChange}
                fullWidth
                margin="normal"
              />
            </div>
          )}
          {selectedOption === "supplierInvoiceLink" && (
            <div>
              <TextField
                label="Supplier Invoice Link"
                name="supplierInvoiceLink"
                value={invoiceData?.supplierInvoiceLink}
                onChange={handleTextFieldChange}
                fullWidth
                margin="normal"
              />
              <a
                href={invoiceData?.supplierInvoiceLink}
                target="_blank"
                rel="noreferrer"
              >
                <Button disabled={!invoiceData?.supplierInvoiceLink}>
                  See Supplier Invoice
                </Button>
              </a>
            </div>
          )}
          {selectedOption === "insuranceLink" && (
            <div>
              <TextField
                label="Insurance Link"
                name="insuranceLink"
                value={invoiceData?.insuranceLink}
                onChange={handleTextFieldChange}
                fullWidth
                margin="normal"
              />
              <a
                href={invoiceData?.insuranceLink}
                target="_blank"
                rel="noreferrer"
              >
                <Button disabled={!invoiceData?.insuranceLink}>
                  See Insurance
                </Button>
              </a>
            </div>
          )}
          {selectedOption === "freightLink" && (
            <div>
              <TextField
                label="Freight Link"
                name="freightLink"
                value={invoiceData?.freightLink}
                onChange={handleTextFieldChange}
                fullWidth
                margin="normal"
              />
              <a
                href={invoiceData?.freightLink}
                target="_blank"
                rel="noreferrer"
              >
                <Button disabled={!invoiceData?.freightLink}>
                  See Freight
                </Button>
              </a>
            </div>
          )}
          {selectedOption === "billOfLadingLink" && (
            <div>
              <TextField
                label="BL Number"
                name="billOfLadingNumber"
                value={invoiceData?.billOfLadingNumber}
                onChange={handleTextFieldChange}
                fullWidth
                margin="normal"
              />
              <TextField
                size="small"
                label="BL Date"
                fullWidth
                name="billOfLadingDate"
                margin="normal"
                value={
                  invoiceData?.billOfLadingDate
                    ? format(
                        parseISO(invoiceData.billOfLadingDate),
                        "yyyy-MM-dd",
                      )
                    : ""
                }
                id="date"
                type="date"
                inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                InputLabelProps={{
                  shrink: true,
                }}
                onKeyDown={(e) => e.preventDefault()}
                onChange={(e) => {
                  handleTextFieldChange(e);
                }}
              />
              <TextField
                label="BL Link"
                name="billOfLadingLink"
                value={invoiceData?.billOfLadingLink}
                onChange={handleTextFieldChange}
                fullWidth
                margin="normal"
              />
              <a
                href={invoiceData?.billOfLadingLink}
                target="_blank"
                rel="noreferrer"
              >
                <Button disabled={!invoiceData?.billOfLadingLink}>
                  See BL
                </Button>
              </a>
            </div>
          )}
          {selectedOption === "weightListLink" && (
            <div>
              <TextField
                label="Weight List Link"
                name="weightListLink"
                value={invoiceData?.weightListLink}
                onChange={handleTextFieldChange}
                fullWidth
                margin="normal"
              />
              <a
                href={invoiceData?.weightListLink}
                target="_blank"
                rel="noreferrer"
              >
                <Button disabled={!invoiceData?.weightListLink}>
                  See Weight List
                </Button>
              </a>
            </div>
          )}
          {selectedOption === "cmrLink" && (
            <div>
              <TextField
                label="CMR Number"
                name="cmrNumber"
                value={invoiceData?.cmrNumber}
                onChange={handleTextFieldChange}
                fullWidth
                margin="normal"
              />
              <TextField
                size="small"
                label="CMR Date"
                fullWidth
                name="cmrDate"
                margin="normal"
                value={
                  invoiceData?.cmrDate
                    ? format(parseISO(invoiceData.cmrDate), "yyyy-MM-dd")
                    : ""
                }
                id="date"
                type="date"
                inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                InputLabelProps={{
                  shrink: true,
                }}
                onKeyDown={(e) => e.preventDefault()}
                onChange={(e) => {
                  handleTextFieldChange(e);
                }}
              />
            </div>
          )}
          {selectedOption === "goodsReceiptNumber" && (
            <TextField
              label="GR Number"
              name="goodsReceiptNumber"
              value={invoiceData?.goodsReceiptNumber}
              onChange={handleTextFieldChange}
              fullWidth
              margin="normal"
            />
          )}
          {selectedOption === "landedCostNumber" && (
            <TextField
              label="LC Number"
              name="landedCostNumber"
              value={invoiceData?.landedCostNumber}
              onChange={handleTextFieldChange}
              fullWidth
              margin="normal"
            />
          )}
          {selectedOption === "deliveryNumber" && (
            <TextField
              label="Del Number"
              name="deliveryNumber"
              value={invoiceData?.deliveryNumber}
              onChange={handleTextFieldChange}
              fullWidth
              margin="normal"
            />
          )}
          {selectedOption === "shippingDetails" && (
            <div>
              <TextField
                label="Booking Number"
                name="bookingNumber"
                value={invoiceData?.bookingNumber}
                onChange={handleTextFieldChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Shipping Line"
                name="shippingLine"
                value={invoiceData?.shippingLine}
                onChange={handleTextFieldChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Vessel Name"
                name="vesselName"
                value={invoiceData?.vesselName}
                onChange={handleTextFieldChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Voyage Number"
                name="voyageNumber"
                value={invoiceData?.voyageNumber}
                onChange={handleTextFieldChange}
                fullWidth
                margin="normal"
              />
              <Button
                variant="contained"
                onClick={togglePortsVisibility}
                style={{ marginBottom: "16px", marginTop: "16px" }}
              >
                Transshipment Ports
              </Button>

              {showPorts && (
                <div>
                  {invoiceData?.transshipmentPorts?.map((port, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "8px",
                      }}
                    >
                      <TextField
                        label={`Transshipment Port ${index + 1}`}
                        value={port}
                        onChange={(e) =>
                          handleTransshipmentPortChange(e, index)
                        }
                        fullWidth
                        margin="normal"
                      />
                      <IconButton
                        onClick={() => handleRemoveTransshipmentPort(index)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  ))}
                  <IconButton onClick={handleAddTransshipmentPort}>
                    <AddIcon />
                  </IconButton>
                </div>
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUploadDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              console.log(invoiceData);
              updateShippingInstructionApi(invoiceData?.id, invoiceData)
                .then((response) => {
                  toast.success("Shipping Instruction edited successfully.");
                  window.location.reload();
                })
                .catch((error) => {
                  toast.error("Something went wrong.");
                  console.log(error.response.data);
                });
            }}
            color="primary"
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>

        <>
          {showFilters && (
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <Grid
                container
                spacing={1}
                justifyContent="flex-start"
                alignItems="center"
              >
                <Autocomplete
                  multiple
                  id="outlined-select-po"
                  disableClearable
                  open={componentsState.shipmentRequest.dropdownOpen}
                  onOpen={() =>
                    updateComponentState("shipmentRequest", {
                      dropdownOpen: true,
                    })
                  }
                  inputValue={componentsState.shipmentRequest.inputValue}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "input") {
                      updateComponentState("shipmentRequest", {
                        inputValue: newInputValue,
                      });
                    }
                  }}
                  options={[
                    ...shipmentRequests.filter((shipmentRequest) =>
                      filterShippingInstruction.shipmentRequestIdList.includes(
                        shipmentRequest.id,
                      ),
                    ),
                    ...shipmentRequests.filter(
                      (shipmentRequest) =>
                        !filterShippingInstruction.shipmentRequestIdList.includes(
                          shipmentRequest.id,
                        ),
                    ),
                  ]}
                  getOptionLabel={(option) => {
                    const values = [
                      option?.id,
                      option?.purchaseOrder?.purchaseOrderNumber
                        ? `SAP PO Number: ${option.purchaseOrder?.purchaseOrderNumber}`
                        : null,
                      option?.purchaseOrder?.offer?.stakeholder?.name
                        ? `Supplier: ${option.purchaseOrder?.offer?.stakeholder?.name}`
                        : null,
                      option?.purchaseOrder?.offer?.item?.origin
                        ? `Origin: ${option.purchaseOrder?.offer?.item?.origin}`
                        : null,
                      option?.purchaseOrder?.offer?.item?.buyingGrade
                        ? `Buying Grade: ${option.purchaseOrder?.offer?.item?.buyingGrade}`
                        : null,
                    ].filter((value) => value);

                    return values.join(" - ");
                  }}
                  value={shipmentRequests.filter((shipmentRequest) =>
                    filterShippingInstruction.shipmentRequestIdList.includes(
                      shipmentRequest.id,
                    ),
                  )}
                  onChange={(event, newValue) => {
                    setFilterShippingInstruction({
                      ...filterShippingInstruction,
                      shipmentRequestIdList: newValue
                        .map((shipmentRequest) => shipmentRequest.id)
                        .filter((id) => id !== null),
                    });
                  }}
                  renderOption={(props, option, { selected }) => {
                    const values = [
                      option?.id,
                      option?.purchaseOrder?.purchaseOrderNumber
                        ? `SAP PO Number: ${option.purchaseOrder?.purchaseOrderNumber}`
                        : null,
                      option?.purchaseOrder?.offer?.stakeholder?.name
                        ? `Supplier: ${option.purchaseOrder?.offer?.stakeholder?.name}`
                        : null,
                      option?.purchaseOrder?.offer?.item?.origin
                        ? `Origin: ${option.purchaseOrder?.offer?.item?.origin}`
                        : null,
                      option?.purchaseOrder?.offer?.item?.buyingGrade
                        ? `Buying Grade: ${option.purchaseOrder?.offer?.item?.buyingGrade}`
                        : null,
                    ].filter((value) => value);

                    const optionLabel = values.join(" - ");
                    return (
                      <li
                        {...props}
                        style={{
                          ...props.style,
                          backgroundColor: selected ? "lightblue" : undefined,
                          fontWeight: selected ? 700 : 400,
                        }}
                      >
                        {optionLabel}
                      </li>
                    );
                  }}
                  renderInput={(params) => {
                    const label =
                      filterShippingInstruction?.shipmentRequestIdList.length >
                      0
                        ? `Shipment Requests Selected (${filterShippingInstruction?.shipmentRequestIdList.length})`
                        : "Shipment Request";

                    return (
                      <TextField
                        {...params}
                        label={label}
                        type="search"
                        value={componentsState.shipmentRequest.inputValue}
                        onChange={(e) => {
                          updateComponentState("shipmentRequest", {
                            inputValue: e.target.value,
                          });
                        }}
                        onMouseDown={() => retrieveShipmentRequests()}
                        InputProps={{
                          ...params.InputProps,
                          style: { height: 42 },
                        }}
                        onBlur={() =>
                          updateComponentState("shipmentRequest", {
                            dropdownOpen: false,
                          })
                        }
                      />
                    );
                  }}
                  renderTags={() => null}
                />

                <TextField
                  id="outlined-number"
                  label="SI Number"
                  value={
                    filterShippingInstruction?.finalShippingInstructionNumber ||
                    ""
                  }
                  onChange={(e) =>
                    setFilterShippingInstruction({
                      ...filterShippingInstruction,
                      finalShippingInstructionNumber: e.target.value,
                    })
                  }
                />

                <TextField
                  id="date"
                  type="date"
                  label="SI Date"
                  value={
                    filterShippingInstruction?.shippingInstructionDate || ""
                  }
                  inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) =>
                    setFilterShippingInstruction({
                      ...filterShippingInstruction,
                      shippingInstructionDate: e.target.value,
                    })
                  }
                />

                <TextField
                  label="Status"
                  type="search"
                  id="outlined-select-status"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterShippingInstruction.statusList || [],
                    onChange: (e) => {
                      setFilterShippingInstruction({
                        ...filterShippingInstruction,
                        statusList: e.target.value,
                      });
                    },
                  }}
                >
                  {shippingInstructionStatus.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  id="date"
                  type="date"
                  label="Date Of Loading"
                  value={filterShippingInstruction?.dateOfLoading || ""}
                  inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) =>
                    setFilterShippingInstruction({
                      ...filterShippingInstruction,
                      dateOfLoading: e.target.value,
                    })
                  }
                />

                <Autocomplete
                  multiple
                  id="outlined-select-freight-offer"
                  disableClearable
                  open={componentsState.freightOffer.dropdownOpen}
                  onOpen={() =>
                    updateComponentState("freightOffer", { dropdownOpen: true })
                  }
                  inputValue={componentsState.freightOffer.inputValue}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "input") {
                      updateComponentState("freightOffer", {
                        inputValue: newInputValue,
                      });
                    }
                  }}
                  options={[
                    ...freightOffers.filter((freightOffer) =>
                      filterShippingInstruction.freightOfferIdList.includes(
                        freightOffer.id,
                      ),
                    ),
                    ...freightOffers.filter(
                      (freightOffer) =>
                        !filterShippingInstruction.freightOfferIdList.includes(
                          freightOffer.id,
                        ),
                    ),
                  ]}
                  getOptionLabel={(option) => {
                    const values = [
                      option?.id,
                      option?.forwarder,
                      option?.pointOfLoading,
                      option?.pointOfDischarge,
                      option?.fortyFtRate
                        ? `40Ft: ${option.fortyFtRate} ${
                            option?.fortyFtCurrency || ""
                          }`
                        : null,
                      option?.twentyFtRate
                        ? `20Ft: ${option.twentyFtRate} ${
                            option?.twentyFtCurrency || ""
                          }`
                        : null,
                      option?.transitTime
                        ? `Transit: ${option.transitTime}`
                        : null,
                    ].filter((value) => value);

                    return values.join(" - ");
                  }}
                  value={freightOffers.filter((freightOffer) =>
                    filterShippingInstruction.freightOfferIdList.includes(
                      freightOffer.id,
                    ),
                  )}
                  onChange={(event, newValue) => {
                    setFilterShippingInstruction({
                      ...filterShippingInstruction,
                      freightOfferIdList: newValue
                        .map((freightOffer) => freightOffer.id)
                        .filter((id) => id !== null),
                    });
                  }}
                  renderOption={(props, option, { selected }) => {
                    const values = [
                      option?.id,
                      option?.forwarder,
                      option?.pointOfLoading,
                      option?.pointOfDischarge,
                      option?.fortyFtRate
                        ? `40Ft: ${option.fortyFtRate} ${
                            option?.fortyFtCurrency || ""
                          }`
                        : null,
                      option?.twentyFtRate
                        ? `20Ft: ${option.twentyFtRate} ${
                            option?.twentyFtCurrency || ""
                          }`
                        : null,
                      option?.transitTime
                        ? `Transit: ${option.transitTime}`
                        : null,
                    ].filter((value) => value);

                    const optionLabel = values.join(" - ");

                    return (
                      <li
                        {...props}
                        style={{
                          ...props.style,
                          backgroundColor: selected ? "lightblue" : undefined,
                          fontWeight: selected ? 700 : 400,
                        }}
                      >
                        {optionLabel}
                      </li>
                    );
                  }}
                  renderInput={(params) => {
                    const label =
                      filterShippingInstruction?.freightOfferIdList.length > 0
                        ? `Freight Offers Selected (${filterShippingInstruction?.freightOfferIdList.length})`
                        : "Freight Offer";

                    return (
                      <TextField
                        {...params}
                        label={label}
                        type="search"
                        value={componentsState.freightOffer.inputValue}
                        onChange={(e) => {
                          updateComponentState("freightOffer", {
                            inputValue: e.target.value,
                          });
                        }}
                        onMouseDown={() => retrieveFreightOffers()}
                        InputProps={{
                          ...params.InputProps,
                          style: { height: 42 },
                        }}
                        onBlur={() =>
                          updateComponentState("freightOffer", {
                            dropdownOpen: false,
                          })
                        }
                      />
                    );
                  }}
                  renderTags={() => null}
                />

                <TextField
                  id="outlined-number"
                  label="Transportation Contact"
                  value={filterShippingInstruction?.transportationContact || ""}
                  onChange={(e) =>
                    setFilterShippingInstruction({
                      ...filterShippingInstruction,
                      transportationContact: e.target.value,
                    })
                  }
                />

                <TextField
                  id="outlined-number"
                  label="Carton Number"
                  value={filterShippingInstruction?.cartonNumber || ""}
                  onChange={(e) =>
                    setFilterShippingInstruction({
                      ...filterShippingInstruction,
                      cartonNumber: e.target.value,
                    })
                  }
                />

                <TextField
                  label="To Be Shipped By"
                  type="search"
                  id="outlined-select-shipped-by"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterShippingInstruction.shippedByList || [],
                    onChange: (e) => {
                      setFilterShippingInstruction({
                        ...filterShippingInstruction,
                        shippedByList: e.target.value,
                      });
                    },
                  }}
                >
                  {shippedBy.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  id="outlined-number"
                  label="Bill Of Lading Number"
                  value={filterShippingInstruction?.billOfLadingNumber || ""}
                  onChange={(e) =>
                    setFilterShippingInstruction({
                      ...filterShippingInstruction,
                      billOfLadingNumber: e.target.value,
                    })
                  }
                />

                <TextField
                  id="date"
                  type="date"
                  label="Bill Of Lading Date"
                  value={filterShippingInstruction?.billOfLadingDate || ""}
                  inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) =>
                    setFilterShippingInstruction({
                      ...filterShippingInstruction,
                      billOfLadingDate: e.target.value,
                    })
                  }
                />

                <TextField
                  id="outlined-number"
                  label="CMR Number"
                  value={filterShippingInstruction?.cmrNumber || ""}
                  onChange={(e) =>
                    setFilterShippingInstruction({
                      ...filterShippingInstruction,
                      cmrNumber: e.target.value,
                    })
                  }
                />

                <TextField
                  id="date"
                  type="date"
                  label="CMR Date"
                  value={filterShippingInstruction?.cmrDate || ""}
                  inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) =>
                    setFilterShippingInstruction({
                      ...filterShippingInstruction,
                      cmrDate: e.target.value,
                    })
                  }
                />

                <TextField
                  label="Point Of Loading"
                  type="search"
                  id="outlined-select-point-of-loading"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterShippingInstruction.pointOfLoadingList || [],
                    onChange: (e) => {
                      setFilterShippingInstruction({
                        ...filterShippingInstruction,
                        pointOfLoadingList: e.target.value,
                      });
                    },
                  }}
                >
                  {portsOfLoading.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  label="Point Of Unloading"
                  type="search"
                  id="outlined-point-of-unloading"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterShippingInstruction.pointOfUnloadingList || [],
                    onChange: (e) => {
                      setFilterShippingInstruction({
                        ...filterShippingInstruction,
                        pointOfUnloadingList: e.target.value,
                      });
                    },
                  }}
                >
                  {portsOfDischarge.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  id="outlined-search"
                  label="Description"
                  value={filterShippingInstruction?.description || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterShippingInstruction({
                      ...filterShippingInstruction,
                      description: e.target.value,
                    })
                  }
                />

                <TextField
                  id="outlined-search"
                  label="Remark"
                  value={filterShippingInstruction?.remark || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterShippingInstruction({
                      ...filterShippingInstruction,
                      remark: e.target.value,
                    })
                  }
                />

                <TextField
                  label="Record Status"
                  type="search"
                  id="outlined-select-status"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterShippingInstruction.recordStatusList || [],
                    onChange: (e) => {
                      setFilterShippingInstruction({
                        ...filterShippingInstruction,
                        recordStatusList: e.target.value,
                      });
                    },
                  }}
                >
                  {recordStatus.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <br></br>
              <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={3} sm={1}>
                  <Button
                    disableElevation
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      retrieveFilteredShippingInstructionsApi(
                        filterShippingInstruction,
                      )
                        .then((response) => {
                          setRows(response?.data);
                        })
                        .catch((error) => {
                          console.log(error?.response?.data);
                        });
                    }}
                  >
                    Search
                  </Button>
                </Grid>
                <Grid item xs={3} sm={1}>
                  <Button
                    disableElevation
                    variant="outlined"
                    fullWidth
                    onClick={() => {
                      setFilterShippingInstruction({
                        id: 0,
                        shipmentRequestIdList: [],
                        shippingInstructionNumber: null,
                        shippingInstructionDate: "",
                        dateOfLoading: "",
                        purchaseOrderIdList: [],
                        salesOrderIdList: [],
                        freightOfferIdList: [],
                        transportationContact: "",
                        cartonNumber: "",
                        finalShippingInstructionNumber: "",
                        shippedByList: [],
                        billOfLadingNumber: "",
                        billOfLadingDate: "",
                        cmrNumber: "",
                        cmrDate: "",
                        shipperIdList: [],
                        consigneeIdList: [],
                        notifyIdList: [],
                        pointOfLoadingList: [],
                        pointOfUnloadingList: [],
                        description: "",
                        remark: "",
                        statusList: [],
                        recordStatusList: ["ACTIVE"],
                      });
                    }}
                  >
                    Clear Filters
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}

          <Box sx={{ width: "100%", mt: 2 }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
              <EnhancedTableToolbar
                numSelected={selected.length}
                showFilters={showFilters}
                setShowFilters={setShowFilters}
                rows={rows}
                selected={selected}
                selectedData={selectedData}
              />
              <TableContainer style={{ maxHeight: 700 }}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  sx={{
                    minWidth: 750,
                    "& .MuiTableCell-root": {
                      borderRight: "1px solid rgba(224, 224, 224, 1)",
                    },
                    "& .MuiTableCell-head": {
                      backgroundColor: "#20315d",
                      color: "white",
                      borderRight: "1px solid rgba(49, 64, 128, 1)",
                    },
                  }}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                  style={{ width: "max-content" }}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {visibleRows.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          style={
                            isSelected(row.id)
                              ? { background: "#d7e7fc" }
                              : row?.status === "Closed"
                              ? { background: "#c8e6c9" }
                              : index % 2
                              ? { background: "#f0f0f0" }
                              : { background: "white" }
                          }
                          hover
                          onClick={(event) => handleClick(event, row.id, row)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell padding="normal">
                          <Button
                           onClick={(e) => {
                           setSelectedRow(row);
                           setIsEditShippingCostDialogOpen(true);
                           e.preventDefault();
                           }}
                          >
                           Edit Costs
                          </Button>
                          </TableCell>
                          <TableCell align="center">
                            <ShippingInstructionHistoryButton
                              fetchHistoryApi={retrieveFilteredShippingInstructionsApi}
                              currentRow={row}
                            />
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              position: "sticky",
                              left: 0,
                              background: "inherit",
                              zIndex: 1,
                            }}
                          >
                            {row?.id}
                          </TableCell>
                          <TableCell align="center">
                            {row?.shipmentRequest?.id}
                          </TableCell>
                          <TableCell align="center">
                            {row?.shipmentRequest?.shipmentRequestType}
                          </TableCell>
                          <TableCell align="center">
                            {row?.shipmentRequest?.shipmentQuantity
                              ? `${new Intl.NumberFormat("de-DE", {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                }).format(
                                  row.shipmentRequest.shipmentQuantity,
                                )} ${
                                  row?.shipmentRequest?.shipmentQuantityUnit
                                }`
                              : ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.shipmentRequest?.storageQuantity
                              ? `${new Intl.NumberFormat("de-DE", {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                }).format(
                                  row.shipmentRequest.storageQuantity,
                                )}`
                              : ""}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              position: "sticky",
                              left: 50,
                              background: "inherit",
                              zIndex: 1,
                            }}
                          >
                            {row?.finalShippingInstructionNumber}
                          </TableCell>
                          <TableCell align="center">
                            {row?.shippingInstructionDate
                              ? format(
                                  new Date(row?.shippingInstructionDate),
                                  "dd/MM/yyyy",
                                )
                              : ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.actualTimeOfArrival
                              ? format(
                                  new Date(row?.actualTimeOfArrival),
                                  "dd/MM/yyyy",
                                )
                              : ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.shipmentRequest?.purchaseOrder?.id}
                          </TableCell>
                          <TableCell align="center">
                            {row?.shipmentRequest?.purchaseOrder?.company}
                          </TableCell>
                          <TableCell align="center">
                            {
                              row?.shipmentRequest?.purchaseOrder
                                ?.purchaseOrderNumber
                            }
                          </TableCell> 
                          <TableCell align="center">
                            {row?.shipmentRequest?.salesOrder?.id}
                          </TableCell>
                          <TableCell align="center">
                            {row?.shipmentRequest?.salesOrder?.company}
                          </TableCell>
                          <TableCell align="center">
                            {row?.shipmentRequest?.salesOrder?.salesOrderNumber}
                          </TableCell>
                          <TableCell align="center">{row?.shipmentRequest?.salesOrder?.finalPrice
                              ? `${new Intl.NumberFormat("en-US", {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                }).format(row?.shipmentRequest?.salesOrder?.finalPrice)} ${row?.shipmentRequest?.salesOrder?.finalPriceCurrency || ""}` 
                              : ""}{" "}
                            </TableCell>
                          <TableCell align="center">
                            {row?.shipmentRequest?.salesOrder?.offer?.item
                              ?.id ||
                              row?.shipmentRequest?.purchaseOrder?.offer?.item
                                ?.id ||
                              ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.shipmentRequest?.salesOrder?.offer?.item
                              ?.buyingGrade ||
                              row?.shipmentRequest?.purchaseOrder?.offer?.item
                                ?.buyingGrade ||
                              ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.shipmentRequest?.customerGradeMark}
                          </TableCell>
                          <TableCell align="center">
                            {row?.shipmentRequest?.purchaseOrder?.item?.crop || row?.shipmentRequest?.salesOrder?.item?.crop}
                          </TableCell>
                          <TableCell align="center">{row?.warehouse}</TableCell>
                          <TableCell align="center">
                            {row?.destination}
                          </TableCell>
                          <TableCell align="center">
                            {row?.pointOfLoading}
                          </TableCell>
                          <TableCell align="center">
                            {row?.pointOfUnloading}
                          </TableCell>
                          <TableCell align="center">
                            {row?.freightOffer?.id}
                          </TableCell>
                          <TableCell align="center">{row?.shipmentRequest?.purchaseOrder?.finalPrice
                              ? `${new Intl.NumberFormat("en-US", {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                }).format(row?.shipmentRequest?.purchaseOrder?.finalPrice)} ${row?.shipmentRequest?.purchaseOrder?.finalPriceCurrency || ""}` 
                              : ""}{" "}
                            </TableCell>
                            <TableCell align="center">{row?.shipmentRequest?.purchaseOrder?.usdFinalPrice
                              ? `${new Intl.NumberFormat("en-US", {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                }).format(row?.shipmentRequest?.purchaseOrder?.usdFinalPrice)} USD` 
                              : ""}{" "}
                            </TableCell>
                          <TableCell align="center">{row?.actualFreightPrice
                              ? `${new Intl.NumberFormat("en-US", {
                                  style: "decimal",
                                  minimumFractionDigits: 4,
                                }).format(row?.actualFreightPrice)} ${row.actualFreightCurrency || ""}` 
                              : ""}{" "}
                            </TableCell>
                            <TableCell align="center">{row?.actualFreightPriceUsd
                              ? `${new Intl.NumberFormat("en-US", {
                                  style: "decimal",
                                  minimumFractionDigits: 4,
                                }).format(row?.actualFreightPriceUsd)} USD` 
                              : ""}{" "}
                            </TableCell>
                          <TableCell align="center">{row?.actualInsuranceCost
                              ? `${new Intl.NumberFormat("en-US", {
                                  style: "decimal",
                                  minimumFractionDigits: 4,
                                }).format(row?.actualInsuranceCost)} ${row.actualInsuranceCurrency || ""}` 
                              : ""}{" "}
                            </TableCell>
                            <TableCell align="center">{row?.actualInsuranceCostUsd
                              ? `${new Intl.NumberFormat("en-US", {
                                  style: "decimal",
                                  minimumFractionDigits: 4,
                                }).format(row?.actualInsuranceCostUsd)} USD` 
                              : ""}{" "}
                            </TableCell>
                            <TableCell align="center">{row?.baseStorageCost
                              ? `${new Intl.NumberFormat("en-US", {
                                  style: "decimal",
                                  minimumFractionDigits: 4,
                                }).format(row?.baseStorageCost)}` 
                              : ""}{" "}
                            </TableCell>
                            <TableCell align="center">{row?.baseStorageCostUsd
                              ? `${new Intl.NumberFormat("en-US", {
                                  style: "decimal",
                                  minimumFractionDigits: 4,
                                }).format(row?.baseStorageCostUsd)}` 
                              : ""}{" "}
                            </TableCell>
                            <TableCell align="center">{row?.actualStorageCost
                              ? `${new Intl.NumberFormat("en-US", {
                                  style: "decimal",
                                  minimumFractionDigits: 4,
                                }).format(row?.actualStorageCost)} ${row.actualStorageCurrency || ""} ${
                                  row.actualStoragePricing ? "Per "+row.actualStoragePricing : ""
                                }`
                              : ""}
                            </TableCell>
                            <TableCell align="center">{row?.actualStorageCostUsd
                              ? `${new Intl.NumberFormat("en-US", {
                                  style: "decimal",
                                  minimumFractionDigits: 4,
                                }).format(row?.actualStorageCostUsd)} USD ${
                                  row.actualStoragePricing ? "Per "+row.actualStoragePricing : ""
                                }`
                              : ""}
                            </TableCell>
                            <TableCell align="center">
                                  {row?.actualStoragePricing === "Carton" && row?.actualStorageCost && row?.shipmentRequest?.netWeightPerCase
                                    ? `${new Intl.NumberFormat("en-US", {
                                        style: "decimal",
                                        minimumFractionDigits: 4,
                                      }).format(row.actualStorageCost / row.shipmentRequest.netWeightPerCase)} ${row.actualStorageCurrency || ""}`
                                    : row?.actualStorageCost
                                    ? `${new Intl.NumberFormat("en-US", {
                                        style: "decimal",
                                        minimumFractionDigits: 4,
                                      }).format(row.actualStorageCost)} ${row.actualStorageCurrency || ""}`
                                    : ""}
                                </TableCell>
                                <TableCell align="center">
                                  {row?.actualStoragePricing === "Carton" && row?.actualStorageCostUsd && row?.shipmentRequest?.netWeightPerCase
                                    ? `${new Intl.NumberFormat("en-US", {
                                        style: "decimal",
                                        minimumFractionDigits: 4,
                                      }).format(row.actualStorageCostUsd / row.shipmentRequest.netWeightPerCase)} USD`
                                    : row?.actualStorageCostUsd
                                    ? `${new Intl.NumberFormat("en-US", {
                                        style: "decimal",
                                        minimumFractionDigits: 4,
                                      }).format(row.actualStorageCostUsd)} USD`
                                    : ""}
                                </TableCell>
                                <TableCell align="center">
                                  {(() => {
                                    const freight = row?.actualFreightPriceUsd ? Number(row.actualFreightPriceUsd) : 0;
                                    const insurance = row?.actualInsuranceCostUsd ? Number(row.actualInsuranceCostUsd) : 0;
                                    const storage = row?.actualStorageCostUsd
                                      ? row?.actualStoragePricing === "Carton" && row?.shipmentRequest?.netWeightPerCase
                                        ? Number(row.actualStorageCostUsd) / Number(row.shipmentRequest.netWeightPerCase)
                                        : Number(row.actualStorageCostUsd)
                                      : 0;
                                  
                                    const finalPrice = row?.shipmentRequest?.purchaseOrder?.usdFinalPrice
                                      ? Number(row.shipmentRequest.purchaseOrder.usdFinalPrice)
                                      : 0;

                                    const secondFreight = row?.cost?.secondFreightUsd
                                      ? Number(row.cost.secondFreightUsd)
                                      : 0;

                                    const importExportExpense = row?.cost?.importExportExpenseUsd
                                      ? Number(row.cost.importExportExpenseUsd)
                                      : 0;

                                      const demurrage = row?.cost?.demurrageUsd
                                      ? Number(row.cost.demurrageUsd)
                                      : 0;

                                      const fumagation = row?.cost?.fumagationUsd
                                      ? Number(row.cost.fumagationUsd)
                                      : 0;

                                      const handling = row?.cost?.handlingUsd
                                      ? Number(row.cost.handlingUsd)
                                      : 0;

                                      const debitNote = row?.cost?.debitNoteUsd
                                      ? Number(row.cost.debitNoteUsd)
                                      : 0;

                                      const creditNote = row?.cost?.creditNoteUsd
                                      ? Number(row.cost.creditNoteUsd)
                                      : 0;

                                    const total = freight + insurance + storage + finalPrice;
                                  
                                    return total > 0
                                      ? new Intl.NumberFormat("en-US", {
                                          style: "decimal",
                                          minimumFractionDigits: 4,
                                        }).format(total) + " USD"
                                      : "";
                                  })()}
                                </TableCell>
                          <TableCell align="center">
                            {row?.shipmentRequest?.numberOfCases}
                          </TableCell>
                          <TableCell align="center">
                            {row?.shipmentRequest?.netWeightPerCase}
                          </TableCell>
                          <TableCell align="center">{row?.remark}</TableCell> 
                          <TableCell align="center">
                          {
                              row?.shipmentRequest
                                ?.referenceShippingInstructionId
                            }-{
                              row?.shipmentRequest
                                ?.referenceShippingInstructionNumber
                            }
                          </TableCell>
                          <TableCell align="center">{row?.status}</TableCell>
                          <TableCell align="center">
                            {row?.timestamp
                              ? format(
                                  new Date(row.timestamp),
                                  "dd/MM/yyyy HH:mm",
                                )
                              : ""}
                          </TableCell>
                          <TableCell align="center">{row?.creator}</TableCell>
                          <TableCell align="center">
                            {row?.recordStatus}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <div style={{ marginTop: "20px", marginLeft: "20px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "20px",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span
                      style={{
                        background: "#c8e6c9",
                        padding: "4px 8px",
                        marginRight: "5px",
                        borderRadius: "8px",
                        display: "inline-block",
                      }}
                    >
                      Closed
                    </span>
                    <span>- SI Closed</span>
                  </div>
                </div>
              </div>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label="Dense padding"
            />
          </Box>
          <EditShippingInstructionCostDialog
  open={isEditShippingCostDialogOpen}
  onClose={() => setIsEditShippingCostDialogOpen(false)}
  rowData={selectedRow}
  onUpdated={retrieveShippingInstructions}
/>;
        </>
    </div>
  );
}
