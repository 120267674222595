import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TableCell,
    TextField,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Tooltip,
    Grid,} from '@mui/material';
import { format } from 'date-fns';
import toast from "react-hot-toast";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function ApproveOrderButton({ row, approveOrder }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleApprove = () => {
    approveOrder(row?.id);
    setOpen(false); 
  };

  const formattedToday = new Date().toISOString().slice(0, 10); 

  return (
    <>
        <Tooltip title="Approve Order">
  <IconButton
    color="primary"
    disabled={
      row?.approvalStatus === "Approved" ||
      row?.orderStatus === "Cancelled" ||
      !row?.assignedUser
    }
    onClick={() => {
      if (localStorage.getItem("username") === row?.assignedUser?.email) {
        handleClickOpen();
      } else {
        toast.error("This order is not assigned to you.");
      }
    }}
  >
    <CheckCircleIcon />
  </IconButton>
</Tooltip>
     <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
     <DialogTitle>Approve Order</DialogTitle>
     <DialogContent>
     <TableContainer component={Paper}
           sx={{
            border: "2px solid #d1dfe8",
            borderRadius: "8px", 
            overflow: "hidden", 
          }}>

      <Table   sx={{
      borderCollapse: "separate", 
      borderSpacing: "0", 
      tableLayout: "fixed",
      width: "100%", 
    }}>
        <TableHead>
          <TableRow>
         <TableCell sx={{ width: "8%", border: "1px solid #d1dfe8",
            backgroundColor: "#98c1d9",
           fontSize: "0.7rem", 
           padding: "4px 8px", 
           whiteSpace: "nowrap",
           textAlign: "center", }}>
             SAP Number
           </TableCell>
            <TableCell sx={{ width: "10%", border: "1px solid #d1dfe8",
             backgroundColor: "#98c1d9",
            fontSize: "0.7rem", 
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Company
            </TableCell>
            <TableCell sx={{ width: "15%", border: "1px solid #d1dfe8",
             backgroundColor: "#98c1d9",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Vendor
            </TableCell>
            <TableCell sx={{ width: "6%", border: "1px solid #d1dfe8",
            backgroundColor: "#98c1d9",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center",
            fontSize: "0.7rem", }}>
              # Of Cartons
            </TableCell>
            <TableCell sx={{ width: "6%", border: "1px solid #d1dfe8",
            backgroundColor: "#98c1d9",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center",
            fontSize: "0.7rem", }}>
              Net Weight Per Carton
            </TableCell>
            <TableCell sx={{ width: "10%", border: "1px solid #d1dfe8",
            backgroundColor: "#98c1d9",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Volume
            </TableCell>
            <TableCell sx={{ width: "10%", border: "1px solid #d1dfe8",
            backgroundColor: "#98c1d9",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Final Price
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>

          <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={row?.purchaseOrderNumber || ""}
              />
            </TableCell>

          <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={row?.company || ""}
              />
            </TableCell>

            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={row?.offer?.stakeholder ? ((row?.offer?.stakeholder?.code || "")+ " "+(row?.offer?.stakeholder?.name || "")) : "" }
              />
            </TableCell>


            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={
                    row?.numberOfCases || ""
                }
              />
            </TableCell>
            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={
                    row?.netWeightPerCase || ""
                }
              />
            </TableCell>
            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={
                    row?.volume || ""
                }
              />
            </TableCell>
            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={
                    row?.finalOrderPrice ? (row?.finalOrderPrice + " " + (row?.finalOrderPriceCurrency||"")):""
                }
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <br></br>

      <Table   sx={{
      borderCollapse: "separate", 
      borderSpacing: "0", 
      tableLayout: "fixed",
      width: "100%", 
    }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "10%", border: "1px solid #d1dfe8",
             backgroundColor: "#98c1d9",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Tobacco Code
            </TableCell>
            <TableCell sx={{ width: "10%", border: "1px solid #d1dfe8",
             backgroundColor: "#98c1d9",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Buying Grade
            </TableCell>
            <TableCell sx={{ width: "10%", border: "1px solid #d1dfe8",
             backgroundColor: "#98c1d9",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Sales Grade
            </TableCell>
            <TableCell sx={{ width: "8%", border: "1px solid #d1dfe8",
            backgroundColor: "#98c1d9",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Origin
            </TableCell>
            <TableCell sx={{ width: "6%", border: "1px solid #d1dfe8",
            backgroundColor: "#98c1d9",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Crop
            </TableCell>
            <TableCell sx={{ width: "6%", border: "1px solid #d1dfe8",
            backgroundColor: "#98c1d9",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Nicotine
            </TableCell>
            <TableCell sx={{ width: "6%", border: "1px solid #d1dfe8",
            backgroundColor: "#98c1d9",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Sugar
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={row?.offer?.item?.tobaccoCode || ""}
              />
            </TableCell>

            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={row?.offer?.item?.buyingGrade || ""}
              />
            </TableCell>

            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={row?.offer?.salesGrade || ""}
              />
            </TableCell>

            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={row?.offer?.item?.origin || ""}
              />
            </TableCell>

            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={row?.offer?.item?.crop || ""}
              />
            </TableCell>


            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={row?.offer?.item?.nicotine || ""}
              />
            </TableCell>

            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={row?.offer?.item?.sugar || ""}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
       <p><strong>Order ID:</strong> {row?.id}</p>
       <p>Are you sure you want to approve this order?</p>
     </DialogContent>
     <DialogActions>
       <Button onClick={handleClose} color="secondary">
         Cancel
       </Button>
       <Button onClick={handleApprove} color="primary" variant="contained">
         Yes, Approve
       </Button>
     </DialogActions>
   </Dialog>
   </>
  );
}

export default ApproveOrderButton;
