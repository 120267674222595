import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TableCell,
    TextField,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Paper,} from '@mui/material';
import { format } from 'date-fns';

function ApproveOfferButton({ row, approveOffer }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleApprove = () => {
    // Proceed to approve the offer only after confirmation
    approveOffer(row?.id);
    setOpen(false); // Close the dialog
  };

  const formattedToday = new Date().toISOString().slice(0, 10); // Assuming today's date is formatted as YYYY-MM-DD

  return (
    <>
      <Button
        variant="outlined"
        disabled={
          row?.approvalStatus === "Approved" ||
          ((row?.statusDeadline
            ? format(new Date(row?.statusDeadline), "dd/MM/yyyy")
            : "") < formattedToday &&
            row?.statusDeadline != null)
        }
        onClick={handleClickOpen} // Open the confirmation dialog
      >
        Approve Offer
      </Button>
     <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
     <DialogTitle>Approve Offer</DialogTitle>
     <DialogContent>
     <TableContainer component={Paper}
           sx={{
            border: "2px solid #d1dfe8",
            borderRadius: "8px", 
            overflow: "hidden", 
          }}>

      <Table   sx={{
      borderCollapse: "separate", 
      borderSpacing: "0", 
      tableLayout: "fixed",
      width: "100%", 
    }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "15%", border: "1px solid #d1dfe8",
             backgroundColor: "#98c1d9",
            fontSize: "0.7rem", 
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Customer
            </TableCell>
            <TableCell sx={{ width: "10%", border: "1px solid #d1dfe8",
             backgroundColor: "#98c1d9",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Buying Grade
            </TableCell>
            <TableCell sx={{ width: "8%", border: "1px solid #d1dfe8",
            backgroundColor: "#98c1d9",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Origin
            </TableCell>
            <TableCell sx={{ width: "6%", border: "1px solid #d1dfe8",
            backgroundColor: "#98c1d9",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Crop
            </TableCell>
            <TableCell sx={{ width: "6%", border: "1px solid #d1dfe8",
            backgroundColor: "#98c1d9",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Nicotine
            </TableCell>
            <TableCell sx={{ width: "6%", border: "1px solid #d1dfe8",
            backgroundColor: "#98c1d9",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Sugar
            </TableCell>
            <TableCell sx={{ width: "9%", border: "1px solid #d1dfe8",
            backgroundColor: "#98c1d9",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center",
            fontSize: "0.7rem", }}>
              # Of Cartons
            </TableCell>
            <TableCell sx={{ width: "10%", border: "1px solid #d1dfe8",
            backgroundColor: "#98c1d9",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center",
            fontSize: "0.7rem", }}>
              Net Weight Per Carton
            </TableCell>
            <TableCell sx={{ width: "11%", border: "1px solid #d1dfe8",
            backgroundColor: "#98c1d9",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Volume
            </TableCell>
            <TableCell sx={{ width: "10%", border: "1px solid #d1dfe8",
            backgroundColor: "#98c1d9",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Final Price
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>

            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={row?.stakeholder ? ((row?.stakeholder?.code || "")+ " "+(row?.stakeholder?.name || "")) : "" }
              />
            </TableCell>

            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={row?.item?.buyingGrade || ""}
              />
            </TableCell>

            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={row?.item?.origin || ""}
              />
            </TableCell>

            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={row?.item?.crop || ""}
              />
            </TableCell>


            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={row?.item?.nicotine || ""}
              />
            </TableCell>

            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={row?.item?.sugar || ""}
              />
            </TableCell>

            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={
                    row?.numberOfCases || ""
                }
              />
            </TableCell>
            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={
                    row?.netWeightPerCase || ""
                }
              />
            </TableCell>
            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={
                    row?.volume || ""
                }
              />
            </TableCell>
            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={
                    row?.finalOfferPrice ? (row?.finalOfferPrice + " " + (row?.finalOfferPriceCurrency||"")):""
                }
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
       {/* Display details related to the offer here */}
       <p><strong>Offer ID:</strong> {row?.id}</p>
       {/* Add more details as needed */}
       <p>Are you sure you want to approve this offer?</p>
     </DialogContent>
     <DialogActions>
       <Button onClick={handleClose} color="secondary">
         Cancel
       </Button>
       <Button onClick={handleApprove} color="primary" variant="contained">
         Yes, Approve
       </Button>
     </DialogActions>
   </Dialog>
   </>
  );
}

export default ApproveOfferButton;
