import {
  Autocomplete,
  Button,
  Chip,
  Divider,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Box from "@mui/material/Box";
import { format, parseISO } from "date-fns";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { retrieveAllOffersApi } from "../../../components/api/OfferApiService";
import { updateOrderApi } from "../../../components/api/OrderApiService";
import { retrievePurposeFilteredSamplesApi } from "../../../components/api/SampleApiService";
import {
  currencies,
  incoterms,
  packagingTypes,
  portsOfDischarge,
  portsOfLoading,
  paymentTerm,
  recordStatus,
  sampleStatus,
  status,
  units,
  companies,
} from "../../../utils/menu-items";

const EditVendorOrder = (props) => {
  const { editVendorOrder, setEditVendorOrder } = props;

  function retrieveOffers() {
    retrieveAllOffersApi("VENDOR")
      .then((response) => {
        setOffers(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveFilteredSamples(samplePurpose) {
    retrievePurposeFilteredSamplesApi(samplePurpose)
      .then((response) => {
        setFilteredSamples(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }
  const [offers, setOffers] = useState([]);
  const [filteredSamples, setFilteredSamples] = useState([]);
  const [selectedFirstSample, setSelectedFirstSample] = useState(
    editVendorOrder?.data?.firstSample || null,
  );

  const initialPaymentTerm = paymentTerm.find(
    (term) => term.value === editVendorOrder?.data?.paymentTerm,
  );
  const [selectedPaymentTerm, setSelectedPaymentTerm] = useState(
    initialPaymentTerm || null,
  );
  const [selectedOffer, setSelectedOffer] = useState(
    editVendorOrder?.data?.offer || null,
  );
  const initialPortOfDischarge = portsOfDischarge.find(
    (term) => term.value === editVendorOrder?.data?.portOfDischarge,
  );
  const [selectedPortOfDischarge, setSelectedPortOfDischarge] = useState(
    initialPortOfDischarge || null,
  );

  const initialPortOfLoading = portsOfLoading.find(
    (term) => term.value === editVendorOrder?.data?.portOfLoading,
  );
  const [selectedPortOfLoading, setSelectedPortOfLoading] = useState(
    initialPortOfLoading || null,
  );
  const [previousVolume, setPreviousVolume] = useState(
    editVendorOrder?.data?.volume || 0,
  );
  const [oldRemainingQuantity, setOldRemainingQuantity] = useState(
    editVendorOrder?.data?.remainingQuantity || 0,
  );

  return (
    <>
      <Stack direcion="column">
        <Typography variant="body1" mb={2}>
          Edit Purchase Order: {editVendorOrder?.data?.id}
          {editVendorOrder?.data?.approvalStatus === "Approved" && (
            <Typography variant="h5" color="error" sx={{ marginTop: "10px" }}>
              Approved orders can only be edited by managers.
            </Typography>
          )}
        </Typography>
        <Grid container item xs={12} spacing="8px">
          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Order Year</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editVendorOrder?.data?.orderYear}
              onChange={(e) =>
                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    orderYear: e.target.value,
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}></Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Buying Company</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              select
              value={editVendorOrder?.data?.company}
              onChange={(e) =>
                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    company: e.target.value,
                  },
                })
              }
            >
              {companies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">SAP PO Number</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editVendorOrder?.data?.purchaseOrderNumber}
              onChange={(e) =>
                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    purchaseOrderNumber: e.target.value,
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}></Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">SG Approval Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              disabled
              value={editVendorOrder?.data?.approvalStatus}
            >
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}></Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Order Number</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editVendorOrder?.data?.orderGroupNumber}
              onChange={(e) =>
                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    orderGroupNumber: e.target.value,
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={7}></Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Record Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-status"
              select
              value={editVendorOrder?.data?.recordStatus}
              onChange={(e) =>
                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    recordStatus: e.target.value,
                  },
                })
              }
            >
              {recordStatus.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}></Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Contract Value Date</Typography>
            <TextField
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              variant="outlined"
              size="small"
              fullWidth
              value={
                editVendorOrder?.data?.contractValueDate
                  ? format(
                      parseISO(editVendorOrder.data.contractValueDate),
                      "yyyy-MM-dd",
                    )
                  : ""
              }
              onChange={(e) =>
                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    contractValueDate: e.target.value
                      ? e.target.value + "T00:00:00.000+00:00"
                      : null,
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}></Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant="body2">Vendor</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editVendorOrder?.data?.offer?.stakeholder?.name}
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={2}></Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Edit Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              select
              value={editVendorOrder?.data?.editingPermission}
              onChange={(e) =>
                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    editingPermission: e.target.value,
                  },
                })
              }
            >
              {status.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
          <Typography variant="body2">Order Status</Typography>
          <TextField
          variant="outlined"
          size="small"
          fullWidth
          select
          value={editVendorOrder?.data?.orderStatus}
          onChange={(e) =>
            setEditVendorOrder({
              ...editVendorOrder,
              data: {
                ...editVendorOrder.data,
                orderStatus: e.target.value,
              },
            })
          }
        >
          {status.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        </Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Issue Date</Typography>
            <TextField
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              error={!editVendorOrder?.data?.operationDate}
              helperText={!editVendorOrder?.data?.operationDate ? "Issue date is required" : ""}
              variant="outlined"
              size="small"
              fullWidth
              value={
                editVendorOrder?.data?.operationDate
                  ? format(
                      parseISO(editVendorOrder.data.operationDate),
                      "yyyy-MM-dd",
                    )
                  : ""
              }
              onChange={(e) =>
                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    operationDate: e.target.value
                      ? e.target.value + "T00:00:00.000+00:00"
                      : null,
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}></Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">SAP Vendor Code</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editVendorOrder?.data?.offer?.stakeholder?.code}
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={4}></Grid>

          <Grid item xs={12} sm={4}>
            <Typography variant="body2">Contract Link</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editVendorOrder?.data?.contractLink}
              onChange={(e) =>
                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    contractLink: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Divider
              sx={{
                "&.MuiDivider-root": {
                  margin: "10px 0",
                  borderColor: "#1890ff",
                },
              }}
            />
          </Grid>

          <TableContainer component={Paper}
           sx={{
            border: "2px solid #d1dfe8",
            borderRadius: "8px", 
            overflow: "hidden", 
          }}>

      <Table   sx={{
      borderCollapse: "separate", 
      borderSpacing: "0", 
      tableLayout: "fixed",
      width: "100%", 
    }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "15%", border: "1px solid #d1dfe8",
            backgroundColor: "#98c1d9",
            fontSize: "0.7rem", 
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Offer
            </TableCell>
            <TableCell sx={{ width: "10%", border: "1px solid #d1dfe8",
             backgroundColor: "#98c1d9",
            fontSize: "0.7rem", 
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              ID - Year - Number
            </TableCell>
            <TableCell sx={{ width: "10%", border: "1px solid #d1dfe8",
             backgroundColor: "#98c1d9",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Buying Grade
            </TableCell>
            <TableCell sx={{ width: "10%", border: "1px solid #d1dfe8",
            backgroundColor: "#98c1d9",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Internal Grade
            </TableCell>
            <TableCell sx={{ width: "10%", border: "1px solid #d1dfe8",
            backgroundColor: "#98c1d9",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Sales Grade
            </TableCell>
            <TableCell sx={{ width: "10%", border: "1px solid #d1dfe8",
            backgroundColor: "#98c1d9",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Origin
            </TableCell>
            {selectedOffer?.item?.origin==="China" && <TableCell sx={{ width: "10%", border: "1px solid #d1dfe8",
            backgroundColor: "#98c1d9",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Province
            </TableCell>}
            <TableCell sx={{ width: "6%", border: "1px solid #d1dfe8",
            backgroundColor: "#98c1d9",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Crop
            </TableCell>
            <TableCell sx={{ width: "6%", border: "1px solid #d1dfe8",
            backgroundColor: "#98c1d9",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Nicotine
            </TableCell>
            <TableCell sx={{ width: "6%", border: "1px solid #d1dfe8",
            backgroundColor: "#98c1d9",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Sugar
            </TableCell>
            <TableCell sx={{ width: "6%", border: "1px solid #d1dfe8",
            backgroundColor: "#98c1d9",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Moisture
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                flexWrap: "nowrap",
              }}
            >
              <Autocomplete
                options={offers}
                sx={{ maxWidth: "250px", width: "100%" }}
                getOptionLabel={(option) =>
                  `Id: ${option?.id || ""}, Number: ${
                    option?.offerYear || ""
                  }-${option?.offerGroupNumber || ""}`
                }
                value={selectedOffer}
                onChange={(event, newValue) => {
                  setSelectedOffer(newValue);
                  setEditVendorOrder({
                    ...editVendorOrder,
                    data: {
                      ...editVendorOrder.data,
                      offer: newValue || {},
                    },
                  });
                }}
                onOpen={retrieveOffers}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    label={`Id: ${selectedOffer?.id || ""}, Number: ${
                      selectedOffer?.offerYear || ""
                    }-${selectedOffer?.offerGroupNumber || ""}`}
                  />
                )}
                renderTags={() => null}
              />
             {selectedOffer && (
        <IconButton
          onClick={() => {
            setSelectedOffer(null);
            setEditVendorOrder({
              ...editVendorOrder,
              data: {
                ...editVendorOrder.data,
                offer: {},
              },
            });
          }}
          sx={{
            padding: 0, 
            color: "red", 
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
            </Box>
            </TableCell>

            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={(selectedOffer?.id || "") + " - " +(selectedOffer?.offerYear || "") + " - " + (selectedOffer?.offerGroupNumber || "")}
              />
            </TableCell>

            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={
                  selectedOffer?.item?.buyingGrade || ""
                }
              />
            </TableCell>

            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={
                  selectedOffer?.item?.internalGrade || ""
                }
              />
            </TableCell>

            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={
                  selectedOffer?.salesGrade || ""
                }
              />
            </TableCell>

            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={
                  selectedOffer?.item?.origin || ""
                }
              />
            </TableCell>

            {selectedOffer?.item?.origin==="China" && <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={
                  selectedOffer?.item?.province || ""
                }
              />
            </TableCell>}

            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={
                  selectedOffer?.item?.crop || ""
                }
              />
            </TableCell>
            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={
                  selectedOffer?.item?.nicotine || ""
                }
              />
            </TableCell>
            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={
                  selectedOffer?.item?.sugar || ""
                }
              />
            </TableCell>
            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={
                  selectedOffer?.item?.moisture || ""
                }
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

          <Grid item xs={12} sm={2}></Grid>
          <Grid item xs={12} sm={12}></Grid>
          <Grid item xs={12} sm={12}></Grid>

           <TableContainer component={Paper}
           sx={{
            border: "2px solid #abcfff",
            borderRadius: "8px", 
            overflow: "hidden", 
          }}>

      <Table   sx={{
      borderCollapse: "separate", 
      borderSpacing: "0", 
      tableLayout: "fixed",
      width: "100%", 
    }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "9.5%", border: "1px solid #e0dada",
            backgroundColor: "#d7e7fc",
            fontSize: "0.7rem", 
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Net Weight Per Carton
            </TableCell>
            <TableCell sx={{ width: "9.5%", border: "1px solid #e0dada",
             backgroundColor: "#d7e7fc",
            fontSize: "0.7rem", 
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Number Of Cartons
            </TableCell>
            <TableCell sx={{ width: "10.5%", border: "1px solid #e0dada",
             backgroundColor: "#d7e7fc",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Volume
            </TableCell>
            <TableCell sx={{ width: "9%", border: "1px solid #e0dada",
            backgroundColor: "#d7e7fc",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Unit
            </TableCell>
            <TableCell sx={{ width: "9.5%", border: "1px solid #e0dada",
            backgroundColor: "#d7e7fc",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Packaging
            </TableCell>
            <TableCell sx={{ width: "9%", border: "1px solid #e0dada",
            backgroundColor: "#d7e7fc",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Final Price
            </TableCell>
            <TableCell sx={{ width: "6%", border: "1px solid #e0dada",
            backgroundColor: "#d7e7fc",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Currency
            </TableCell>
            <TableCell sx={{ width: "10%", border: "1px solid #e0dada",
            backgroundColor: "#d7e7fc",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Total Amount
            </TableCell>
            <TableCell sx={{ width: "10%", border: "1px solid #e0dada",
            backgroundColor: "#d7e7fc",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Incoterm
            </TableCell>
            <TableCell sx={{ width: "19%", border: "1px solid #e0dada",
            backgroundColor: "#d7e7fc",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Payment Term
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {/* Net Weight Per Carton */}
            <TableCell sx={{ border: "1px solid #e0dada" }}>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                fullWidth
                value={editVendorOrder?.data?.netWeightPerCase}
                onWheel={(e) => e.target.blur()}
                onChange={(e) => {
                  const newVolume =
                    e.target.value * editVendorOrder?.data?.numberOfCases;
                  const remainingQuantity =
                    oldRemainingQuantity + (newVolume - previousVolume);

                  setEditVendorOrder({
                    ...editVendorOrder,
                    data: {
                      ...editVendorOrder.data,
                      netWeightPerCase: e.target.value,
                      totalAmount:
                        e.target.value *
                        editVendorOrder?.data?.numberOfCases *
                        editVendorOrder?.data?.finalPrice,
                      volume: newVolume,
                      remainingQuantity: remainingQuantity,
                    },
                  });
                }}
              />
            </TableCell>

            {/* Number Of Cartons */}
            <TableCell sx={{ border: "1px solid #e0dada" }}>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                fullWidth
                value={editVendorOrder?.data?.numberOfCases}
                onWheel={(e) => e.target.blur()}
                onChange={(e) => {
                  const newVolume =
                    e.target.value * editVendorOrder?.data?.netWeightPerCase;
                  const remainingQuantity =
                    oldRemainingQuantity + (newVolume - previousVolume);

                  setEditVendorOrder({
                    ...editVendorOrder,
                    data: {
                      ...editVendorOrder.data,
                      numberOfCases: e.target.value,
                      totalAmount:
                        e.target.value *
                        editVendorOrder?.data?.netWeightPerCase *
                        editVendorOrder?.data?.finalPrice,
                      volume: newVolume,
                      remainingQuantity: remainingQuantity,
                    },
                  });
                }}
              />
            </TableCell>

            {/* Volume */}
            <TableCell sx={{ border: "1px solid #e0dada" }}>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                fullWidth
                value={editVendorOrder?.data?.volume}
                onWheel={(e) => e.target.blur()}
                onChange={(e) => {
                  const newVolume = e.target.value;
                  const remainingQuantity =
                    oldRemainingQuantity + (newVolume - previousVolume);
                  setEditVendorOrder({
                    ...editVendorOrder,
                    data: {
                      ...editVendorOrder.data,
                      volume: newVolume,
                      totalAmount:
                        e.target.value * editVendorOrder?.data?.finalPrice,
                      remainingQuantity: remainingQuantity,
                    },
                  });
                }}
              />
            </TableCell>

            {/* Volume Unit */}
            <TableCell sx={{ border: "1px solid #e0dada" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                select
                defaultValue={"KG"}
                value={editVendorOrder?.data?.volumeUnit}
                onChange={(e) =>
                  setEditVendorOrder({
                    ...editVendorOrder,
                    data: {
                      ...editVendorOrder.data,
                      volumeUnit: e.target.value,
                    },
                  })
                }
              >
                {units.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </TableCell>

            {/* Packaging Type */}
            <TableCell sx={{ border: "1px solid #e0dada" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                select
                value={editVendorOrder?.data?.packaging}
                onChange={(e) =>
                  setEditVendorOrder({
                    ...editVendorOrder,
                    data: {
                      ...editVendorOrder.data,
                      packaging: e.target.value,
                    },
                  })
                }
              >
                {packagingTypes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </TableCell>

            {/* Final Price */}
            <TableCell sx={{ border: "1px solid #e0dada" }}>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                fullWidth
                value={editVendorOrder?.data?.finalPrice}
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  setEditVendorOrder({
                    ...editVendorOrder,
                    data: {
                      ...editVendorOrder.data,
                      finalPrice: e.target.value,
                      totalAmount: e.target.value * editVendorOrder?.data?.volume,
                    },
                  })
                }
              />
            </TableCell>

            {/* Final Price Currency */}
            <TableCell sx={{ border: "1px solid #e0dada" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                select
                value={editVendorOrder?.data?.finalPriceCurrency}
                onChange={(e) =>
                  setEditVendorOrder({
                    ...editVendorOrder,
                    data: {
                      ...editVendorOrder.data,
                      finalPriceCurrency: e.target.value,
                    },
                  })
                }
              >
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </TableCell>

            {/* Total Amount */}
            <TableCell sx={{ border: "1px solid #e0dada" }}>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                fullWidth
                value={editVendorOrder?.data?.totalAmount}
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  setEditVendorOrder({
                    ...editVendorOrder,
                    data: {
                      ...editVendorOrder.data,
                      totalAmount: e.target.value,
                    },
                  })
                }
              />
            </TableCell>

            {/* Incoterm */}
            <TableCell sx={{ border: "1px solid #e0dada" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                select
                value={editVendorOrder?.data?.incoterm}
                onChange={(e) =>
                  setEditVendorOrder({
                    ...editVendorOrder,
                    data: {
                      ...editVendorOrder.data,
                      incoterm: e.target.value,
                    },
                  })
                }
              >
                {incoterms.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </TableCell>

            {/* Payment Term */}
            <TableCell sx={{ border: "1px solid #e0dada" }}>
              <Autocomplete
                options={paymentTerm}
                getOptionLabel={(option) => option?.label || ""}
                value={selectedPaymentTerm}
                onChange={(event, newValue) => {
                  setSelectedPaymentTerm(newValue);
                  setEditVendorOrder({
                    ...editVendorOrder,
                    data: {
                      ...editVendorOrder.data,
                      paymentTerm: newValue?.value || "",
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" size="small" />
                )}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Box sx={{ marginTop: "16px" }}></Box>

      <Table
  sx={{
    borderCollapse: "separate", // Allows borderRadius to work
    borderSpacing: "0", // Removes unwanted spacing between cells
    tableLayout: "fixed",
    width: "100%", // Ensures the table takes full width
  }}
>
  <TableHead>
    <TableRow>
      <TableCell
        sx={{
          width: "19%",
          border: "1px solid #e0dada",
          backgroundColor: "#d7e7fc",
          fontSize: "0.7rem",
          padding: "4px 8px",
          whiteSpace: "nowrap",
          textAlign: "center",
        }}
      >
        Port Of Discharge
      </TableCell>
      <TableCell
        sx={{
          width: "19%",
          border: "1px solid #e0dada",
          backgroundColor: "#d7e7fc",
          fontSize: "0.7rem",
          padding: "4px 8px",
          whiteSpace: "nowrap",
          textAlign: "center",
        }}
      >
        Port Of Loading
      </TableCell>
      <TableCell
        sx={{
          width: "9%",
          border: "1px solid #e0dada",
          backgroundColor: "#d7e7fc",
          fontSize: "0.7rem",
          padding: "4px 8px",
          whiteSpace: "nowrap",
          textAlign: "center",
        }}
      >
        ETD
      </TableCell>
      <TableCell
        sx={{
          width: "9%",
          border: "1px solid #e0dada",
          backgroundColor: "#d7e7fc",
          fontSize: "0.7rem",
          padding: "4px 8px",
          whiteSpace: "nowrap",
          textAlign: "center",
        }}
      >
        ETA
      </TableCell>
      <TableCell
        sx={{
          width: "22%",
          border: "1px solid #e0dada",
          backgroundColor: "#d7e7fc",
          fontSize: "0.7rem",
          padding: "4px 8px",
          whiteSpace: "nowrap",
          textAlign: "center",
        }}
      >
        Shipment Schedule
      </TableCell>
      <TableCell
        sx={{
          width: "22%",
          border: "1px solid #e0dada",
          backgroundColor: "#d7e7fc",
          fontSize: "0.7rem",
          padding: "4px 8px",
          whiteSpace: "nowrap",
          textAlign: "center",
        }}
      >
        Shipping Term
      </TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
    <TableRow>
      {/* Port Of Discharge */}
      <TableCell sx={{ border: "1px solid #e0dada" }}>
        <Autocomplete
          options={portsOfDischarge}
          getOptionLabel={(option) => option?.label || ""}
          value={selectedPortOfDischarge}
          onChange={(event, newValue) => {
            setSelectedPortOfDischarge(newValue);
            setEditVendorOrder({
              ...editVendorOrder,
              data: {
                ...editVendorOrder.data,
                portOfDischarge: newValue?.value || "",
              },
            });
          }}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" size="small" fullWidth />
          )}
          renderTags={() => null}
        />
      </TableCell>

      {/* Port Of Loading */}
      <TableCell sx={{ border: "1px solid #e0dada" }}>
        <Autocomplete
          options={portsOfLoading}
          getOptionLabel={(option) => option?.label || ""}
          value={selectedPortOfLoading}
          onChange={(event, newValue) => {
            setSelectedPortOfLoading(newValue);
            setEditVendorOrder({
              ...editVendorOrder,
              data: {
                ...editVendorOrder.data,
                portOfLoading: newValue?.value || "",
              },
            });
          }}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" size="small" fullWidth />
          )}
          renderTags={() => null}
        />
      </TableCell>

      {/* ETD */}
      <TableCell sx={{ border: "1px solid #e0dada" }}>
        <TextField
          id="date"
          type="date"
          inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
          InputLabelProps={{ shrink: true }}
          onKeyDown={(e) => e.preventDefault()}
          variant="outlined"
          size="small"
          fullWidth
          value={
            editVendorOrder?.data?.estimatedTimeOfDeparture
              ? format(
                  parseISO(editVendorOrder.data.estimatedTimeOfDeparture),
                  "yyyy-MM-dd"
                )
              : ""
          }
          onChange={(e) =>
            setEditVendorOrder({
              ...editVendorOrder,
              data: {
                ...editVendorOrder.data,
                estimatedTimeOfDeparture: e.target.value
                  ? e.target.value + "T00:00:00.000+00:00"
                  : null,
              },
            })
          }
        />
      </TableCell>

      {/* ETA */}
      <TableCell sx={{ border: "1px solid #e0dada" }}>
        <TextField
          id="date"
          type="date"
          inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
          InputLabelProps={{ shrink: true }}
          onKeyDown={(e) => e.preventDefault()}
          variant="outlined"
          size="small"
          fullWidth
          value={
            editVendorOrder?.data?.estimatedTimeOfArrival
              ? format(
                  parseISO(editVendorOrder.data.estimatedTimeOfArrival),
                  "yyyy-MM-dd"
                )
              : ""
          }
          onChange={(e) =>
            setEditVendorOrder({
              ...editVendorOrder,
              data: {
                ...editVendorOrder.data,
                estimatedTimeOfArrival: e.target.value
                  ? e.target.value + "T00:00:00.000+00:00"
                  : null,
              },
            })
          }
        />
      </TableCell>

      {/* Shipment Schedule */}
      <TableCell sx={{ border: "1px solid #e0dada" }}>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          value={editVendorOrder?.data?.shipmentSchedule}
          onChange={(e) =>
            setEditVendorOrder({
              ...editVendorOrder,
              data: {
                ...editVendorOrder.data,
                shipmentSchedule: e.target.value,
              },
            })
          }
        />
      </TableCell>

      {/* Shipping Term */}
      <TableCell sx={{ border: "1px solid #e0dada" }}>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          value={editVendorOrder?.data?.shippingTerm}
          onChange={(e) =>
            setEditVendorOrder({
              ...editVendorOrder,
              data: {
                ...editVendorOrder.data,
                shippingTerm: e.target.value,
              },
            })
          }
        />
      </TableCell>
    </TableRow>
  </TableBody>
</Table>

<Box sx={{ marginTop: "16px" }}></Box>

<Table
 sx={{
  borderCollapse: "separate", // Allows borderRadius to work
  borderSpacing: "0", // Removes unwanted spacing between cells
  tableLayout: "fixed",
  width: "100%", // Ensures the table takes full width
}}
>
  <TableHead>
    <TableRow>
      <TableCell
        sx={{
          width: "27%",
          border: "1px solid #e0dada",
          backgroundColor: "#d7e7fc",
          fontSize: "0.7rem",
          padding: "4px 8px",
          whiteSpace: "nowrap",
          textAlign: "center",
        }}
      >
        Redrawn Sample
      </TableCell>
      <TableCell
        sx={{
          width: "11%",
          border: "1px solid #e0dada",
          backgroundColor: "#d7e7fc",
          fontSize: "0.7rem",
          padding: "4px 8px",
          whiteSpace: "nowrap",
          textAlign: "center",
        }}
      >
        Redrawn Sample Status
      </TableCell>
      <TableCell
        sx={{
          width: "31%",
          border: "1px solid #e0dada",
          backgroundColor: "#d7e7fc",
          fontSize: "0.7rem",
          padding: "4px 8px",
          whiteSpace: "nowrap",
          textAlign: "center",
        }}
      >
        Remark
      </TableCell>
      <TableCell
        sx={{
          width: "31%",
          border: "1px solid #e0dada",
          backgroundColor: "#d7e7fc",
          fontSize: "0.7rem",
          padding: "4px 8px",
          whiteSpace: "nowrap",
          textAlign: "center",
        }}
      >
        Comment
      </TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
    <TableRow>
      {/* Redrawn Sample */}
      <TableCell sx={{ border: "1px solid #e0dada" }}>
        <Autocomplete
          options={filteredSamples}
          getOptionLabel={(option) =>
            `ID: ${option?.id || ""}, Remark: ${
              option?.remark || ""
            }, Buying Grade: ${option?.item?.buyingGrade || ""}`
          }
          value={selectedFirstSample}
          onChange={(event, newValue) => {
            setSelectedFirstSample(newValue);
            setEditVendorOrder({
              ...editVendorOrder,
              data: {
                ...editVendorOrder.data,
                firstSample: newValue || {},
              },
            });
          }}
          onOpen={() => retrieveFilteredSamples("Redrawn Sample")}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              fullWidth
              label={`ID: ${selectedFirstSample?.id || ""}, Remark: ${
                selectedFirstSample?.remark || ""
              }, Buying Grade: ${
                selectedFirstSample?.item?.buyingGrade || ""
              }`}
            />
          )}
          renderTags={() => null}
        />
        {selectedFirstSample && (
          <Box mt={2}>
            <Chip
              label={`ID: ${selectedFirstSample?.id || ""}, Remark: ${
                selectedFirstSample?.remark || ""
              }, Buying Grade: ${
                selectedFirstSample?.item?.buyingGrade || ""
              }`}
              onDelete={() => {
                setSelectedFirstSample(null);
                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    firstSample: {},
                  },
                });
              }}
            />
          </Box>
        )}
      </TableCell>

      {/* Redrawn Sample Status */}
      <TableCell sx={{ border: "1px solid #e0dada" }}>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          select
          value={editVendorOrder?.data?.firstSampleStatus}
          onChange={(e) =>
            setEditVendorOrder({
              ...editVendorOrder,
              data: {
                ...editVendorOrder.data,
                firstSampleStatus: e.target.value,
              },
            })
          }
        >
          {sampleStatus.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </TableCell>

      {/* Remark */}
      <TableCell sx={{ border: "1px solid #e0dada" }}>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          value={editVendorOrder?.data?.remark}
          onChange={(e) =>
            setEditVendorOrder({
              ...editVendorOrder,
              data: {
                ...editVendorOrder.data,
                remark: e.target.value,
              },
            })
          }
        />
      </TableCell>

      {/* Comment */}
      <TableCell sx={{ border: "1px solid #e0dada" }}>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          value={editVendorOrder?.data?.comment}
          onChange={(e) =>
            setEditVendorOrder({
              ...editVendorOrder,
              data: {
                ...editVendorOrder.data,
                comment: e.target.value,
              },
            })
          }
        />
      </TableCell>
    </TableRow>
  </TableBody>
</Table>

    </TableContainer>
 


        </Grid>
        <Stack direction="row" spacing={1} mt={1}>
          <Button
            variant="outlined"
            onClick={() =>
              setEditVendorOrder({ visible: false, data: null, index: null })
            }
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={!editVendorOrder?.data?.operationDate}
            onClick={() => {
              let updatedOrderData = { ...editVendorOrder.data };

              if (updatedOrderData.remainingQuantity > 0) {
                updatedOrderData = {
                  ...updatedOrderData,
                  orderStatus: "Open",
                };
              } else if (updatedOrderData.remainingQuantity <= 0) {
                updatedOrderData = {
                  ...updatedOrderData,
                  orderStatus: "Closed",
                };
              }

              updateOrderApi(updatedOrderData.id, updatedOrderData)
                .then((response) => {
                  toast.success("Purchase order edited successfully.");
                  window.location.reload();
                  setEditVendorOrder({
                    visible: false,
                    data: null,
                    index: null,
                  });
                })
                .catch((error) => {
                  toast.error("Something went wrong.");
                  console.log(error.response.data);
                });
            }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              editVendorOrder.data.recordStatus = "PASSIVE";
              updateOrderApi(editVendorOrder.data.id, editVendorOrder.data)
                .then((response) => {
                  toast.success("Purchase order deleted successfully.");
                  window.location.reload();
                  setEditVendorOrder({
                    visible: false,
                    data: null,
                    index: null,
                  });
                })
                .catch((error) => {
                  toast.error("Something went wrong.");
                  console.log(error.response.data);
                });
            }}
          >
            Delete
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default EditVendorOrder;
