import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createShipmentRequestApi } from "../../../components/api/ShipmentRequestApiService";
import {
  currencies,
  incoterms,
  status,
  units,
  recordStatus,
  portsOfLoading,
  portsOfDischarge,
  shipmentRequestTypes,
  shipmentRequestStatus,
} from "../../../utils/menu-items";
import toast from "react-hot-toast";
import {
  retrieveAllOrdersApi,
  retrieveFilteredOrdersApi,
} from "../../../components/api/OrderApiService";
import ShipmentRequestView from "./ShipmentRequestView";
import order from "../../../menu-items/order";
import { retrieveQuantityByItemIdApi } from "../../../components/api/QuantityApiService";
import { format, parseISO } from "date-fns";
import { retrieveAllStakeholdersApi } from "../../../components/api/StakeholderApiService";
import { retrieveFilteredShippingInstructionsApi } from "../../../components/api/ShippingInstructionApiService";
import WarehouseInventoryButton from "./Dialogs/WarehouseInventoryButton";
import { retrieveFilteredWarehouseRecordsApi } from "../../../components/api/WarehouseApiService";

const AddShipmentRequest = (props) => {
  const { setAddViewStates, addViewStates, rows, setRows, setAddView } = props;

  useEffect(() => {
    setAddViewStates({
      ...addViewStates,
      orderType: "",
      shipmentQuantityUnit: "KG",
      approvalStatus: "Not Approved",
      shipmentEstimatedTimeOfDeparture: "",
    });
    console.log(addViewStates);
  }, []);

  const navigate = useNavigate();
  const [stakeholders, setStakeholders] = useState([]);
  const [selectedStakeholder, setSelectedStakeholder] = useState(null);

  const [shippingInstructions, setShippingInstructions] = useState([]);
  const [selectedSi, setSelectedSi] = useState(null);

  const [filterShippingInstruction, setFilterShippingInstruction] = useState({
    id: 0,
    shipmentRequestIdList: [],
    freightOfferIdList: [],
    purchaseOrderIdList: [],
    salesOrderIdList: [],
    transportationContact: "",
    cartonNumber: "",
    finalShippingInstructionNumber: "",
    shippedByList: [],
    billOfLadingNumber: "",
    cmrNumber: "",
    shipperIdList: [],
    consigneeIdList: [],
    notifyIdList: [],
    pointOfLoadingList: [],
    pointOfUnloadingList: [],
    description: "",
    remark: "",
    statusList: ["Closed"],
    recordStatusList: ["ACTIVE"],
  });

  const filterWarehouse = {
    recordStatusList: ["ACTIVE"],
  };

  function retrieveFilteredShippingInstructions(filterShippingInstruction) {
    retrieveFilteredShippingInstructionsApi(filterShippingInstruction)
      .then((response) => {
        setShippingInstructions(response?.data);
        return response?.data || [];
      })
      .catch((error) => console.log(error.response?.data));
  }

  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);

  function retrieveFilteredWarehouseRecords(filterWarehouse) {
    retrieveFilteredWarehouseRecordsApi(filterWarehouse)
      .then((response) => {
        setWarehouses(response?.data);
        return response?.data || [];
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveStakeholders() {
    Promise.all([
      retrieveAllStakeholdersApi("VENDOR"),
      retrieveAllStakeholdersApi("CUSTOMER"),
    ])
      .then(([vendorResponse, customerResponse]) => {
        setStakeholders([...vendorResponse?.data, ...customerResponse?.data]);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveOrders(orderType) {
    let filterOrder;
    if(["FWTW", "FWTC", "FWTIMP", "FWTINV", "FWTWCT"].includes(addViewStates?.shipmentRequestType)){
       filterOrder = {
        orderType: orderType,
        approvalStatusList: ["Approved"],
        recordStatusList: ["ACTIVE"],
      };
    }else{
       filterOrder = {
        orderType: orderType,
        remainingQuantity: 1,
        approvalStatusList: ["Approved"],
        recordStatusList: ["ACTIVE"],
      };
    }

    retrieveFilteredOrdersApi(filterOrder)
      .then((response) => {
        setOrders(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }
  function retrieveQuantity(itemId) {
    retrieveQuantityByItemIdApi(itemId)
      .then((response) => {
        console.log("QUANTITY: ", response?.data);
        setQuantity(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  const tpOptions = [
    {
      value: true,
      label: "Sales",
    },
    {
      value: false,
      label: "Inward",
    },
  ];

  function retrieveFilteredOrders(orderDto) {
    retrieveFilteredOrdersApi(orderDto)
      .then((response) => {
        setOrders(response?.data);
        return response?.data || [];
      })
      .catch((error) => console.log(error.response?.data));
  }

  const orderDto = {
    orderType: "",
    offerId: addViewStates?.offer?.id,
    offerIdList: [] || [],
    itemIdList: [],
    stakeholderIdList: [],
    costIdList: [],
    companyList: [],
    approvalStatusList: ["Approved"],
    orderStatusList: [],
    customerList: [],
    firstSampleIdList: [],
    secondSampleIdList: [],
    firstSampleStatusList: [],
    secondSampleStatusList: [],
    paymentTermList: [],
    incotermList: [],
    portOfLoadingList: [],
    portOfDischargeList: [],
    packagingList: [],
    volumeUnitList: [],
    firstPriceCurrencyList: [],
    finalPriceCurrencyList: [],
    documentStatusList: [],
    originList: [],
    typeList: [],
    leafFormList: [],
    colorList: [],
    storageList: [],
    recordStatusList: ["ACTIVE"],
  };

  const [fvSelected, setFvSelected] = useState(false);
  const [fwSelected, setFwSelected] = useState(false);
  const [tcSelected, setTcSelected] = useState(false);
  const [twSelected, setTwSelected] = useState(false);
  const [tpSelected, setTpSelected] = useState(false);
  const [ctSelected, setCtSelected] = useState(false);
  const [tpSales, setTpSales] = useState(false);
  const [orders, setOrders] = useState([]);
  const [quantity, setQuantity] = useState([]);
  const [selectedPo, setSelectedPo] = useState(null);
  const [selectedSo, setSelectedSo] = useState(null);

  useEffect(() => {
    if (selectedPo?.estimatedTimeOfDeparture) {
      if (
        addViewStates?.shipmentRequestType === "FVTW" ||
        addViewStates?.shipmentRequestType === "FWTW" ||
        addViewStates?.shipmentRequestType === "FVTWCT" ||
        addViewStates?.shipmentRequestType === "FWTWCT" ||
        addViewStates?.shipmentRequestType === "FWTINW" ||
        addViewStates?.shipmentRequestType === "FVTINW"
      ) {
        setAddViewStates({
          ...addViewStates,
          shipmentEstimatedTimeOfDeparture: format(
            parseISO(selectedPo.estimatedTimeOfDeparture),
            "yyyy-MM-dd",
          ),
        });
      }
    }
  }, [selectedPo]);

  // Event handler for checkbox selections
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (name === "fv") {
      setFvSelected(checked);
      setFwSelected(false);
      setSelectedSo(null);
      setSelectedPo(null);
      setSelectedSi(null);
      setCtSelected(null);
      setSelectedWarehouse(null);
      setSelectedStakeholder(selectedPo?.offer?.stakeholder || null);
      setAddViewStates({
        ...addViewStates,
        shipmentRequestType:
          "FV" +
          (addViewStates.shipmentRequestType
            ? addViewStates.shipmentRequestType.slice(2)
            : ""),
        shipmentRequestTo: selectedPo?.offer?.stakeholder?.name || "",
        portOfDischarge: "",
        portOfLoading: "",
        destination: "",
        warehouse: "",
      });
    } else if (name === "fw") {
      setFvSelected(false);
      setFwSelected(checked);
      setCtSelected(null);
      setSelectedSo(null);
      setSelectedPo(null);
      setSelectedSi(null);
      setSelectedWarehouse(null);
      setSelectedStakeholder(null);
      setAddViewStates({
        ...addViewStates,
        shipmentRequestType:
          "FW" +
          (addViewStates.shipmentRequestType
            ? addViewStates.shipmentRequestType.slice(2)
            : ""),
        shipmentRequestTo: "",
        portOfDischarge: "",
        portOfLoading: "",
        destination: "",
        warehouse: "",
      });
    }
    if (name === "ct") {
      setCtSelected(checked);
      let newShipmentRequestType = addViewStates?.shipmentRequestType;

  if (checked) {
    if (fvSelected) {
      newShipmentRequestType = "FVTWCT";
    } else if (fwSelected) {
      newShipmentRequestType = "FWTWCT";
    }
  } else {
    // Handle unchecking `ct`
    if (fvSelected) {
      newShipmentRequestType = "FVTW";
    } else if (fwSelected) {
      newShipmentRequestType = "FWTW";
    }
  }

  setAddViewStates({
    ...addViewStates,
    shipmentRequestType: newShipmentRequestType,
  });
    }

    if (name === "tc") {
      setTcSelected(checked);
      setTwSelected(false);
      setTpSelected(false);
      setTpSales(false);
      setSelectedSo(null);
      setSelectedPo(null);
      setSelectedSi(null);
      setCtSelected(null);
      setSelectedWarehouse(null);
      setSelectedStakeholder(null);
      if (fvSelected) {
        setAddViewStates({
          ...addViewStates,
          shipmentRequestType: "FVTC",
          shipmentRequestTo: "",
          portOfDischarge: "",
          portOfLoading: "",
          destination: "",
          warehouse: "",
          productionTobaccoCode: "",
        });
      } else if (fwSelected) {
        setAddViewStates({
          ...addViewStates,
          shipmentRequestType: "FWTC",
          shipmentRequestTo: "",
          portOfDischarge: "",
          portOfLoading: "",
          destination: "",
          warehouse: "",
          productionTobaccoCode: "",
        });
      } else {
        setAddViewStates({
          ...addViewStates,
          shipmentRequestType: "AATC",
          shipmentRequestTo: "",
          portOfDischarge: "",
          portOfLoading: "",
          destination: "",
          warehouse: "",
        });
      }
    } else if (name === "tw") {
      setTcSelected(false);
      setTwSelected(checked);
      setTpSelected(false);
      setTpSales(false);
      setSelectedSo(null);
      setSelectedPo(null);
      setSelectedSi(null);
      setCtSelected(null);
      setSelectedWarehouse(null);
      setSelectedStakeholder(null);
      if (fvSelected) {
        setAddViewStates({
          ...addViewStates,
          shipmentRequestType: "FVTW",
          shipmentRequestTo: "",
          portOfDischarge: "",
          portOfLoading: "",
          destination: "",
          warehouse: "",
        });
      } else if (fwSelected) {
        setAddViewStates({
          ...addViewStates,
          shipmentRequestType: "FWTW",
          shipmentRequestTo: "",
          portOfDischarge: "",
          portOfLoading: "",
          destination: "",
          warehouse: "",
        });
      } else {
        setAddViewStates({
          ...addViewStates,
          shipmentRequestType: "AATW",
          shipmentRequestTo: "",
          portOfDischarge: "",
          portOfLoading: "",
          destination: "",
          warehouse: "",
        });
      }
    } else if (name === "tp") {
      setTcSelected(false);
      setTwSelected(false);
      setTpSelected(checked);
      setTpSales(false);
      setSelectedSo(null);
      setSelectedPo(null);
      setSelectedSi(null);
      setCtSelected(null);
      setSelectedWarehouse(null);
      setSelectedStakeholder(null);
      if (fvSelected) {
        setAddViewStates({
          ...addViewStates,
          shipmentRequestType: "FVTP",
          shipmentRequestTo: "",
          portOfDischarge: "",
          portOfLoading: "",
          destination: "",
          warehouse: "",
        });
      } else if (fwSelected) {
        setAddViewStates({
          ...addViewStates,
          shipmentRequestType: "FWTP",
          shipmentRequestTo: "",
          portOfDischarge: "",
          portOfLoading: "",
          destination: "",
          warehouse: "",
        });
      } else {
        setAddViewStates({
          ...addViewStates,
          shipmentRequestType: "AATP",
          shipmentRequestTo: "",
          portOfDischarge: "",
          portOfLoading: "",
          destination: "",
          warehouse: "",
        });
      }
    }
  };

  return (
    <>
      <Stack direcion="column">
        <Typography variant="body1" mb={2}>
          Create New Shipment Request {addViewStates?.shipmentRequestType}
        </Typography>
        <Grid container xs={12} spacing="8px">
          <Grid item xs={12} sm={1.2}>
            <Typography variant="body1" mb={2}>
              From:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={1.5}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={fvSelected}
                  onChange={handleCheckboxChange}
                  name="fv"
                />
              }
              label="Vendor"
            />
          </Grid>
          <Grid item xs={12} sm={1.5}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={fwSelected}
                  onChange={handleCheckboxChange}
                  name="fw"
                />
              }
              label="Warehouse"
            />
          </Grid>
          <Grid item xs={12} sm={7.8}></Grid>

          <Grid item xs={12} sm={1.2}>
            <Typography variant="body1" mb={2}>
              To:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={1.5}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={tcSelected}
                  onChange={handleCheckboxChange}
                  name="tc"
                />
              }
              label="Customer"
            />
          </Grid>
          <Grid item xs={12} sm={1.5}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={twSelected}
                  onChange={handleCheckboxChange}
                  name="tw"
                />
              }
              label="Warehouse"
            />
          </Grid>
          <Grid item xs={12} sm={1.5}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={tpSelected}
                  onChange={handleCheckboxChange}
                  name="tp"
                />
              }
              label="Production"
            />
          </Grid>
          <Grid item xs={12} sm={6.3}></Grid>

          {twSelected && (
          <Grid item xs={12} sm={1.5}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={ctSelected}
                  onChange={handleCheckboxChange}
                  name="ct"
                />
              }
              label="CrossTrades"
            />
          </Grid>)}

          {tpSelected && (
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Production Type</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                id="outlined-select-incoterm"
                select
                onChange={(e) => {
                  const newValue = e.target.value;
                  setTpSales(newValue);
                  if (fvSelected) {
                    setAddViewStates({
                      ...addViewStates,
                      shipmentRequestType: newValue ? "FVTIMP" : "FVTINW",
                    });
                  } else if (fwSelected) {
                    setAddViewStates({
                      ...addViewStates,
                      shipmentRequestType: newValue ? "FWTIMP" : "FWTINW",
                    });
                  } else {
                    setAddViewStates({
                      ...addViewStates,
                      shipmentRequestType: newValue ? "AATIMP" : "AATINW",
                    });
                  }
                }}
              >
                {tpOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
          <Grid item xs={12} sm={11}></Grid>
          {(tcSelected || tpSelected || twSelected) && (
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Select Purchase Order</Typography>
              <Autocomplete
                options={orders || []}
                getOptionLabel={(option) =>
                  `ID: ${option?.id || ""}, SAP PO Number: ${
                    option?.purchaseOrderNumber || ""
                  }, Vendor: ${option?.stakeholder?.name || ""}`
                }
                value={selectedPo}
                onChange={(event, newValue) => {
                  setSelectedPo(newValue);
                  setSelectedStakeholder(newValue?.offer?.stakeholder || null);
                  setSelectedSi(null);
                  setFilterShippingInstruction({
                    ...filterShippingInstruction,
                    purchaseOrderIdList: [newValue ? newValue.id : ""],
                  });
                  setAddViewStates({
                    ...addViewStates,
                    purchaseOrderId: newValue ? newValue.id : "",
                    shipmentRequestTo: newValue
                      ? newValue?.offer?.stakeholder?.name
                      : "",
                    productionTobaccoCode: newValue
                      ? newValue?.offer?.item?.tobaccoCode
                      : "",
                  });
                  retrieveQuantity(newValue?.offer?.item?.id);
                }}
                onOpen={() => {
                  if (selectedSo) {
                    orderDto.orderType = "VENDOR";
                    orderDto.itemIdList = [selectedSo?.offer?.item?.id];
                    retrieveFilteredOrders(orderDto);
                  } else {
                    retrieveOrders("VENDOR");
                  }
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                )}
                renderTags={() => null}
              />
              {selectedPo && (
                <Box mt={2}>
                  <Chip
                    label={`ID: ${selectedPo?.id || ""}`}
                    onDelete={() => {
                      setSelectedPo(null);
                      setAddViewStates({
                        ...addViewStates,
                        purchaseOrderId: "",
                      });
                    }}
                    style={{ marginBottom: "5px" }}
                  />{" "}
                  <br></br>
                  <Chip
                    label={`SAP PO Number: ${
                      selectedPo?.purchaseOrderNumber || ""
                    }`}
                    style={{ marginBottom: "5px" }}
                  />
                  <br></br>
                  <Chip
                    label={`Quantity: ${selectedPo?.volume || ""} ${
                      selectedPo?.volumeUnit || ""
                    }`}
                    style={{ marginBottom: "5px" }}
                  />
                  <br></br>
                  <Chip
                    label={`Supplier: ${
                      selectedPo?.offer?.stakeholder?.name || ""
                    }`}
                    style={{ marginBottom: "5px" }}
                  />
                  <br></br>
                  <Chip
                    label={`Item ID: ${selectedPo?.offer?.item?.id || ""}`}
                    style={{ marginBottom: "5px" }}
                  />
                  <br></br>
                  <Chip
                    label={`ETD: ${
                      selectedPo?.estimatedTimeOfDeparture
                        ? format(
                            parseISO(selectedPo?.estimatedTimeOfDeparture),
                            "yyyy-MM-dd",
                          )
                        : ""
                    }`}
                    style={{ marginBottom: "5px" }}
                  />
                  <br></br>
                  <Chip
                    label={`Unshipped Quantity: ${
                      selectedPo?.remainingQuantity
                        ? `${new Intl.NumberFormat("de-DE", {
                            style: "decimal",
                            minimumFractionDigits: 2,
                          }).format(selectedPo.remainingQuantity)} ${
                            selectedPo?.volumeUnit || ""
                          }`
                        : ""
                    }`}
                    style={{ marginBottom: "5px" }}
                  />
                  <br></br>
                </Box>
              )}
            </Grid>
          )}

          {fwSelected && (tcSelected || twSelected || tpSelected) && (
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Select SI</Typography>
              <Autocomplete
                options={shippingInstructions || []}
                getOptionLabel={(option) =>
                  `ID: ${option?.id || ""}, SI Number: ${
                    option?.finalShippingInstructionNumber || ""
                  }, SR : ${
                    option?.shipmentRequest?.shipmentRequestType || ""
                  }, SR Number: ${option?.shipmentRequest?.id || ""}, Date: ${
                    option?.stakeholder?.name || ""
                  }, POD: ${option?.pointOfUnloading || ""}`
                }
                value={selectedSi}
                onChange={(event, newValue) => {
                  setSelectedSi(newValue);
                  setAddViewStates({
                    ...addViewStates,
                    referenceShippingInstructionId: newValue
                    ? newValue.id
                    : "",
                    referenceShippingInstructionNumber: newValue
                      ? newValue.finalShippingInstructionNumber
                      : "",
                    warehouse: newValue ? newValue?.destination : "",
                  });
                }}
                onOpen={() =>
                  retrieveFilteredShippingInstructions(
                    filterShippingInstruction,
                  )
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                )}
                renderTags={() => null}
                disabled={!selectedPo} 
              />
              {selectedSi && (
                <Box mt={2}>
                  <Chip
                    label={`ID: ${selectedSi?.id || ""}`}
                    onDelete={() => {
                      setSelectedSi(null);
                      setAddViewStates({
                        ...addViewStates,
                        referenceShippingInstructionNumber: "",
                        referenceShippingInstructionId: null,
                        warehouse: "",
                      });
                    }}
                  />
                </Box>
              )}
            </Grid>
          )}
          {fwSelected &&
            (tcSelected || twSelected || tpSelected) &&
            selectedSi && (
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Warehouse</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled
                  id="outlined-select-pol"
                  value={selectedSi?.destination}
                />
              </Grid>
            )}
          {(tcSelected || (tpSelected && tpSales) || (twSelected && ctSelected)) && (
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Select Sales Order</Typography>
              <Autocomplete
                options={orders || []}
                getOptionLabel={(option) =>
                  `ID: ${option?.id || ""}, SAP SO Number: ${
                    option?.salesOrderNumber || ""
                  }, Customer: ${option?.stakeholder?.name || ""}`
                }
                value={selectedSo}
                onChange={(event, newValue) => {
                  setSelectedSo(newValue);
                  setAddViewStates({
                    ...addViewStates,
                    salesOrderId: newValue ? newValue.id : "",
                    destination: `${newValue?.incoterm || ""} ${
                      newValue?.portOfDischarge || ""
                    }`,
                    customerGradeMark: newValue
                      ? newValue?.offer?.salesGrade
                      : "",
                    productionTobaccoCode: newValue
                      ? newValue?.offer?.item?.tobaccoCode
                      : "",
                  });
                  retrieveQuantity(newValue?.offer?.item?.id);
                }}
                onOpen={() => {
                  if (selectedPo) {
                    orderDto.orderType = "CUSTOMER";
                    orderDto.itemIdList = [selectedPo?.offer?.item?.id];
                    retrieveFilteredOrders(orderDto);
                  } else {
                    retrieveOrders("CUSTOMER");
                  }
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                )}
                renderTags={() => null}
              />
              {selectedSo && (
                <Box mt={2}>
                  <Chip
                    label={`ID: ${selectedSo?.id || ""}`}
                    onDelete={() => {
                      setSelectedSo(null);
                      setAddViewStates({ ...addViewStates, salesOrderId: "" });
                    }}
                    style={{ marginBottom: "5px" }}
                  />{" "}
                  <br></br>
                  <Chip
                    label={`SAP SO Number: ${
                      selectedSo?.salesOrderNumber || ""
                    }`}
                    style={{ marginBottom: "5px" }}
                  />
                  <br></br>
                  <Chip
                    label={`Quantity: ${selectedSo?.volume || ""} ${
                      selectedSo?.volumeUnit || ""
                    }`}
                    style={{ marginBottom: "5px" }}
                  />
                  <br></br>
                  <Chip
                    label={`Redrawn Status: ${
                      selectedSo?.secondSampleStatus || ""
                    }`}
                    style={{ marginBottom: "5px" }}
                  />
                  <br></br>
                  <Chip
                    label={`Customer: ${
                      selectedSo?.offer?.stakeholder?.name || ""
                    }`}
                    style={{ marginBottom: "5px" }}
                  />
                  <br></br>
                  <Chip
                    label={`Customer Grade Mark: ${
                      selectedSo?.offer?.salesGrade || ""
                    }`}
                    style={{ marginBottom: "5px" }}
                  />
                  <br></br>
                  <Chip
                    label={`Item ID: ${selectedSo?.offer?.item?.id || ""}`}
                    style={{ marginBottom: "5px" }}
                  />
                  <br></br>
                  <Chip
                    label={`ETD: ${
                      selectedSo?.estimatedTimeOfDeparture
                        ? format(
                            parseISO(selectedSo?.estimatedTimeOfDeparture),
                            "yyyy-MM-dd",
                          )
                        : ""
                    }`}
                    style={{ marginBottom: "5px" }}
                  />
                  <br></br>
                  <Chip
                    label={`Unshipped Quantity: ${
                      selectedSo?.remainingQuantity
                        ? `${new Intl.NumberFormat("de-DE", {
                            style: "decimal",
                            minimumFractionDigits: 2,
                          }).format(selectedSo.remainingQuantity)} ${
                            selectedSo?.volumeUnit || ""
                          }`
                        : ""
                    }`}
                    style={{ marginBottom: "5px" }}
                  />
                  <br></br>
                </Box>
              )}
            </Grid>
          )}
          {(selectedSo || selectedPo) && (
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Item</Typography>
              <TextField
                disabled
                type="number"
                variant="outlined"
                size="small"
                fullWidth
                value={
                  selectedSo?.offer?.item?.id ||
                  selectedPo?.offer?.item?.id ||
                  ""
                }
              />
              {(selectedSo || selectedPo) && (
                <Box mt={2}>
                  <Chip
                    label={`ID: ${
                      selectedSo?.offer?.item?.id ||
                      selectedPo?.offer?.item?.id ||
                      ""
                    }`}
                    style={{ marginBottom: "5px" }}
                  />{" "}
                  <br></br>
                  <Chip
                    label={`Origin: ${
                      selectedSo?.offer?.item?.origin ||
                      selectedPo?.offer?.item?.origin ||
                      ""
                    }`}
                    style={{ marginBottom: "5px" }}
                  />
                  <br></br>
                  <Chip
                    label={`Type: ${
                      selectedSo?.offer?.item?.type ||
                      selectedPo?.offer?.item?.type ||
                      ""
                    }`}
                    style={{ marginBottom: "5px" }}
                  />
                  <br></br>
                  <Chip
                    label={`Buying Grade: ${
                      selectedSo?.offer?.item?.buyingGrade ||
                      selectedPo?.offer?.item?.buyingGrade ||
                      ""
                    }`}
                    style={{ marginBottom: "5px" }}
                  />
                  <br></br>
                  <Chip
                    label={`Sales Grade: ${
                      selectedSo?.offer?.item?.salesGrade ||
                      selectedPo?.offer?.item?.salesGrade ||
                      ""
                    }`}
                    style={{ marginBottom: "5px" }}
                  />
                  <br></br>
                  <Chip
                    label={`Crop: ${
                      selectedSo?.offer?.item?.crop ||
                      selectedPo?.offer?.item?.crop ||
                      ""
                    }`}
                    style={{ marginBottom: "5px" }}
                  />
                  <br></br>
                  <Chip
                    label={`Storage: ${
                      selectedSo?.offer?.item?.storage ||
                      selectedPo?.offer?.item?.storage ||
                      ""
                    }`}
                    style={{ marginBottom: "5px" }}
                  />
                  <br></br>
                  <Chip
                    label={`Remaining Quantity: ${
                      quantity?.quantityRemainingInNetKg || ""
                    } KG`}
                    style={{ marginBottom: "5px" }}
                  />
                  <br></br>
                </Box>
              )}
            </Grid>
          )}
          {(tcSelected || tpSelected || twSelected) && (
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">SI To</Typography>
              <Autocomplete
                options={stakeholders}
                getOptionLabel={(option) =>
                  `${option.id || ""} - ${option.type || ""} - ${
                    option.name || ""
                  }`
                }
                value={selectedStakeholder}
                onChange={(event, newValue) => {
                  setSelectedStakeholder(newValue);
                  setAddViewStates({
                    ...addViewStates,
                    shipmentRequestTo: newValue ? newValue?.name : null,
                  });
                }}
                onOpen={retrieveStakeholders}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled={
                      addViewStates?.shipmentRequestType === "FVTC" ||
                      addViewStates?.shipmentRequestType === "FVTW" ||
                      addViewStates?.shipmentRequestType === "FVTWCT" ||
                      addViewStates?.shipmentRequestType === "FVTP" ||
                      addViewStates?.shipmentRequestType === "FVTIMP" ||
                      addViewStates?.shipmentRequestType === "FVTINW"
                    }
                    value={selectedStakeholder?.name || ""}
                  />
                )}
                renderTags={() => null}
              />
              {selectedStakeholder && (
                <Box mt={2}>
                  <Chip
                    label={`ID: ${selectedStakeholder?.id || ""}`}
                    style={{ marginBottom: "5px" }}
                    onDelete={() => setSelectedStakeholder(null)}
                  />
                  <br />
                  <Chip
                    label={`Type: ${selectedStakeholder?.type || ""}`}
                    style={{ marginBottom: "5px" }}
                  />
                  <br />
                  <Chip
                    label={`Name: ${selectedStakeholder?.name || ""}`}
                    style={{ marginBottom: "5px" }}
                  />
                  <br />
                </Box>
              )}
            </Grid>
          )}

          {tpSelected && (
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Production Tobacco Code</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                value={addViewStates?.productionTobaccoCode || ""}
                onChange={(e) => {
                  setAddViewStates({
                    ...addViewStates,
                    productionTobaccoCode: e.target.value,
                  });
                }}
              />
            </Grid>
          )}

          {(tcSelected || tpSelected || twSelected) && (
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Customer Grade Mark</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                value={addViewStates?.customerGradeMark || ""}
                onChange={(e) => {
                  setAddViewStates({
                    ...addViewStates,
                    customerGradeMark: e.target.value,
                  });
                }}
              />
            </Grid>
          )}
          {tcSelected && (
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Destination</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                value={addViewStates?.destination || ""}
                onChange={(e) => {
                  setAddViewStates({
                    ...addViewStates,
                    destination: e.target.value,
                  });
                }}
              />
            </Grid>
          )}

          {(tpSelected || twSelected) && (
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Destination</Typography>
              <Autocomplete
                options={warehouses || []}
                getOptionLabel={(option) =>
                  `ID: ${option?.id || ""}, Code: ${
                    option?.code || ""
                  }, Name: ${option?.name || ""}`
                }
                value={selectedWarehouse}
                onChange={(event, newValue) => {
                  setSelectedWarehouse(newValue);
                  setAddViewStates({
                    ...addViewStates,
                    destination: newValue ? newValue?.name : "",
                  });
                }}
                onOpen={() => retrieveFilteredWarehouseRecords(filterWarehouse)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                )}
                renderTags={() => null}
              />
              {selectedWarehouse && (
                <Box mt={2}>
                  <Chip
                    label={`ID: ${selectedWarehouse?.id || ""}`}
                    onDelete={() => {
                      setSelectedWarehouse(null);
                      setAddViewStates({ ...addViewStates, destination: "" });
                    }}
                    style={{ marginBottom: "5px" }}
                  />
                  <br></br>
                  <Chip
                    label={`Code: ${selectedWarehouse?.code || ""}`}
                    style={{ marginBottom: "5px" }}
                  />
                  <br />
                  <Chip
                    label={`Name: ${selectedWarehouse?.name || ""}`}
                    style={{ marginBottom: "5px" }}
                  />
                  <br />
                  <Chip
                    label={`Contact: ${
                      selectedWarehouse?.contactPerson?.contactPerson || ""
                    }`}
                    style={{ marginBottom: "5px" }}
                  />
                  <br />
                  <Chip
                    label={`Storage Pricing By ${
                      selectedWarehouse?.storagePricingBy || ""
                    }`}
                    style={{ marginBottom: "5px" }}
                  />
                  <br />
                </Box>
              )}
            </Grid>
          )}

          {(tpSelected || twSelected) && (
            <Grid item xs={12} sm={2}>
              <WarehouseInventoryButton
                warehouse={addViewStates?.destination}
                itemIdList={[
                  selectedPo?.offer?.item?.id || 0,
                  selectedSo?.offer?.item?.id || 0,
                ]}
              />
            </Grid>
          )}

          {(tcSelected || tpSelected || twSelected) && (
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Port Of Loading</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                id="outlined-select-pol"
                select
                label={addViewStates?.portOfLoading || ""}
                value={addViewStates?.portOfLoading || ""}
                onChange={(e) =>
                  setAddViewStates({
                    ...addViewStates,
                    portOfLoading: e.target.value,
                  })
                }
              >
                {portsOfLoading.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}

          {(tcSelected || tpSelected || twSelected) && (
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Port Of Discharge</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                id="outlined-select-pod"
                select
                label={addViewStates?.portOfDischarge || ""}
                value={addViewStates?.portOfDischarge || ""}
                onChange={(e) => {
                  const updatedState = {
                    ...addViewStates,
                    portOfDischarge: e.target.value,
                  };
                  if (tcSelected) {
                    updatedState.destination = `${selectedSo?.incoterm || ""} ${
                      e.target.value || ""
                    }`;
                  }
                  setAddViewStates(updatedState);
                }}
              >
                {portsOfDischarge.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}

          {(tcSelected || tpSelected || twSelected) && (
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Number Of Cartons</Typography>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                fullWidth
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  setAddViewStates({
                    ...addViewStates,
                    numberOfCases: e.target.value,
                    shipmentQuantity:
                      e.target.value * addViewStates?.netWeightPerCase,
                  })
                }
              />
            </Grid>
          )}

          {(tcSelected || tpSelected || twSelected) && (
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Net Weight Per Carton</Typography>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                fullWidth
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  setAddViewStates({
                    ...addViewStates,
                    netWeightPerCase: e.target.value,
                    shipmentQuantity:
                      e.target.value * addViewStates?.numberOfCases,
                  })
                }
              />
            </Grid>
          )}

          {(tcSelected || tpSelected || twSelected) && (
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Shipment Quantity</Typography>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                fullWidth
                value={addViewStates?.shipmentQuantity}
                onWheel={(e) => e.target.blur()}
                onChange={(e) => {
                  if (e.target.value > selectedSo?.volume) {
                    toast.error(
                      "Shipment quantity cannot be greater than SO Quantity!",
                    );
                  } else {
                    setAddViewStates({
                      ...addViewStates,
                      shipmentQuantity: e.target.value,
                    });
                  }
                }}
              />
            </Grid>
          )}
          {(tcSelected || tpSelected || twSelected) && (
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Unit</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                id="outlined-select-shipment-quantity-unit"
                defaultValue={"KG"}
                select
                onChange={(e) =>
                  setAddViewStates({
                    ...addViewStates,
                    shipmentQuantityUnit: e.target.value,
                  })
                }
              >
                {units.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}

          {(tcSelected || tpSelected || twSelected) && (
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Cartons Per Container</Typography>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                fullWidth
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  setAddViewStates({
                    ...addViewStates,
                    cartonsPerContainer: e.target.value,
                  })
                }
              />
            </Grid>
          )}
          {(tcSelected || tpSelected || twSelected) && (
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Shipment ETD</Typography>
              <TextField
                size="small"
                fullWidth
                variant="outlined"
                id="date"
                type="date"
                inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                value={
                  addViewStates?.shipmentEstimatedTimeOfDeparture
                    ? format(
                        parseISO(
                          addViewStates.shipmentEstimatedTimeOfDeparture,
                        ),
                        "yyyy-MM-dd",
                      )
                    : ""
                }
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) =>
                  setAddViewStates({
                    ...addViewStates,
                    shipmentEstimatedTimeOfDeparture: e.target.value,
                  })
                }
              />
            </Grid>
          )}
          {(tcSelected || tpSelected || twSelected) && (
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Approval Status</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                id="outlined-select-approval-status"
                defaultValue={"Not Approved"}
              ></TextField>
            </Grid>
          )}

          {(tcSelected || tpSelected || twSelected) && (
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Loading Remark</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                onChange={(e) =>
                  setAddViewStates({
                    ...addViewStates,
                    loadingRemark: e.target.value,
                  })
                }
              />
            </Grid>
          )}
          {(tcSelected || tpSelected || twSelected) && (
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Remark</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                onChange={(e) =>
                  setAddViewStates({
                    ...addViewStates,
                    remark: e.target.value,
                  })
                }
              />
            </Grid>
          )}
          {(tcSelected || tpSelected || twSelected) && (
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Status</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                id="outlined-select-status"
                select
                onChange={(e) =>
                  setAddViewStates({
                    ...addViewStates,
                    status: e.target.value,
                  })
                }
              >
                {shipmentRequestStatus.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
          {/*  {(tcSelected || (tpSelected) || twSelected) && (
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Payment Status</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                id="outlined-select-payment-status"
                select
                onChange={(e) =>
                  setAddViewStates({
                    ...addViewStates,
                    paymentStatus: e.target.value,
                  })
                }
              >
                {status.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            )}
            */}
          {(tcSelected || tpSelected || twSelected) && (
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Record Status</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                id="outlined-select-record-status"
                select
                onChange={(e) =>
                  setAddViewStates({
                    ...addViewStates,
                    recordStatus: e.target.value,
                  })
                }
              >
                {recordStatus.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
        </Grid>
        <Stack direction="row" spacing={1} mt={1}>
          <Button variant="outlined" onClick={() => setAddView(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={async () => {
              if (
                /^FW.*/.test(addViewStates?.shipmentRequestType) &&
                quantity?.quantityRemainingInWarehouseInNetKg -
                  addViewStates?.shipmentQuantity <
                  0
              ) {
                alert(`
        Selected item's:

        Remaining quantity in vendor: ${
          quantity?.quantityRemainingInVendorInNetKg || "N/A"
        } KG
        Remaining quantity in warehouse: ${
          quantity?.quantityRemainingInWarehouseInNetKg || "N/A"
        } KG
        Remaining quantity in production: ${
          quantity?.quantityRemainingInProductionInNetKg || "N/A"
        } KG
        Remaining net quantity: ${quantity?.quantityRemainingInNetKg} KG

        Shipment request could not be created due to missing quantity in warehouse.
      `);
                return;
              } else if (
                /^FV.*/.test(addViewStates?.shipmentRequestType) &&
                quantity?.quantityRemainingInVendorInNetKg -
                  addViewStates?.shipmentQuantity <
                  0
              ) {
                alert(`
        Selected item's:

        Remaining quantity in vendor: ${
          quantity?.quantityRemainingInVendorInNetKg || "N/A"
        } KG
        Remaining quantity in warehouse: ${
          quantity?.quantityRemainingInWarehouseInNetKg || "N/A"
        } KG
        Remaining quantity in production: ${
          quantity?.quantityRemainingInProductionInNetKg || "N/A"
        } KG
        Remaining net quantity: ${quantity?.quantityRemainingInNetKg} KG

        Shipment request could not be created due to missing quantity.
      `);
                return;
              }

              try {
                const newId = Math.floor(Math.random() * 100000);
                setAddViewStates({ ...addViewStates, id: newId });
                setRows([addViewStates, ...rows]);
                setAddView(false);

                await createShipmentRequestApi(addViewStates);
                toast.success("ShipmentRequest added successfully.");

                window.location.reload();
                navigate("/shipment-request-view");
              } catch (error) {
                console.error(error?.response?.data || error);
                toast.error("Something went wrong. Please try again.");
              }
            }}
            disabled={
              (!fvSelected && !fwSelected) ||
              (!tcSelected && !twSelected && !tpSelected) ||
              (tpSelected &&
                !["FVTIMP", "FVTINW", "FWTIMP", "FWTINW"].includes(
                  addViewStates?.shipmentRequestType,
                )) || !selectedPo
            }
            style={
              (!fvSelected && !fwSelected) ||
              (!tcSelected && !twSelected && !tpSelected)
                ? { whiteSpace: "nowrap" }
                : {}
            }
          >
            Add
          </Button>
        </Stack>
      </Stack>
      <br></br>
      {!fvSelected && !fwSelected && (
        <Typography variant="body2" color="error">
          Please select one of 'From Vendor', or 'From Warehouse'.
        </Typography>
      )}
      {!tcSelected && !twSelected && !tpSelected && (
        <Typography variant="body2" color="error">
          Please select one of 'To Customer', 'To Warehouse', or 'To
          Production'.
        </Typography>
      )}
      {!selectedPo && (
        <Typography variant="body2" color="error">
          Please select a PO.
        </Typography>
      )}
      {tpSelected &&
        addViewStates.shipmentRequestType !== "FVTIMP" &&
        addViewStates.shipmentRequestType !== "FVTINW" &&
        addViewStates.shipmentRequestType !== "FWTIMP" &&
        addViewStates.shipmentRequestType !== "FWTINW" && (
          <Typography variant="body2" color="error">
            Please select a production type.
          </Typography>
        )}
    </>
  );
};

export default AddShipmentRequest;
