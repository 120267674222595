import {
  Autocomplete,
  Button,
  Chip,
  Grid,
  MenuItem,
  MenuList,
  ButtonGroup,
  Popper,
  Stack,
  TextField,
  Typography,
  Paper,
  ClickAwayListener,
  Grow,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createSampleApi } from "../../../components/api/SampleApiService";
import SampleDialog from "./Dialogs/SampleDialog";
import {
  color,
  leafForm,
  origin,
  recordStatus,
  samplePurposes,
  type,
 } from "../../../utils/menu-items";
import { createItemApi, retrieveAllItemsApi, retrieveItemByIdApi, updateItemApi } from "../../../components/api/ItemApiService";
import { retrieveAllStakeholdersApi } from "../../../components/api/StakeholderApiService";
import Box from "@mui/material/Box";
import toast from "react-hot-toast";
import { retrieveQuantityByItemIdApi } from "../../../components/api/QuantityApiService";

const samples = [
  { id: 152456, label: "PT Namora" },
  { id: 5856, label: "ULT Dubai/Mr Lin" },
  { id: 741856, label: "Khatoco" },
  { id: 12854, label: "Wan Ting" },
];

const AddSample = (props) => {
  const navigate = useNavigate();
  const { setAddViewStates, addViewStates, rows, setRows, setAddView } = props;
  const [sampleOpt, setSampleOpt] = useState(samples);
  const [openSampleDialog, setOpenSampleDialog] = useState(false);
  const [item, setItem] = useState([]);
  const [items, setItems] = useState([]);
  const [stakeholders, setStakeholders] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const anchorRef = React.useRef(null);
  const options = ["New", "Edit"];
  const [open, setOpen] = useState(false);
  const [quantity, setQuantity] = useState([]);
  const [isNewItemOpen, setIsNewItemOpen] = useState(false);
  const [isEditItemOpen, setIsEditItemOpen] = useState(false);
  const [selectedOrigin, setSelectedOrigin] = useState("");
  const [selectedSender, setSelectedSender] = useState(
    addViewStates?.sender || "",
  );
  const [selectedReceiver, setSelectedReceiver] = useState(
    addViewStates?.receiver || "",
  );
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClick = () => {
    if (selectedIndex === 0) {
      setIsNewItemOpen(true);
      setIsEditItemOpen(false);
    } else {
      setIsEditItemOpen(true);
      setIsNewItemOpen(false);
      retrieveItemById(addViewStates?.itemId);
    }
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };
  function retrieveQuantity(itemId) {
    retrieveQuantityByItemIdApi(itemId)
      .then((response) => {
        console.log("QUANTITY: ",response?.data)
        setQuantity(response?.data)
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveItemById(id) {
    retrieveItemByIdApi(id)
      .then((response) => {
        setItem(response?.data);
        console.log(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }
  function retrieveItems() {
    retrieveAllItemsApi()
      .then((response) => {
        setItems(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }
  function retrieveStakeholders() {
    Promise.all([
      retrieveAllStakeholdersApi("VENDOR"),
      retrieveAllStakeholdersApi("CUSTOMER")
    ])
      .then(([vendorResponse, customerResponse]) => {
        setStakeholders([
          ...vendorResponse?.data,
          ...customerResponse?.data
        ]);
      })
      .catch((error) => console.log(error.response?.data));
  }

  return (
    <>
      <Stack direcion="column">
        <Typography variant="body1" mb={2}>
          Create New Sample
        </Typography>
        <Grid container xs={12} spacing="8px">
        <Grid item xs={12} sm={2}>
            <Typography variant="body2">Sample Purpose</Typography>
            <TextField
              id="outlined-select-status"
              select
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  purpose: e.target.value,
                })
              }
            >
              {samplePurposes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={2}>
  <Typography variant="body2">Select An Item</Typography>
  <Grid container xs={12} spacing="8px">
    <Grid item xs={12} sm={8}>
      <Autocomplete
        options={items}
        getOptionLabel={(option) =>
          `${option?.id || ""} - Buying Grade: ${option?.buyingGrade || ""} - ${option?.origin || ""} - ${option?.crop || ""}`
        }
        value={selectedItem}
        onChange={(event, newValue) => {
          setSelectedItem(newValue);
          setSelectedOrigin(newValue ? newValue?.origin : "")
          setAddViewStates({
            ...addViewStates,
            itemId: newValue ? newValue.id : null,
            salesGrade: newValue ? newValue?.buyingGrade : null,
            salesNicotine: newValue ? newValue?.nicotine : null,
            salesSugar: newValue ? newValue?.sugar : null,
            salesOrigin: newValue ? newValue?.origin : null,
            salesCrop: newValue ? newValue?.crop : null,
          });
          retrieveQuantity(newValue?.id);
        }}
        onOpen={retrieveItems}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            fullWidth
          />
        )}
        renderTags={() => null}
      />
      {selectedItem && (
        <Box mt={2}>
          <Chip
            label={`ID: ${selectedItem?.id || ""}`}
            style={{ marginBottom: '5px' }}
            onDelete={() => setSelectedItem(null)}
          /><br />
         <Chip
                     label={`Origin: ${selectedItem?.origin || ""}`}
                     style={{ marginBottom: '5px' }}
                   /><br></br>
                   <Chip
                     label={`Type: ${selectedItem?.type || ""}`}
                     style={{ marginBottom: '5px' }}
                   /><br></br>
                   <Chip
                     label={`Buying Grade: ${selectedItem?.buyingGrade || ""}`}
                     style={{ marginBottom: '5px' }}
                   /><br></br>
                   <Chip
                     label={`Sales Grade: ${selectedItem?.salesGrade || ""}`}
                     style={{ marginBottom: '5px' }}
                   /><br></br>
                   <Chip
                     label={`Crop: ${selectedItem?.crop || ""}`}
                     style={{ marginBottom: '5px' }}
                   /><br></br>
                    <Chip
                     label={`Nicotine: ${selectedItem?.nicotine || ""}`}
                     style={{ marginBottom: '5px' }}
                   /><br></br>
                    <Chip
                     label={`Sugar: ${selectedItem?.sugar || ""}`}
                     style={{ marginBottom: '5px' }}
                   /><br></br>
                   <Chip
                     label={`Storage: ${selectedItem?.storage || ""}`}
                     style={{ marginBottom: '5px' }}
                   /><br></br>
                    <Chip
                     label={`Quantity: ${quantity?.quantityRemainingInNetKg || ""} KG`}
                     style={{ marginBottom: '5px' }}
                   /><br></br>
        </Box>
      )}
    </Grid>
    <Grid item xs={12} sm={4}>
      <ButtonGroup
        variant="outlined"
        ref={anchorRef}
        aria-label="Button group with a nested menu"
      >
        <Button onClick={handleClick}>
          {options[selectedIndex]}
        </Button>
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          sx={{
            width: "20px !important",
            minWidth: "0px !important",
          }}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom"
                  ? "center top"
                  : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      disabled={index === 2}
                      selected={index === selectedIndex}
                      onClick={(event) =>
                        handleMenuItemClick(event, index)
                      }
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Grid>
  </Grid>
</Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Sample Image Link</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  image: e.target.value,
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}>
  <Typography variant="body2">Select Sender</Typography>
      <Autocomplete
        options={stakeholders}
        getOptionLabel={(option) =>
          `${option.id || ""} - ${option.type || ""} - ${option.name || ""}`
        }
        value={selectedSender}
        onChange={(event, newValue) => {
          setSelectedSender(newValue);
          setAddViewStates({
            ...addViewStates,
            senderId: newValue ? newValue.id : null,
          });
        }}
        onOpen={retrieveStakeholders}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            fullWidth
          />
        )}
        renderTags={() => null}
      />
      {selectedSender && (
        <Box mt={2}>
          <Chip
            label={`ID: ${selectedSender?.id || ""}`}
            style={{ marginBottom: '5px' }}
            onDelete={() => setSelectedSender(null)}
          /><br />
          <Chip
            label={`Type: ${selectedSender?.type || ""}`}
            style={{ marginBottom: '5px' }}
          /><br />
          <Chip
            label={`Name: ${selectedSender?.name || ""}`}
            style={{ marginBottom: '5px' }}
          /><br />
        </Box>
      )}
</Grid>

<Grid item xs={12} sm={2}>
  <Typography variant="body2">Select Receiver</Typography>
  <Autocomplete
    options={stakeholders}
    getOptionLabel={(option) =>
      `${option.id || ""} - ${option.type || ""} - ${option.name || ""}`
    }
    value={selectedReceiver}
    onChange={(event, newValue) => {
      setSelectedReceiver(newValue);
      setAddViewStates({
        ...addViewStates,
        receiverId: newValue ? newValue.id : null,
      });
    }}
    onOpen={retrieveStakeholders}
    renderInput={(params) => (
      <TextField
        {...params}
        variant="outlined"
        size="small"
        fullWidth
      />
    )}
    renderTags={() => null}
  />
  {selectedReceiver && (
    <Box mt={2}>
      <Chip
        label={`ID: ${selectedReceiver?.id || ""}`}
        style={{ marginBottom: '5px' }}
        onDelete={() => setSelectedReceiver(null)}
      /><br />
      <Chip
        label={`Type: ${selectedReceiver?.type || ""}`}
        style={{ marginBottom: '5px' }}
      /><br />
      <Chip
        label={`Name: ${selectedReceiver?.name || ""}`}
        style={{ marginBottom: '5px' }}
      /><br />
    </Box>
  )}
</Grid>


          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Enter Sales Grade</Typography>
            <TextField
              variant="outlined"
              size="small"
              value={addViewStates?.salesGrade}
              fullWidth
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  salesGrade: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Enter Sales Nicotine</Typography>
            <TextField
              variant="outlined"
              size="small"
              value={addViewStates?.salesNicotine}
              type="number"
              fullWidth
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  salesNicotine: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Enter Sales Sugar</Typography>
            <TextField
              variant="outlined"
              size="small"
              value={addViewStates?.salesSugar}
              type="number"
              fullWidth
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  salesSugar: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Sales Origin</Typography>
                  <Autocomplete
                    options={origin}
                    value={selectedOrigin}
                    onChange={(event, newValue) => {
                      const newTerm = newValue ? newValue.value : "";
                      setSelectedOrigin(newTerm);
                      setAddViewStates({
                        ...addViewStates,
                        salesOrigin: newTerm,
                      });
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    )}
                    renderTags={() => null}
                  />
                  {selectedOrigin && (
                    <Box mt={2}>
                      <Chip
                        label={
                          origin.find((term) => term.value === selectedOrigin)
                            ?.label || ""
                        }
                        onDelete={() => setSelectedOrigin("")}
                      />
                    </Box>
                  )}
                </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Enter Sales Crop</Typography>
            <TextField
              variant="outlined"
              size="small"
              value={addViewStates?.salesCrop}
              fullWidth
              type="number"
            onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  salesCrop: e.target.value,
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Enter Date Of Delivery</Typography>
            <TextField
              size="small"
              fullWidth
              variant="outlined"
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  deliveryDate: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Enter Date Of Receive</Typography>
            <TextField
              size="small"
              fullWidth
              variant="outlined"
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  receiveDate: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Enter Courier Information</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  courier: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Enter AWB Number</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  awbNumber: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Enter Remark</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  remark: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Enter Customer Feedback</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  customerFeedback: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Is Sample Approved</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  approved: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Enter SG Approval Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  approvalStatus: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Sample Paid By</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  paidBy: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Status</Typography>
            <TextField
              id="outlined-select-status"
              select
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  status: e.target.value,
                })
              }
            >
              {recordStatus.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        {isNewItemOpen && (
          <>
            {" "}
            <Grid item xs={12} style={{ position: "relative" }}>
              <Typography
                variant="h3"
                align="center"
                style={{
                  color: "#777f87",
                  position: "absolute",
                  top: "10px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  backgroundColor: "#fafafb",
                  padding: "0 10px",
                }}
              >
                New Item Details
              </Typography>
            </Grid>
            <Grid
              container
              spacing="12px"
              style={{
                border: "1px solid #dbd9d9",
                borderRadius: "20px",
                marginTop: "30px",
                padding: "20px",
                boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Grid container xs={12} spacing="8px">
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Tobacco Code</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setItem({
                        ...item,
                        tobaccoCode: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Sales Grade</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setItem({
                        ...item,
                        salesGrade: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Buying Grade</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setItem({
                        ...item,
                        buyingGrade: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Internal Grade</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setItem({
                        ...item,
                        internalGrade: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Select Origin</Typography>
                  <Autocomplete
                    options={origin}
                    value={selectedOrigin}
                    onChange={(event, newValue) => {
                      const newTerm = newValue ? newValue.value : "";
                      setSelectedOrigin(newTerm);
                      setItem({
                        ...item,
                        origin: newTerm,
                      });
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    )}
                    renderTags={() => null}
                  />
                  {selectedOrigin && (
                    <Box mt={2}>
                      <Chip
                        label={
                          origin.find((term) => term.value === selectedOrigin)
                            ?.label || ""
                        }
                        onDelete={() => setSelectedOrigin("")}
                      />
                    </Box>
                  )}
                </Grid>

                {( selectedOrigin==="China" && 
                <Grid item xs={12} sm={2}>
          <Typography variant="body2">Province</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            onChange={(e) =>
              setItem({
                ...item,
                province: e.target.value,
              })
            }
          />
        </Grid>
)}
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Select Type</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    id="outlined-select-type"
                    select
                    onChange={(e) =>
                      setItem({
                        ...item,
                        type: e.target.value,
                      })
                    }
                  >
                    {type.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Select Leaf Form</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    id="outlined-select-leaf-form"
                    select
                    onChange={(e) =>
                      setItem({ ...item, leafForm: e.target.value })
                    }
                  >
                    {leafForm.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Select Color</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    id="outlined-select-color"
                    select
                    onChange={(e) =>
                      setItem({ ...item, color: e.target.value })
                    }
                  >
                    {color.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Crop Year</Typography>
                  <TextField
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setItem({
                        ...item,
                        crop: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Current Location</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    id="outlined-select-storage"
                    value={item?.storage}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        storage: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Nicotine Value</Typography>
                  <TextField
                    size="small"
                    type="number"
                    fullWidth
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        nicotine: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Sugar Value</Typography>
                  <TextField
                    size="small"
                    type="number"
                    fullWidth
                    variant="outlined"
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        sugar: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Moisture Value</Typography>
                  <TextField
                    size="small"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={item?.moisture}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        moisture: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Status</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    id="outlined-select-status"
                    select
                    onChange={(e) =>
                      setItem({
                        ...item,
                        status: e.target.value,
                      })
                    }
                  >
                    {recordStatus.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={2}>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ ml: 1.5, mt: 4, mr: 1 }}
                  onClick={() => {
                    setIsNewItemOpen(false);
                    setItem([]);
                    setSelectedOrigin("");
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ mt: 4 }}
                  onClick={() => {
                    createItemApi(item)
                      .then((response) => {
                        toast.success("Item added successfully.");
                      })
                      .catch((error) => {
                        console.log(error.response.data);
                        toast.error("Something Went Wrong.");
                      });
                    setIsNewItemOpen(false);
                    setItem([]);
                    setSelectedOrigin("");
                  }}
                >
                  Create Item
                </Button>
              </Grid>
            </Grid>{" "}
          </>
        )}
        {isEditItemOpen && (
          <>
            {" "}
            <Grid item xs={12} style={{ position: "relative" }}>
              <Typography
                variant="h3"
                align="center"
                style={{
                  color: "#777f87",
                  position: "absolute",
                  top: "10px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  backgroundColor: "#fafafb",
                  padding: "0 10px",
                }}
              >
                Edit Item {item?.id} Details
              </Typography>
            </Grid>
            <Grid
              container
              spacing="12px"
              style={{
                border: "1px solid #dbd9d9",
                borderRadius: "20px",
                marginTop: "30px",
                padding: "20px",
                boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Grid container xs={12} spacing="8px">
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Tobacco Code</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={item?.tobaccoCode || ""}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        tobaccoCode: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Sales Grade</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={item?.salesGrade || ""}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        salesGrade: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Buying Grade</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={item?.buyingGrade || ""}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        buyingGrade: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Internal Grade</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={item?.internalGrade || ""}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        internalGrade: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Select Origin</Typography>
                  <Autocomplete
                    options={origin}
                    value={selectedOrigin || ""}
                    onChange={(event, newValue) => {
                      const newTerm = newValue ? newValue.value : "";
                      setSelectedOrigin(newTerm);
                      setItem({
                        ...item,
                        origin: newTerm,
                      });
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        label={item?.origin}
                        fullWidth
                      />
                    )}
                    renderTags={() => null}
                  />
                  {selectedOrigin && (
                    <Box mt={2}>
                      <Chip
                        label={
                          origin.find((term) => term.value === selectedOrigin)
                            ?.label || ""
                        }
                        onDelete={() => setSelectedOrigin("")}
                      />
                    </Box>
                  )}
                </Grid>

                {( (selectedOrigin==="China" || item?.origin==="China") && 
                <Grid item xs={12} sm={2}>
          <Typography variant="body2">Province</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            value={item?.province || ""}
            onChange={(e) =>
              setItem({
                ...item,
                province: e.target.value,
              })
            }
          />
        </Grid>
)}

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Select Type</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    label={item?.type || ""}
                    id="outlined-select-type"
                    select
                    onChange={(e) =>
                      setItem({
                        ...item,
                        type: e.target.value,
                      })
                    }
                  >
                    {type.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Select Leaf Form</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    label={item?.leafForm || ""}
                    id="outlined-select-leaf-form"
                    select
                    onChange={(e) =>
                      setItem({ ...item, leafForm: e.target.value })
                    }
                  >
                    {leafForm.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Select Color</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    label={item?.color || ""}
                    id="outlined-select-color"
                    select
                    onChange={(e) =>
                      setItem({ ...item, color: e.target.value })
                    }
                  >
                    {color.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Crop Year</Typography>
                  <TextField
                    size="small"
                    fullWidth
                    value={item?.crop || ""}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        crop: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Current Location</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    id="outlined-select-storage"
                    value={item?.storage || ""}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        storage: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Nicotine Value</Typography>
                  <TextField
                    size="small"
                    type="number"
                    fullWidth
                    value={item?.nicotine || ""}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        nicotine: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Sugar Value</Typography>
                  <TextField
                    size="small"
                    type="number"
                    fullWidth
                    value={item?.sugar || ""}
                    variant="outlined"
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        sugar: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Moisture Value</Typography>
                  <TextField
                    size="small"
                    type="number"
                    fullWidth
                    value={item?.moisture || ""}
                    variant="outlined"
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        moisture: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Status</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    label={item?.status}
                    id="outlined-select-status"
                    select
                    onChange={(e) =>
                      setItem({
                        ...item,
                        status: e.target.value,
                      })
                    }
                  >
                    {recordStatus.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={2}>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ ml: 1.5, mt: 4, mr: 1 }}
                  onClick={() => {
                    setIsEditItemOpen(false);
                    setItem([]);
                    setSelectedOrigin("");
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ mt: 4 }}
                  onClick={() => {
                    updateItemApi(item.id, item)
                      .then((response) => {
                        toast.success("Item edited successfully.");
                      })
                      .catch((error) => {
                        toast.error("Something went wrong.");
                        console.log(error.response.data);
                      });
                    setIsEditItemOpen(false);
                    setItem([]);
                    setSelectedOrigin("");
                  }}
                >
                  Save Item
                </Button>
              </Grid>
            </Grid>{" "}
          </>
        )}
        <Stack direction="row" spacing={1} mt={1}>
          <Button variant="outlined" onClick={() => setAddView(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              const newId = Math.floor(Math.random() * 100000);
              setAddViewStates({ ...addViewStates, id: newId });
              setRows([addViewStates, ...rows]);
              setAddView(false);

              createSampleApi(addViewStates)
                .then((response) => {
                  toast.success("Sample added successfully.");
                  window.location.reload();
                  navigate("/sample-view");
                })
                .catch((error) => {
                  toast.error("Something went wrong.");
                  console.log(error.response.data);
                });
            }}
          >
            Add
          </Button>
        </Stack>
      </Stack>

      {openSampleDialog && (
        <SampleDialog
          open={openSampleDialog}
          setOpen={setOpenSampleDialog}
          sampleOpt={sampleOpt}
          setSampleOpt={setSampleOpt}
        />
      )}
    </>
  );
};

export default AddSample;
