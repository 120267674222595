import { ReloadOutlined } from "@ant-design/icons";
import {
  Button,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { createCostApi, eurUsdExchangeApi } from "../../../components/api/CostApiService";
import { currencies, recordStatus } from "../../../utils/menu-items";

const AddCost = (props) => {
  const navigate = useNavigate();
  const { setAddViewStates, addViewStates, rows, setRows, setAddView } = props;

  useEffect(() => {
    setAddViewStates({
      ...addViewStates,
      basePriceCurrency: "USD",
      freightCurrency: "USD",
      insuranceCurrency: "USD",
      storageCostCurrency: "USD",
      financeCostCurrency: "USD",
      othersCurrency: "USD",
      firstCommisionCurrency: "USD",
      secondCommisionCurrency: "USD",
      thirdCommisionCurrency: "USD",
      profitCurrency: "USD",
    });
  }, []);

  const initialCurrencyState = {
    basePriceCurrency: "USD",
    freightCurrency: "USD",
    insuranceCurrency: "USD",
    storageCostCurrency: "USD",
    financeCostCurrency: "USD",
    othersCurrency: "USD",
    firstCommisionCurrency: "USD",
    secondCommisionCurrency: "USD",
    thirdCommisionCurrency: "USD",
    profitCurrency: "USD",
  };
  
  const [currenciesState, setCurrenciesState] = useState(initialCurrencyState);
  
  const addViewStatesFieldMap = {
    basePriceCurrency: "basePrice",
    freightCurrency: "freightCost",
    insuranceCurrency: "insuranceCost",
    storageCostCurrency: "storageCost",
    financeCostCurrency: "financeCost",
    othersCurrency: "others",
    firstCommisionCurrency: "firstCommision",
    secondCommisionCurrency: "secondCommision",
    thirdCommisionCurrency: "thirdCommision",
    profitCurrency: "profit",
  };

  const handleCurrencyExchange = async (field, amount) => {
    const addViewStatesField = addViewStatesFieldMap[field];
    const currentCurrency = addViewStates[field];
    const targetCurrency = currentCurrency === "EUR" ? "USD" : "EUR";
  
    try {
      const response = await eurUsdExchangeApi(currentCurrency, amount);
      const updatedCost = response?.data;
  
      setAddViewStates((prevCost) => ({
        ...prevCost,
        [addViewStatesField]: updatedCost,
        [field]: targetCurrency,
      }));
    } catch (error) {
      setAddViewStates((prevCost) => ({
        ...prevCost,
        [addViewStatesField]: prevCost[addViewStatesField],
        [field]: prevCost[field],
      }));
      console.error(error.response.data);
    }
  };


  const calculateTotalCost = () => {
    const totalCost =
      (parseFloat(addViewStates?.basePrice) || 0) +
      (parseFloat(addViewStates?.freightCost) || 0) +
      (parseFloat(addViewStates?.insuranceCost) || 0) +
      (parseFloat(addViewStates?.storageCost) || 0) +
      (parseFloat(addViewStates?.financeCost) || 0) +
      (parseFloat(addViewStates?.others) || 0) +
      (parseFloat(addViewStates?.firstCommision) || 0) +
      (parseFloat(addViewStates?.secondCommision) || 0) +
      (parseFloat(addViewStates?.thirdCommision) || 0);
    setAddViewStates({ ...addViewStates, totalCost });
  };

  const calculateTotalCostWithProfit = () => {
    const totalOfferCost =
      (parseFloat(addViewStates?.basePrice) || 0) +
      (parseFloat(addViewStates?.freightCost) || 0) +
      (parseFloat(addViewStates?.insuranceCost) || 0) +
      (parseFloat(addViewStates?.storageCost) || 0) +
      (parseFloat(addViewStates?.financeCost) || 0) +
      (parseFloat(addViewStates?.others) || 0) +
      (parseFloat(addViewStates?.firstCommision) || 0) +
      (parseFloat(addViewStates?.secondCommision) || 0) +
      (parseFloat(addViewStates?.thirdCommision) || 0) +
      (parseFloat(addViewStates?.profit) || 0);
    setAddViewStates({ ...addViewStates, totalOfferCost });
  };

  return (
    <>
      <Stack direcion="column">
        <Typography variant="body1" mb={2}>
          Create New Cost
        </Typography>
        <Grid container xs={12} spacing="8px">
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Base Price</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={addViewStates?.basePrice}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  basePrice: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Base Price Currency</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              label={addViewStates?.basePriceCurrency}
              value={addViewStates?.basePriceCurrency}
              id="outlined-select-incoterm-price-currency"
              select
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  basePriceCurrency: e.target.value,
                })
              }
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={1}>
  <Typography variant="body2">Exchange</Typography>
  <Button
    variant="outlined"
    disabled={
      addViewStates?.basePriceCurrency === "USD"
    }
    onClick={() => handleCurrencyExchange("basePriceCurrency", addViewStates?.basePrice || 0.0)}
  >
    $
  </Button>
  <Button
    variant="outlined"
    disabled={
      addViewStates?.basePriceCurrency === "EUR"
    }
    onClick={() => handleCurrencyExchange("basePriceCurrency", addViewStates?.basePrice || 0.0)}
  >
    €
  </Button>
</Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Price Per Container</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={addViewStates?.pricePerContainer}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  pricePerContainer: e.target.value,
                  freightCost: (e.target.value / addViewStates?.kgPerContainer).toFixed(2),
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Kg Per Container</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={addViewStates?.kgPerContainer}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  kgPerContainer: e.target.value,
                  freightCost: (addViewStates?.pricePerContainer / e.target.value).toFixed(2),
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Freight Cost Per Kg</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={addViewStates?.freightCost}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  freightCost: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <Typography variant="body2">
              Select Freight Cost Currency
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              label={addViewStates?.freightCurrency}
              value={addViewStates?.freightCurrency}
              id="outlined-select-freight-cost-currency"
              select
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  freightCurrency: e.target.value,
                })
              }
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={1}>
  <Typography variant="body2">Exchange</Typography>
  <Button
    variant="outlined"
    disabled={
      addViewStates?.freightCurrency === "USD"
    }
    onClick={() => handleCurrencyExchange("freightCurrency", addViewStates?.freightCost || 0.0)}
  >
    $
  </Button>
  <Button
    variant="outlined"
    disabled={
      addViewStates?.freightCurrency === "EUR"
    }
    onClick={() => handleCurrencyExchange("freightCurrency", addViewStates?.freightCost || 0.0)}
  >
    €
  </Button>
</Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Freight Offer By</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  freightOfferBy: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Feight Offer Date</Typography>
            <TextField
              size="small"
              fullWidth
              variant="outlined"
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  freightOfferDate: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Insurance Cost</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={addViewStates?.insuranceCost}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  insuranceCost: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Insurance Cost Currency</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-insurance-cost-currency"
              select
              label={addViewStates?.insuranceCurrency}
              value={addViewStates?.freightCurrency}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  insuranceCurrency: e.target.value,
                })
              }
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={1}>
  <Typography variant="body2">Exchange</Typography>
  <Button
    variant="outlined"
    disabled={
      addViewStates?.insuranceCurrency === "USD"
    }
    onClick={() => handleCurrencyExchange("insuranceCurrency", addViewStates?.insuranceCost || 0.0)}
  >
    $
  </Button>
  <Button
    variant="outlined"
    disabled={
      addViewStates?.insuranceCurrency === "EUR"
    }
    onClick={() => handleCurrencyExchange("insuranceCurrency", addViewStates?.insuranceCost || 0.0)}
  >
    €
  </Button>
</Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Storage Cost</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={addViewStates?.storageCost}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  storageCost: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Storage Cost Currency</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-storage-cost-currency"
              select
              label={addViewStates?.storageCostCurrency}
              value={addViewStates?.freightCurrency}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  storageCostCurrency: e.target.value,
                })
              }
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={1}>
  <Typography variant="body2">Exchange</Typography>
  <Button
    variant="outlined"
    disabled={
      addViewStates?.storageCostCurrency === "USD"
    }
    onClick={() => handleCurrencyExchange("storageCostCurrency", addViewStates?.storageCost || 0.0)}
  >
    $
  </Button>
  <Button
    variant="outlined"
    disabled={
      addViewStates?.storageCostCurrency === "EUR"
    }
    onClick={() => handleCurrencyExchange("storageCostCurrency", addViewStates?.storageCost || 0.0)}
  >
    €
  </Button>
</Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Finance Cost</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={addViewStates?.financeCost}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  financeCost: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Finance Cost Currency</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-finance-cost-currency"
              select
              label={addViewStates?.financeCostCurrency}
              value={addViewStates?.freightCurrency}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  financeCostCurrency: e.target.value,
                })
              }
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={1}>
  <Typography variant="body2">Exchange</Typography>
  <Button
    variant="outlined"
    disabled={
      addViewStates?.financeCostCurrency === "USD"
    }
    onClick={() => handleCurrencyExchange("financeCostCurrency", addViewStates?.financeCost || 0.0)}
  >
    $
  </Button>
  <Button
    variant="outlined"
    disabled={
      addViewStates?.financeCostCurrency === "EUR"
    }
    onClick={() => handleCurrencyExchange("financeCostCurrency", addViewStates?.financeCost || 0.0)}
  >
    €
  </Button>
</Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Other Costs</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={addViewStates?.others}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  others: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Other Costs Currency</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-other-cost-currency"
              select
              label={addViewStates?.othersCurrency}
              value={addViewStates?.freightCurrency}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  othersCurrency: e.target.value,
                })
              }
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={1}>
  <Typography variant="body2">Exchange</Typography>
  <Button
    variant="outlined"
    disabled={
      addViewStates?.othersCurrency === "USD"
    }
    onClick={() => handleCurrencyExchange("othersCurrency", addViewStates?.others || 0.0)}
  >
    $
  </Button>
  <Button
    variant="outlined"
    disabled={
      addViewStates?.othersCurrency === "EUR"
    }
    onClick={() => handleCurrencyExchange("othersCurrency", addViewStates?.others || 0.0)}
  >
    €
  </Button>
</Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Other Costs Remark</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  othersRemark: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">First Commision</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={addViewStates?.firstCommision}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  firstCommision: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <Typography variant="body2">First Commision Currency</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-status"
              select
              label={addViewStates?.firstCommisionCurrency}
              value={addViewStates?.freightCurrency}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  firstCommisionCurrency: e.target.value,
                })
              }
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={1}>
  <Typography variant="body2">Exchange</Typography>
  <Button
    variant="outlined"
    disabled={
      addViewStates?.firstCommisionCurrency === "USD"
    }
    onClick={() => handleCurrencyExchange("firstCommisionCurrency", addViewStates?.firstCommision || 0.0)}
  >
    $
  </Button>
  <Button
    variant="outlined"
    disabled={
      addViewStates?.firstCommisionCurrency === "EUR"
    }
    onClick={() => handleCurrencyExchange("firstCommisionCurrency", addViewStates?.firstCommision || 0.0)}
  >
    €
  </Button>
</Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Second Commision</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={addViewStates?.secondCommision}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  secondCommision: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Second Commision Currency</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-status"
              select
              label={addViewStates?.secondCommisionCurrency}
              value={addViewStates?.freightCurrency}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  secondCommisionCurrency: e.target.value,
                })
              }
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={1}>
  <Typography variant="body2">Exchange</Typography>
  <Button
    variant="outlined"
    disabled={
      addViewStates?.secondCommisionCurrency === "USD"
    }
    onClick={() => handleCurrencyExchange("secondCommisionCurrency", addViewStates?.secondCommision || 0.0)}
  >
    $
  </Button>
  <Button
    variant="outlined"
    disabled={
      addViewStates?.secondCommisionCurrency === "EUR"
    }
    onClick={() => handleCurrencyExchange("secondCommisionCurrency", addViewStates?.secondCommision || 0.0)}
  >
    €
  </Button>
</Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Third Commision</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={addViewStates?.thirdCommision}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  thirdCommision: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Third Commision Currency</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-status"
              select
              label={addViewStates?.thirdCommisionCurrency}
              value={addViewStates?.freightCurrency}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  thirdCommisionCurrency: e.target.value,
                })
              }
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={1}>
  <Typography variant="body2">Exchange</Typography>
  <Button
    variant="outlined"
    disabled={
      addViewStates?.secondCommisionCurrency === "USD"
    }
    onClick={() => handleCurrencyExchange("secondCommisionCurrency", addViewStates?.secondCommision || 0.0)}
  >
    $
  </Button>
  <Button
    variant="outlined"
    disabled={
      addViewStates?.secondCommisionCurrency === "EUR"
    }
    onClick={() => handleCurrencyExchange("secondCommisionCurrency", addViewStates?.secondCommision || 0.0)}
  >
    €
  </Button>
</Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">
              Total Unit Cost Before Profit
            </Typography>
            <Grid container spacing={1} alignItems="center">
              <Grid Grid item xs={10} sm={8}>
                <TextField
                  type="number"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={addViewStates?.totalCost || ""}
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) =>
                    setAddViewStates({
                      ...addViewStates,
                      totalCost: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={1} sm={1}>
                <Button
                  style={{ padding: 0, color: "red" }}
                  onClick={calculateTotalCost}
                >
                  <ReloadOutlined style={{ fontSize: "16px" }} />
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Profit</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={addViewStates?.profit}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  profit: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Profit Currency</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-profit-currency"
              select
              label={addViewStates?.profitCurrency}
              value={addViewStates?.freightCurrency}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  profitCurrency: e.target.value,
                })
              }
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={1}>
  <Typography variant="body2">Exchange</Typography>
  <Button
    variant="outlined"
    disabled={
      addViewStates?.profitCurrency === "USD"
    }
    onClick={() => handleCurrencyExchange("profitCurrency", addViewStates?.profit || 0.0)}
  >
    $
  </Button>
  <Button
    variant="outlined"
    disabled={
      addViewStates?.profitCurrency === "EUR"
    }
    onClick={() => handleCurrencyExchange("profitCurrency", addViewStates?.profit || 0.0)}
  >
    €
  </Button>
</Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">
              Total Unit Cost After Profit
            </Typography>
            <Grid container spacing={1} alignItems="center">
              <Grid Grid item xs={10} sm={9}>
                <TextField
                  type="number"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={addViewStates?.totalOfferCost || ""}
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) =>
                    setAddViewStates({
                      ...addViewStates,
                      totalOfferCost: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={1} sm={1}>
                <Button
                  style={{ padding: 0, color: "red" }}
                  onClick={calculateTotalCostWithProfit}
                >
                  <ReloadOutlined style={{ fontSize: "16px" }} />
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Remark</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  remark: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-status"
              select
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  recordStatus: e.target.value,
                })
              }
            >
              {recordStatus.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Stack direction="row" spacing={1} mt={1}>
          <Button variant="outlined" onClick={() => setAddView(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              const newId = Math.floor(Math.random() * 100000);
              setAddViewStates({ ...addViewStates, id: newId });
              setRows([addViewStates, ...rows]);
              setAddView(false);

              createCostApi(addViewStates)
                .then((response) => {
                  toast.success("Cost added successfully.");
                  window.location.reload();
                  navigate("/cost-view");
                })
                .catch((error) => {
                  toast.error("Something went wrong.");
                  console.log(error.response.data);
                });
            }}
          >
            Add
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default AddCost;
