import {
  Button,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { createItemApi } from "../../../components/api/ItemApiService";
import {
  leafForm,
  origin,
  recordStatus,
  type,
} from "../../../utils/menu-items";
import ItemDialog from "./Dialogs/ItemDialog";

const items = [
  { id: 152456, label: "PT Namora" },
  { id: 5856, label: "ULT Dubai/Mr Lin" },
  { id: 741856, label: "Khatoco" },
  { id: 12854, label: "Wan Ting" },
];

const AddItem = (props) => {
  const navigate = useNavigate();
  const { setAddViewStates, addViewStates, rows, setRows, setAddView } = props;

  const [itemOpt, setItemOpt] = useState(items);

  const [openItemDialog, setOpenItemDialog] = useState(false);

    useEffect(
      () =>
        setAddViewStates({
          ...addViewStates,
          moisture: 12.5,
        }),
      [],
    );

  return (
    <>
      <Stack direcion="column">
        <Typography variant="body1" mb={2}>
          Create New Item
        </Typography>
        <Grid container xs={12} spacing="8px">
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Enter Tobacco Code</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  tobaccoCode: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Enter Sales Grade</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  salesGrade: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Enter Buying Grade</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  buyingGrade: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Internal Grade</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  internalGrade: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Select Origin</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-origin"
              select
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  origin: e.target.value,
                  storage: e.target.value,
                })
              }
            >
              {origin.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {( addViewStates?.origin ==="China" && 
                <Grid item xs={12} sm={2}>
          <Typography variant="body2">Province</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            onChange={(e) =>
              setAddViewStates({
                ...addViewStates,
                province: e.target.value,
              })
            }
          />
        </Grid>
)}

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Select Type</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-type"
              select
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  type: e.target.value,
                })
              }
            >
              {type.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Select Leaf Form</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-leaf-form"
              select
              onChange={(e) =>
                setAddViewStates({ ...addViewStates, leafForm: e.target.value })
              }
            >
              {leafForm.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Enter Crop Year</Typography>
            <TextField
              size="small"
              fullWidth
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  crop: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Current Location</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-storage"
              value={addViewStates?.storage}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  storage: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Nicotine</Typography>
            <TextField
              size="small"
              type="number"
              fullWidth
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  nicotine: e.target.value,
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Sugar</Typography>
            <TextField
              size="small"
              type="number"
              fullWidth
              variant="outlined"
            onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  sugar: e.target.value,
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Moisture</Typography>
            <TextField
              size="small"
              type="number"
              fullWidth
              variant="outlined" 
              value={addViewStates?.moisture}  
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  moisture: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              label={"ACTIVE"}
              id="outlined-select-status"
              select
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  status: e.target.value,
                })
              }
            >
              {recordStatus.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Stack direction="row" spacing={1} mt={1}>
          <Button variant="outlined" onClick={() => setAddView(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              const newId = Math.floor(Math.random() * 100000);
              setAddViewStates({ ...addViewStates, id: newId });
              setRows([addViewStates, ...rows]);
              setAddView(false);

              createItemApi(addViewStates)
                .then((response) => {
                  toast.success("Item added successfully.");
                  window.location.reload();
                  navigate("/item-view");
                })
                .catch((error) => {
                  toast.error("Something went wrong.", error.response.data);
                  console.log(error.response.data);
                });
            }}
          >
            Add
          </Button>
        </Stack>
      </Stack>

      {openItemDialog && (
        <ItemDialog
          open={openItemDialog}
          setOpen={setOpenItemDialog}
          itemOpt={itemOpt}
          setItemOpt={setItemOpt}
        />
      )}
    </>
  );
};

export default AddItem;
