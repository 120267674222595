import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import React, { useState } from "react";
import { updateFreightOfferApi } from "../../../components/api/FreightOfferApiService";
import { currencies, incoterms, portsOfDischarge, portsOfLoading, recordStatus, status, units } from "../../../utils/menu-items";
import toast from "react-hot-toast";

const EditFreightOffer = (props) => {
  const { editFreightOffer, setEditFreightOffer } = props;


  return (
    <>
      <Stack direcion="column">
        <Typography variant="body1" mb={2}>
          Edit Freight Offer
        </Typography>
        <Grid container item xs={12} spacing="8px">

        <Grid item xs={12} sm={2}>
            <Typography variant="body2">Forwarder</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-forwarder"
              value={editFreightOffer?.data?.forwarder}
              onChange={(e) =>
                setEditFreightOffer({
                  ...editFreightOffer,
                  data: {
                    ...editFreightOffer.data,
                    forwarder: e.target.value,
                  },
                })
              }
            >
            </TextField>
          </Grid>

        <Grid item xs={12} sm={2}>
            <Typography variant="body2">Point Of Loading</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-pol"
              select
              value={editFreightOffer?.data?.pointOfLoading}
              onChange={(e) =>
                setEditFreightOffer({
                  ...editFreightOffer,
                  data: {
                    ...editFreightOffer.data,
                    pointOfLoading: e.target.value,
                  },
                })
              }
            >
              {portsOfLoading.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Point Of Discharge</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-pod"
              select
              value={editFreightOffer?.data?.pointOfDischarge}
              onChange={(e) =>
                setEditFreightOffer({
                  ...editFreightOffer,
                  data: {
                    ...editFreightOffer.data,
                    pointOfDischarge: e.target.value,
                  },
                })
              }
            >
              {portsOfDischarge.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Incoterm</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-incoterm"
              select
              value={editFreightOffer?.data?.terms}
              onChange={(e) =>
                setEditFreightOffer({
                  ...editFreightOffer,
                  data: {
                    ...editFreightOffer.data,
                    terms: e.target.value,
                  },
                })
              }
            >
              {incoterms.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          </Grid>
         </Stack>
          <Stack direcion="column" style={{marginBottom:"10px", marginTop:"10px"}}>
          <Grid container xs={12} spacing="8px">    

            <>
        <Grid item xs={12} sm={1}>
          <Typography variant="body2">20" Rate</Typography>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            type="number"
            value={editFreightOffer?.data?.twentyFtRate}
            onWheel={(e) => e.target.blur()}
            onChange={(e) =>
              setEditFreightOffer({
                ...editFreightOffer,
                data: {
                  ...editFreightOffer.data,
                  twentyFtRate: e.target.value,
                },
              })
            }
          />
        </Grid>
        
        <Grid item xs={12} sm={1}>
        <Typography variant="body2">
          20 Ft Currency
        </Typography>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          id="outlined-select-20ft-currency"
          select
          value={editFreightOffer?.data?.twentyFtCurrency}
          onChange={(e) =>
            setEditFreightOffer({
              ...editFreightOffer,
              data: {
                ...editFreightOffer.data,
                twentyFtCurrency: e.target.value,
              },
            })
          }
        >
          {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      </>
    
            <>
        <Grid item xs={12} sm={1}>
          <Typography variant="body2">40" Rate</Typography>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            type="number"
            value={editFreightOffer?.data?.fortyFtRate}
            onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setEditFreightOffer({
                  ...editFreightOffer,
                  data: {
                    ...editFreightOffer.data,
                    fortyFtRate: e.target.value,
                  },
                })
              }
          />
        </Grid>
        <Grid item xs={12} sm={1}>
            <Typography variant="body2">
              40 Ft Currency
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-40ft-currency"
              select
              value={editFreightOffer?.data?.fortyFtCurrency}
              onChange={(e) =>
                setEditFreightOffer({
                  ...editFreightOffer,
                  data: {
                    ...editFreightOffer.data,
                    fortyFtCurrency: e.target.value,
                  },
                })
              }
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

</>

            <>
        <Grid item xs={12} sm={1}>
          <Typography variant="body2">40HC Rate</Typography>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            type="number"
            value={editFreightOffer?.data?.fortyHcRate}
            onWheel={(e) => e.target.blur()}
            onChange={(e) =>
              setEditFreightOffer({
                ...editFreightOffer,
                data: {
                  ...editFreightOffer.data,
                  fortyHcRate: e.target.value,
                },
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={1}>
            <Typography variant="body2">
              40 HC Currency
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-40hc-currency"
              select
              value={editFreightOffer?.data?.fortyHcCurrency}
              onChange={(e) =>
                setEditFreightOffer({
                  ...editFreightOffer,
                  data: {
                    ...editFreightOffer.data,
                    fortyHcCurrency: e.target.value,
                  },
                })
              }
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

</>

            <>
        <Grid item xs={12} sm={1}>
          <Typography variant="body2" >FTL Rate</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            type="number"
            value={editFreightOffer?.data?.ftlRate}
            onWheel={(e) => e.target.blur()}
            onChange={(e) =>
              setEditFreightOffer({
                ...editFreightOffer,
                data: {
                  ...editFreightOffer.data,
                  ftlRate: e.target.value,
                },
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={1}>
            <Typography variant="body2">
              FTL Currency
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-40hc-currency"
              select
              value={editFreightOffer?.data?.ftlCurrency}
              onChange={(e) =>
                setEditFreightOffer({
                  ...editFreightOffer,
                  data: {
                    ...editFreightOffer.data,
                    ftlCurrency: e.target.value,
                  },
                })
              }
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

</>
      </Grid>
      </Stack>
      <Stack direcion="column">
      <Grid container xs={12} spacing="8px">   
          <Grid item xs={12} sm={2}>
          <Typography variant="body2">Valid From</Typography>
          <TextField
            id="date"
            type="date"
            inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
            InputLabelProps={{
              shrink: true,
            }}
            onKeyDown={(e) => e.preventDefault()}
            variant="outlined"
            size="small"
            fullWidth
            value={
              editFreightOffer?.data?.validFrom
                ? format(
                    parseISO(editFreightOffer.data.validFrom),
                    "yyyy-MM-dd",
                  )
                : ""
            }
            onChange={(e) =>
              setEditFreightOffer({
                ...editFreightOffer,
                data: {
                  ...editFreightOffer.data,
                  validFrom: e.target.value
                    ? e.target.value + "T00:00:00.000+00:00"
                    : null,
                },
              })
            }
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Valid To</Typography>
          <TextField
            id="date"
            type="date"
            inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
            InputLabelProps={{
              shrink: true,
            }}
            onKeyDown={(e) => e.preventDefault()}
            variant="outlined"
            size="small"
            fullWidth
            value={
              editFreightOffer?.data?.validTo
                ? format(
                    parseISO(editFreightOffer.data.validTo),
                    "yyyy-MM-dd",
                  )
                : ""
            }
            onChange={(e) =>
              setEditFreightOffer({
                ...editFreightOffer,
                data: {
                  ...editFreightOffer.data,
                  validTo: e.target.value
                    ? e.target.value + "T00:00:00.000+00:00"
                    : null,
                },
              })
            }
          />
        </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Transit Time</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={editFreightOffer?.data?.transitTime}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setEditFreightOffer({
                  ...editFreightOffer,
                  data: {
                    ...editFreightOffer.data,
                    transitTime: e.target.value,
                  },
                })
              }
            />
          </Grid>
    
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Remark</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editFreightOffer?.data?.remark}
              onChange={(e) =>
                setEditFreightOffer({
                  ...editFreightOffer,
                  data: {
                    ...editFreightOffer.data,
                    remark: e.target.value,
                  },
                })
              }
            />
          </Grid>
    
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Carrier</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editFreightOffer?.data?.carrier}
              onChange={(e) =>
                setEditFreightOffer({
                  ...editFreightOffer,
                  data: {
                    ...editFreightOffer.data,
                    carrier: e.target.value,
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Record Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-status"
              select
              value={editFreightOffer?.data?.recordStatus}
              onChange={(e) =>
                setEditFreightOffer({
                  ...editFreightOffer,
                  data: {
                    ...editFreightOffer.data,
                    recordStatus: e.target.value,
                  },
                })
              }
            >
              {recordStatus .map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Stack direction="row" spacing={1} mt={1}>
          <Button
            variant="outlined"
            onClick={() =>
              setEditFreightOffer({ visible: false, data: null, index: null })
            }
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              updateFreightOfferApi(editFreightOffer?.data?.id, editFreightOffer?.data)
                .then((response) => {
                  toast.success("FreightOffer edited successfully.");
                  window.location.reload();

                  setEditFreightOffer({ visible: false, data: null, index: null });
                })
                .catch((error) => {
                  toast.error("Something went wrong.");
                  console.log(error.response.data);
                });
            }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              editFreightOffer.data.recordStatus = "PASSIVE";
              updateFreightOfferApi(editFreightOffer.data.id, editFreightOffer.data)
                .then((response) => {
                  toast.success("Freight offer deleted successfully.");
                  window.location.reload();
                  setEditFreightOffer({ visible: false, data: null, index: null });
                })
                .catch((error) => {
                  toast.error("Something went wrong.");
                  console.log(error.response.data);
                });
            }}
          >
            Delete
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default EditFreightOffer;